// Swedish
// use {{ $t('TRANSLATOR.SELECT') }}
export const locale = {
  ERRORS: {
    HEADERS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Mall saknas',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'Föreningar saknas',
      ERR_TODO_SET_DATE: 'Datum saknas',
      ERR_TODO_MISSING_SUBTASKS: 'Åtgärd saknas',
      ERR_REFUND_AMOUNT_ABOVE_0: 'Felaktig summa',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'Otillåten åtgärd',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Otillåten åtgärd',
      ERR_PENDING_PAYMENTS_MEMBER: 'Saknas betalning',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'Inga mottagare',
      ERR_MEMBER_ALREADY_EXISTS: 'Medlem finns redan',
      ERR_EMAIL_ALREADY_REGISTERED: 'E-post finns redan',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'SKV Period saknas',
      ERR_MEMBER_SET_REG: 'Felaktig handling',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'Medlemskap saknas',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Maximala biljetter uppnått',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'Medlemskap saknas',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Okänt fel',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'Endast medlemmar',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'Aktivt medlemskap',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Ej tillåtet',
      ERR_FORBIDDEN: 'Ej tillåtet',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'Bekräftelsesida saknas',
      ERR_409_GROUP: 'Tillgång till moderförening',
      ERR_RENEWS_HAVE_ERRORS: 'Fel i förnyelser',
      ERR_MULTIPLE_WORKSHEETS: 'Flera arbetsblad',
      ERR_IMPORT_TOO_MANY_ROWS: 'För många rader',
    },
    TEXTS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Inbjudan till system mallen har ej ställts in. Gå till System -> Användarmallar -> Inbjudan till system.',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'Du måste ange minst en förening som skall ta emot ärendet.',
      ERR_TODO_SET_DATE: 'Du måste ange start och slutdatum för ärendet.',
      ERR_TODO_MISSING_SUBTASKS: 'Du måste skapa minst en åtgärd i ärendet.',
      ERR_REFUND_AMOUNT_ABOVE_0: 'Återbetalningssumman måste överstiga 0 samt vara mindre än totalsumman för ordern.',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'En medlem måste ha minst 1 medlemskap.',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Medlemskap med koppling till faktura kan ej tas bort.',
      ERR_PENDING_PAYMENTS_MEMBER: 'Ditt medlemskap är obetalat och därför ej tillåten att logga in. Vänta minst 5 minuter och registera dig igen med betalning.',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'Inga mottagare har valts. Utför en sökning för att bestämma vilka mottagare som ska få utskicket.',
      ERR_MEMBER_ALREADY_EXISTS: 'Denna medlem existerar redan',
      ERR_EMAIL_ALREADY_REGISTERED: 'En användare med denna e-post är redan registrerad',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'Medlemmen saknar Skatteverket period (SKV Period)',
      ERR_MEMBER_SET_REG: 'Att sätta medlemmen som "pågående registrering" är inte tillåtet',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'För att kunna köpa biljett i detta evenemang krävs det att du har ett aktivt betalat medlemskap. Förnya ditt medlemskap för att kunna fortsätta.',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Du har uppnått gränsen för hur många biljetter man får köpa i detta evenemang.',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'För att kunna gå med i detta evenemang måste du ha ett aktivt, betalat medlemskap i föreningen som har evenemanget.',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Ett oväntat fel har uppstått. Kontakta evenemangsansvarig och bifoga följande text för snabbare hantering: ',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'Detta evenemang kräver att man är medlem för att gå med',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'Du saknar aktivt medlemskap i ditt konto.',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Det är inte tillåtet att gå med i detta evenemang.',
      ERR_FORBIDDEN: 'Du har inte tillräckliga rättigheter för att utföra denna åtgärd.',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'Alla formulär måste ha en bekräftelsesida. Vänligen skapa en bekräftelsesida för detta formulär.',
      ERR_409_GROUP: 'Du kan inte ta bort en underförening när gruppen har tillgång till moderföreningen.',
      ERR_RENEWS_HAVE_ERRORS: 'Det finns rader med fel (röda) i tabellen. Du kan inte skapa fakturor på felaktiga förnyelser.',
      ERR_MULTIPLE_WORKSHEETS: 'Filen får maximalt innehålla 1 arbetsblad. Ta bort dolda eller inaktiva arbetsblad.',
      ERR_IMPORT_TOO_MANY_ROWS: 'Importen kan maximalt stödja 100 000 rader. Ta bort överflödiga rader.',
    },
  },

  VALIDATIONS: {
    ENTER_PASSWORD: 'Ange lösenord, minst 6 tecken'
  },

  BANKID: {
    SCAN_QR: 'Scanna QR koden i BankID',
    TIMEOUT: 'Inloggningen tog för lång tid. Ladda om sidan.',
    COMPLETE: 'BankID Signerat!',
    FAILED: 'Fel från BankID',
    OPEN_BANKID: 'Öppna BankID',
  },

  AUTH: {
    ADMINISTRATOR: 'Administratör',
    MENU: {
      DONATIONS: 'Donationer',
      CUSTOMERS: 'Kunder',
      MEMBERS: 'MEDLEMMAR',
      MEMBERS_CONTROL: 'Medlemmar',
      HISTORY: 'Händelselogg',
      MEMBER_REGISTRATIONS: 'Medlemsansökningar',
      DASHBOARD: 'Dashboard',
      TODO: 'Ärendehantering',
      ERROR: 'Felrapport',
      EVENT: 'Evenemang',
      REPORTS: 'Rapporter',
      MEMBER_EDITOR: 'Medlemsredigering',
      QUICKSEARCH: 'Snabbsök',
      ADV_SEARCH: 'Avancerad Sök',
      DUPLICATES: 'Dubbletter',
      SPAR: 'SPAR',
      IMPORT: 'Importera',
      STICKPROV: 'Stickprover',
      LADOK: 'LADOK',
      ATTRIBUTES: 'Attribut',
      RENEWS: 'Förnyelser',
      RENEWS_DEPRECATED: 'Förnyelser (Gammal länk)',
      MUCF: 'MUCF',
      SETTINGS: 'Inställningar',
      REGISTERSETUP: 'Registreringswizard',
      FORMS: 'Formulär',
      PERIODS: 'Perioder',
      USERS: 'Användare',
      SUBCOMPANY: 'Underföreningar',
      FILES: 'Filer',
      SHOPITEM: 'Butik/Medlemskap',
      BOOK: 'Bokföring',
      SENDOUT: 'Utskick',
      TEMPLATES: 'Mallar',
      SUPPORT: 'Support',
      BILLING: 'Kostnader',
      COMPANIES: 'Föreningar',
      LANGUAGE: 'Språk',
      SYSTEM: 'System',
      IMPORTCOMPANIES: 'Import föreningar',
      APIKEYS: 'API: Nycklar',
      PAYMENTS: 'Betalningar',
      TRANSACTIONS: 'Transaktioner',
      SMS: 'SMS',
      GRANT: 'Bidrag',
      PAGE: 'Sidor',
      REDOC: 'API: Dokumentation',
      ADMIN: 'Administration',
      ORDERS: 'Orderhistorik',
      INVOICES: 'Fakturor',
      FORTNOX_INVOICES: 'Fortnox Fakturor',
      COUPONS: 'Kuponger (BETA)',
      JOBS: 'Körningar',
      EMAILS: 'E-posthändelser',
      EDUCATION: 'Utbildningar',
      THS_INSTANCES: 'Instanser',
      THS_LOGS: 'THS Loggar',
      FAITH: 'Samfund',
      COMMUNICATION: 'Kommunikation',
      NOTES: 'Anteckningar',
      USER_PERMISSIONS: 'Användarrättigheter',
      INSTRUMENT_TYPES: 'Instrumenttyper',
      SWISH_MERCHANT: 'Swish Handel',
      SUPPORT_ADMIN: 'Support Admin',
      KVITTO: 'Underlagstjänst'
    }
  },

  BOILERPLATE: {
    TEXTAREA: 'Prisma model source',
    BOILERPLATE: 'Boilerplate',
    PRISMA_BOILERPLATE: 'Prisma Boilerplate',
  },

  DASHBOARD: {
    MEMBERS: 'Nya medlemmar',
    MEMBERSHIPS: 'Medlemskap',
    PERIOD_MEMBERSHIPS: 'Medlemskap vald period',
    DATE_MEMBERSHIPS: 'Medlemskap inom datum',
    MUCF_6_25: 'MUCF 6-25 år',
    MUCF_TOTAL: 'MUCF Total',
    COUNT: 'Beräkning',
    SKV_INTAG: 'Skatteverket Intag'
  },

  MUCF: {
    MUCF_GUIDE: 'MUCF Guiden',
    SETTINGS: 'Inställningar',
    SEARCH_COMPANY: 'Sök förening',
    NUM_ROWS: 'Antal rader'
  },

  MEMBER_EDUCATION: {
    CREATE_SOURCES: {
      LADOK: 'LADOK',
      ADMIN: 'Administratör',
      MEMBER: 'Medlem'
    },
    CREATING: 'Kopplar utbildning',
    EDITING: 'Kopplad utbildning',
    ID: 'Id',
    CREATED_AT: 'Kopplad datum',
    SOURCE: 'Källa',
    EDUCATION_ID: 'Utbildning',
    MEMBER_ID: 'Medlem',
    PERIOD_ID: 'Period',
    HEADER: 'Medlemsutbildningar',
    TAB_MEMBER_EDUCATION: 'Utbildningar',
    TAB_SETTINGS: 'Inställningar',
    CREATED: 'Skapad ubildning',
    DELETED: 'Raderad utbildning',
    UPDATED: 'Uppdaterad utbildning',
    UNABLE_TO_LOAD: 'Kunde inte ladda utbildningar',
    UNABLE_TO_CREATE: 'Kunde inte skapa utbildning',
    UNABLE_TO_UPDATE: 'Kunde inte uppdatera utbildning',
    UNABLE_TO_DELETE: 'Kunde inte radera utbildning',
    NO_RECORDS_FOUND: 'Inga utbildningar hittades',
    CREATE_MEMBER_EDUCATION: 'Koppla utbildning',
    ADDED: 'Utbildning kopplat',
    UNABLE_ADD: 'Kunde inte koppla utbildning',
  },

  SWISH_MERCHANT: {
    TEST_CREATED: 'Swish test skapades',
    FINALIZE_SETUP: 'Slutför installation: ',
    CREATE_TEST: 'Skapa Swish Test',
    AN_ERROR_OCCURED: 'Kunde inte skapa Swish test',

    PAYER_NUMBER: 'Swishnummer',
    AMOUNT: 'Summa',
    MESSAGE: 'Message',
    USE_SWISH_NUMBER: 'Använd swishnummer',
    USE_QR_CODE: 'Använd QR kod',
    USE_LOCAL_APP: 'Öppna App lokalt',

    HEADER: 'Swish Handel',
    UNABLE_LOAD_FILES: 'Kunde inte ladda certifikatfiler',
    TAB_MERCHANTS: 'Swish Handel Konton',
    TAB_SETTINGS: 'Inställningar',
    NO_RECORDS_FOUND: 'Inga rader hittades',
    CREATE_MERCHANT: 'Skapa Swish Handel',
    CREATED_AT: 'Datum skapat',
    KEY_FILE: 'Merchant Key file (.key)',
    CERT_FILE: 'Merchant Cert file (.pem)',

    DELETED: 'Swish handel raderad',

    PAYER_ALIAS: 'Swishnummer',
    PAYEE_ALIAS: 'Swish alias',
    PASSPHRASE: 'Passphrase',
    STATUS: 'Status',
    STATUSES: {
      PENDING: 'Väntar',
      VALID_CERTS: 'Väntar på test',
      OPERATIONAL: 'Igång',
      ERROR: 'Fel'
    }
  },


  VALIDATION: {
    REQUIRED: 'Fältet är obligatoriskt',
    EMAIL: 'Fältet måste vara en giltig e-postadress',
    ADM_FEE: 'Administrativ avgift är ogiltigt',
    MEM_FEE: 'Avgiftssats medlem är ogiltigt',
  },
  HEADER: {
    FILTER: 'Filter',
    FILTER_HELP: 'Filtrera på org. eller namn'
  },

  SPAR: {
    ID: 'ID',
    JOB_NAME: 'Namn på körning'

  },

  FORM: {
    FORMS: 'Formulär',
    REQUIRE_PARENTS_BELOW_AGE: 'Kräv vårdnadshavare till och med ålder',
    AGE_REQUIRED: 'Ålder krävs',
    VISIBILITY_OPTION: 'Visningsinställning',
    VISIBILITY_OPTIONS: {
      ALWAYS: 'Alltid',
      IF_FAMILY_MEMBERSHIP: 'Om familjemedlemskap valt',
    },
    IF_FAMILY_MEMBERSHIP_INFO: 'OBS! Detta kräver att butik/medlemskapssidan ligger före denna sida!',
    ASSIGN_SHOP_ITEM: 'Tilldela artikel/medlemskap',
    ASSIGN_COMPANY: 'Tilldela förening',
    ADDED_ATTRIBUTES: 'Tilldelade attribut',
    ADD_ATTRIBUTE: 'Lägg till attribut',
    SELECT_ATTRIBUTES: 'Välj attribut',
    FORM_RULE_DELETED: 'Regel raderad',

    FORM_RULE_CREATED: 'Regel skapad',
    FORM_RULE_UPDATED: 'Regel uppdaterad',
    RULES: 'Regler',

    STYLE: {
      BG: 'Bakgrundsfärg',
      BUTTON_COLOR: 'Knappfärg',
    },
    FORM_VISIT_TYPE: 'Besöktstyp',
    FORM_VISIT_TYPES: {
      FIRST: 'Första besök',
      STEP: 'Steg',
      DONE_OK: 'Avklarad - OK',
      DONE_ERROR: 'Fel',
      DONE_WAIT_PAYMENT: 'Avklarad - Inväntar betalning',
    },
    TOTAL_VISITS: 'Totala besök',
    TOTAL_DONE: 'Avklarade',
    BAR_VISITORS: 'Formulärsbesökare',
    CREATE_FORM_RULE: 'Skapa regel',
    FORM_RULES: 'Regler',

    FORM_RULE_TYPE: 'Applicerad regel',
    FORM_RULE_TYPES: {
      ASSIGN_MEMBERSHIP: 'Tilldela medlemskap (Förening och artikel)',
      ASSIGN_COMPANY: 'Tilldela förening',
      ASSIGN_SHOP_ITEM: 'Tilldela artikel',
      ASSIGN_ATTRIBUTE: 'Tilldela attribut',
    },
    PRESELECTED_COMPANY: 'Förinställd förening (endast ifall formuläret saknar föreningsval)',
    PRESELECTED_COMPANY_INFO: 'Ifall förmuläret inte har föreningsval som sida. I så fall kommer medlemmen registreras på denna förening.',
    PRESELECTED_SHOP_ITEM: 'Förinställd artikel/medlemskap (endast ifall formuläret saknar medlemskap/butik)',
    PRESELECTED_SHOP_ITEM_INFO: 'Ifall förmuläret inte har butik/medlemskap som sida. I så fall kommer medlemmen tilldelas denna artikel.',
    APPEARANCE: 'Utseende',
    MERGE_TAG: 'Merge tag för mall',
    PAGE_NOT_CREATED: 'Sidan har inte skapats',
    COMPANY_ADMINISTER: 'Föreningsadministration',
    SELECTED_COMPANIES: 'Valda föreningar ({count})',
    COMPANIES_WITH_MEMBERSHIPS: 'Föreningar med medlemskap ({count})',
    SELECT_COMPANIES: 'Inställningar för föreningsval',
    VALIDATION: 'Validering',
    PAGE_VALIDATIONS: {
      NULL: 'Fel',
      NO_GROUP_IDS: 'Frågor saknas',
      MISSING_FIELDS: 'Fält saknas',
      NO_COMPANY_HIERARCHY: 'Föreningsinställningar saknas',
      OK: 'OK'
    },
    VISIBLE_FIELDS: 'Synliga fält',
    SELECT_QUESTIONS: 'Välj frågor/attribut',
    PAGE_NAME: 'Namn på sida',
    HTML: 'Beskrivning (HTML)',
    FIELD_NAME: 'Fält',
    REQUIRED: 'Krav',
    SHOW: 'Visa',
    PAGE_CREATED: 'Sida skapad',
    PAGE_UPDATED: 'Sida uppdaterad',
    PAGE_DELETED: 'Sida raderad',
    UNABLE_CREATE_PAGE: 'Kunde inte skapa sida',
    UNABLE_UPDATE_PAGE: 'Kunde inte uppdatera sida',
    UNABLE_DELETE_PAGE: 'Kunde inte radera sida',
    SETTINGS: 'Inställningar',
    PAGES: 'Sidor',
    TEXTS_TAB: 'Texter',
    ID: 'ID',
    REQUIRED_CONFIRM_PAGE_MISSING: 'Bekräftelsesida saknas - Alla formulär måste ha en bekräftelsesida',
    CREATE: 'Nytt formulär',
    COPIED: 'Länk kopierad',
    LINK_URL: 'Publik länk till formulär',
    UNABLE_LIST: 'Kunde inte lista formulär',
    UNABLE_COPY: 'Kunde inte kopiera länk',
    UNABLE_CREATE: 'Kunde inte skapa formulär',
    UNABLE_DELETE: 'Kunde inte radera formulär',
    UNABLE_LOAD: 'Kunde inte ladda formulär',
    UNABLE_UPDATE: 'Kunde inte uppdatera formulär',
    DELETED: 'Formulär raderat',
    CREATED: 'Formulär skapat',
    UPDATED: 'Formulär uppdaterat',
    NAME: 'Formulärnamn',
    CREATED_AT: 'Skapad datum',
    DELETE_CONFIRM_TITLE: 'Radera formulär',
    DELETE_CONFIRM_MESSAGE: 'Är du säker på att du vill radera formuläret?',
    INDEX: 'Ordning',
    PAGE_DELETED: 'Sidan raderades',
    NAME_REQUIRED: 'Namn krävs',
    TYPE: 'Typ av formulär',
    TYPES: {
      REGISTER_MEMBER: 'Registrera medlem',
      RENEW_MEMBER: 'Förnya medlem',
      COMPANY: 'Registrera förening',
      EVENT: 'Evenemangsregistrering',
    },
    CREATE_PAGE: 'Skapa sida',
    PAGE_TYPE: 'Sidtyp',
    PAGE_TYPES: {
      PERSONNR: 'Personnummer',
      QUESTIONS: 'Frågeformulär',
      PERSON_DETAILS: 'Persondetaljer',
      FAMILY: 'Familj',
      PARENTS: 'Vårdnadshavare',
      COMPANY_SELECT: 'Föreningsval/Medlemskap',
      COMPANY_DETAILS: 'Föreningsdetaljer',
      SHOP: 'Butik/Medlemskap',
      DONATION: 'Donation',
      TICKETS: 'Biljetter',
      PAYMENT: 'Betalning',
      CONFIRM: 'Bekräftelse',
      BANKID_LOGIN: 'BankID Login',
      BANKID_SIGN: 'BankID Signering',
      LOGIN: 'Inloggning'
    },
    REQUIRED_TEXT: 'Obligatoriskt fält',
    TEXTS: {
      SUCCESS_HEADER: 'Titel vid lyckad registrering',
      SUCCESS_TEXT: 'Text vid lyckad registrering',
      FAILURE_HEADER: 'Titel vid misslyckad registrering',
      FAILURE_TEXT: 'Text vid misslyckad registrering',
    }
  },

  MEMBERTYPE: {
    CREATE: 'Skapa medlemstyp',
    CREATED: 'Medlemstyp skapad',
    CREATED_AT: 'Skapad datum',
    UNABLE_CREATE: 'Kunde inte skapa medlemstyp',
    UPDATED: 'Medlemstyp uppdaterad',
    UNABLE_UPDATE: 'Kunde inte uppdatera medlemstyp',
    DELETED: 'Medlemstyp raderad',
    UNABLE_DELETE: 'Kunde inte radera medlemstyp',
    NAME: 'Namn',
    NONE_FOUND: 'Inga medlemstyper hittades',
  },

  MEMBER_EDITOR: {
    SHOW: 'Visa',
    SECTION_FIELD: 'Sektion/Fält',
    CREATE_MEMBER: 'Skapar medlem',
    EDIT_MEMBER: 'Redigerar medlem',
    PERSONAL_DETAILS: 'Personuppgifter',
    CONTACT_INFORMATION: 'Kontaktinformation',
    INVOICE_ADDRESS: 'Fakturaadress',
    STATUS: 'Status',
    MEMBERSHIPS: 'Medlemskap',
    EVENTS: 'Evenemang',
    EDUCATIONS: 'Utbildningar',
    FAMILY: 'Familj',
    PARENTS: 'Vårdnadshavare',
    FAITH: 'Samfund',
    ATTRIBUTES: 'Attribut',
    FILES: 'Filer',
    RENEWALS: 'Förnyelse',
    INVOICES: 'Fakturor',
    PAYMENTS: 'Betalningar',
    NOTES: 'Anteckningar',
    OPTIONS: 'Alternativ',
    OTHER: 'Övrigt'
  },
  USER: {

    NOT_LOGGED_IN: 'Du är ej inloggad',
    YOU_ARE_NOT_LOGGED_IN: 'Din session har avslutats, du måste logga in igen för att fortsätta.',
    GOTO_LOGIN: 'Till inloggning',

    INTERNET_ERROR: 'Ingen anslutning',
    INTERNET_ERROR_INFO: 'Din webbläsare har tappat uppkopplingen mot tjänsten. Ladda om sidan för att försöka igen.',

    SUPPORT_NEEDED_HEADER: 'Support',
    SUPPORT_NEEDED_INFO: 'Vi hjälper er gärna med problem eller övriga förfrågningar. Har ni besökt Memlists hjälptexter och dokumentation?',

    SUPPORT_NOT_IN_DOCUMENTATION: 'Saknas dokumentation för det ni behöver hjälp med? Kontakta oss här',

    NEW_VERSION_HEADER: 'Ny version',
    NEW_VERSION_INFO: 'En ny version finns tillgänglig, vänligen ladda om sidan för att använda den nya versionen.',

    GOTO_FEEDBACK: 'Gå till formuläret',
    GOTO_RELEASE_NOTES: 'Gå till versionsanteckningar',

    NEW_PATCH_NOTES: 'Versionsanteckningar tillgängliga',
    PLEASE_READ_PATCH_NOTES: 'Vi har en lista på förändringar som har genomförts i systemet. Klicka på länken nedan för att visa dessa.',

    SESSION_ENDED: 'Din session har avslutats',
    CLICK_TO_LOGIN_AGAIN: 'Klicka här för att logga in igen',
    UPDATE_AVAILABLE: 'En uppdatering finns tillgänglig. Klicka på knappen nedan för att ladda om sidan.',
    UPDATE_PAGE: 'Ladda om sidan',
    FEEDBACK_HEADER: 'Vänligen besvara feedback!',
    PLEASE_PROVIDE_FEEDBACK: 'Vi behöver din hjälp att bli bättre! För att vi ska kunna genomföra förändringar i systemet som är relevanta för er verksamhet behöver vi er feedback. Vänligen klicka på länken nedan och besvara formuläret. Din medverkan betyder mycket för oss!',
    ADDRESS: 'Adress',
    NUMBER_USERS_FOUND: 'Antal användare: ',
    SET_PAGES_FROM_COMPANY: 'Sätt sidor från markerad förening',
    CLICK_COMPANY_INFO: 'Klicka på namnet på föreningen för mera alternativ',
    READ_INFO: 'Läs - Kan se sidan men ej ge tillgång',
    ADMIN_INFO: 'Admin - Kan ge tillgång till andra',
    ACCESS_V2: 'Tillgång v2',
    ACCESS_V3: 'Tillgång v3',
    SET_ACCESS_ON_COMPANIES: '<< Sätt sidtillgångar på markerade föreningar',
    SELECT_ALL_SUBCOMPANIES: 'Markera alla underföreningar för vald förening',
    DESELECT_ALL_SUBCOMPANIES: 'Avmarkera alla underföreningar för vald förening',
    SHOW_ACCESS: 'Visa tillgång',
    EDIT_ACCESS: 'Ändra tillgång',
    ACCESS_HIERARCHY: 'Tillgång v2(BETA)',
    NOTIFICATIONS_UPDATED: 'Notifikationsinställningar uppdaterade',
    USERS: 'Användare',
    ADD_USER: 'Lägg till användare',
    CONFIRM_DELETE: 'Är du säker på att du vill radera användaren?',
    CREATE_ADD_EMAIL: 'Lägg till en ny användare genom att ange e-postadressen till personen nedan.',
    INVITE: 'Bjud in',
    USER_DATA: 'Användardata',
    ACCESS: 'Tillgång',
    NOTIFICATIONS: 'Notifikationer',
    ACCESS_INFO: 'Användaren kommer att få tillgång till angivna sidor för respektive förening. Ifall "Kan tilldela" är icheckad så kan användaren ge den tillgången till andra användare.',
    NOTIFICATIONS_INFO: 'Följande notifikationer kommer användaren att ta del av per förening. Observera att avgift tillkommer på skickade SMS.',
    ACCESS_GRANTED: 'Användartillgång skapad',
    UNABLE_ACCESS_GRANT: 'Kunde inte skapa användartillgång',
    ACCESS_UPDATED: 'Användartillgång uppdaterades',
    UNABLE_ACCESS_UPDATE: 'Kunde inte uppdatera användartillgång',
    DELETED: 'Användare raderad',
    UNABLE_DELETE: 'Kunde inte radera användare',
    REMOVED_FROM_COMPANY: 'Användare togs bort från förening',
    UNABLE_REMOVE_FROM_COMPANY: 'Kunde inte ta bort användare från förening',
    UNABLE_LIST_ACCESS: 'Kunde inte lista tillgångar',
    UNABLE_LIST_COMPANY: 'Kunde inte lista föreningar',
    UNABLE_LIST: 'Kunde inte lista användare',
    UNABLE_CREATE: 'Kunde inte skapa användare',
    UPDATED: 'Användare uppdaterad',
    UNABLE_UPDATE: 'Kunde inte uppdatera användare',
    INVALID_EMAIL: 'Oglitig e-post',
    EMAIL_TAKEN: 'E-postadressen är redan registrerad',
    INVITATION_SENT: 'Inbjudan har skickats',
    UNABLE_SEND_INVITATION: 'Kunde inte skicka inbjudan',
    EMAIL_TAKEN_INFO: 'E-postadressen är redan inlagd i systemet. För att bjuda in till denna förening, använd "Bjud in" knappen.',
    TITLE: 'Titel',
    IS_DIRECTOR: 'Är styrelsemedlem',
    IS_ACCOUNTANT: 'Är revisor',
    DELETE: 'Radera användare',
    NEW: 'Ny användare',
    INVITE_USER: 'Bjud in användare',
    NO_ACCESS: 'Användaren har inte tillgång till någon sida och kan därför inte logga in',
    HAS_ACCESS: 'Har tillgång',
    HAS_GRANT: 'Kan tilldela',
    IS_SUPERADMIN: 'Är superadmin',
    SEARCH_COMPANY: 'Sök förening',
    MY_INFORMATION: 'Mina uppgifter',
    NOTIFICATIONS: 'Notifikationer',
    UNABLE_UPDATE_ACCESS: 'Kunde inte uppdatera tillgångar',
    CANT_UPDATE_YOURSELF: 'Du kan inte tilldela tillgång till dig själv',
    ADD_READ_ALL: 'Lägg till läsrättigheter för samtliga',
    ADD_WRITE_ALL: 'Lägg till skrivrättigheter för samtliga',
    REMOVE_READ_ALL: 'Ta bort läsrättigheter för samtliga',
    REMOVE_WRITE_ALL: 'Ta bort skrivrättigheter för samtliga',
    PAGES_ACCESS_INFO: 'På denna sida kan du ta bort eller ge rättigheter till alla användare',
    BEARER: 'Bearer token',
    BEARER_INFO: 'Använd Bearer token nedan för "Authorization" header värdet. "Bearer <token>".',
    SCOPES: 'Scopes/rättigheter',
    SAVE_SCOPES: 'Spara rättigheter',
    API_KEY: 'Apinycklar',
    AREA_ALLOC: 'Föreningstilldening',
    BILLING: 'Kostnader',
    TRANSACTION: 'Transaktioner',
    BOOK: 'Bokföring',
    COMPANY_USER: 'Föreningstillgång',
    CARD: 'Medlemskort',
    COMPANY: 'Förening',
    CAMPAIGN: 'Kampanj',
    EDUCATION: 'Utbildning',
    EMAIL: 'Epost',
    EVENTS: 'Evenemang',
    EVENT_CATEGORY: 'Evenemangkategori',
    EXTRA_DATA: 'Extradata',
    FILE: 'Filer',
    FILEUPLOAD: 'Filuppladdning',
    FORTNOX: 'Fortnox',
    IMPORT: 'Medlemsimport',
    INVOICE: 'Faktura',
    JOB: 'Körning',
    LINK: 'Länk',
    LOCATION: 'Adressregister',
    MEMBER_COMPANY: 'Medlemskap',
    MEMBER_EVENT: 'Evenemangdeltagare',
    MEMBER_PROPERTY: 'Medlemsattribut',
    MEMBER_RENEW: 'Medlemsförnyelse',
    MEMBER_STICKPROV: 'Medlemsstickprov',
    MEMBER: 'Medlem',
    PAGE: 'Sida',
    PAYMENT: 'Orderhistorik',
    PERIOD: 'Period',
    PERSON: 'Person',
    PROPERTY_GROUP: 'Attributkategori',
    REDEEM_CODE: 'Kupong',
    SEARCH: 'Sök',
    SENDOUT: 'Utskick',
    SHOP_ITEM: 'Butik',
    SHOP_ORDER: 'Order',
    SMS: 'SMS',
    SPAR: 'SPAR',
    STICKPROV: 'Stickprov',
    STRIPE: 'Stripe',
    SYSTEM: 'System',
    TEMPLATE: 'Mall',
    TODO_TASK: 'Ärendehanteringsåtgärder',
    TODO: 'Ärendehantering',
    TRACKING: 'Spårning',
    USER: 'Användare',
    TITLE: 'Titel',
    MEMBERTYPE: 'Medlemstyp',
    WEBHOOK: 'Webhook',
    AUTH_TOKEN: 'Nycklar',
    UNABLE_LIST_AUTH: 'Kunde inte lista nycklar',
    AUTH_DELETED: 'Nyckel raderad',
    AUTH_CREATED: 'Nyckel skapad',
    SHOW_BEARER_INFO: 'Du måste kopiera nyckeln nu. Ifall du glömmer att kopiera nyckeln så finns det ingen möjlighet att visa den vid senare tillfälle.',
    SHOW_TOO_LATE: 'Tidigare skapade nycklar kan ej visas.',
    FULL_ACCESS: 'Ge full tillgång',
    ACCESS_GIVEN: 'Full tillgång OK',
    TAB_USERS: 'Användare',
    TAB_TERMS: 'Användarvillkor',
    TAB_GROUPS: 'Grupper',
    TABLE_HEADER_TERMS: 'Användarvillkor',
    ACCEPTED_TERM: 'Godkänt',

    NOTIF_EMAIL: 'Notifiering via e-post',
    NOTIF_SMS: 'Notifiering via SMS',
    USER_ID: 'AnvändarID',
    FIRSTNAME: 'Förnamn',
    LASTNAME: 'Efternamn',
    EMAIL: 'E-post',
    REFRESH_TOKEN: 'Refresh Token',
    CREATED_AT: 'Skapad',
    VERIFY_PASSWORD: 'Ange användarens lösenord',
    INPUT_PASSWORD: 'Lösenord',
    INVALID_PASSWORD: 'Felaktigt lösenord',
    GET_TOKEN: 'Hämta token',
    ROLE: 'Roll',
    ROLES: {
      ADMIN: 'Admin',
      OWNER: 'Ägare',
      USER: 'Användare',
      NONE: 'Ingen'
    },
    DETAILS: 'Detaljer',
    TERMS: 'Användarvillkor',
    ID: 'AnvändarID',
    USER_ID: 'AnvändarID',
    NAME: 'Namn/E-post',
    GROUP: 'Grupptillhörighet',
    CREATE: 'Skapa användare',
    UNABLE_GET: 'Kunde inte hämta användare',
    UNABLE_LIST: 'Kunde inte lista användare',
    UNABLE_DELETE: 'Kunde inte radera användare',
    UNABLE_CREATE: 'Kunde inte skapa användare',
    UNABLE_UPDATE: 'Kunde inte uppdatera användare',
    UNABLE_UPDATE_PASSWORD: 'Kunde inte uppdatera lösenord',
    CREATED: 'Användare skapad',
    UPDATED: 'Användare uppdaterad',
    DELETED: 'Användare raderad',
    EMAIL: 'E-post',
    PHONE: 'Telefon',
    FIRSTNAME: 'Förnamn',
    LASTNAME: 'Efternamn',
    PASSWORD_UPDATED: 'Lösenord uppdaterat',
    PASSWORDS_MISMATCH: 'Lösenorden måste vara likadana',
    PASSWORDS_6CHAR: 'Lösenordet måste vara minst 6 tecken',
    PASSWORD_INFO: 'Lämna tomt för att behålla nuvarande lösenord',
    PASSWORD_REPEAT: 'Repetera lösenord',
    PASSWORD: 'Lösenord',
    NEW_PASSWORD: 'Nytt lösenord',
    CONFIRM_PASSWORD: 'Bekräfta lösenord',
    UPDATE_PASSWORD: 'Uppdatera lösenord',
    CREATED_AT: 'Skapad datum',
    PERSONNR: 'Personnummer'
  },
  ATTRIBUTE: {
    PRIORITY: 'Prioritet',
    UNABLE_SAVE: 'Kunde inte spara attribut',
    FREE_TEXT: 'Fritext',
    IS_FREE_TEXT: 'Är fritext',
    DESCR_PLACEHOLDER: 'Denna text är synlig för användaren',
    DESCR: 'Synlig text',
    NAME: 'Internt namn',
    EDIT_ATTRIBUTE: 'Redigera attribut',
    SAVE: 'Spara attribut',
    CREATED: 'Attribut skapat',
    UNABLE_CREATE: 'Kunde inte skapa attribut',
    UNABLE_LIST: 'Kunde inte lista attribut',
    FORM_INVALID: 'Formuläret är ogiltigt',
    UPDATED: 'Attribut uppdaterat',
    UNABLE_UPDATE: 'Kunde inte uppdatera attribut',
    DELETED: 'Attribut raderat',
    UNABLE_DELETE: 'Kunde inte radera attribut',
    ALREADY_DEFINED: 'Attributet finns redan på medlemmen',
    TEXT_TOO_LONG: 'Texten måste vara färre än 32 tecken'
  },
  HISTORY: {
    MEMBER: 'Medlem',
    USER: 'Användare',
    HISTORY_TYPE: {
      LOGIN_FAIL: 'Misslyckad inloggning',
      LOGIN_SUCCESS: 'Lyckad inloggning',
      SEARCH_MEMBERS: 'Söker medlemmar',
      SEARCH_USERS: 'Söker användare',
      VIEW_MEMBER: 'Visar medlem',
      VIEW_USER: 'Visar användare',
      EDIT_MEMBER: 'Redigerar medlem',
      EDIT_USER: 'Redigerar användare',
      DELETE_MEMBER: 'Raderar medlem',
      DELETE_USER: 'Raderar användare',
      CREATE_MEMBER: 'Skapar medlem',
      CREATE_USER: 'Skapar användare',
    },
    FIELD: 'Fält',
    OLD_VALUE: 'Gammalt värde',
    NEW_VALUE: 'Nytt värde',
    NO_DATA_FOUND: 'Ingen data hittades',
    GROUP: 'Grupp',
    DATA: 'Förändrad data',
    INSPECTOR: 'Inspekterar händelse',
    HISTORY_SETTINGS_UPDATED: 'Inställningar uppdaterade',
    UNABLE_UPDATE_HISTORY_SETTINGS: 'Kunde inte uppdatera inställningar',
    UNABLE_LOAD_HISTORY_SETTINGS: 'Kunde inte ladda historikinställningar',
    NAME: 'Inställning',
    SETTINGS: 'Inställningar',
    CREATE: 'Skapa',
    VIEW: 'Läsa',
    UPDATE: 'Uppdatera',
    DELETE: 'Radera',
    LOGIN: 'Logga in',
    ACTIVATE: 'Aktivera',

    HISTORY: 'Händelser',
    CREATED_AT: 'Tidpunkt',
    TYPE: 'Typ',
    CREATED_BY: 'Utförd av',
    USERS: 'Användarhändelser',
    MEMBERS: 'Medlemshändelser',
    USER: 'Admin',
    MEMBER: 'Medlem',
    CREATED_BY_TYPE: 'Roll',
    NO_FOUND: 'Inga händelser hittades',
    NUM_ROWS: 'Antal händelser',
    DETAILS: 'Detaljer',
    UNABLE_GET_USER: 'Kunde inte hämta användare',
    UNABLE_GET_MEMBER: 'Kunde inte hämta medlem',
  },
  SETTINGS: {
    STRIPE_ACCESS_TOKEN: 'Stripe Access Key (SK)',
    STRIPE_PK_TOKEN: 'Stripe Publishable Key (PK)',
    INVALID_STRIPE_ACCESS_TOKEN: 'Ogilitg Stripe Access Key, formatet måste vara "sk_live_..."',
    INVALID_STRIPE_PK_TOKEN: 'Ogiltig Stripe Publishable Key (PK), formatet måste vara "pk_live_..."',
    ENABLED_STRIPE: 'Aktivera Stripe',
    ENABLED_SWISH: 'Aktivera Swish',
    ENABLED_INVOICE: 'Aktivera Betala med faktura',
    
    SWISH_SETTINGS: 'Swish inställningar',
    STRIPE_SETTINGS: 'Stripe inställningar',
    INVALID_SWISH_PAYEE_ALIAS: 'Ogiltigt Swishnummer',
    SWISH_PAYEE_ALIAS: 'Swishnummer',
    VERIFY_SWISH_SETTINGS: 'Verifiera Swish inställningar',
    SWISH_TEST_SUCCESS: 'Swish verifiering lyckades',
    SWISH_TEST_FAILURE: 'Swish verifiering misslyckades',
    SWISH_OPERATIONAL: 'Swish är igång',
    INVOICE_PAYMENTS: 'Fakturabetalningar'
  },
  EDUCATION: {
    STATUSES: {
      FINISHED: 'Klar',
      RUN_FIRST_IMPORT: 'Validerar...',
      DONE_FIRST: 'Klar validering',
      FAIL_FIRST: 'Fel validering',
      RUN_FIN_IMPORT: 'Importerar...',
      DONE_FINAL: 'Klar import',
      FAIL_FINAL: 'Fel import',

      RUN_FAST_FIRST_IMPORT: 'Förbereder...',
      DONE_FAST_FIRST_IMPORT: 'Redo att importera',
      RUN_FAST_FINAL_IMPORT: 'Importerar...',
      DONE_FAST_FINAL_IMPORT: 'Import avklarad',
      FAIL_FAST_FIRST_IMPORT: 'Fel vid förberedelse',
      FAIL_FAST_FINAL_IMPORT: 'Fel vid import',
      VALIDATING: 'Validerar...',
      VALIDATED_OK: 'Validerat OK',
      VALIDATED_ERROR: 'Valideringsfel',

      OK: 'Klart',
      FAIL: 'Fel'
    },
    ACTIVATE_EDUCATIONS: 'Aktivera utbildningar',
    TYPE: 'Typ',
    ADDED: 'Utbildningen lades till',
    UNABLE_ADD: 'Kunde inte lägga till utbildning',
    UNABLE_DELETE: 'Kunde inte radera utbildning',
    TYPES: {
      NONE: 'Inget',
      PROGRAM: 'Program',
      COURSE: 'Kurs',
      FREE_COURSE: 'Frikurs',
      UNKNOWN: 'Okänt'
    },
    NUM_ROWS: 'Antal rader',
    STUDENT_STATUS: 'Studentstatus',
    STUDENT_STATUSES: {
      UNKNOWN: 'Ej bestämt',
      NONE: 'Inget',
      STUDENT: 'Student',
      NOT_STUDENT: 'Ej student'
    },
    EDUCATION: 'Utbildning',
    NO_EDUCATIONS_FOUND: 'Inga utbildningar hittades',
    EDUCATIONS: 'Utbildningar',
    NAME: 'Namn',
    SHORTNAME: 'Kortnamn',
    UNABLE_GET: 'Kunde inte hämta utbildning',
    UNABLE_LIST: 'Kunde inte lista utbildningar',
    UNABLE_DELETE: 'Kunde inte ta bort utbildning',
    UNABLE_CREATE: 'Kunde inte skapa utbildning',
    UNABLE_UPDATE: 'Kunde inte uppdatera utbildning',
    CREATE: 'Skapa utbildning',
    UPDATE: 'Uppdatera utbildning',
    DELETE: 'Ta bort utbildning',
    CONFIRM_DELETE: 'Är du säker på att du vill ta bort utbildningen?',
    CONFIRM_DELETE_MULTIPLE: 'Är du säker på att du vill ta bort utbildningarna?',
    CREATE_SUCCESS: 'Utbildning skapad',
    UPDATE_SUCCESS: 'Utbildning uppdaterad',
    DELETE_SUCCESS: 'Utbildning borttagen',
    DELETE_MULTIPLE_SUCCESS: 'Utbildningar borttagna',
    CREATE_ERROR: 'Kunde inte skapa utbildning',
    UPDATE_ERROR: 'Kunde inte uppdatera utbildning',
    DELETE_ERROR: 'Kunde inte ta bort utbildning',
    DELETE_MULTIPLE_ERROR: 'Kunde inte ta bort utbildningar',
    SELECT_EDUCATION: 'Välj utbildning',
    SELECT_EDUCATION_PLACEHOLDER: 'Välj utbildning...',
    SECTION: 'Sektion',
    PERIOD: 'Period',
    CODE: 'Kod',
    LADOK_ROWS: 'Ladok rader',
    STUDENT_ID: 'Student-ID',
    COURSE_CODE: 'Kurskod',
    PROGRAM_CODE: 'Programkod',
    FACULTY: 'Fakultet',
    CREATED_AT: 'Skapad',
    NO_LADOK_ROWS: 'No LADOK records',
    LADOK_FILE_ROW_ID: 'LADOK rad ID',
    LADOK_FILE_ID: 'LADOK fil ID',
    NO_FOUND: 'Inga utbildningar hittades',
  },
  FILE: {
    UPLOADED: 'Fil uppladdad',
    UPLOAD_FILE: 'Ladda upp',
    VISIBLE_MEMBER_TYPE: 'Synlig för medlemstyp på Mina sidor',
    MEMBER_TYPE_ANY: 'Ingen',
    HEADER: 'Filer',
    FILE_ID: 'Fil ID',
    FILENAME: 'Filnamn',
    SIZE_KB: 'Filstorlek',
    CREATED_AT: 'Skapad',
    CREATED_BY: 'Skapad av',
    UPLOAD_TO_FORTNOX: 'Ladda upp till Fortnox',
    NO_FILES: 'Inga filer hittades',
    DELETED: 'Filen raderades',
    UNABLE_DELETE: 'Kunde inte radera filen',
    DOWNLOAD: 'Ladda ned',
    UPDATED: 'Fil uppdaterad',
    UNABLE_UPDATE: 'Kunde inte uppdatera fil',
    UPLOADED: 'Filen laddades upp',
    UNABLE_UPLOAD: 'Kunde inte ladda upp fil',
    CREATE: 'Ladda upp',
    UNABLE_CREATE: 'Kunde inte skapa fil',
    UPLOAD: 'Ladda upp',
    ALLOW_UPLOAD: 'Tillåt uppladdning av filer',
  },
  SKV: {
    UPDATED: 'Skatteverket status uppdaterat',
    UNABLE_UPDATE: 'Kunde inte uppdatera',
    SKV_STATUS: 'Skatteverket Status',
    SKV_PERIOD: 'SKV Period',
    COMPANY: 'Församling',
    MEMBER_COMPANY_UPDATED: 'Medlemskap uppdaterat',
    UNABLE_UPDATE_MEMBER_COMPANY: 'Kunde inte uppdatera medlemskap',
    SKV_STATUSES: {
      IGNORE: 'Ignorera',
      MINOR_IGNORE: 'Minderårig - Ignorera',
      REGISTERED: 'Registrerad',
      DELETED: 'Borttagen',
      PEN_REGISTER: 'Väntar på registrering',
      PEN_DELETED: 'Väntar på rättelse',
      ERR_PERSONNR: 'Felaktigt personnummer',
      ERR_DUPLICATE: 'Fel - Dubblett',
    }
  },

  ACCESS: {

    TOKEN: {
      API_ACCESS: 'Api åtkomst',
      REFRESH_TOKEN: 'Refresh Token',
      CREATED_AT: 'Created at'
    },


    GROUP: {
      NEW_GROUP: 'Ny grupp',
      DETAILS: 'Detaljer',
      COMPANIES: 'Föreningar',
      PERMISSIONS: 'Rättigheter',
      PAGES: 'Sidor',
      GROUPS: 'Grupper',
      NAME: 'Gruppnamn',
      GROUP_ID: 'GruppID',
      HAS_ACCESS: 'Tillgång',
      ID: 'GruppID',
      CREATED_AT: 'Skapad datum',
      CREATE: 'Skapa grupp',
      CREATED: 'Grupp skapad',
      UPDATED: 'Grupp uppdaterad',
      DELETED: 'Grupp raderad',
      USER_REMOVED: 'Användare borttagen från grupp',
      UNABLE_CREATE: 'Kunde inte skapa grupp',
      UNABLE_UPDATE: 'Kunde inte uppdatera grupp',
      UNABLE_DELETE: 'Kunde inte radera grupp',
      UNABLE_GET: 'Kunde inte hämta grupp',
      UNABLE_ADD_PAGE: 'Kunde inte lägga till sida',
      UNABLE_REMOVE_PAGE: 'Kunde inte ta bort sida',
      UNABLE_GET_PAGES: 'Kunde inte hämta sidor',
      UNABLE_GET_HIERARCHY: 'Kunde inte hämta hierarki',
      UNABLE_REMOVE_COMPANY: 'Kunde inte ta bort förening',
      UNABLE_REMOVE_USER: 'Kunde inte ta bort användare',
      UNABLE_ADD_COMPANY: 'Kan inte lägga till förening',
    },


    PAGE: {
      NAME: 'Sida',
      ACCESS: 'Tillgång',
      UNABLE_ADD: 'Kunde inte lägga till sida',
      UNABLE_REMOVE: 'Kunde inte ta bort sida',
      UNABLE_GET: 'Kunde inte hämta sida',
    },

    PERMISSIONS: {
      NAME: 'Rättighet',
      UNABLE_ADD: 'Kunde inte lägga till rättighet',
      UNABLE_REMOVE: 'Kunde inte ta bort rättighet',
      UNABLE_GET: 'Kunde inte hämta rättighet',
      UNABLE_SET: 'Kunde inte sätta rättighet',

      PERMISSION: {
        A: 'Admin',
        R: 'Läsa',
        N: 'Ingen',
      },

      MEMBER: {
        NAME: 'Medlemmar',
        C: 'Skapa medlem',
        R: 'Läsa medlem',
        U: 'Uppdatera medlem',
        D: 'Radera medlem',
        S: 'Sök medlemmar',
        PUBLIC_ID: 'Ändra medlemsnummer'
      },
      USER: {
        NAME: 'Användare',
        C: 'Skapa användare',
        R: 'Läsa användare',
        U: 'Uppdatera användare',
        D: 'Radera användare'
      },
      EVENT: {
        NAME: 'Evenemang',
        C: 'Skapa evenemang',
        R: 'Läsa evenemang',
        U: 'Uppdatera evenemang',
        D: 'Radera evenemang',
        VIEW_PARTICIPANTS: 'Visa deltagare',
        EDIT_PARTICIPANTS: 'Ändra deltagare',
      },

      COMPANY: {
        NAME: 'Förening',
        C: 'Skapa förening',
        R: 'Läs förening',
        U: 'Uppdatera förening',
        D: 'Radera förening'
      },
      BILLING: {
        NAME: 'Kostnader',
        C: 'Skapa kostnader',
        R: 'Läs kostnader',
        U: 'Uppdatera kostnader',
        D: 'Radera kostnader'
      },
      CAMPAIGN: {
        NAME: 'Kampanj',
        C: 'Skapa kampanj',
        R: 'Läs kampanj',
        U: 'Uppdatera kampanj',
        D: 'Radera kampanj'
      },
      COMPANY_PERIOD: {
        NAME: 'Föreningsperiod',
        C: 'Skapa föreningsperiod',
        R: 'Läs föreningsperiod',
        U: 'Uppdatera föreningsperiod',
        D: 'Radera föreningsperiod'
      },
      EDUCATION: {
        NAME: 'Utbildning',
        C: 'Skapa utbildning',
        R: 'Läs utbildning',
        U: 'Uppdatera utbildning',
        D: 'Radera utbildning'
      },
      SENDOUT: {
        NAME: 'Utskick',
        C: 'Skapa utskick',
        R: 'Läs utskick',
        U: 'Uppdatera utskick',
        D: 'Radera utskick'
      },
      FILE: {
        NAME: 'Fil',
        C: 'Skapa fil',
        R: 'Läs fil',
        U: 'Uppdatera fil',
        D: 'Radera fil'
      },
      IMPORT: {
        NAME: 'Import',
        C: 'Skapa import',
        R: 'Läs import',
        U: 'Uppdatera import',
        D: 'Radera import'
      },
      INVOICE: {
        NAME: 'Faktura',
        C: 'Skapa faktura',
        R: 'Läs faktura',
        U: 'Uppdatera faktura',
        D: 'Radera faktura'
      },
      LADOK: {
        NAME: 'Ladok',
        C: 'Skapa Ladok',
        R: 'Läs Ladok',
        U: 'Uppdatera Ladok',
        D: 'Radera Ladok'
      },
      HISTORY: {
        NAME: 'Historik',
        C: 'Skapa historik',
        R: 'Läs historik',
        U: 'Uppdatera historik',
        D: 'Radera historik'
      },
      FORTNOX: {
        NAME: 'Fortnox',
        C: 'Skapa Fortnox',
        R: 'Läs Fortnox',
        U: 'Uppdatera Fortnox',
        D: 'Radera Fortnox'
      },
      MEMBER_COMPANY: {
        NAME: 'Medlemskap',
        C: 'Skapa medlemskap',
        R: 'Läs medlemskap',
        U: 'Uppdatera medlemskap',
        D: 'Radera medlemskap'
      },
      MEMBER_RENEW: {
        NAME: 'Förnyelser',
        C: 'Skapa förnyelse',
        R: 'Läs förnyelse',
        U: 'Uppdatera förnyelse',
        D: 'Radera förnyelse'
      },
      MEMBER_INSURANCE: {
        NAME: 'Försäkringar',
        C: 'Skapa försäkring',
        R: 'Läs försäkring',
        U: 'Uppdatera försäkring',
        D: 'Radera försäkring'
      },
      MEMBER_PROPERTY: {
        NAME: 'Attribut',
        C: 'Skapa Attribut',
        R: 'Läs Attribut',
        U: 'Uppdatera Attribut',
        D: 'Radera Attribut'
      },
      STICKPROV: {
        NAME: 'Stickprov',
        C: 'Skapa stickprov',
        R: 'Läs stickprov',
        U: 'Uppdatera stickprov',
        D: 'Radera stickprov'
      },
      PERIOD: {
        NAME: 'Period',
        C: 'Skapa period',
        R: 'Läs period',
        U: 'Uppdatera period',
        D: 'Radera period'
      },
      PERSON: {
        NAME: 'Person',
        C: 'Skapa person',
        R: 'Läs person',
        U: 'Uppdatera person',
        D: 'Radera person'
      },
      SEARCH: {
        NAME: 'Sök',
        C: 'Skapa sökning',
        R: 'Läs sökning',
        U: 'Uppdatera sökning',
        D: 'Radera sökning'
      },
      SHOP_ITEM: {
        NAME: 'Artiklar',
        C: 'Skapa artikel',
        R: 'Läs artikel',
        U: 'Uppdatera artikel',
        D: 'Radera artikel'
      },
      SHOP_ORDER: {
        NAME: 'Beställningar',
        C: 'Skapa beställning',
        R: 'Läs beställning',
        U: 'Uppdatera beställning',
        D: 'Radera beställning'
      },
      SMS: {
        NAME: 'SMS',
        C: 'Skapa SMS',
        R: 'Läs SMS',
        U: 'Uppdatera SMS',
        D: 'Radera SMS'
      },
      SKV: {
        NAME: 'Skatteverket',
        C: 'Skapa Skatteverket',
        R: 'Läs Skatteverket',
        U: 'Uppdatera Skatteverket',
        D: 'Radera Skatteverket'
      },
      TEMPLATE: {
        NAME: 'Mallar',
        C: 'Skapa mall',
        R: 'Läs mall',
        U: 'Uppdatera mall',
        D: 'Radera mall'
      },
      SYSTEM: {
        NAME: 'System',
        C: 'Skapa system',
        R: 'Läs system',
        U: 'Uppdatera system',
        D: 'Radera system'
      },
      TODO: {
        NAME: 'Att göra/ärenden',
        C: 'Skapa ärenden',
        R: 'Läs ärenden',
        U: 'Uppdatera ärenden',
        D: 'Radera ärenden'
      },
      TRANSACTION: {
        NAME: 'Transaktion',
        C: 'Skapa transaktion',
        R: 'Läs transaktion',
        U: 'Uppdatera transaktion',
        D: 'Radera transaktion'
      },
      WEBHOOK: {
        NAME: 'Webhook',
        C: 'Skapa webhook',
        R: 'Läs webhook',
        U: 'Uppdatera webhook',
        D: 'Radera webhook'
      },
      API: {
        NAME: 'API',
        C: 'Skapa API',
        R: 'Läs API',
        U: 'Uppdatera API',
        D: 'Radera API'
      },
      TODO_TASK: {
        NAME: 'Ärendeåtgärd',
        C: 'Skapa åtgärd',
        R: 'Läs åtgärd',
        U: 'Uppdatera åtgärd',
        D: 'Radera åtgärd'
      },
      BANKID: {
        NAME: 'BankId',
        C: 'Skapa BankId',
        R: 'Läs BankId',
        U: 'Uppdatera BankId',
        D: 'Radera BankId'
      },
      EMAIL: {
        NAME: 'E-post',
        C: 'Skapa e-post',
        R: 'Läs e-post',
        U: 'Uppdatera e-post',
        D: 'Radera e-post'
      },
      INSTRUMENT: {
        NAME: 'Instrument',
        C: 'Skapa instrument',
        R: 'Läs instrument',
        U: 'Uppdatera instrument',
        D: 'Radera instrument'
      },
      INVOICE_TEMPLATE: {
        NAME: 'Fakturamall',
        C: 'Skapa fakturamall',
        R: 'Läs fakturamall',
        U: 'Uppdatera fakturamall',
        D: 'Radera fakturamall'
      },
      MEMBER_TYPE: {
        NAME: 'Medlemstyp',
        C: 'Skapa medlemstyp',
        R: 'Läs medlemstyp',
        U: 'Uppdatera medlemstyp',
        D: 'Radera medlemstyp'
      },
      PAYMENT: {
        NAME: 'Betalning',
        C: 'Skapa betalning',
        R: 'Läs betalning',
        U: 'Uppdatera betalning',
        D: 'Radera betalning'
      },
      REDEEM_CODE: {
        NAME: 'Inlösenkod',
        C: 'Skapa inlösenkod',
        R: 'Läs inlösenkod',
        U: 'Uppdatera inlösenkod',
        D: 'Radera inlösenkod'
      },
      SAMFUND: {
        NAME: 'Samfund',
        C: 'Skapa samfund',
        R: 'Läs samfund',
        U: 'Uppdatera samfund',
        D: 'Radera samfund'
      },
      SPAR: {
        NAME: 'SPAR',
        C: 'Skapa SPAR',
        R: 'Läs SPAR',
        U: 'Uppdatera SPAR',
        D: 'Radera SPAR'
      },
      TITLE: {
        NAME: 'Titlar',
        C: 'Skapa titel',
        R: 'Läs titel',
        U: 'Uppdatera titel',
        D: 'Radera titel'
      },
      TRACKING: {
        NAME: 'Spårning',
        C: 'Skapa spårning',
        R: 'Läs spårning',
        U: 'Uppdatera spårning',
        D: 'Radera spårning'
      },
      CARD: {
        NAME: 'Kort',
        C: 'Skapa kort',
        R: 'Läs kort',
        U: 'Uppdatera kort',
        D: 'Radera kort'
      },
      COMPANY_TERM: {
        NAME: 'Företagsvillkor',
        C: 'Skapa företagsvillkor',
        R: 'Läs företagsvillkor',
        U: 'Uppdatera företagsvillkor',
        D: 'Radera företagsvillkor'
      },
      BOOK: {
        NAME: 'Bokföring',
        C: 'Skapa bokföring',
        R: 'Läs bokföring',
        U: 'Uppdatera bokföring',
        D: 'Radera bokföring'
      },
      REGISTRATION: {
        NAME: 'Registrering',
        C: 'Skapa registrering',
        R: 'Läs registrering',
        U: 'Uppdatera registrering',
        D: 'Radera registrering'
      },
      AUTH: {
        NAME: 'Autentisering',
        C: 'Skapa autentisering',
        R: 'Läs autentisering',
        U: 'Uppdatera autentisering',
        D: 'Radera autentisering'
      },
      ACCESS: {
        NAME: 'Åtkomst',
        C: 'Skapa åtkomst',
        R: 'Läs åtkomst',
        U: 'Uppdatera åtkomst',
        D: 'Radera åtkomst'
      },
      THS: {
        NAME: 'THS',
        C: 'Skapa THS',
        R: 'Läs THS',
        U: 'Uppdatera THS',
        D: 'Radera THS'
      },
      SERVER: {
        NAME: 'Server',
        C: 'Skapa server',
        R: 'Läs server',
        U: 'Uppdatera server',
        D: 'Radera server'
      }

    },

    TERMS: {
      ACCEPTED_AT: 'Datum accepterat',
      NAME: 'Användarvillkor',
      NO_TERMS: 'Inga villkor accepterade'
    }
  },

  BOOK: {
    STATUS: 'Status',
    ERROR: 'Felmeddelande',
    CREATED_AT: 'Skapad',
    SEARCH_VOUCHER: 'Sök verifikat',
    ADD_ROW: 'Lägg till rad',
    REMOVE_ROW: 'Ta bort rad',
    BOOKIT: 'Bokför',
    CONFIRM_DELETE: 'Bekräfta borttagning av verifikat',
    CHECK_FORTNOX_STATUS: 'Fortnox status kontrolleras...',
    FORTNOX_NOT_CONNECTED: 'Fortnox är ej kopplat för denna förening och därför går det inte att skapa verifikat i Fortnox',
    FORTNOX_IS_CONNECTED: 'Fortnox är kopplat',
    VOUCHERS: 'Verifikat',
    BOOKED: 'Bokförda',
    NOT_BOOKED: 'Ej bokförda',
    UPLOAD_DINTERO_FILE: 'Ladda upp Dintero fil',
    CREATE_VOUCHER: 'Skapa verifikat',
    VOUCHER_NUMBER: 'Verifikatnummer',
    MESSAGE: 'Meddelande',
    ROWS: 'Rader',
    ACCOUNT: 'Konto',
    COST_CENTER: 'Kostnadsställe',
    PROJECT: 'Projekt',
    DEBIT: 'Debet',
    CREDIT: 'Kredit',
    DESCRIPTION: 'Beskrivning',
    VOUCHER_CREATED: 'Verifikat skapat',
    UNABLE_CREATE_VOUCHER: 'Kunde inte skapa verifikat',
    VOUCHER_UPDATED: 'Verifikat uppdaterat',
    UNABLE_UPDATE_VOUCHER: 'Kunde inte uppdatera verifikat',

  },

  FORTNOX: {
    FORTNOX: 'Fortnox',
    ENABLE_BGMAX_FORTNOX_INFO: 'Transaktioner i BGMax filen kommer att bokföras i Fortnox. Endast transaktioner som är kopplade med OCR för memlist faktura kommer att hamna i Fortnox.',
    ENABLE_BGMAX_FORTNOX: 'Bokför transaktioner från BGMax',
    UNABLE_CHECK_STATUS: 'Kunde inte kontrollera status för fortnox',
    API_SETTINGS_SAVED: 'Fortnox API uppgifter sparade',
    UNABLE_UPDATE_API: 'Kunde inte uppdatera API koppling',
    DELETED_API_CONNECTION: 'Fortnox Api koppling borttagen',
    UNABLE_GET_VOUCHER_SERIES: 'Kunde inte hämta Fortnox Verifikatserier',
    ACCOUNTING_METHOD: 'Bokföringsmetod',
    API_ERROR: 'Ett fel har uppstått med fortnox kopplingen',
    IS_CONNECTED: 'Fortnox är kopplat till denna förening',
    PAYMENT_ACCOUNT: 'Konto för betalning (fyra siffror)',
    FEE_ACCOUNT: 'Konto för Dinteros transaktionsavgifter (fyra siffror)',
    VOUCHER_SERIES: 'Verifikationsserie (en bokstav)',
    LEV_ACCOUNT: 'Konto för Leverantörsskuld (Fakturametoden)',
    CASH_METHOD: 'Kontantmetoden',
    INVOICING_METHOD: 'Fakturametoden',
    ENABLE_AUTO_BOOK_INFO: 'Automatisk bokföring sker efter transaktion har skett mot er bank genom en så kallad "Settlement". Denna transaktion kommer att ha summan av betalningen minus transaktionsavgiften. Detta innebär att verfikatet som skapas kommer att vara ARTIKELKOSTNAD MINUS AVGIFT',
    ENABLE_AUTO_BOOK: 'Aktivera automatisk bokföring',
    BUNDLE_BOOKS_INFO: 'Detta alternativ innebär att alla transaktioner kommer hamna i samma verifikat, ifall checkboxen är avmarkerad så kommer varje transaktion att bli sitt egna verifikat.',
    BUNDLE_BOOKS: 'Gruppera transaktioner i samma verifikat',
    ENABLE_FORTNOX_INVOICE_INFO: 'Fortnox fakturor skapas genom menyalternativet "Fortnox Fakturor"',
    ENABLE_FORTNOX_INVOICE: 'Aktivera Fortnox Fakturor',
    DELETE_CONNECTION: 'Ta bort koppling',
    AUTHORIZE_INSTRUCTIONS: 'För att koppla Fortnox till denna förening, auktorisera vår integration genom att klicka på knappen nedan och logga in om ni blir ombedda',
    AUTHORIZE: 'Auktorisera Fortnox',
  },

  DUPLICATE: {
    TAB_HEADER: 'Dubletter',
    MERGE_MEMBER_OPTION: 'Alternativ för Medlemsrader: ',
    MERGE_MEMBERS_INFORMATION: 'Medlemmar kommer att slås samman enligt följande regel. För de medlemmar där data saknas kommer datat behållas. Exempel: Oavsett vilket alternativ som är valt, ifall Berit Nilsson finns som medlem 2 gånger, och e-post saknas på den som skapades först, men finns på den som skapades sist, så kommer e-post att följa med på den slutgiltiga medlemmen.',
    MERGE_MEMBER_OPTIONS: {
      KEEP_NEWEST_CREATED: 'Behåll nyaste medlemmen',
      KEEP_OLDEST_CREATED: 'Behåll äldsta medlemmen',
    },
    MERGE_MEMBERSHIP_INFORMATION: 'Medlemskap från de andra medlemmarna kommer att läggas över på den slutgiltiga medlemmen. Följande regel bestämmer vilka medlemskap som ska behållas.',
    MERGE_MEMBERSHOP_OPTION: 'Alternativ för Medlemskap: ',
    MERGE_MEMBERSHIP_OPTIONS: {
      KEEP_ALL: 'Behåll alla medlemskap',
      REMOVE_EXACT_DUPLICATES: 'Ta bort medlemskap som är identiska i giltighet, förening, period, betalstatus och artikel.'
    },
    EXCEPTIONS: 'Undantag',
    CONFLICT_INFORMATION: 'Vissa medlemrader kan skapa konfliker ifall de slås samman. Ifall någon av dessa villkor är sanna kommer den medlemmen att exkluderas i körningen: ',
    CONFLICT_INFORMATION1: 'Ifall det finns aktiva förnyelser på flera medlemmar. Endast en förnyelse får vara aktiv på en medlem.',
    CONFLICT_INFORMATION2: 'Ifall en medlem är huvudmedlem och någon annan medlem är icke huvudmedlem.',
  },

  SCANQR: {
    REDIRECTING: 'Omdirigerar...',
    NO_TOKEN: 'Ingen token',
    NO_MEMBER: 'Medlem hittades ej'
  },

  SYSTEM: {
    ONLY_THS: 'Endast för THS',
    UPDATED: 'Systemet uppdaterades',
    UNABLE_UPDATE: 'Kunde inte uppdatera systemet',
    TEMPLATE: {
      INVITE_BY_EMAIL_TEMPLATE: 'Inbjudan till system',
      CONTACT_FORM: 'Kontaktformulär',
      STICKPROV_SMS: 'Stickprov SMS',
      STICKPROV_EMAIL: 'Stickprov E-post',
      SUBCOMPANY_REQUEST: 'Till ansökande: Anslutande ansökan',
      SUBCOMPANY_ACCEPT: 'Till ansökande: Anslutning godkänd',
      SUBCOMPANY_REJECT: 'Till ansökande: Anslutning nekad',
      PARENT_COMPANY_REQUEST: 'Till moderförening: Anslutande ansökan',
      GRANT_FINALIZED: 'Till ansökande: Ansökan är avklarad och avslutad',
      GRANT_MUST_HANDIN: 'Till ansökande: Ansökande förening måste lämna in kvitton för bidrag',
      GRANT_REJECT: 'Till ansökande: Ansökande förening får avslag på bidragsansökan',
      GRANT_ACCEPT: 'Till ansökande: Ansökande förening får godkännande av bidragsansökan',
      GRANT_CREATED: 'Till moderförening: Bidragsansökan skapad',
      GRANT_HAS_HANDIN: 'Till moderförening: Ansökande förening har lämnat in kvitton',
      GRANT_DELETED: 'Till moderförening: Ansökande förening har raderat ansökan',
      EVENT_JOINED: 'När medlem/person gått med i evenemang',
      MEMBER_JOINED: 'Medlem skapad',
      MEMBER_RENEW_PERIOD_SMS: 'SMS för medlemsförnyelse',
      MEMBER_RENEW_REMIND_SMS: 'Medlem förnyelse påminnelse SMS',
      MEMBER_RENEW_CONFIRM_SMS: 'Medlem förnyelse bekräftelse SMS',
      MEMBER_RENEW_PERIOD_EMAIL: 'Medlem förnyelse E-post',
      MEMBER_RENEW_REMIND_EMAIL: 'Medlem förnyelse påminnelse E-post',
      MEMBER_RENEW_CONFIRM_EMAIL: 'Medlem förnyelse bekräftelse E-post',
      PAYMENT_REMINDER: 'Medlem saknas betalning',
      PAYMENT_RECIEVED: 'Medlem betalning mottagen',
      PAYMENT_FAIL: 'Medlem betalning misslyckades',
      INVITE_NONMEMBER_FROM_EVENT: 'Till ickemedlem, inbjudan att bli medlem via evenemang',
      REDEEM_CODE_EMAIL: 'Kupong med kod (E-post)',
      MEMBER_APPLICATION_PENDING_TEMPLATE_ID: 'Medlemsansökan: Behandlas (E-post)',
      MEMBER_APPROVED_EMAIL_TEMPLATE_ID: 'Medlemsansökan: Godkänt (E-post)',
      MEMBER_REJECTED_EMAIL_TEMPLATE_ID: 'Medlemsansökan: Nekad (E-post)',
      ADMIN_EXIT_REQUESTED_TEMPLATE_ID: 'Admin: Uppsägning begärd notifikation (E-post)',
    }
  },

  RENEW_WIZARD: {
    UNABLE_GET_DATA: 'Kunde inte hämta data. Du kanske redan har förnyat ditt medlemskap?',
    ERROR_NOT_RENEWED1: 'Ditt konto blev inte förnyat. Försök igen senare.',
    ERROR_ALREADY_RENEWED1: 'Ditt medlemskap är redan förnyat - Denna länk är nu ogiltig',
    MEMBERSHIP_RENEWED: 'Ditt medlemskap har förnyats',
    MEMBERSHIP_RENEWED_HEADER: 'Förnyat',
    ALREADY_RENEWED: 'Redan förnyat'
  },

  COMPANY_PERMISSION: {
    GROUP: 'Grupp',
    USER: 'Användare',

    ASSIGNED_GROUP: 'Tilldelad',
    NAME: 'Namn',
    READ: 'Läs',
    ADMIN: 'Admin',
    YES: 'Ja',
    NO: 'Nej',
    PAGES: 'Sidor',
    ASSIGNED: 'Tillgång({num})',
    NOT_ASSIGNED: 'Ingen tillgång',
    PERMISSIONS: 'Rättigheter'
  },

  LADOK: {

    XLSX_JOB_HEADER: 'Exportkörning startad',
    XLSX_JOB_INFO: 'Exporter kan ta flera minuter. När exporten är klar finns filen att ladda hem under "Filer".',
    
    PERIOD: 'Period',
    SEARCH_INPUT: 'Sök',
    MEMBER: 'Medlem',
    STATUS: 'Status',
    STATUSES: {
      MEMBER: 'Medlem',
      NOT_MEMBER: 'Ej medlem',
      ALL: 'Alla'
    },
    SEARCH: 'LADOK sökning',
    SEARCH_HEADER: 'Sök',
    SEARCH_PLACEHOLDER: 'StudentId, Medlemsnummer, Personnummer, Kurskod, Programkod...',
    
    KURSKOD: 'Kurskod',
    PROGRAMKOD: 'Programkod',
    
    FILE_ERRORS: {
      NOT_ENOUGH_CAPTIONS: 'Inte tillräkligt med kolumner',
      MISSING_HEADER: 'Saknar kolumn med namn',
      INVALID_PERSONNR: 'Ogiltigt personnummer',
      NO_DELIMITER: 'Avgränsare saknas, kolumnerna måste avgränsas med ; tecken ',
      NO_ROWS: 'Inga rader i filen'
    },
    UNABLE_CREATE: 'Kunde inte skapa LADOK körning',
    UNABLE_START: 'Kunde inte starta LADOK körning',
    CREATED: 'LADOK körning skapad',
    STARTED: 'LADOK körning startad',
    UNABLE_UPLOAD: 'Kunde inte ladda upp fil',
    UNKNOWN_ERROR: 'Ett okänt fel har uppstått',
    ERROR_IN_FILE: 'LADOK filen har fel',
    ERROR_REASON: 'Felmeddelande: ',
  },

  COMPANY: {
    POSTMARK_INBOUND_EMAIL: 'Inkommande e-post',
    COUNT: '#',
    SELECTED_COMPANY: 'Vald förening',
    SELECT_TO_EDIT: 'Klicka på en förening nedan för att kunna redigera',
    NEW: 'Ny förening',
    EDIT: 'Redigera förening',
    CREATE: 'Skapa förening',
    UPDATE: 'Uppdatera förening',
    DELETE: 'Radera förening',
    COMPANY_NAME: 'Föreningsnamn',
    CNUM_ALREADY_EXISTS: 'Förening med nummer {cnum} finns redan',
    COMPANY_HIERARCHY: 'Föreningshierarki',
    YOU_ARE_EDITING: 'Du redigerar förening {company}',
    YOU_ARE_CREATING: 'Du kommer att skapa en underförening till {company}',
    CONFIRM_DELETE: 'Är du säker på att du vill ta bort föreningen?',
    DELETE_HEADER_CONFIRM: 'Bekräfta borttagning',
    SUBCOMPANIES: 'Underföreningar',
    COMPANIES: 'Föreningar',
    COMPANY: 'Förening',
    REG_DATE: 'Reg datum',
    VFDT: 'Från datum',
    VTDT: 'Till datum',
    ADD_MEMBERSHIP: 'Lägg till medlemskap',
    COMPANY_ID: 'FöreningsID',
    NAME: 'Namn',
    SHORTNAME: 'Kortnamn',
    UPLOAD_LOGO: 'Ladda upp logotyp',
    CNUM: 'Föreningsnummer',
    TYPE: 'Typ',
    ORG_NUMBER: 'Org. nummer',
    VAT: 'Momsnummer',
    MPID: 'Medlemslöpnummer',
    PC_ID: 'ModerföreningsID',
    STREET: 'Gatuadress',
    CO: 'C/o',
    ZIPCODE: 'Postnummer',
    POST: 'Postort',
    REGION_CODE: 'Region',
    COMMUNE_CODE: 'Kommun',
    EMAIL: 'E-post (ej offentlig)',
    PHONE: 'Telefon (ej offentlig)',
    LANDLINE: 'Fast telefon',
    C_NAME: 'Kontakt namn',
    C_EMAIL: 'Kontakt e-post',
    C_PHONE: 'Kontakt telefon',
    WEB: 'Hemsida',
    MUCF_INCLUDE: 'Inkludera i MUCF beräkningarna',
    PCINC: 'Statistik ska inkluderas i moderföreningen',
    RIKS: 'Riksförbund',
    DEFAULT: 'Förening',
    STUDENT: 'Studentkår',
    WORKGROUP: 'Arbetsgrupp',
    UNABLE_GET: 'Kunde inte hämta förening',
    UNABLE_GET_NOTES: 'Kunde inte hämta anteckningar',
    UNABLE_LIST: 'Kunde inte lista förening',
    UNABLE_DELETE: 'Kunde inte radera förening',
    UNABLE_CREATE: 'Kunde inte skapa förening',
    UNABLE_UPDATE: 'Kunde inte uppdatera förening',
    UNABLE_UPDATE_NOTES: 'Kunde inte uppdatera anteckningar',
    CREATED: 'Förening skapad',
    UPDATED: 'Förening uppdaterad',
    UPDATED_NOTES: 'Anteckningar uppdaterad',
    DELETED: 'Förening raderad',
    ALL_COMPANIES: 'Alla föreningar',
    CONFIRM_DELETE: 'Bekräfta borttagning',
    CONFIRM_DELETE_MESSAGE: 'Är du säker på att du vill ta bort föreningen?',
    RENEWABLE: 'Förnya medlemskap till nästa period',
    THS_NOTES: 'THS Anteckningar',
    ACCOUNT_NO: 'Kontonummer',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Adress+Postnummer+Postort',
    BANK_NAME: 'Bank',
    BG: 'Bankgiro',
    PG: 'Plusgiro',
    CREATED_AT: 'Skapad datum',
    IBAN: 'IBAN',
    PC_CNUM: 'Moderförening',
    SKIP: 'Hoppa över',
    SWIFT_BIC: 'SWIFT/BIC',
    'ZIPCODE+POSTADDRESS': 'Postnummer+Postort',
    CLEARING_NO: 'Clearing nummer',
    SWISH: 'Swish',
    INVOICE_HEADING: 'Fakturaadress',
    INVOICE_CO: 'C/o',
    INVOICE_ADDRESS: 'Adress',
    INVOICE_ZIPCODE: 'Postnummer',
    INVOICE_POST: 'Postort',
    INVOICE_COUNTRY: 'Land',
    INVOICE_EMAIL: 'E-post',
  },
  CUSTOMER: {
    CUSTOMERS: 'Kunder',
    CREATE: 'Skapa kund',
    LOGO: 'Logotyp',
    NAME: 'Kund',
    CREATED_AT: 'Skapad',
    DELETE_CONFIRM_TITLE: 'Radera kund',
    DELETE_CONFIRM_MESSAGE: 'Bekräfta att du vill radera kunden',
    CONTACT_NAME: 'Kontaktnamn',
    ADMIN_PHONE: 'Administratörs telefon',
    ADMIN_EMAIL: 'Administratörs e-post',
    ROOT_COMPANY_ID: 'Huvudförening',
    ID: 'ID',
    CONTACT_INFORMATION: 'Kontaktinformation',
    CUSTOMER_EDITOR: 'Redigera kund',
    BASIC_INFORMATION: 'Grundläggande information'
  },
  ACCOUNT: {
    NO_BANKID_DATA: 'Oglitig BankID data',
    INVALID_BANKID_TOKEN: 'Ogiltig BankID token',
    INVALID_LINK2: 'Denna länk är ogiltig.',
    TAB_BANKID: 'BankID',
    TAB_EMAIL: 'E-post/Lösenord',
    NO_USER: 'Ingen användare',
    NO_USER_INFO: 'Det finns ingen användare med det personnummret. Kontakta administratör.',
    START_BANKID_SIGN: 'Legitimera dig',
    MOBILE_BANKID: 'Mobilt BankID på annan enhet',
    OPEN_BANKID: 'Öppna BankID',
    BANKID_ERROR: 'BankID fel',
    START_BANKID_QR: 'Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i appen för att skanna QR-koden på den här sidan.',
    LOGIN_BANKID: 'Logga in med BankId',
    LOGGING_IN: 'Loggar in...',
    NO_ACCESS_HEADER: 'Ingen tillgång',
    NO_ACCESS_INFO1: 'Ditt konto har inte tillgång till någon sida.',
    NO_ACCESS_INFO2: 'Be administratör att lägga till dig på minst en sida i en förening för att kunna fortsätta.',
    MY_PROFILE: 'Min profil',
    LOGIN: 'Logga in',
    LOGOUT: 'Logga ut',
    LACKS_ACCESS: 'Du lyckades logga in, men du har inte tillgång till någon sida. Be administratören att ge dig tillgång till minst en sida för att kunna fortsätta.',
    INVALID_EMAIL_PASSWORD: 'Fel e-post eller lösenord',
    PASSWORD_RULE_NOT_MET: 'Ogiltigt lösenord',
    TO_LOGIN: 'Till inloggningen',
    PASSWORD_WAS_CHANGED: 'Lösenordet uppdaterades',
    PASSWORDS_MISMATCH: 'Lösenorden måste vara likadana',
    PASSWORDS_6CHAR: 'Lösenordet måste vara minst 6 tecken',
    UNABLE_RESET: 'Kunde inte återställa lösenord',
    RESET: 'Lösenordet har återställts',
    UPDATE_PASSWORD: 'Uppdatera lösenord',
    RESET_CLICK: 'Clicka för att skicka länk',
    LINK_SENT: 'Länk skickad',
    LINK_SENT_INFO: 'Kontrollera din e-post för instruktioner',
    ENTER_EMAIL: 'Ange e-post för att få instruktioner',
    RENEW_YOUR_MEMBERSHIP: 'Förnya ditt medlemskap',
    YOU_CAN_RENEW: 'Nu kan du förnya ditt medlemskap',
    RENEW_MEMBERSHIP: 'Förnya medlemskap',
    INVALID_LINK: 'Felaktig inloggningslänk. Kontakta din förening för att begära en korrekt länk.'
  },
  ONBOARDING: {
    CREATE: 'Skapa',
    NAME: 'Onboarding namn',
    CREATE_ONBOARDING: 'Skapa onboarding',
    NEW_STEP: 'Nytt steg',
    NEW_GROUP: 'Ny grupp',
    GROUP_NAME: 'Gruppnamn',


    CREATED: 'Onboarding skapad',
    STEP_CREATED: 'Onboarding steg skapat',
    UNABLE_CREATE: 'Kunde inte skapa onboarding',
    UNABLE_CREATE_STEP: 'Kunde inte skapa onboarding steg',

    UPDATED: 'Onboarding uppdaterad',
    UNABLE_UPDATE: 'Kunde inte uppdatera onboarding',
    UNABLE_UPDATE_STEP: 'Kunde inte uppdatera onboarding steg',
    UNABLE_DELETE: 'Kunde inte ta bort onboarding',
    UNABLE_DELETE_STEP: 'Kunde inte ta bort onboarding steg',

    CREATE_GROUP: 'Skapa grupp',
    HEADER: 'Onboarding',
    STATUSES: {
      PENDING: 'Väntar',
      BLOCKED: 'Blockat',
      SKIP: 'Hoppa över',
      DELETED: 'Raderat',
      DONE: 'Klart',
    },
    DEPARTMENTS: {
      SALES: 'Sälj',
      SUPPORT: 'Support',
      TECH: 'Tech',
      CUSTOMER: 'Kund',
    }
  },
  SHOP_ITEM: {
    IS_DONATION_INFO: 'Donationsartikel som kan användas publikt',
    IS_DONATION: 'Är donation',
    SHOW_INACTIVE: 'Visa inaktiva',
    AMOUNT: 'Pris',
    AMOUNT_VAT: 'Moms',
    UNABLE_UPDATE: 'Kunde inte uppdatera artikeln',
    UPDATED: 'Artikeln uppdaterades',
    IS_ACTIVE: 'Aktiv',
    ERROR: 'Status',
    ERRORS: {
      NO_PERIOD_TYPE: 'Periodtyp',
      NO_PERIOD: 'Saknar period',
      OK: 'OK',
      NO_NEXT_PERIOD: 'Ingen nästa period',
      NO_NEXT_SHOP_ITEM: 'Ingen nästa artikel',
    },
    MUST_CREATE_FIRST: 'Artikeln måste skapas innan bild kan laddas upp',

    NEXT_ARTICLE_INFO: 'Viktigt! Ifall ni använder förnyelser behöver medlemskapets nästa medlemskapsartikel och period ställas in.',

    PRIORITY_INFO: 'Detta bestämmer ordningen som artiklarna visas i registreringen. 0 är högst upp, 1 hamnar under 0, 2 hamnar under 1 osv.',
    IS_ACTIVE_INFO: 'En aktiv artikel kommer alltid att visas i registreringen ifall denna förening är vald av medlemmen.',

    IS_DEFAULT_INFO: 'En förinställd artikel krävs ifall medlemmen ej får något val av medlemskap under registreringen. Väljer man att dölja medlemskap/butikssidan i registreringen så har medlemmen ingen möjlighet att välja artikel, då måste denna checkbox vara markerad.',

    RENEWAL: 'Förnyelseinställningar',

    VISIBILITY: 'Synlighet',

    PERIOD_TYPE: 'Periodtyp för medlemskap',

    ERROR_DEFAULT_MUST_NOT_COST: 'Förvalt medlemskap får ej ha pris satt',
    ERROR_CREATE_WITH_NAME: 'Måste ha namn',
    ERROR_PERIOD_TYPE_MUST_BE_SET: 'Periodtyp måste sättas',
    ERROR_ONLY_MEMBERSHIPS_ALLOWED: 'Endast medlemskap är tillåtet',
    ERROR_MEMBERSHIP_NO_PERIOD_TYPE: 'En medlemskapsartikel måste ha en typ av period satt',
    ERROR_NUM_DAYS_MUST_SET: 'Antal dagar måste vara större än 0 för dagsmedlemskap',
    ERROR_INTERVAL_MUST_SET: 'Datumintervall är ogiltig',
    ERROR_INTERVAL_DATE_FROM_TO: 'Datumintervall måste vara från < till',
    ERROR_MISSING_PERIODS: 'Saknas perioder för periodmedlemskap',

    CREATING: 'Skapar artikel',
    UPDATING: 'Uppdaterar artikel',

    FROM_DATE: 'Från datum',
    TO_DATE: 'Till datum',

    CREATE_FOR_NEXT: 'Skapa för nästa period',
    NEXT_SHOP_ITEM_ID: 'Nästa artikel för förnyelse',
    NEXT_PERIOD_ID: 'Nästa period för förnyelse',
    DAYS: 'Medlemskapet gäller i antal dagar',
    PERIODS: 'Medlemskapet gäller i antal perioder',
    RANGE: 'Medlemskapet gäller i datumintervall',
    IS_DEFAULT: 'Är förinställd för "{company_name}"',
    IS_FAMILY: 'Är familjemedlemskapsavgift',
    PERIOD: {
      INFO: 'Medlemsavgiften avser följande perioder',
      NAME: 'Namn',
      SELECT: 'Välj',
      FROM: 'Period från',
      TO: 'Period till'

    },
    TYPE: {
      UNKNOWN: 'Okänt',
      REGISTER: 'Registrering',
      RENEW: 'Förnyelse',
      TICKET: 'Biljett',
      COMPANY: 'Förening',
      MEMBER: 'Medlem',
      REDEEM_CODE: 'Kupong',
      STRIPE_INVOICE: 'Stripefaktura'
    }
  },
  INVOICE: {
    CREATE_BGMAX: 'Ny BGMax fil',
    MISSING_ACCOUNT_NO: 'Kontonummer måste sättas för att skapa BGMax fil',
    DOWNLOADING: 'Fakturan laddas ned...',
    CAN_INVOICE_ERROR: 'Kan endast skicka "Ej betalade" fakturor.',
    TEXT: 'Fritext',
    MATCH_DELETED: 'BGMax rad raderad',
    UNABLE_DELETE_MATCH: 'Kunde inte radera BGMax rad',
    CONFIRM_DELETE: 'Är du säker på att du vill makulera den valda fakturan? Detta kommer ändra statusen på fakturan till makulerad.',
    DELETED: 'Faktura makulerad',
    UNABLE_DELETE: 'Kunde ej makulera faktura',
    PAYMENT_CREATED: 'Betalning skapades',
    UNABLE_CREATE_PAYMENT: 'Kunde inte skapa betalning',
    TOTAL_SEK: 'Total',
    AMOUNT_PAID: 'Betalad summa',
    PAID_AT: 'Betaldatum',
    SUM: 'Summa',
    MARK_PAID_MEMBERSHIP: 'Markera medlemskap som betalat',
    MUST_SELECT_TEMPLATE: 'Måste välja mall',
    TEMPLATE_MISSING_INVOICE_PDF_LINK: 'Mallen saknar taggen %INVOICE_PDF_LINK%, kan ej skicka.',
    EMAIL_SENT: 'E-post skickat',
    UNABLE_SEND_EMAIL: 'Kunde inte skicka e-post',
    SEND_EMAIL: 'Skicka faktura via e-post',
    NO_SHOP_ITEM: 'Fel: Ingen artikel',
    SHOP_ITEM: 'Artikel',
    AMOUNT: 'Summa',
    INVALID_AMOUNT: 'Ogiltig summa',
    NO_UNPAID_MEMBERSHIPS: 'Inga obetalade medlemskap hittades',
    SELECT_MEMBERSHIPS: 'Välj medlemskap att fakturera',
    SELECT_MEMBERSHIPS_INFO: '',
    CREATING: 'Skapar faktura',
    EDITING: 'Redigerar faktura',
    INVOICE_TEMPLATE: 'Fakturamall',
    CREATE: 'Skapa faktura',
    CREATED: 'Faktura skapad',
    DELETE: 'Radera faktura',
    NUM_ROWS: 'Antal rader',
    UPDATE_STATUS: 'Uppdatera status',
    PAYMENT_STATUS: 'Betalstatus',
    PRINTED: 'Utskriven',
    INVOICE: 'Faktura',
    INVOICES: 'Fakturor',
    NO_INSURANCES: 'Inga försäkringar',
    NO_INVOICES: 'Inga fakturor',
    DOWNLOAD: 'Ladda ned',
    CREATED_AT: 'Skapat',
    INVOICE_NUMBER: 'Fakturanummer',
    INVOICE_DATE: 'Fakturadatum',
    DUE_DATE: 'Förfallodatum',
    AMOUNT: 'Summa',
    STATUS: 'Status',
    IS_PAID: 'Betalstatus',

    NONE_FOUND: 'Inga fakturor hittades',
    ORDERBY: 'Sortering',
    ZIPCODE: 'Postnummer',
    INVOICE_NO: 'Fakturanummer',
    MATCHED: 'Kopplad',
    UNABLE_MATCH: 'Kunde inte koppla betalning',
    EDIT_BGMAX: 'Redigera Bgmax',
    INVOICE_ID: 'Fakturanummer',
    RECIPIENT: 'Mottagare',
    INVOICE: 'Faktura',
    PAYMENT: 'Betalning',
    MATCH_PAYMENT_INFO: 'Följande betalning kommer att matchas mot nedanstående faktura',
    MATCH_INVOICE: 'Koppla betalning',
    MATCH_OCR_INFO: 'I tabellen nedan kan du välja vilken faktura som transaktionen tillhör genom att klicka på (+) knappen.',
    CREATED_AT: 'Skapad datum',
    FIND_MATCH: 'Välj faktura som tillhör transaktionen',
    FIND_INVOICE: 'Hitta faktura',
    MANUAL_FIX_INVOICE_ID: 'Matcha till fakturanummer: ',
    AMOUNT: 'Summa',
    ERROR: 'Fel',
    STATUS: 'Status',
    STATUSES: {
      NONE: 'Inget',
      PENDING_SEND: 'Levereras',
      WAITING_PAYMENT: 'Inväntar betalning',
      PAID: 'Betalad',
      DELETED: 'Makulerad'
    },
    BGMAX_ERROR_ERROR: {
      UNMATCHED: 'Ingen match',
      ALREADY_PAID: 'Redan betalat',
      UNKNOWN: 'Okänt'
    },
    BGMAX_ERROR_STATUS: {
      NONE: 'Ej hanterat',
      MATCHED: 'Matchat',
      DELETED: 'Raderat',
      IGNORE: 'Ignorerat'
    },
    PAYMENT_STATUS: 'Betalstatus',
    PAYMENT_STATUSES: {
      LEGACY: 'Ogiltigt',
      FREE: 'Gratis',
      NOT_PAID: 'Ej betalad',
      PARTIALLY_PAID: 'Delvis betalad',
      PAID_IN_FULL: 'Betalad',
      REFUND: 'Återbetalad'
    },
    TAB_INVOICES: 'FAKTUROR',
    TAB_BGMAX: 'BGMAX',
    TAB_BGMAX_ERRORS: 'BGMAX FEL',
    TAB_COMPANIES: 'FÖRENINGAR',
    TAB_TEMPLATES: 'MALLAR',
    TAB_SETTINGS: 'INSTÄLLNINGAR',
    UNABLE_DOWNLOAD: 'Kunde inte ladda hem faktura',
    RECREATED: 'Avi/Faktura har skapats om',
    UNABLE_BGMAX_ERROR_GET: 'Kunde inte hämta BGMAX fel',
    UNABLE_RECREATE: 'Kunde inte skapa om Avi/faktura',
    UNABLE_CREATE: 'Kunde inte skapa Avi/faktura',
    CREATED: 'Faktura/Avi skapad',
    OCR: 'OCR',
    DUE_DATE: 'Förfallodatum',
    CREATE: 'Skapa Avi/faktura',
    INVOICE_CREATE_SHOP_ITEM_MISSING_INFO: 'Butiksartikel saknas på en eller flera valda medlemskap, du måste sätta artikeln innan du kan skapa faktura/avi.',
    INVOICE_CREATE_DIFFERENT_PERIODS: 'Valda medlemskap har olika perioder, de måste vara samma period för att kunna fortsätta.',
    MESSAGE: 'Text i fakturan',
    GENERATION: 'Fakturagenerering',
    HAS_EMAIL: 'Har e-post',
    MISSING_EMAIL: 'Saknar e-post',
    MARK_PRINTED_INFO: 'En körning kommer att startas för att skapa PDF filer av fakturorna. När körningen är klar kommer filen finnas tillgänglig under "Filer"',
    NOT_MARK_PRINTED_INFO: 'Denna körning kommer endast att skapa "fakturor" för förnyelserna enligt alternativen.',
    CREATE_PDF: 'Generera PDF fil (fakturor markeras som "utskriven")',
    NUM_SELECTED: 'Antal markerade',
    PAYMENT_STATUS: 'Betalstatus',
    RENEW_STATUS: 'Förnyelsestatus',
    CREATED_TO: 'Skapad till och med',
    CREATED_FROM: 'Skapad från och med',
    CREATE_FOR_FILTERS: 'Skapa från filter',
    CREATE_FOR_SELECTION: 'Skapa från markerade',
    COUNT_SELECTED: 'Antal valda fakturor',
    JOB_INFO: 'Denna körning kommer att skapa individuella PDF filer som sedan slås samman till en stor PDF. Fakturor i körningen kommer att markeras som "Utskrivna" och därför kommer inte kunna tas med i framtida körningar. När filen är klar kommer denna att kunna hämtas från "Filer" i menyn till vänster.',
    PAID: 'Betalat',
    WAIT: 'Obetalat',
    LATE: 'Försenat',
    PAID_AT: 'Betaldatum',
    PRINTED: 'Utskriven',
    TOTAL_SEK: 'Total',
    MEMBER: 'Medlem',
    NUMBER: 'Fakturanummer',
    SELECTED: 'Vald',
    NOT_PRINTED: 'Ej utskriven',
    PARTIALLY_PAID: 'Delvis betalad',
    NOT_PAID: 'Ej betalad',
    STATUS: 'Status',
    CREATE_STRIPE_INVOICE: 'Skapa Stripe Faktura',
    RUN_JOB: 'Starta körning',
    EMAIL_SENDOUT: 'E-postutskick',
    EMAIL_SENDOUT_INFO: 'Samtliga resultat i tabellen kommer att få e-post skickat till sig med vald mall.',
    REMINDER_DUE_DATE: 'Nytt förfallodatum (endast påminnelse)',
    EMAIL_TEMPLATE: 'E-postmall',
    UNABLE_LIST_TEMPLATES: 'Kunde inte lista mallar',
    INVALID_TEMPLATE: 'Ogiltig, måste välja e-postmall',
    UNABLE_GET_COUNT: 'Kunde inte hämta antal fakturor',
    CREATE_PDF: 'Skapa PDF',
    PDF_TITLE: 'Skapa PDF fil',
    PDF_TEMPLATES: 'Mallar',
    INVALID_PDF_TEMPLATE: 'Ogiltig, måste välja en PDF mall',
    UNABLE_LIST_TEMPLATES: 'Kunde inte lista mallar',
    RECIPIENT: 'Mottagare',
    INVOICE_TEMPLATE: 'Fakturamall (PDF)',
    INVOICING: 'Fakturering',
    CONFIRM_DELETE: 'Bekräfta att fakturan skall makuleras',
    JOB_RESULT: 'Resultat av körning',
    JOB_RESULT_BAD_TEXT: 'Kunde inte skapa e-post för {num} fakturor.',
    JOB_RESULT_GOOD_TEXT: 'Lyckades skapa e-post för {num} fakturor.'
  },
  EXPORT: {
    MEMBER_LETTERS_CREATED: 'En körning har skapats. Denna körning kan ta mycket lång tid beroende på hur många medlemmar som fanns i resultatet. När filen är klar finns den att ladda hem under "Filer".',
    EXPORT: 'Exportera',
    EXCEL: 'Excel',
    LETTER_LABELS: 'Etiketter',
    PRINT_LABELS: 'Skriv ut etiketter'
  },
  EVENT: {
    QUESTIONS: 'Frågor',
    EVENT: 'Evenemang',
    FROM_DATETIME: 'Från',
    TO_DATETIME: 'Till',
    VAT_OPTION: 'Visningsalternativ för moms',
    VAT_OPTIONS: {
      INC_VAT: 'Inkl. moms',
      EX_VAT: 'Exkl. moms'
    }
  },
  MEMBER_EVENT: {

    EVENT_NAME: 'Evenemang',
    SIGNUP: 'Anmälan',
    NO_FOUND: 'Inga evenemang hittades'
  },
  MEMBERSHIP: {
    PERIOD: 'Period',
    CREATED: 'Medlemskap skapat',
    UPDATED: 'Medlemskap uppdaterat',
    DELETED: 'Medlemskap raderat',
    UNABLE_CREATE: 'Kunde inte skapa medlemskap',
    UNABLE_UPDATE: 'Kunde inte uppdatera medlemskap',
    UNABLE_DELETE: 'Kunde inte radera medlemskap',
    UNABLE_GET: 'Kunde inte hämta medlemskap',
    CREATE: 'Skapa medlemskap',
    UPDATE: 'Uppdatera medlemskap',
    DELETE: 'Radera medlemskap',
    CONFIRM_DELETE: 'Är du säker på att du vill radera medlemskapet?',
    SHOP_ITEM: 'Artikel',
    CREATING: 'Skapar medlemskap',
    EDITING: 'Redigerar medlemskap',
    EDIT_MEMBERSHIP: 'Redigera medlemskap',
    MEMBERSHIPS: 'Medlemskap',
    NO_MEMBERSHIPS_FOUND: 'Inga medlemskap hittades',
    VFDT: 'Giltig från',
    VTDT: 'Giltig till',
    REG_AT: 'Reg datum',
    IS_PAID: 'Är betalad'
  },
  PAYMENT: {
    PAYMENTS: 'Betalningar',
    ARTICLE: 'Artikel',
    TYPE: 'Typ',
    CREATED_AT: 'Skapad',
    AMOUNT: 'Summa',
    STATUS: 'Status',
    RECEIPT: 'Kvitto',
    DOWNLOAD: 'Ladda ned',
  },
  MEMBER_RENEW: {
    ACTIVATE_ALL: 'Autoförnyelse på alla',
    DEACTIVATE_ALL: 'Ingen autoförnyelse på alla',
    AUTORENEW_SETTINGS: 'Autoförnyelser',
    SET_AUTORENEW_ALL: 'Aktivera/Deaktivera autoförnyelse för alla medlemmar',
    SENDOUT_SETTINGS: 'Utskick och mallar',

    
    INVOICE_ERROR1: 'Faktureringsfel 1: Faktura kan ej skapas då det helt saknas medlemskap att förnyas från',
    INVOICE_ERROR2: 'Faktureringsfel 2: Faktura kan ej skapas då det saknas artikel på medlemskapet',
    INVOICE_ERROR3: 'Faktureringsfel 3: Faktura kan ej skapas då artikeln på medlemskapet som ska förnyas ej har satt "Nästa artikel för förnyelse"',

    PAYMENT_STATUS: 'Betalstatus',
    TO_PERIOD_INFINITE: 'Löpande',

    SENDOUT_METHODS: {
      EMAIL: 'Epost',
      SMS: 'SMS'
    },
    METHOD: 'Metod',
    SENDOUT_NONE_FOUND: 'Inga utskick hittades',

    CONFIRM_CREATE_RENEWAL_BATCH: 'Är du säker på att förnyelser skall skapas för medlemmar i sökresultatet? Förnyelselänkar kommer att skickas med angivna utskicksmetoder.',
    COUNT_MEMBERS: 'Antal medlemmar: {count}',
    COUNT_MEMBERS_TO_RENEW: 'Antal medlemmar',

    RENEWAL_TYPE_INFO: '"Förnyelser": Registreringswizard och betalning. "Bekräftelse": Enkelt formulär där ingen betalning krävs.',

    EMAIL_TEMPLATE: 'E-postmall',
    SMS_TEMPLATE: 'SMSmall',

    UNABLE_CREATE_BATCH: 'Kunde inte skapa förnyelsekörning',
    BATCH_CREATED: 'Förnyelsekörning skapad',

    CREATE_RENEW_BATCH: 'Skapa förnyelser från resultat ({count})',

    CREATING_BATCH: 'Skapar manuell körning',
    EDITING_BATCH: 'Redigerar manuell körning',

    CREATE_RENEWS: 'Skapa förnyelser',

    SMS_EMAIL_SET_INFO: 'Medlemmar i sökresultatet kommer att få en förnyelselänk skickad till sig när du klickar på "Bekräfta Förnyelse"',

    PERIOD_FROM: 'Förnya från period',
    PERIOD_TO: 'Förnya till period',

    MEMBERS_TO_RENEW_INFO: 'Här skapar man en förnyelsekörning för de medlemmar som visades i tabellen. Medlem som får utskick kommer kunna klicka på länk och förnya medlemskap direkt.',
    SHORT_LINK: 'Länk till förnyelsen',
    INFINITE_NOT_PERIOD_DAYS: 'Löpande perioder kräver medlemskap med "Antal dagar" satt.',
    UNPAID_MEMBERSHIPS: 'Det finns obetalade medlemskap på medlemmen',
    ALREADY_HAVE_PERIOD: 'Det finns redan en aktiv förnyelse för {period}.',
    DO_SEND: 'Skicka',
    DONT_SEND: 'Skicka ej',
    SEND_FAIL: 'Fel uppstod',
    TRY_SEND: 'Pågående utskick',
    EMAIL_OPTION: 'Skicka e-post',
    SMS_OPTION: 'Skicka SMS',
    FROM_PERIOD: 'Från period',
    TO_PERIOD: 'Till period',
    STATUS: 'Status',
    RENEW_TYPES: {
      AUTOMATIC: 'Automatisk',
      MANUAL_SINGLE: 'Administratör - Enskild',
      MANUAL_BATCH: 'Administratör - Körning'
    },
    CREATED: 'Förnyelse skapad',
    UNABLE_CREATE: 'Kunde inte skapa medlemsförnyelse',
    DELETED: 'Förnyelse raderad',
    UNABLE_DELETE: 'Kunde inte radera förnyelse',
    SCHEDULED_AT: 'Schemalagd till',
    INVALID_DATE: 'Ogiltigt datum',
    UNABLE_GET_SHOP_ITEMS: 'Kunde inte hämta butiksartiklar',
    CREATE_RENEW: 'Skapa förnyelse',
    RENEWAL_TYPE: 'Förnyelsetyp',
    IS_CONFIRM: 'Är bekräftelse',
    IS_RENEWAL: 'Är förnyelse',
    STATUSES: {
      SCHEDULED: 'Schemalagd',
      ACTIVE: 'Inväntar svar',
      STARTED_LINK: 'Påbörjad av medlem',
      INVOICED: 'Fakturerad',
      TIME_OUT: 'Ej besvarad',
      AWAITING_PAYMENT: 'Inväntar betalning',
      DONE: 'Förnyad - Avslutad',
      DELETED: 'Borttagen - Avslutad'
    },
    SOURCE: 'Källa',
    SOURCES: {
      MEMBER_REQUEST: '',
      NONE: 'Inget',
      MANUAL_SINGLE: 'Admin - Manuell',
      MANUAL_BATCH: 'Admin - Batch',
      AUTOMATIC: 'Automatisk',
      MEMBER_REQUEST: 'Medlem'
    },
    DETAILS: 'Detaljer',
    SENDOUTS: 'Utskick',
    PERIOD_TYPE_INFINITE: 'Löpande',
    PERIOD_TYPE_PERIOD: 'Kalenderår',
    WIZARD: {
      HEADER: 'Förnyelseguiden',
      INTRO_HEADER: 'Introduktion',
      INTRO_INFO1: 'Förnyelseguiden hjälper er att ställa in förnyelseinställningarna för er förening. ',
      INTRO_INFO2: 'För att komma igång, klicka på knappen nedan.',
      PERIOD_HEADER: 'Period',
      PERIOD_INFO1: 'Er förening är inställd på följande vis gällande perioder: {type}',
      PERIOD_INFINITE_INFO: 'När en medlem förnyar sig kan det ske när som helst under året och medlemskapet kommer att gälla i den längd som medlemskapet har bestämt.',
      PERIOD_PERIOD_INFO: 'Kalenderår innebär att när medlemmen förnyar sig så kommer medlemskapet att gälla från 1 januari till 31 december under nästkommande år.',
      PERIOD_INFO2: 'Fortsätt till nästa steg',
      SELECTED_PERIOD_INFO: 'Förnyelse sker från {from_period} till period {to_period}, ifall ni vill förnya till en annan period, byt period högst upp på sidan.',

      EMAIL_OPTION_HEADER: 'E-post',
      EMAIL_OPTION_INFO1: 'Välj om medlemmen ska få ett e-postmeddelande när medlemskapet är på väg att gå ut.',
      EMAIL_OPTION_YES: 'Ja, e-postmeddelande ska skickas',
      EMAIL_OPTION_NO: 'Nej, e-postmeddelande ska inte skickas',

      EMAIL_NUM_REMINDERS_INFO: 'Ange hur många e-postmeddelanden utöver det första e-postmeddelandet som ska skickas som påminnelser.',
      EMAIL_NUM_REMINDERS: 'Antal e-postmeddelanden påminnelser',

      EMAIL_TEMPLATE_INFO: 'Ange vilket e-postmeddelande (mall) som ska skickas till medlemmen.',
      EMAIL_TEMPLATE_REMINDER_INFO: 'Ange vilket e-postmeddelande (mall) som ska skickas till medlemmen som påminnelse.',

      EMAIL_NUM_DAYS_WAIT: 'Hur många dagar ska vi vänta innan vi skickar påminnelser?',

      SMS_OPTION_HEADER: 'SMS',
      SMS_OPTION_INFO1: 'Välj om medlemmen ska få ett SMS när medlemskapet är på väg att gå ut.',
      SMS_OPTION_YES: 'Ja, SMS ska skickas',
      SMS_OPTION_NO: 'Nej, SMS ska inte skickas',

      SMS_NUM_REMINDERS_INFO: 'Ange hur många SMS utöver det första SMS:et som ska skickas som påminnelser.',
      SMS_NUM_REMINDERS: 'Antal SMS påminnelser',

      SMS_TEMPLATE_INFO: 'Ange vilket SMS (mall) som ska skickas till medlemmen.',

      SMS_TEMPLATE_REMINDER_INFO: 'Ange vilket SMS (mall) som ska skickas till medlemmen som påminnelse.',

      SMS_NUM_DAYS_WAIT: 'Antal dagar innan SMS påminnelse skickas',

      INVOICE_OPTION_HEADER: 'Faktura',
      INVOICE_OPTION_INFO1: 'Ska medlemmarna få en faktura/avi hemskickad till sig?',

      INVOICE_OPTION_YES_DIRECT: 'Ja, faktura ska skapas direkt när det är dags för förnyelse',
      INVOICE_OPTION_YES_LATER: 'Ja, men fakturan skall inte skapas förrän e-post och SMS påminnelserna har skickats och medlemmen inte har förnyat inom angiven tid',
      INVOICE_OPTION_NO: 'Nej, ingen faktura/avi ska skapas',

      INVOICE_NUM_REMINDERS_INFO: 'Ange hur många påminnelser som ska skickas utöver den första fakturan/avin.',
      INVOICE_NUM_REMINDERS: 'Antal fakturapåminnelser',
      INVOICE_TEMPLATE_INFO: 'Välj vilken fakturamall som ska användas',
      INVOICE_TEMPLATE_REMINDER_INFO: 'Välj vilken fakturamall som ska användas för påminnelse',
      INVOICE_REMINDER_NUM_DAYS_WAIT: 'Hur många dagar ska vi vänta innan vi skickar fakturapåminnelser?',

      INVOICE_SEND_OPTION_INFO: 'Vill ni att Memlist ska hantera utskick av faktura/avi? Ifall ni vill sköta detta själva så kommer varken ursprungsfakturan eller automatiska påminnelser att skickas ut.',
      INVOICE_SEND_OPTION_OPTION_YES: 'Ja, Memlist ska skicka faktura/avi enligt gällande prislista.',
      INVOICE_SEND_OPTION_OPTION_NO: 'Nej, vi vill själva skriva ut fakturor/avier och skicka.',

      INVOICE_DIRECT_DANGER_HEADER: 'Problem kan uppstå!',
      INVOICE_DIRECT_DANGER_INFO: 'Ni har valt att faktura/avi ska skapas direkt när ni även kommer att skicka ut e-post och SMS med uppmaning att betala online. Detta innebär att ifall medlemmen betalar online och får en avi hemskickad kan det bli en dubbel betalning för medlemskap.',

      MEMBERSHIP_OPTION_HEADER: 'Medlemskap',
      MEMBERSHIP_OPTION_INFO1: 'För att en medlem ska kunna förnya sitt medlemskap behöver det aktiva medlemskapet på medlemmen ha en koppling till nästa medlemskap. Medlemskapen i tabellen nedan visar alla medlemskap som finns kopplade på medlemmarna och vart dom kopplas till. Ifall ett medlemskap inte har någon koppling kommer förnyelsen att misslyckas.',
      MEMBERSHIP_OPTION_INFO2: 'I tabellen nedan kan ni skapa nästa medlemskap som kopplas till det gamla, men för att säkerställa att alla värden blir korrekta, gå till "Butik" och ställ in alla eventuella värden som behövs där.',

      MEMBERSHIP_CURRENT_NAME: 'Nuvarande medlemskap',
      MEMBERSHIP_NEXT_NAME: 'Nästa medlemskap',
      MEMBERSHIP_COMPANY_NAME: 'Förening',


      MEMBERSHIP_CURRENT_AMOUNT: 'Nuvarande medlemskap pris',
      MEMBERSHIP_NEXT_AMOUNT: 'Nästa medlemskap pris',

      NEXT_MISSING: 'Ej uppsatt',
      CREATE_NEXT: 'Skapa nästa medlemskap',

      SCHEDULE_HEADER: 'Schemalägg',
      SCHEDULE_INFO: 'Här är inställningarna för schemaläggning av förnyelserna. Man kan antingen välja att förnyelserna skall skapas per automatik innan medlemskapen löper ut, eller att man vill att förnyelserna ska skapas vid en given tidpunkt.',
      SCHEDULE_OPTION_AUTORENEW: 'Skapa automatiskt förnyelse för varje medlem individuellt innan medlemskapet löper ut',
      SCHEDULE_OPTION_SCHEDULE: 'Skapa förnyelserna för denna period vid en given tidpunkt',
      SCHEDULE_AT_DATE: 'Ange datum när förnyelserna ska skapas',

    },
    ACTIVE_RENEWAL: 'Aktiv förnyelse',
    RENEWALS: 'Förnyelser',
    EDITING: 'Redigerar förnyelse',
    CREATING: 'Skapar förnyelse',
    NO_RENEWS: 'Inga förnyelser',
    FORCE_RUN_AUTORENEW: 'Tvinga Autoförnyelsekörning',
    UNABLE_FORCE_RUN_AUTORENEW: 'Kunde inte tvinga autoförnyelse',
    CONFIRM_RENEW: 'Bekräfta förnyelse',
    ARE_YOU_SURE_DELETE: 'Är du säker på att du vill radera förnyelse?',
    CONFIRM: 'Bekräfta',
    UNABLE_GET_RENEWS_FOR_EMAIL_BATCH: 'Kunde inte hämta lista på förnyelser för e-postkörning',
    UNABLE_GET_RENEWS_FOR_SMS_BATCH: 'Kunde inte hämta lista på förnyelser för smskörning'
  },
  MEMBER_RELATION: {
    CREATING: 'Skapar familjemedlem',
    EDITING: 'Redigerar familjemedlem',
  },

  MEMBER_PROPERTY: {

    PROP_NAME: 'Attribut',
    PRIORITY: 'Prioritet',
    PRIORITY_INFO: 'Mindre siffra visas först',
    ADMINISTER: 'Hantera attribut',
    QUESTIONEER_INFO: 'Frågor i enkät som måste besvaras av deltagaren',
    QUESTIONEER: 'Enkät att besvara',
    ADD_GROUP: 'Lägg till attributkategori',
    SELECT_GROUPS_HEADER: 'Valda attributkategorier',
    SELECT_GROUPS: 'Valda attributkategorier ({count})',
    PROPERTY_GROUP_HEADER: 'Redigera attributkategori',
    CREATING: 'Skapar attributkategori',
    UPDATING: 'Uppdaterar attributkategori',
    IS_MULTI: 'Flerval',
    MULTIPLE_OPTIONS_INFO: 'Kan flera alternativ väljas?',
    CONFIRM_DELETE: 'Är du säker på att du vill radera attributkategorin?',
    SAVE_MEMBER_PROPERTY: 'Spara attribut',
    SAVE_PROPERTY_GROUP: 'Spara attributkategori',
    GROUP_NAME: 'Kategori'
  },

  PARENT: {
    NONE_FOUND: 'Inga vårdnadshavare hittades',
    CREATE: 'Skapa vårdnadshavare',
    DELETE: 'Radera vårdnadshavare',
    CREATING: 'Skapar vårdnadshavare',
    EDITING: 'Redigerar vårdnadshavare',
    DELETED: 'Vårdnadshavare raderad',
    UPDATED: 'Vårdnadshavare uppdaterad',
    CREATED: 'Vårdnadshavare skapad',
    UNABLE_DELETE: 'Kunde inte radera vårdnadshavare',
    UNABLE_CREATE: 'Kunde inte skapa vårdnadshavare',
    UNABLE_UPDATE: 'Kunde inte uppdatera vårdnadshavare',
    CONFIRM_DELETE: 'Bekräfta borttagning av vårdnadshavare',
    PERSON_ID: 'PersonID'
  },

  SENDOUT_TEMPLATE: {
    CREATE: 'Skapa mallkoppling',
    TYPE: 'Typ',
    CREATED_AT: 'Skapad',
    TEMPLATE_ID: 'Mall'
  },

  KVITTO_RULE: {
    TITLE: 'Kvittoregeler',
    NAME: 'Regelnamn',
    COMPANY_ID: 'Förening',
    FROM_EMAIL: 'Avsändare',
    SUBJECT_LIKE: 'Ämne innheåller',
    SUBJECT_LIKE_COUNT: 'Antal matchade på ämne',
    FROM_ORIGINAL_EMAIL: 'Email Ursprung',
    AI_MATCH_DESCRIPTION: 'AI matchningstext',
    AI_MATCH_COUNT: 'Antal matchade med AI',
    FORWARD_EMAIL_TO: 'Vidarebefordra till',
    NEW_RULE: 'Ny regel',
    CREATED: 'Regel skapades',
    UPDATED: 'Regel uppdaterades',
    UNABLE_CREATE: 'Kunde ej skapa regel',
    UNABLE_UPDATE: 'Kunde ej uppdatera regel',
    DELETED: 'Regel raderades',
    UNABLE_DELETE: 'Kunde ej radera regel'
  },

  KVITTO: {
    ASSIGNED_USER_ID: 'Tilldelad användare',
    STATUS: 'Status',
    SELECT_STATUS: 'Välj status',
    SELECT_USER: 'Välj användare',
    CREATED_TO: 'Skapad till',
    CREATED_FROM: 'Skapad från',
    SEARCH: 'Sök',
    SEARCH_CONTENT: 'Sök efter namn',
    KVITTO_RULES: 'Regler',
    NO_FILE_FOUND: 'Ingen fil hittades',
    EDIT_TODO_ITEM_TIP: 'Redigera kvitto eller transaktion',
    CONNECT_TRANSACTION_TIP: 'Koppla transaktion till kvitto',
    UPLOAD_KVITTO_TIP: 'Ladda upp och koppla kvitto',
    DOWNLOAD_KVITTO_TIP: 'Ladda ned kopplat kvitto',
    DELETE_KVITTO_TRANSACTION_TIP: 'Radera kvitto och transaktion',
    RECEIPT_TIP: 'Kvitto',
    TRANSACTION_TIP: 'Transaktion',

    TODO_STATUSES: {
      TODO: 'Att göra',
      DONE: 'Klart',
      ERROR: 'Fel!',
      ACCOUNTING: 'Bokföring'
    },

    CONNECTED_OK: 'Transaktion kopplad till kvitto',
    CONNECT_TIP: 'Koppla kvittot',
    NAME: 'Text',
    CREATED_AT_TIP: 'När kvittot kom in till denna tjänst',
    REGISTERED_AT: 'Registreringsdatum',
    ORIGINAL_EMAIL_TIP: 'Orginal e-post',
    TITLE_TIP: 'Titeln på kvittot',
    TITLE: 'Titel',
    ORIGINAL_EMAIL: 'E-post ursprung',
    AMOUNT: 'Summa',
    AMOUNT_VAT: 'Moms',
    CREATED_AT: 'Skapad',
    DATA_STATUS: 'Data status',
    DATA_ERROR: 'Data felkod',
    CURRENCY: 'Valuta',
    SELECT_ACCOUNT: 'Konto för transaktioner',
    IMPORT_FAILED: 'Importering av transaktioner misslyckades',
    SELECT_ACCOUNT_LABEL: 'Välj konto',

    VIEW_INBOUND_EMAIL: 'Visa original e-post',
    NO_INBOUND_EMAIL: 'Ingen e-post',
    INCOMING_EMAILS: 'Inkommande e-post',
    INCOMING_EMAIL: {
      ORIGINAL_EMAIL: 'Ursprungssändare',
      ORIGIN_NAME: 'Ursprungsnamn',
      FROM_EMAIL: 'Från epost',
      FROM_NAME: 'Från namn',
      TO: 'Till',
      CREATED_AT: 'Skapad',
      TEXT_BODY: 'Text',
      FROM: 'Från',
      MESSAGE_ID: 'Meddelande ID',
      CUSTOMER_ID: 'Kund',
      CC: 'CC',
      SUBJECT: 'Ämne',
      ID: 'ID',
    },

    MY_LIST: 'Min lista',
    HEADER: 'Underlagstjänst',
    NEW_TRANSACTION_FILE: 'Ny transaktionsfil',
    FILE_UPLOAD_SUCCESS: 'Filuppladdning lyckades',
    FILE_UPLOAD_FAILURE: 'Filuppladdning misslyckades',
    ACCOUNTS: 'Konton',
    USERS: 'Användare',
    FILES: 'Transaktionsfiler',
    RECEIPTS: 'Kvitton/Underlag',
    NEW_KVITTO: 'Nytt kvitto/underlag',
    NEW_ACCOUNT: 'Nytt konto',
    NONE_ASSIGNED: 'Ingen tilldelad',
    ACCOUNT: {
      NAME: 'Kontonamn',
      NAME_TIP: 'Namn på konto för att separera enklare',
      ASSIGNED_USER_ID: 'Tilldelad',
      ASSIGNED_USER_ID_TIP: 'Tilldelad användare till kontot',
      EXTERNAL_ID: 'Externt ID',
    },
    STATUS: 'Status',
    SEARCH_STR: 'Söktext',
    STATUSES: {
      NONE: 'Ej matchad',
      MAPPED: 'Matchad',
      BOOKED: 'Bokförd',
      URGENT_NEED_MAPPING: 'Matchning krävs!',
      URGENT_NEED_BOOKED: 'Bokföring krävs!',
      CLOSED: 'Klar',
      SKIPPED: 'Hoppa över'
    }
  },

  MEMBER: {

    DEACTIVATE_REASON: 'Avregistreringsorsak (SPAR)',

    EMIGRATED: 'Utflyttad',
    
    INVALID_DATE: 'Ogiltigt datum, måste vara: ÅÅÅÅ-MM-DD',
    SUPPRESSION_REMOVED: 'Återprenumerera skickad',
    UNABLE_REMOVE_SUPPRESSION: 'Kunde inte skicka återprenumeration',
    REMOVE_SUPPRESSION_DONE: 'Prenumeration aktiverad',
    REMOVE_SUPPRESSION: 'Återprenumerera på e-post',
    INFO_REMOVE_SUPPRESSION: 'Ange din e-postadress nedan om du vill börja ta emot e-postmeddelanden igen som du tidigare har avprenumererat.',

    DELETE_STRING: 'RADERA',
    IS_MEMBER: 'Är medlem',
    IS_PERSON: 'Är person',
    PRINT_DEACTIVATE_LETTER: 'Skriv ut avslutningsbrev',
    PRINT_DEACTIVATE_LETTER_INFO: 'Generera ett avslutsbrev för medlemmen. Kan endast genereras ifall medlemmen är avslutad.',
    MOBILE: 'Mobiltelefon',
    NO_MEMBER: 'Ej medlem', 
    NO_PARENTS_FOUND: 'Inga vårdnadshavare hittades',
    PARENTS: 'Vårdnadshavare',
    DELETED: 'Medlem raderad',
    EXIT_REQUESTED: 'Utträde begärt',
    ERROR_REQUEST_EXIT: 'Kunde inte begära utträde',
    EXIT_CONFIRM_POPUP_TITLE: 'Godkänn utträde',
    EXIT_CONFIRMED_MESSAGE: 'Utträdet har godkänts',
    REQUEST_EXIT: 'Begär utträde',
    PENDING_EXIT: 'Utträde handläggs',
    EXIT_REJECTED_MESSAGE: 'Utträdesansökan har avslagits',
    CONFIRM_REQUEST_EXIT: 'Jag vill härmed avsäga mig mitt medlemskap i organisationen.',
    NO_MEMBERS_SELECTED: 'Inga medlemmar valda',
    SELECT_MEMBERS_TO_DELETE: 'Välj medlemmar att radera',
    UNABLE_DELETE_MEMBERS: 'Kunde inte radera medlemmar',
    MEMBERS_DELETED: 'Medlemmar raderade',
    DONT_SHOW_AGAIN: 'Visa inte detta igen',
    POPUP: 'Popup',
    LOGIN: 'Medlemsinloggning',
    ENABLE_LOGIN_EMAIL: 'Aktivera inloggning med e-post',
    ENABLE_LOGIN_BANKID: 'Aktivera inloggning med BankID',
    PRICE_PER_LOGIN: 'Kostnad tillkommer per inloggning via BankID (se prislista på <a href="https://memlist.se/logga-in-med-bankid">memlist.se/logga-in-med-bankid</a>)',
    UNABLE_TO_LOGIN_DUE_TO_SETTINGS: 'Medlemmar har ingen möjlighet att logga in på grund av att inga inloggningsmetoder är aktiverade.',
    
    ADD_MEMBERSHIP: 'Lägg till medlemskap i andra föreningar',
    SELECT_COMPANIES: 'Välj organisationer att bli medlem i under {period}. De organisationer som du redan är medlem i visas inte.',
    BECOME_MEMBER: 'Bli medlem',

    CREATED_AT: 'Datum skapad',
    UNSET_REG: 'Tvinga registrerad',
    REG: 'Pågående registrering',
    REG_INFO: 'Medlemmen har inte slutfört sin registrering',

    PROFILE_SECTIONS: 'Synliga tabeller: ',
    FILES: 'Filer',
    ORDERS: 'Orderhistorik',
    DOB_INVALID_FEEDBACK: 'Använd format ÅÅÅÅ-MM-DD',
    RENEW_NOW_INFO: 'Ditt medlemskap kan förnyas!',
    RENEW_NOW: 'Förnya dig nu!',

    MUST_CREATE_BEFORE_SPAR: 'Medlem måste skapas innan SPAR uppslag',
    SPAR_LOOKUP: 'SPAR',
    DO_SPAR_LOOKUP: 'Gör SPAR uppslag',
    PENDING_PAYMENT_REG: 'Medlemmen är under pågående registrering och inväntar betalning',
    SWESSN: 'Giltigt personnummer',
    SECRET: 'Sekretess',
    SPARCODEID: 'SparkodID',
    BANKID_VERIFY_PERSONNR_MISMATCH: 'Personummer skiljer sig mellan BankID och angivet formulär.',
    VERIFY_WITH_BANKID: 'Verifiera med BankID',
    START_BANKID_QR: 'Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i appen för att skanna QR-koden på den här sidan.',
    INVALID_RESET_LINK: 'Länken är förbrukad eller ogiltig',
    CONFIRM_DELETE: 'Bekräfta radering',
    CONFIRM_DELETE_MESSAGE: 'Är du säker på att du vill ta bort medlemmen?',
    DELETE_ALL: 'Radera alla',
    CONFIRM_DELETE_ALL: 'Varning: Detta kommer att radera samtliga medlemmar. Detta inkluderar all historik för medlemmarna. Denna åtgärd går inte att ångra. Är du säker på att du vill fortsätta?',
    DELETE_SELECTED: 'Radera markerade',
    CONFIRM_DELETE_SELECTED: 'Är du säker på att du vill radera {count} medlemmar?',
    UNABLE_DELETE_MESSAGE: 'Kunde inte radera medlem',
    MEMBER_UPDATED: 'Medlem uppdaterad',
    UPDATED_AT: 'Uppdaterat',
    SKV_PERIOD_ID: 'Skattverket period',
    INVOICE_CO: 'Faktura C/o',
    REG_AT: 'Första registrering',
    DEACTIVATED: 'Avslutad',
    DECEASED: 'Avliden',
    DO_AUTORENEW: 'Förnya automatiskt',
    NO_FAMILY_MEMBERS: 'Inga familjemedlemmar',
    FAMILY: 'Familjemedlemmar',
    UNABLE_GET_NUM_MEMBERS: 'Kunde inte hämta antal medlemmar',
    DELETE_SELECTED: 'Radera valda',
    DELETE_ALL: 'Radera alla',
    LANG: 'Språk',
    CREATE: 'Skapa medlem',
    LEGAL_SEX: 'Juridiskt kön',
    NUM_ROWS: 'Antal rader',
    MEMBERS: 'Medlemmar',
    CREATE_MEMBER: 'Skapa medlem',
    MEMBERS_PURGE: 'Rensa medlemmar',
    CREATE_BEFORE_EDIT: 'Skapa medlemmen innan du redigerar',
    NO_FILES: 'Inga filer',
    NO_PARENTS: 'Inga vårdnadshavare',
    STUDENT_ID: 'Student-ID',
    STUDENT_STATUS: 'Student Status',
    INVOICE_ADDRESS_HEADER: 'Fakturaadress',
    ADDRESS_HEADER: 'Adress',
    CONTACT: 'Kontaktuppgifter',
    DETAILS: 'Medlemsuppgifter',
    SAVED: 'Uppgifter sparade',
    STATUS: 'Medlemsstatus',
    STATUSES: {
      MEMBER: 'Medlem',
      REJECTED: 'Nekad',
      PENDING: 'Väntar',
      PENDING_EXIT: 'Väntar utträde',
      EXITED: 'Utträtt'
    },
    SAMFUND: 'Samfundsinställningar',
    MEMBER_ID: 'Databas ID',
    PROFILE_SETTINGS_INFO: 'Markerade datafält nedan kommer att visas på medlemmens profilsida och kan uppdateras av medlemmen.',
    MEMBERSHIPS: 'Medlemskap',
    RENEW_ON_TOP_ONLY: 'Förnyelser kan endast skapas från moderföreningen.',
    DEACTIVATE_NOW: 'Avsluta omedelbart',
    ACTIVATE_NOW: 'Aktivera omedelbart',
    DEACTIVATE_AT_INFO: 'Ifall du sätter ett datum för avslutande så kommer medlemmen att avslutas vid valt datum. Ifall inget datum väljs så kommer medlemmen avslutas omedelbart.',
    DEACTIVATE_UPDATE: 'Uppdatera aktivering',
    ACTIVATION_UPDATED: 'Aktiveringsstatus uppdaterat',
    UNABLE_UPDATE_ACTIVATE: 'Kunde inte uppdatera aktivering',
    DEACTIVATE_AT: 'Utträdesdatum',
    DEACTIVATE: 'Avsluta medlem',
    ACTIVATE: 'Aktivera medlem',
    INVOICE_HEADER: 'Fakturaadress',
    INVOICES: 'Avier',
    NO_GUARDIANS: 'Det finns inga kopplade vårdnadshavare.',
    YOUR_PARENTS: 'Dina vårdnadshavare',
    PARENTS_INFO: 'Vårdnadshavare kopplade till ditt konto.',
    FAMILY_INFO: 'Familjemedlemmar är personer som är kopplade till ditt konto som delar dina förmåner.',
    YOUR_FAMILY: 'Dina familjemedlemmar',
    DOWNLOAD_INFORMATION: 'Ladda ned dina personuppgifter',
    YOUR_PROFILE: 'Din profil',
    UPDATE_INFO: 'Uppdatera din profil',
    EVENT: 'Evenemang',
    FROM: 'Från',
    TO: 'Till',
    ACTIVE_EVENTS: 'Aktiva evenemang',
    NO_EVENTS_FOUND: 'Inga evenemang hittades',
    NO_ORDERS_TABLE: 'Inga ordrar hittades',
    CONTACT: 'Kontaktuppgifter',
    SYS: 'Ta emot systemmeddelanden',
    DEF: 'Ta emot nyhetsbrev',
    IMP: 'Ta emot viktiga meddelanden',
    NOTIFICATIONS: 'Notifikationer',
    SHOW_CARD: 'Visa medlemskort',
    HIDE_CARD: 'Dölj medlemskort',
    CARD: 'Medlemskort',
    NAME: 'Namn',
    PERS_ORGNR: 'Person/Orgnummer',
    PERSONNR: 'Personnummer',
    PERSONNR_INFO: 'Personnummer (YYYYMMDD-1234)',
    DOUBLE_SSN_INFO: 'Personnumret finns redan registrerat!',
    PASSWORD: 'Lösenord',
    REPEAT_PASSWORD: 'Repetera lösenord',
    NEW_PASSWORD: 'Nytt lösenord',
    CONFIRM_PASSWORD: 'Bekräfta lösenord',
    COMPANY_NAME: 'Företagsnamn',
    ORG_NUMBER: 'Organisationsnummer',
    ORG_NUMBER_INFO: 'Org nummer: XXYYZZ1234',
    EMAIL: 'E-post',
    REPEAT_EMAIL: 'Repetera e-post',
    FIRSTNAME: 'Förnamn',
    LASTNAME: 'Efternamn',
    PHONE: 'Telefon',
    ADDRESS: 'Gatuadress',
    ZIPCODE: 'Postnummer',
    POSTAL: 'Postort',
    CO: 'C/o',
    COMMUNE: 'Kommun',
    REGION: 'Region',
    INVOICE: 'Faktura',
    MEMBER: 'Medlem',
    PERSON: 'Person (Ickemedlem)',
    INVOICE_INFO: 'Fakturauppgifter',
    MEMBER_CREATED: 'Medlem skapad',
    MEMBER_UPDATED: 'Medlem uppdaterad',
    MEMBER_DELETED: 'Medlem raderad',
    UNABLE_CREATE_MEMBER: 'Kunde inte skapa medlem',
    UNABLE_UPDATE_MEMBER: 'Kunde inte uppdatera medlem',
    UNABLE_DELETE_MEMBER: 'Kunde inte radera medlem',
    CREATE_MEMBER: 'Skapa medlem',
    MEMBER_DATA: 'Medlemsdata',
    LEAVE_EMPTY_AUTO: 'Lämna tomt för autogenererat',
    MEMBER_NUMBER: 'Medlemsnummer',
    PUBLIC_ID: 'Medlemsnummer',
    PUBLIC_ID_SHORT: 'Medlemsnr',
    UPDATE_FROM_SPAR: 'Uppdatera från SPAR',
    DEFINED_GENDER: 'Eget definerat kön',
    MEMBER_DEACTIVATED: 'Medlemmen är avslutad',
    MEMBER_DECEASED: 'Medlemmen är avliden',
    HD: 'Huvudmedlem',
    TYPE: 'Medlemstyp',
    AUTORENEW: 'Autoförnyelse',
    MOBILE: 'Mobiltelefon',
    LANDLINE: 'Fast telefon',
    EMAIL_ALREADY_REGISTERED: 'E-postadressen är redan registrerad i systemet. För att bjuda in denna e-post till denna förening, använd "Bjud in" knappen',
    UPDATE_PASSWORD: 'Uppdatera lösenord',
    UPDATE_PASSWORD_INFO: 'Skapa medlem först, sedan kan du uppdatera lösenordet',
    DEPARTMENT: 'Avdelning',
    COUNTRY: 'Land',
    INVOICE_ADDRESS_INFO: 'Faktureringsadress',
    FAMILY_DATA: 'Familjedata',
    GUARDIANS: 'Vårdnadshavare',
    ATTRIBUTE: 'Attribut',
    OTHER_INFO: 'Annan information',
    FIRST_REGISTER: 'Första registreringen',
    EDUCATIONS_INFO: 'Dina kopplade utbildningar',
    YOUR_EDUCATIONS: 'Dina utbildningar',
    SPURL: 'Stickprov URL',
    MEMBERSHIP: 'Medlemskap',
    COPY_INFO: 'Kopiera till annan förening och period',
    EDUCATIONS: 'Utbildningar',
    RENEWALS: 'Förnyelser',
    INVOICES: 'Fakturor',
    TRANSACTIONS: 'Transaktioner',
    NOTES: 'Anteckningar',
    OPTONS: 'Alternativ',
    SEND_RESET_PASSWORD: 'Skicka återställande av lösenord (e-post)',
    SEND_MEMBER_JOINED_EMAIL: 'Skicka välkomstbrev till medlem (Mall "Medlem skapad")',
    RESET_PASSWORD_INFO: 'Vill du skicka e-post om återställande?',
    RESET_PASSWORD: 'Återställ lösenord',
    RESET_RENEWALS: 'Nollställ förnyelseinställningar',
    LOGIN_AS_MEMBER: 'Logga in som medlem',
    DOWNLOAD_GDPR: 'Ladda ned personuppgifter (GDPR)',
    CREATE_INVOICE_FROM_MEMBERSHIPS_INFO: 'Skapa en ny medlemsavi. Alla medlemskap med "Nej" som betalningstatus kommer att inkluderas i Avin. OBS! Detta är ej kopplat till förnyelser.',
    CREATE_INVOICE: 'Skapa avi',
    DELETE_FROM_SYSTEM: 'Radera helt från systemet',
    CONVERT: 'Konvertera',
    CONVERT_MEMBER_TO_PERSON: 'Konvertera medlem till person',
    WARN_MANDATORY: 'Varning! Vissa obligatoriska fält är inte satta!',
    OK_MANDATORY: 'Alla medlemsvalideringskrav som är inställda på denna förening är giltiga för denna medlem.',
    PASSWORD_AT_LEAST: 'Minst 6 tecken',
    PASSWORDS_DIFFERS: 'Lösenorden skiljer sig',
    PASSWORD_UPDATED: 'Lösenordet uppdaterades',
    UNABLE_PASSWORD_UPDATE: 'Kunde inte uppdatera lösenord',
    DO_SPAR: 'Gör SPAR uppslag',
    UNABLE_DO_SPAR: 'Kunde inte göra SPAR uppslag',
    SPAR_DISABLED: 'SPAR ej aktiverat',
    NO_FAMILY: 'Det finns inga kopplade familjemedlemmar',
    NO_EDUCATIONS: 'Det finns inga kopplade utbildningar',
    FAMILY_REMOVED: 'Familjemedlem togs bort',
    UNABLE_FAMILY_REMOVE: 'Kunde inte ta bort familjemedlem',
    FAMILY_SAVED: 'Familjemedlem sparad',
    UNABLE_FAMILY_SAVE: 'Kunde inte spara familjemedlem',
    UNABLE_FAMILY_ADD: 'Kunde inte ta lägga till familjemedlem',
    UNABLE_REMOVE_MEMBERSHIP: 'Kunde inte ta bort medlemskap',
    MEMBERSHIP_REMOVED: 'Medlemskapet togs bort',
    NON_MEMBER: 'Ej medlem',
    CNAME: 'Företagsnamn',
    RDT: 'Reg. datum',
    EXIT_AT: 'Utträdesdatum',
    AVD: 'Avdelning',
    INVOICE_FIRSTNAME: 'Faktura Förnamn',
    INVOICE_LASTNAME: 'Faktura Efternamn',
    INVOICE_EMAIL: 'Faktura E-post',
    INVOICE_PHONE: 'Faktura Telefon',
    INVOICE_ADDRESS: 'Faktura Gatuadress',
    INVOICE_POST: 'Faktura Postort',
    INVOICE_ZIPCODE: 'Faktura Postnummer',
    INVOICE_COUNTRY: 'Faktura Land',
    COUNTRY: 'Land',
    AGE: 'Ålder',
    IS_HD: 'Är huvudmedlem',
    NOT_HD: 'Ej huvudmedlem',
    OPTIONS: 'Alternativ',
    PUBLIC_SEX: 'Eget kön',
    LADOK: 'LADOK status',
    FMT_PERSONNR: 'Formatterat personnummer',
    DOB: 'Födelsedag',
    DOB_INFO: 'ÅÅÅÅMMDD',
    DAY: 'Dag',
    MONTH: 'Månad',
    YEAR: 'År',
    INTERNATIONAL_DOB: 'Använd 3 separata fält (Dag, Månad, År)',
    SEX: 'Juridiskt kön',
    POST: 'Postort',
    OPTIONS: 'Alternativ',
    RESET_PASSWORD_SENT: 'Lösenordslänk har skickats med e-post',
    PARENT_CREATED: 'Vårdnadshavare skapad',
    UNABLE_CREATE_PARENT: 'Kunde inte skapa vårdnadshavare',
    PARENT_REMOVED: 'Vårdnadshavare borttagen',
    UNABLE_REMOVE_PARENT: 'Kunde inte ta bort vårdnadshavare',
    NO_PARENT_ID: 'Kunde inte hitta vårdnadshavarens ID',
    RENEW_RESET: 'Förnyelsen återställdes',
    FORGET_PASSWORD: 'Glömt lösenord',
    FORGET_PASSWORD_INFO: 'Skriv in din e-post för att ta emot instruktioner om hur man återställer lösenordet',
    EMAIL_SENT: 'E-post skickades',
    STRIPE_ID: 'Stripe ID',
    UPDATE_STRIPE: 'Uppdatera Stripe Kund',
    CREATE_STRIPE: 'Skapa Stripe Kund',
    STRIPE_CREATED: 'Stripe Kund skapad',
    STRIPE_UPDATED: 'Stripe Kund uppdaterad',
    STRIPE_MISSING_INFO: 'Stripe Kund måste skapas för att kunna skapa fakturor i Stripe',
    STRIPE_INVOICE_CREATED: 'Stripe Faktura skapad',
    STRIPE_INVOICE_UNABLE_DELETE: 'Kunde inte radera Stripe faktura',
    STRIPE_INVOICE_DELETED: 'Stripe faktura raderat',
    STRIPE_INVOICE_PUBLISHED: 'Stripe faktura skickad',
    STRIPE_INVOICE_UNABLE_PUBLISH: 'Kunde inte skicka Stripe faktura',
    IN_PERSONNR: 'Personnummer',
    FID: 'FamiljeID',
    'FIRSTNAME+LASTNAME': 'Förnamn+Efternamn',
    VFDT: 'Giltig från',
    VTDT: 'Giltig till',
    PAID_AT: 'Betaldatum',
    TRANSACTION: 'Transaktion',
    SKIP: 'Hoppa över',
    MEMBERSHIP_ITEM_ID: 'Medlemskap butiksID',
    SHOP_ITEM_ID: 'MedlemskapsID/ButiksartikelID',
    IS_PARENT: 'Är vårdnadshavare',
    SOURCE: 'Källa',
    COMMUNE_CODE: 'Kommunkod',
    CNUM: 'Föreningsnummer',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Adress+Postnummer+Postort',
    REGION_CODE: 'Regionskod',
    'LASTNAME+FIRSTNAME': 'Efternamn+Förnamn',
    PAYING: 'Betalande',
    IS_PAID: 'Är betalad (1 eller 0)',
    PARENT_ID: 'Vårdnadshavare ID',
    'ZIPCODE+POSTADDRESS': 'Postnummer+Postort',
    UNABLE_GET_PERMISSIONS: 'Kunde inte hämta användarrättigheter',
    UNABLE_LIST_FAMILY: 'Kunde inte lista familjemedlemmar',
    ADD_FAMILY_MEMBER: 'Familjemedlem tillagd',
    UNABLE_ADD_FAMILY_MEMBER: 'Kunde inte lägga till familjemedlem',
    UNABLE_ADD_MEMBER_IN_ANOTHER_FAMILY: 'Familjemedlemmen finns redan i en annan familj',
    UPDATE_HD: 'Huvudmedlem uppdaterades',
    UNABLE_UPDATE_HD: 'Kunde inte uppdatera huvudmedlem',
    DELETE_HD_NOT_ALLOWED: 'Kan ej ta bort en huvudmedlem med kopplingar till andra familjemedlemmar!',
    HEAD_MEMBER_INFO: 'Huvudmedlem kan ändras i Familjedata',
    FAMILY_MEMBERS: 'Familjemedlemmar',
    ATTRIBUTES: 'Attribut',
  },
  SAMPLE: {
    SAMPLE: 'Stickprov',
    SAMPLES: 'Stickprov',
    NO_SAMPLES: 'Inga stickprov',
    SAMPLE_ID: 'Stickprov ID',
    NAME: 'Namn',
    CREATED_AT: 'Skapad',
    UNABLE_GET: 'Kunde inte hämta stickprov',
    UNABLE_LIST: 'Kunde inte lista stickprov',
    UNABLE_CREATE: 'Kunde inte skapa stickprov',
    UNABLE_UPDATE: 'Kunde inte uppdatera stickprov',
    UNABLE_DELETE: 'Kunde inte radera stickprov',
    CREATED: 'Stickprov skapad',
    UPDATED: 'Stickprov uppdaterad',
    DELETED: 'Stickprov raderad',
    CREATE: 'Skapa stickprov',
    UPDATE: 'Uppdatera stickprov',
    DELETE: 'Radera stickprov',
    CREATED: 'Stickprov skapad',
    UPDATED: 'Stickprov uppdaterad',
    DELETED: 'Stickprov raderad',
    UNABLE_CONFIRM: 'Kunde inte bekräfta stickprov',
    UNABLE_REJECT: 'Kunde inte neka stickprov',
    UNABLE_LIST_MEMBERS: 'Kunde inte lista medlemmar',
    START: 'Starta stickprov',
    UNABLE_START: 'Kunde inte starta stickprov',
    STARTED: 'Stickprov startat',
    STOP: 'Avsluta stickprov',
    UNABLE_STOP: 'Kunde inte avsluta stickprov',
    STOPPED: 'Stickprov avslutat',
    UNABLE_CREATE_EXCEL: 'Kunde inte skapa Excel',
    DO_MANUAL: 'Manuell',
    STATUS: 'Status',
    ANSWER_YES: 'Svara ja',
    ANSWER_NO: 'Svara nej',
    STATUSES: {
      CON: 'Bekräftat',
      REJ: 'Ej medlem',
      NO_ANS: 'Ej svar'
    },
    COMPANIES_INCLUDED: 'Föreningar inkluderade i stickprovet',
    SAMPLE_ALREADY_EXISTS: 'Det finns redan ett stickprov på föreningen',
    SHOW_HISTORY: 'Visa historik',
    DEADLINE: 'Deadline',
    SHORT_NUM_ANSWERS: '# Svar',
    SHORT_NUM_MEMBERS: '# Medlemmar',
    INTRO: 'Introduktion',
    COMPANY_SELECTION: 'Föreningsval',
    SELECTION: 'Urvalsgrupp',
    OPTIONS: 'Alternativ',
    END_DATE: 'Slutdatum',
    TEMPLATES: 'Mallar',
    CONFIRM: 'Bekräfta',
    CREATE_INFO: 'Du kommer nu att skapa ett nytt stickprov. Följ guiden och fyll i allt som behövs. Stickprovet kommer inte vara igång förrän det har skapats OCH du har valt att starta stickprovet.',
    SELECT_COMPANY_INFO: 'Välj vilja föreningar som ska ingå i stickprovet. Du kan även ange att varje förening ska få ett eget individuellt stickprov skapat åt sig.',
    OPTION_SPLIT: 'Alla markerade föreningar får egna stickprover',
    SAMPLE_SIZE: 'Ange hur stor andel (i procent) av medlemmar som ska ingå i stickprovet',
    NUM_MEMBERS: 'Antal medlemmar',
    PCT_MEMBERS: 'Procent av medlemmar',
    OPTIONS_INFO: 'Medlemmar som innefattas av stickprovet kommer att få en länk skickad till sig där man bekräftar sitt medlemskap. Ange hur du vill att systemet ska skicka ut dessa länkar<br />Ifall du väljer SMS och/eller E-post så kommer 3 påminnelser att skickas ut till varje medlem som inte svarar, därefter kan du göra manuell uppringning på kvarvarande medlemmar',
    ONLY_DO_MANUAL: 'Endast manuell uppringning',
    END_DATE_INFO: 'Ange sista datum för stickprovet. När detta datum inträffar så kommer inga utskick längre att ske.',
    TEMPLATES_FOR_SENDOUT: 'Mallar för utskick',
    SENDOUT_EMAIL_INFO: 'I stickprovet har det angetts att e-post skall skickas, välj vilken mall som ska skickas ut till medlemmarna.',
    PREVIEW: 'Förhandsgranska',
    SENDOUT_SMS_INFO: 'I stickprovet har det angetts att SMS skall skickas, välj vilken mall som ska skickas ut till medlemmarna.',
    FINAL_STEP_INFO: 'Stickprovet kommer att skapas när du klickar på "Skapa stickprov". Notera att de automatiska utskicken ej kommer att starta förrän du har aktivt valt att "Starta" stickprovet.',
    UNABLE_CALC_MEMBERS: 'Kunde inte beräkna antal medlemmar'
  },
  OTHER: {
    INFO: 'Här kommer era specifika önskemål att hamna'
  },

  PERSON: {
    PERSON_CREATED: 'Person skapad',
    PERSON_UPDATED: 'Person uppdaterad',
    UNABLE_CREATE_PERSON: 'Kunde inte skapa person',
    UNABLE_UPDATE_PERSON: 'Kunde inte uppdatera person',
    UNABLE_GET: 'Kunde inte hämta person',
  },
  LANGUAGE: {
    SWEDISH: 'Svenska',
    ENGLISH: 'Engelska',
    ARABIC: 'Arabiska',
    BOSNIAN: 'Bosniska',
    RUSSIAN: 'Rysska'
  },
  INSTANCES: {
    INSTANCES: 'Instanser',
    INSTANCE: 'Instans',
    SELECT_INSTANCE: 'Välj instans',
    CREATE_TO_INSTANCE: 'Skapa i instanser'
  },
  COMMON: {
    PAID_SHORT: 'Bet',
    LOADING: 'Laddar...',
    SESSION_EXPIRES_IN: 'Utloggas om {days} dagar, {hours}:{minutes}:{seconds} ',
    WRITE_CONFIRM_TEXT_INFO: 'Detta är en extra säkerhetsåtgärd. Ange följande text i fältet nedan: ',
    WRITE_CONFIRM_TEXT: 'Skriv texten för att bekräfta',
    CREATE_SERVICE: 'Skapa service',
    SERVICE_CREATED: 'Tjänst skapad',
    SERVICE_DOMAIN_INFO: 'För att en tjänst ska kunna skapas behövs det läggas till en domän i oderland. Peka domänen till 46.21.97.83 under Zone editor i cPanel.',
    PERIOD_TYPE: 'Periodtyp',
    PERIOD_TYPES: {
      INFINITE: 'Löpande',
      PERIOD: 'Period'
    },
    INSTANCES: 'Instanser',
    INVALID_FORM: 'Ogiltigt formulär',
    ID: 'ID',
    THANK_YOU: 'Tack!',
    ABORT: 'Avbryt',
    FORM_VALIDATION_FAILED: 'Formuläret uppfyller inte valideringskraven, kontrollera fält',
    ARE_YOU_SURE: 'Är du säker på att du vill fortsätta?',
    SELECT_ALL: 'Välj alla',
    SEND_EMAIL: 'Skicka e-post',
    NUM_RECORDS: 'Antal rader',
    COPIED_TO_CLIPBOARD: 'Kopierat till urklipp',
    UNABLE_COPY_CLIPBOARD: 'Kunde inte kopiera till urklipp',
    UPLOAD: 'Ladda upp',
    GENERATE: 'Generera',
    NEW: 'Skapa ny',
    EDIT: 'Redigera',
    FEMALE: 'Kvinna',
    MALE: 'Man',
    UNABLE_GET_PAGE: 'Kunde inte hämta sida',
    SMS: 'SMS',
    UNABLE_DOWNLOAD: 'Kunde inte ladda ned fil',
    SUCCESS: 'Utfört',
    ERROR_LOADING_DATA: 'Kunde inte ladda data',
    CONNECT: 'Koppla',
    SELECT: 'Välj',
    NUM_ROWS: 'Antal rader',
    ERROR_OCCURED: 'Ett fel uppstod',
    REMOVE: 'Ta bort',
    COPY_LINK: 'Kopiera länk',
    SV: 'Svenska',
    EN: 'Engelska',
    LANG: 'Språk',
    IS_BOOKED: 'Bokförd',
    SERVICE: 'Tjänst',
    MODULE: 'Modul',
    PAID_AT: 'Betalad',
    COST_SEK: 'Kostnad',
    SAVE_CHANGES: 'Spara ändringar',
    COPIED: 'Kopierad',
    COST: 'Kostnad',
    BACK: 'Tillbaka',
    PAYMENT_STATUS: 'Betalstatus',
    DESCR: 'Beskrivning',
    SETTINGS: 'Inställningar',
    CONFIRM: 'Bekräfta',
    SHOW: 'Visa',
    HIDE: 'Dölj',
    EXPORT_XLSX: 'Exportera XLSX',
    EXPORT_CSV: 'Exportera CSV',
    YES: 'Ja',
    NO: 'Nej',
    ERROR: 'Fel',
    OK: 'OK',
    LACK_PERMISSION: 'Du saknar tillgång',
    ACTIONS: 'Hantera',
    CANCEL: 'Avbryt',
    NAME: 'Namn',
    PRICE_SEK: 'Kostnad',
    SELECT: 'Välj',
    SELECTED: 'Vald',
    CREATE_NEW: 'Skapa ny',
    UPDATE: 'Uppdatera',
    CREATE: 'Skapa',
    MAN: 'Man',
    WOMAN: 'Kvinna',
    OTHER: 'Annat',
    UNKNOWN: 'Okänt',
    DONT_WANT: 'Vill ej uppge',
    PAID: 'Betalat',
    NOT_PAID: 'Ej betalat',
    LADOK_STUDENT_STATUS: {
      S: 'Hoppa över',
      Y: 'Aktiv student',
      N: 'Ej aktiv student',
      P: 'Väntar'
    },
    ACTIVE_STUDENT: 'Aktiv student',
    NO_STUDENT: 'Ej student',
    WAITING: 'Väntar',
    MISSING: 'Saknas',
    DELETE: 'Radera',
    SEARCH: 'Sök',
    ADD: 'Lägg till',
    COLUMN: 'Kolumn',
    VALUE: 'Värde',
    COPY: 'Kopiera',
    SEND: 'Skicka',
    RESET: 'Nollställ',
    LOGIN: 'Logga in',
    DOWNLOAD: 'Ladda ned',
    CLOSE: 'Stäng',
    AN_ERROR_OCCURED: 'Ett fel uppstod',
    TRY_AGAIN_LATER: 'Försök igen senare',
    EXCEL: 'Excel',
    UNABLE_EXPORT: 'Kunde inte exportera',
    EXPORT_SUCCESS: 'Exporten lyckades',
    TOTAL: 'Total',
    UNABLE_REGIONS: 'Kunde inte hämta regioner',
    UNABLE_COMMUNES: 'Kunde inte hämta kommuner',
    CREATED_FROM: 'Skapad från',
    CREATED_TO: 'Skapad till',
    UNABLE_GET_DATA: 'Kunde inte hämta data',
    CREATED: 'Skapad',
    WAIT_PAYMENT: 'Inväntar betalning',
    PARTIALLY_PAID: 'Delvis betalad',
    PAID_IN_FULL: 'Betalad',
    MEMBER_PERSON: 'Medlem/Person',
    CREATED_AT: 'Skapad',
    SELECTION: 'Urval',
    CONTINUE: 'Fortsätt',
    DATE: 'Datum',
    INVOICE_INFORMATION: 'Fakturamottagare',
    ACTIVE: 'Aktiv',
    NAME: 'Namn',
    TYPE: 'Typ',
    MANDATORY: 'Obligatorisk',
    SAVE: 'Spara',
    SEND_EMAIL: 'Skicka e-post',
    SEND_SMS: 'Skicka SMS',
    LATE: 'Sen',
    BYPOST: 'Brev/Avi',
    STATUS: 'Status',
    ALL: 'Alla',
    CLEAR: 'Rensa',
    EXPORT: 'Exportera',
    MANAGE: 'Hantera',
    UNABLE_UPDATE: 'Kunde inte uppdatera',
    UPDATED: 'Uppdaterat',
    UNABLE_CREATE: 'Kunde inte skapa',
    CREATED: 'Skapad',
    UNABLE_DELETE: 'Kunde inte radera',
    DELETED: 'Raderat',
    INVALID_EMAIL: 'Felaktig e-post',
    INVALID_ROLE: 'Felaktig roll',
    INVALID_GROUP: 'Felaktig grupp',
    JOB_ESTIMATE_COST: 'Körningen estimeras kosta',
    AVAILABLE_BUDGET: 'Tillgänglig budget',
    EXPORT_SUCCESS: 'Exporten lyckades',
    UPDATED_AT: 'Uppdaterades',
    CREATE: 'Skapa',
    UPDATE: 'Uppdatera',
    DONE: 'Klar',
    GENERAL_SUCCESS: 'Åtgärden lyckades',
    NOT_SET: 'Ej satt',
    NUM_ROWS: 'Antal rader',
    SETTLED: 'Överfört',
    IMPORT: 'Importera',
    IS_PAID: 'Betalad',
    PUBLISH: 'Publicera',
    SETTINGS: 'Inställningar',
    HELP: 'Hjälp',
    CLICK_HELP: 'Klicka här för hjälp',
    EMAIL: 'E-post',
    MEMBER_NUMBER: 'Medlemsnummer',
    NOT_ALLOWED: 'Ej tillåtet',
    REQUIRED_FIELDS_INFO: 'Fält markerade med {asterix} är obligatoriska.',
    PREVIEW: 'Förhandsgranska',
    MANDATORY: 'Obligatorisk',
    QUANTITY: 'Antal'
  },
  REPORT: {
    MUST_SELECT_MONTH: 'Måste välja månad'
  },
  PERIOD: {
    SKV_PERIOD_INTAG: 'Intagsperiod SKV',
    MDT_FROM: 'Medlem registrerad från',
    MDT_TO: 'Medlem registrerad till',
    ACTIVE_PERIODS: 'Aktiva perioder',
    PERIODS: 'Perioder',
    PERIOD: 'Period',
    NAME_OF_PERIOD: 'Namn för perioden',
    FROM: 'Från datum',
    TO: 'Till datum',
    CONFIRM_DELETE: 'Är du säker på att du vill radera perioden?',
    CONFIRM: 'Bekräfta',
    CREATED: 'Perioden skapades',
    UNABLE_CREATE: 'Kunde inte skapa perioden',
    UNABLE_OVERLAP: 'Kunde inte skapa period: Perioder får inte överlappa',
    FILL_IN_ALL_FIELDS: 'Vänligen fyll i alla fält',
    UPDATED: 'Perioden uppdaterades',
    UNABLE_UPDATE: 'Kunde inte uppdatera period',
    DELETED: 'Perioden raderades',
    UNABLE_DELETE: 'Kunde inte radera period',
    NEXT_PERIOD: 'Nästa period',
    NEW: 'Ny period',
    YEAR: 'År',
    UNABLE_LIST: 'Kunde inte lista perioder',
    ID: 'Id',
    SELECT_PERIOD_AND_COMPANY: 'Välj period och förening',
    NAME: 'Period'
  },
  JOB: {
    DELETED: 'Körning raderad',
    JOB_RUNNING: 'Pågående körning',
    PROGRESS: 'Pågående',
    ID: 'Körning ID',
    JOBS: 'Körningar',
    JOB: 'Körning',
    NAME: 'Namn',
    CREATED_AT: 'Skapad',
    FINISHED_AT: 'Avslutad',
    NUM_PROCESSED: 'Antal bearbetade',
    NUM_SUCCESS: 'Antal lyckade',
    NUM_FAIL: 'Antal misslyckade',
    UNABLE_CREATE: 'Kunde inte skapa körning',
    UNABLE_LIST: 'Kunde inte lista körningar',
    UNABLE_GET: 'Kunde inte hämta körning',
    UNABLE_UPDATE: 'Kunde inte uppdatera körning',
    CREATED: 'Körning skapades',
    UPDATED: 'Körning uppdaterades',
    CREATED_WITH_COUNT: 'Körning skapad med antal rader: ',
    CREATE: 'Skapa ny körning',
    STOP: 'Stoppa körning',
    STATUS: 'Status',
    ERROR_MESSAGE: 'Felmeddelande',
    RESULT: 'Result',
    NUM_TOTAL: 'Antal totalt',
    NUM_PROCESSED: 'Antal hanterade',
    NUM_OK: 'OK rader',
    NUM_FAIL: 'Felaktiga rader',
    LADOK_RUN: 'Ladok körning',
    UPLOAD_NEW_LADOK: 'Ladda upp ny Ladok fil',
    LADOK_INFO: 'Notera att Ladokkörningen kommer att ske för vald period längst upp på sidan',
    UNABLE_STOP: 'Kunde inte stoppa körningen',
    STOPPED: 'Körningen stoppades',

    RESULTS: {
      PENDING: 'Väntar',
      SUCCESS: 'Avklarat',
      FAILED: 'Misslyckat',
      SKIPPED: 'Hoppat över',
      ERROR: 'Fel uppstod',
      CANCELLED: 'Avbrutet',
      CANCEL_REQUESTED: 'Avbryt'
    },
    STATUSES: {
      DRAFT: 'Utkast',
      START: 'Väntar på start',
      RUNNING: 'Körs',
      FINISHED: 'Klar',
      DELETED: 'Raderat',
      CANCELLED: 'Avbrutet',
      CANCEL_REQUESTED: 'Avbryt'
    }
  },

  MEMBER_COMPANY: {
    DUPLICATE_MEMBERSHIPS: 'Medlemskap existerar redan i period och förening',
    UNABLE_LIST: 'Kunde inte lista medlemskap',
    UPDATED: 'Medlemskap uppdaterat',
    UNABLE_UPDATE: 'Kunde inte uppdatera medlemskap',
    CREATED: 'Medlemskap saknas',
    UNABLE_CREATE: 'Kunde inte skapa medlemskap',
    DELETED: 'Medlemskap raderat',
    UNABLE_DELETE: 'Kunde inte radera medlemskap'
  },

  TEMPLATE: {
    INVALID_EMAILS: 'Reply-to och Från e-post måste vara giltiga e-postadresser, samt ha verifierade domäner. Ifall du vill lägga till en verifierad domän, kontakta support@memlist.se',
    NEW: 'Ny mall',
    SHOW_ALL: 'Visa alla',
    TEMPLATES: 'Mallar',
    NAME: 'Mallnamn',
    UNABLE_LIST: 'Kunde inte lista mallar',
    EMAIL_EDITOR: 'E-postdesigner',
    FROM_EMAIL: 'Från e-post',
    INVALID_EMAIL: 'Fel e-post',
    EMAIL_MUST_BE_VALID: 'E-postadressen måste vara giltig',
    CREATED: 'Mallen skapades',
    UNABLE_CREATE: 'Kunde inte skapa mall',
    UPDATED: 'Mallen uppdaterades',
    UNABLE_UPDATE: 'Kunde inte uppdatera mallen',
    DELETED: 'Mallen raderades',
    UNABLE_DELETE: 'Kunde inte radera mallen',
    UNABLE_GET: 'Kunde inte hämta mallen',
    UNABLE_LIST: 'Kunde inte lista mallar',
    DOMAIN_OK: 'Domän OK',
    DNS_MISSING: 'DNS Saknas',
    CONTACT_SUPPORT: 'Kontakta support',
    VALIDATION: 'Validering',
    COPY_TO: 'Kopiera till',
    SYSTEM: 'Systemet',
    IS_SMS: 'Är SMS',
    IS_EMAIL: 'Är e-post',
    SMS_MESSAGE: 'SMS meddelande',
    NUM_SMS: 'Antal SMS',
    TEXT: 'Klartextmeddelande',
    NOTICE: 'Observera att antalet ord i HTML versionen och text versionen inte får skilja avsevärt. Är det för stor skillnad på antalet ord i HTML och text versionerna så kommer e-posten att klassas som spam.',
    EDIT: 'Redigera mall',
    SYNC_INSTANCE: 'Synka instans',
    SYNCED: 'Mallen har synkats',
    UNABLE_SYNC: 'Kunde inte synka med instans',
    UNABLE_COPY: 'Kunde inte kopiera mall',
    COPIED: 'Mallen kopierades',
    CONFIRM_DELETE: 'Bekräfta att du vill radera mallen',
    DESIGNER: 'Design',
    UPDATE_CLEARTEXT: 'Uppdatera klartext från design',
    EXPORT_PDF: 'Exportera PDF'
  },

  GRANT: {
    SUM_APPLICATION: 'Totalsumma stöd (SEK)',
    BUDGET: 'Budget',
    AMOUNT_LEFT_IN_APPLICATION: 'Ni har högst {amount} kr att ansöka om',
    DESCRIPTION_PLACEHOLDER: 'Ge en så utförlig beskrivning som möjligt gällande er ansökan',
    DESCRIPTION: 'Utförlig beskrivning om ansökan',
    NUM_PARTICIPANTS: 'Antal deltagare (Om evenemang)',
    NAME: 'Titel för ansökan',
    NAME_PLACEHOLDER: 'Titel för ansökan',
    CONFIRM_PUBLISH: 'Denna ansökan kommer att skickas till moderföreningen - Är du säker på att du vill fortsätta?',
    STARTTIME: 'Starttid',
    ENDTIME: 'Sluttid',

    FROM_DATETIME: 'Startdatum för Projekt',
    TO_DATETIME: 'Slutdatum för Projekt',
    PUBLISHED_HEADER: 'Ansökan skickades',
    PUBLISHED_HEADER_INFO: 'Er ansökan har nu skickats vidare för granskning av moderföreningen.',
    CREATED_APPLICATION: 'Ansökan skapades',
    UPDATED_APPLICATION: 'Ansökan uppdaterades',
    UNABLE_CREATE_APPLICATION: 'Kunde inte skapa ansökan',
    UNABLE_UPDATE_APPLICATION: 'Kunde inte uppdatera ansökan',
    UNABLE_GET_COMPANY: 'Kunde inte hämta föreningsinformation för bidrag',
    INCORRECT_SUM: 'Ni kan inte ansöka denna summa. Måste vara mindre eller lika med',
    INCORRECT_BALANCE: 'Intäkter och utgifter belanseras ej, diff: ',
    SEND_APPLICATION: 'Skicka ansökan',
    NOT_PUBLISHED_WARNING: 'Ansökan är ej publicerad, moderföreningen ser ej ansökan'
  },

  SUPPORT: {
    UNHANDLED: 'Ohanterade',
    ACTIVE: 'Aktiva',
    ARCHIVE: 'Arkiverade',
    EMAILS: 'Epostadresser',
    SUMMARY: 'Sammanställning',
    IS_DEDICATED: 'Dedikerad',
    IS_THS: 'ThinkingHat',
    MESSAGE: {
      SENT_BY: 'Skickad av',
      MESSAGE: 'Meddelande',
      SEND_AT: 'Datum skickat',
      CREATE: 'Nytt svar',
      STATUS: 'Status',
      STATUSES: {
        NONE: 'Inget',
        UNREAD: 'Oläst',
        PENDING_SEND: 'Väntar på skickas',
        UNHANDLED: 'Ohanterat',
        REPLIED: 'Besvarar',
        DELETED: 'Raderat'
      }
    },
    TICKET: {
      REPLY_TEXT: 'Svarstext',
      DETAILS: 'Detaljer',
      MESSAGES: 'Meddelanden',
      CREATE: 'Skapa ticket',
      CREATED: 'Ticket skapad',
      UPDATED: 'Ticket uppdaterat',
      DELETED: 'Ticket raderad',
      UNABLE_DELETE: 'Kunde inte radera ticket',
      CREATED_AT: 'Skapad datum',
      ASSIGNED_USER: 'Tilldelad användare',
      NAME: 'Titel',
      DESCRIPTION: 'Beskrivning',
      TYPE: 'Typ',
      UNASSIGNED: 'Ej tilldelad',
      ORIGINAL_SENDER: 'Inkommande avsändare',
      TYPES: {
        UNDETERMINED: 'Ej bestämt',
        SALES_CONTACT: 'Sales contact',
        BUGREPORT: 'Felrapport',
        EDUCATION: 'Fråga',
        SUGGESTION: 'Förslag',
        INQUIRY: 'Förfrågan'
      },
      STATUS: 'Status',
      STATUSES: {
        NONE: 'Inget',
        UNHANDLED: 'Ohanterat',
        TODO: 'Att göra',
        REPLY_SENT: 'Besvarat',
        NEW_MESSAGE: 'Nytt meddelande',
        RESOLVED: 'Avklarat',
        CLOSED: 'Stängt',
        TENTATIVE: 'Framskjutet'
      },
      MESSAGE_TYPE: 'Meddelandetyp',
      MESSAGE_TYPES: {
        NONE: 'Inget',
        UNREAD: 'Oläst',
        UNHANDLED: 'Ohanterat',
        REPLIED: 'Besvarat',
        DELETED: 'Raderat'
      }
    },
  },

  TODO: {
    UNABLE_GET_WIZARD: 'Kunde inte hämta ärendewizard',
    TODO_WIZARD_COMPLETED: 'Ärendeinlämningen är nu slutförd.',
    START_DATE: 'Startdatum',
    END_DATE: 'Slutdatum',
    I_AM_FINISHED_WITH_TASK: 'Jag är klar med denna åtgärd',
    I_AM_NOT_FINISHED_WITH_TASK: 'Jag är EJ klar med denna åtgärd',
    CONFIRM_STATUS_TASK: 'Bekräfta status för denna åtgärd',
    TODO_WIZARD_HEADER: 'Ärenden att göra',
    UPLOAD_FILE: 'Ladda upp fil',
    WIZARD_NOT_DONE_STATUS: 'Måste sättas som klar',
    WIZARD_NO_FILES_UPLOADED: 'Filer saknas',
    NO_FILES_UPLOADED: 'Inga filer uppladdade',
    SUBTASK_UPDATED: 'Åtgärden har uppdaterats',
    PUBLISH_SUCCESS_HEADER: 'Publicerat',
    PUBLISH_SUCCESS_INFO: 'Ärendet har publicerats!',
    CONFIRM_PUBLISH: 'Publicera',
    CONFIRM_PUBLISH_INFO: 'Är du säker på att du vill publicera ärendet?',
    NEW: 'Nytt ärende',
    CLOSE: 'Stäng ärendet',
    DELETE: 'Radera ärendet',
    UNABLE_GET: 'Kunde inte hämta ärenden',
    UNABLE_DELETE_SUBTASK: 'Kunde inte ta bort åtgärd',
    SUBTASK_DELETED: 'Åtgärden togs bort',
    PUBLISHED: 'Ärendet har publicerats',
    UNABLE_PUBLISH: 'Kunde inte publicera ärende',
    HEADER: 'Ärendehantering',
    CREATED: 'Ärendet skapades',
    UPDATED: 'Ärendet uppdaterat',
    UNABLE_CREATE: 'Kunde inte skapa ärendet',
    UNABLE_UPDATE: 'Kunde inte uppdatera ärendet',
    WAIT : 'VÄNTAR',
    DRAFT: 'UTKAST',
    NOT  : 'EJ KLAR',
    DONE : 'KLART',
    CN   : 'BEKRÄFTAD',
    DEL  : 'BORTTAGEN',
    ACC  : 'ACCEPTERAD',
    REJ  : 'AVVISAD',
    BAC  : 'BACKLOG',
    FUT  : 'FRAMTID',
    UNREAD : 'OLÄST',
    READ : 'LÄST',
    DOC  : 'STÄNGT',
    FROM_DATETIME: 'Datum från',
    TO_DATETIME: 'Datum till',
    PUBLISHED_INFO: 'Ärendet är publicerat och visas nu för valda föreningar',
    NOT_PUBLISHED_INFO: 'Ärendet är ej publicerat, underföreningarna ser ej ärendet',
    PUBLISH: 'Publicera',
    CONFIRM_PUBLISH: 'Bekräfta publicering',
    ARE_YOU_SURE_PUBLISH: 'Är du säker på att du vill publicera ärendet?',
    COMPANIES: 'Föreningar',
    NAME: 'Ärendenamn',
    DESCR: 'Beskrivning',
    SEND_TO_ADMINS_ON_STATUS: 'Skicka e-post till administratörer vid statusuppdatering',
    NO_SUBTASKS_INFO: 'Det finns inga åtgärder i ärendet! Du måste lägga till minst en åtgärd för att kunna skapa ärendet.',
    AT_LEAST_1_SUBTASK_INFO: 'Det måste finnas minst en åtgärd.',
    MISSING_FROM_DATE: 'Startdatum saknas',
    MISSING_TO_DATE: 'Slutdatum saknas',
    SUBTASK_DELETED: 'Åtgärd togs bort',
    UNABLE_DELETE_SUBTASK: 'Kunde inte ta bort åtgärd',
    UNABLE_GET: 'Kunde inte hämta ärende',
    UNABLE_GET_SUBTASKS: 'Kunde inte hämta åtgärder'
  },

  SENDOUT: {
    NOT_AVAILABLE: 'Ej tillgänglig',
    NUM_OK: 'OK',
    NUM_FAIL: 'Fel',
    UPDATED: 'Utskick uppdaterat',
    UNABLE_UPDATE: 'Kunde inte uppdatera utskick',
    NO_RECIPIENTS_ERROR: 'Det finns inga mottagare i utskicket'
  },

  SHOP_ORDER: {
    SWISH_REQUEST_ID: 'Swish Request ID',
    SWISH_REFUND_ID: 'Swish Refund ID',

    STRIPE_DASHBOARD_LINK: 'Stripe admin länk',
    DELETED: 'Order raderad',
    REFUND_SWISH: 'Återbetalning Swish',
    REFUND_STRIPE: 'Återbetalning Stripe',
    SWISH_REQUEST_ID: 'Swish Request ID',
    NO_REFUND_METHOD: 'Återbetalning ej tillgänglig',
    UPDATED: 'Order uppdaterad',
    STRIPE_ID: 'Stripe ID',
    SWISH_ID: 'Swish ID',
    DINTERO_TRANSACTION_ID: 'Dintero ID',
    REFUND_AMOUNT_ERROR: 'Återbetalningsbeloppet får inte vara större än betalningen',
    REFUND_SUCCESS: 'Återbetalning lyckades',
    REFUND_FAILED: 'Återbetalning misslyckades',
    MAKE_REFUND: 'Skapa återbetalning',
    REFUNDED_AMOUNT: 'Återbetalad summa',
    NOT_REFUNDED: 'Ej återbetalat',
    NUM_ROWS: 'Antal rader',
    CREATED_AT: 'Skapad datum',
    PAYMENT_AMOUNT: 'Betalad summa',
    DINTERO_ID: 'Dintero ID',
    DINTERO_TRANSACTION_DATE: 'Dintero datum',
    STATUS: 'Status',
    DETAILS: 'Detaljer',
    CREATING: 'Skapar order',
    EDITING: 'Redigerar order',
    PAYMENT_METHOD: 'Metod',
    NONE_FOUND: 'Inga ordrar hittades',
    TYPE: 'Typ',
    PAYMENT_STATUS: 'Betalstatus',
    REFUND_REASON: 'Återbetalningsorsak',
    REFUND_ID: 'ÅterbetalningsID',
    REFUND_AMOUNT: 'Summa återbetalning',
    REFUND_AT: 'Återbetalningsdatum',
    REFUND: 'Återbetala',
    CREATE_REFUND: 'Skapa återbetalning',
    ITEMS: 'Artiklar',
    PAYMENT_STATUSES: {
      LEGACY: 'Ogiltigt',
      FREE: 'Gratis',
      NOT_PAID: 'Ej betalad',
      PARTIALLY_PAID: 'Delvis betalad',
      PAID_IN_FULL: 'Betalad',
      REFUND: 'Återbetalad'
    },
    STATUS: 'Orderstatus',
    STATUSES: {
      NONE: 'Inget',
      CREATED: 'Skapad',
      WAIT_PAYMENT: 'Inväntar betalning',
      PARTIALLY_PAID: 'Delvis betalad',
      PAID_IN_FULL: 'Betalad',
      DELETED: 'Raderad',
      FAILED: 'Misslyckad',
      DONE: 'Avslutad - Klart',
      CANCELLED: 'Avslutad - Avbruten',
    },
    PAYMENT_METHODS: {
      DINTERO: 'Dintero',
      INVOICE: 'Faktura',
      FORTNOX_INVOICE: 'Fortnox faktura',
      STRIPE: 'STRIPE',
      bgmax: 'BGMax',
      UNKNOWN: 'Okänt',
      REDEEMCODE: 'Kupong',
      FREE: 'Gratis',
      SWISH: 'Swish',
    },
    TYPES: {
      UNKNOWN: 'Okänt',
      REGISTER: 'Registrering',
      RENEW: 'Förnyelse',
      TICKET: 'Biljett',
      COMPANY: 'Förening',
      MEMBER: 'Medlem',
      REDEEM_CODE: 'Kupong',
      STRIPE_INVOICE: 'Stripe',
    },

    ALREAD_PAID: 'Ordern har redan betalats',
    FOR_MEMBER: 'Avser medlem',
    PAID_AT: 'Betaldatum',
    DATE: 'Datum',
    PRODUCT: 'Produkt',
    AMOUNT: 'Pris',
    TOTAL_AMOUNT: 'Total summa',
    FILTER: 'Filter',
    ANY_DATE: 'Ej angivet datum',
    ANY_STATUS: 'Alla status',
    COUNT: 'Antal ordrar',
    CREATED_FROM: 'Skapad från och med',
    CREATED_TO: 'Skapad till och med',
    BASED_ON_FILTER: 'Baserat på filter',
    SELECTED_IN_TABLE: 'Valda ordrar i tabellen',
    UNABLE_LIST_ORDER: 'Kunde inte lista betalningar',
    CONFIRM_DELETE: 'Är du säker på att du vill radera betalningen?',
    LINKTO: 'Länk till betalningssidan',
    UNABLE_DELETE: 'Kunde inte radera betalning',
    SHOPORDER_DELETED: 'Betalningen raderades',
    UNABLE_FETCH: 'Kunde inte hämta betalning',
    ORDER_CREATED: 'Betalningen skapades',
    UNABLE_ORDER_CREATE: 'Kunde inte skapa betalning',
    ORDER_UPDATED: 'Betalningen uppdaterades',
    UNABLE_ORDER_UPDATE: 'Kunde inte uppdatera betalning',
    SEND_LINK: 'Skicka länk',
    SEARCH_PARAMS: 'Ordernummer, OCR, Namn eller e-post',
    UNABLE_GET_NUM_ORDERS: 'Kunde inte hämta antal betalningar',
    CLIPBOARD_COPY: 'Kopierat till urklipp',
    UNABLE_CLIPBOARD_COPY: 'Kunde inte kopiera till urklipp',
    PAY_REF: 'Bet. Ref.',
    INVALID_LINK: 'Denna länk är felaktig',
    ERROR_GET: 'Fel uppstod när din beställning skulle hämtas',
    NO_ORDERS: 'Du har inga väntande betalningar',
    CANCEL_IN_SECONDS: 'Ordern avbryts om {seconds} sekunder',
    TIMEOUT: 'Transaktionen tog för lång tid och har avbrutits.',
    LATE: 'Försenad',
    PAID: 'Betalad',
    WAIT: 'Obetalad',
    ARTICLE: 'Artikel',
    TOTAL: 'Total',
    AMOUNT: 'Kostnad',
    COUNT: 'Antal',
    PAY_NOW: 'Betala nu',
    SAVE_INVOICE: 'Spara faktura',
    PAYMENTS: 'Betalningar'
  },

  BILLING: {
    REMAINING: 'Kvarvarande budget',
    ADMINISTRATE: 'Ändra >>'
  },

  COMPANY_BILLING: {
    CREATE: 'Skapa',
    CREATED_AT: 'Skapad',
    UPDATED_AT: 'Uppdaterad',
    PAID_AT: 'Betalad',
    SCHEDULED_AT: 'Schemalagd',
    FROM_DATE: 'Från datum',
    TO_DATE: 'Till datum',
    DUE_DATE: 'Förfallodatum',
    TITLE: 'Titel',
    DESCRIPTION: 'Beskrivning',
    STATUS: 'Status',
    STATUSES: {
      SCHEDULED: 'Schemalagt',
      SENT: 'Skickat',
      LATE: 'Försenat',
      PAID_FULL: 'Betalat',
      PAID_PARTIAL: 'Delvis betalat',
      DISPUTE: 'Överklagan',
      REFUNDED: 'Återbetalat',
    },

    BILLING_SERVICE: 'Tjänst',
    BILLING_SERVICES: {
      MEMLIST: 'Memlist',
      SMS_USAGE: 'SMS Retroaktivt',
      SMS_PREPURCHASE: 'SMS Förköp',
      SPAR_USAGE: 'SPAR Retroaktivt',
      SPAR_PREPURCHASE: 'SPAR Förköp',
      SUPPORT: 'Support',
      MEMBERCARD: 'Medlemskort',
      DEVELOPMENT: 'Utveckling'
    },
    ALL: 'Alla',
    CREATED: 'Kundintäkt skapad',
    UPDATED: 'Kundintäkt uppdaterad',
    DELETED: 'Kundintäkt raderad',

    UNABLE_CREATE: 'Kunde inte skapa kundintäkt',
    UNABLE_UPDATE: 'Kunde inte uppdatera kundintäkt',
    UNABLE_GET: 'Kunde inte hämta kundintäkt',
    UNABLE_LIST: 'Kunde inte lista kundintäkter'
  },

  COMPANY_PERIOD: {
    MUST_SELECT_FILE_AND_PERIOD: 'Måste välja period och fil',
    SKV_ADVANCE_IMPORTED_AT: 'SKV förskott importdatum',
    SKV_SETTLED_IMPORTED_AT: 'SKV slutavräkning importdatum',

    UPDATED_AT: 'Senaste uppdatering',
    ADVANCE_PAYOUT_AMOUNT: 'Utbetalat',
    ADVANCE_PAYOUT_AMOUNT_UPDATED: 'Förskottsutbetalningsbelopp uppdaterat',
    UNABLE_ADVANCE_PAYOUT_AMOUNT_UPDATE: 'Kunde inte uppdatera förskottsutbetalningsbelopp',
    CREATE_NEW_REGISTER_FILE: 'Skapa intag för räkenskapsår',
    CREATE_CORRECTIONS_FILE: 'Skapa rättelsefil',
    CNUM: 'Föreningsnr',
    MEMBER_COUNT: 'Antal medlemmar',
    SKV_INCOME_AMOUNT: 'Prel. Inkomst',
    ADVANCE_INCOME_AMOUNT: 'Prel. Inkomst',
    SETTLED_AMOUNT: 'Slut. Inkomst',
    SETTLED_INCOME_AMOUNT: 'Slut. Inkomst',

    CALCULATE_SETTLED: 'Beräkna Slutavräkning',
    CALCULATE_ADVANCE: 'Beräkna Förskott',
    ADVANCE_CALCULATED: 'Förskott har beräknats',
    ADVANCE_INFO_HEADER: 'Förskott {period}',
    SETTLED_INFO_HEADER: 'Slutavräkning {period}',
    NO_ROWS_FOUND: 'Inga rader hittades',
    NO_COMPANY_PERIODS: 'Det finns inga räkenskapsår för {period} för någon förening',
    CREATE_FOR_ALL: 'Skapa räkenskapsår för {period} för alla föreningar',
    NEW: 'Nytt räkenskapsår',
    UNABLE_LIST: 'Kunde inte lista räkenskapsår',
    UNABLE_GET: 'Kunde inte hämta räkenskapsår',
    UNABLE_CREATE: 'Kunde inte skapa räkenskapsår',
    UNABLE_UPDATE: 'Kunde inte uppdatera räkenskapsår',
    UNABLE_DELETE: 'Kunde inte radera räkenskapsår',
    CREATED: 'Räkenskapsår skapades',
    UPDATED: 'Räkenskapsår uppdaterades',
    DELETED: 'Räkenskapsår raderades',
    ACTIVE: 'Aktiv',
    COMPANY_NAME: 'Församling',
    SHORTNAME: 'Kortnamn',
    ORG_NUMBER: 'Org. nummer',
    POSTADDRESS: 'Postadress',
    PHONE: 'Tel',
    EMAIL: 'E-post',
    MISSING_PREVIOUS_PERIOD: 'Det går inte att skapa räkenskapsår för {period} eftersom det inte finns en period för 2 år bakåt',
    A1_NUM_MEMBERS: 'A1 Antal medlemmar',
    A2_SUM_BASIS_FEE: 'A2 Avgiftsunderlag, kr',
    A3_FEE_PER_MEMBER: 'A3 Avgiftsats medlemmar %',
    A4_ADVANCE_FROM_SKV: 'A4 Förskott från skatteverket (A2 x A3), kr',
    A5_ADM_FEE_MEMBER: 'A5 Administrationsavgift/medlem, kr',
    A6_PCT_FEE_SUBCOMPANY: 'A6 Avgiftsats församling, %',
    A7_FEE_TO_TOPCOMPANY: 'A7 Avgift till samfundet ((A1 x A5) + (A4 x A6)), kr',
    A8_ADVANCE_PAYOUT: 'A8 Förskott, kr',
    B1_NUM_MEMBERS: 'B1 Antal medlemmar',
    B2_SUM_BASIS_FEE: 'B2 Avgiftsunderlag, kr',
    B3_FEE_PER_MEMBER: 'B3 Avgiftsats medlemmar %',
    B4_SETTLED_CALCULATED_SUM: 'B4 Slutlig avgift (B2 x B3), kr',
    B5_ADM_FEE_MEMBER: 'B5 Administrationsavgift/medlem, kr',
    B6_PCT_FEE_SUBCOMPANY: 'B6 Avgiftsats församling, %',
    B7_FEE_TO_TOPCOMPANY: 'B7 Avgift till samfundet ((B1 x B5) + (B4 x B6)), kr',
    B8_SETTLED_SUM: 'B8 Avgår: förskott, kr',
    B9_DIFFERENCE: 'B9 Att få tillbaka +, Att betala -, (B4 - B7 - B8), kr',
    ADVANCE_CALCULATED_AT: 'Förskott beräkningsdatum',
    SETTLED_CALCULATED_AT: 'Slutavräkning beräkningsdatum'
  },


  PAGES: {

    MEMBER_REGISTRATIONS: {
      UNABLE_GET_SYSTEM_SETTINGS: 'Kunde inte hämta systeminställningar',
      APPLICATION_TEMPLATES: 'Ansökningsmallar',
      REQUESTS: 'Ansökningar',
      EXITS: 'Utträdesansökningar',
      EXITS_HISTORY: 'Utträdeshistorik',
      HISTORY: 'Ansökningshistorik',
      CHECKBOX_REG_APPROVAL: 'Medlemsansökningar kräver godkännande',
      TIP_REG_APPROVAL: 'Aktivera för att medlemsansökningar kräver godkännande',
      HEADER_REG_APPROVAL: 'Medlemsansökningar',
      
      CHECKBOX_EXIT_APPROVAL: 'Medlemmar måste godkännas innan de kan lämna',
      TIP_EXIT_APPROVAL: 'Aktivera för att medlemmar måste godkännas innan de kan lämna',
      HEADER_EXIT_APPROVAL: 'Utträdesansökningar',
      
      CONFIRM_REJECT_EXIT: 'Bekräfta avslag av utträde',
      CONFIRM_REJECT_EXIT_MESSAGE: 'Vill du göra avslag på utträdesansökan?',
      CONFIRM_ACCEPT_MESSAGE: 'Godkänner du denna ansökan kommer den ansökande personen sättas som en godkänd medlem i systemet.',
      CONFIRM_REJECT_MESSAGE: 'Nekar du denna ansökan kommer alla uppgifter raderas. <br/><br/>Observera: Återbetalning av eventuella avgifter måste ske innan ett avslag på medlemsansökan kan göras.',
      CONFIRM_ACCEPT: 'Godkänn medlemsansökan',
      CONFIRM_REJECT: 'Avslå medlemsansökan',
      UNABLE_UPDATE: 'Kunde inte uppdatera medlemsansökan',
      UNABLE_LIST: 'Kunde inte lista medlemsansökningar',
      UNABLE_DOWNLOAD: 'Kunde inte ladda ner medlemsansökning',
    },

    MEMBER_JOBS: {
      SELECTED: 'Urval',
      ACCEPTED_AT: 'Datum godkänd',
      ACCEPTED_BY: 'Godkänd av',
      CREATE_NEW: 'Skapa för period',
      ERROR_RUNNING_JOB: 'Kunde inte genomföra körning',
      UNABLE_LOAD: 'Kunde inte lista',
    },
    LOGIN: {
      TIP_REDIRECT: 'Vidarebefodra oinloggade användare till en annan sida',
      HEADER_REDIRECT: 'Vidarebefodring av icke inloggad',

      MUST_HAVE_LOGIN_OPTION: 'Minst en inloggningsmetod måste väljas',
      HEADER_EMAILPASSWORD: 'Logga in med e-post och lösenord',
      TIP_EMAILPASSWORD: 'Aktivera för att logga in med e-post och lösenord.',
      CHECKBOX_EMAILPASSWORD: 'E-post och lösenord',
      HEADER_BANKID: 'Logga in med BankID',
      TIP_BANKID: 'Aktivera för att logga in med BankID.',
      CHECKBOX_BANKID: 'BankID (QR och lokalapp)',
      CONTACT_SUPPORT_BANKID: 'Kontakta support@memlist.se för att låsa upp denna funktion.'
    },

    REPORTS: {
      REPORTS: 'Rapporter',
      DOWNLOAD_SALES: 'Ladda ned säljrapport',
      DOWNLOAD_MUCF_1: 'MUCF Rapport 1',
      DOWNLOAD_MUCF_2: 'MUCF Rapport 2',
      DOWNLOAD_MUCF_3: 'MUCF Rapport 3',
      DOWNLOAD_MONTH_REPORT: 'Medlemskapsrapport',
      MUST_SELECT_INTERVAL: 'Måste välja intervall',
      FROM_DATE: 'Från datum',
      TO_DATE: 'Till datum',
      FOR_YEAR: 'För år'
    },
    PAGE: {
      PAGES: 'Sidor/Moduler',
      PAGE: 'Sida',
      ON: 'På',
      SUPERADMIN: 'Superadmin',
      ACTIVATED: 'Sidan aktiverades',
      REMOVED: 'Sidan togs bort',
      UNABLE_LIST: 'Kunde inte hämta sidor'
    },
    FAITH: {
      DEACTIVATE_INFO: 'Kom ihåg att du måste skapa en rättelesfil och skicka till skatteverket.',
      SKV_INCLUDE_MINORS: 'Inkludera minderåriga i Skatteverketfilerna',
      IMPORT_ERROR_MESSAGE: 'Felmeddelande',
      IMPORT_ERROR_ROW: 'Rad',
      IMPORT_FILE_ERRORS: {
        COMPANY_NOT_FOUND: 'Förening {company} finns ej',
        COMPANY_PERIOD_NOT_FOUND: 'Förening {company} saknar räkenskapsår {period}',
        YEAR_MISMATCH: 'Vald period och år i filen stämmer inte överens',
        INVALID_DATE_FORMAT: 'Filens år kunde inte tolkas',
        ADVANCE_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Förskottsperioden finns ej för slutavräkningsperioden',
        ADVANCE_NOT_CALCULATED_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Förskottsperioden saknar uträkning för slutavräkningsperioden',
      },
      COMPANY_PERIOD_NOT_EXISTS: 'Räkenskapsår saknas för period {period} för förening {company}',
      MOVE_REG_AFTER: 'Flytta nyregistrerade medlemmar till nästa period efter datum',
      ADVANCE_CALCULATED: 'Förskott beräknades',
      UNABLE_CALCULATE_ADVANCE: 'Kunde inte beräkna förskott',
      UNABLE_CALCULATE_SETTLED: 'Kunde inte göra slutberäkning',
      SETTLED_CALCULATED: 'Slutberäkning utförd',

      REGISTRATIONS: 'Intag',
      CORRECTIONS: 'Rättelser',

      TIP_FEES: 'Avgifter för församlingar(underföreningar) ställs in globalt här',
      HEADER_FEES: 'Avgifter för församlingar',

      FEE_PCT: 'Avgift procent (%)',
      ADM_FEE: 'Administrationsavgift kr (SEK)',
      MEM_PCT: 'Avgift procent per medlem (%)',
      MEM_FEE: 'Avgift kr per medlem (SEK)',
      GDPR_CONTACT_NAME: 'GDPR dataskyddsansvarig namn',
      GDPR_CONTACT_EMAIL: 'GDPR dataskyddsansvarig e-post',


      MUST_BE_ENGLISH_FLOAT: 'Måste vara engelsk flyttal med punkt, ej kommatecken',
      AMOUNT_PAID_TO_COMPANY_INFO: 'Utbetalt belopp för {period} till {company}',
      AMOUNT_PAID: 'Utbetalt',

      TIP_INTAG: 'Inställningar för intag till skatteverket',
      HEADER_INTAG: 'Intagsfil till Skatteverket',

      TIP_REDOVISNINGSRAKNING: 'Egenskaper för redovisningsräkningsfilen',
      HEADER_REDOVISNINGSRAKNING: 'Redovisningsräkning',
      REDOVISNINGSRAKNING: {
        SIGNED_NAME: 'Undertecknad namn',
        INVALID_SIGNED_NAME: 'Ogiltigt namn',
        UNABLE_CREATE: 'Kunde inte skapa redovisningsräkning',
        LEFT_HEADER: 'Vänster rubrik',
        NEW_LINE_INFO: 'Lägg till \\n för ny rad',
        INVALID_LEFT_HEADER: 'Ogiltig vänster rubrik',
        RIGHT_HEADER: 'Höger rubrik',
        INVALID_RIGHT_HEADER: 'Ogiltig höger rubrik',
      },
      SETTINGS: 'Inställningar',
      SUMMARY: 'Översikt',
      IMPORT: 'Import',
      EXPORT: 'Export',
      REPORTS: 'Rapporter',
      GENERATE: 'Generera',
      FILE_TYPE: 'Filtyp',
      FOR_PERIOD: 'För period',
      GENERATED_AT: 'Genererad',
      USER_NAME: 'Användare',
      HISTORY: 'Historik',
      GENERATE_FILE: 'Generera ny fil',
      UNABLE_LOAD_FILES: 'Kunde inte hämta fillista',
      SKV_EXPORT_REGISTRATIONS: 'Skatteverket: Intag av medlemmar',
      SKV_EXPORT_INCOME_PERSONNR_ADVANCE: 'Skatteverket: Export inkomst - Förskott',
      SKV_EXPORT_INCOME_PERSONNR_SETTLED: 'Skatteverket: Export inkomst - Slutavräkning',
      SKV_IMPORT_INCOME_PERSONNR_ADVANCE: 'Skatteverket: Import inkomst - Förskott',
      SKV_IMPORT_INCOME_PERSONNR_SETTLED: 'Skatteverket: Import inkomst - Slutavräkning',
      SKV_EXPORT_CORRECTIONS: 'Skatteverket: Rättelsefil',
      UPLOAD_SKATTEVERKET_FILE: 'Ladda upp Skatteverket fil',
      FILE_IMPORTED: 'Importfil skapades',
      UNABLE_FILE_IMPORT: 'Kunde inte ladda upp importfil',
      UPLOAD_FILE: 'Ladda upp fil',
      NO_FILES: 'Inga filer',
      UNABLE_EXPORT_FILE: 'Kunde inte exportera fil',
      NAME_MISSING: 'Namn ej satt',
      FILE_EXPORTED: 'Fil exporterad'
    },
    EDUCATIONS: {
      ALREADY_EXISTS: 'Kursen finns redan',
      FACULTIES: 'Fakulteter',
      EDUCATIONS: 'Utbildningar',
      PROGRAM_CODE: 'Programkod',
      IMPORT_EDUCATIONS: 'Importera utbildningar',
      ADD: 'Lägg till',
      SECTION: 'Sektion',
      SECTION_COMPANY: 'Sektion/Förening',
      NUM_ROWS: 'Antal rader: ',
      FACULTY: 'Fakultet',
      CODE: 'Kod',
      PROGRAM: 'Program',
      COURSE: 'Kurs',
      FREE_COURSE: 'Frikurs',
      NEW: 'Ny utbildning',
      DELETED: 'Utbildning raderad',
      UNABLE_DELETE: 'Kunde inte radera utbildning',
      CREATED: 'Utbildning skapades',
      UNABLE_CREATE: 'Kunde inte skapa utbildning',
      UPDATED: 'Utbildning uppdaterades',
      UNABLE_UPDATE: 'Kunde inte uppdatera utbildning',
      UNABLE_GET: 'Kunde inte hämta utbildning',
      UNABLE_LIST: 'Kunde inte lista utbildningar',
      ERROR_COMPANY: 'Måste sätta sektion/förening',
      ERROR_FACULTY: 'Måste sätta fakultet',
      ERROR_TYPE: 'Måste sätta typ',
      ERROR_CODE: 'Måste sätta kod',
      ERROR_NAME: 'Måste sätta namn',
      NAME: 'Utbildningens namn',
      TYPE: 'Typ',
      TYPE_NONE: 'Inget',
      TYPE_PROGRAM: 'Program',
      TYPE_COURSE: 'Kurs',
      TYPE_FREE_COURSE: 'Frikurs',
      NEW_FACULTY: 'Ny fakultet',
      FACULTY_DELETED: 'Fakultet raderad',
      UNABLE_FACULTY_DELETE: 'Kunde inte radera fakultet',
      FACULTY_CREATED: 'Fakultet skapad',
      UNABLE_FACULTY_CREATE: 'Kunde inte skapa fakultet',
      FACULTY_UPDATED: 'Fakultet uppdaterad',
      UNABLE_FACULTY_UPDATE: 'Kunde inte uppdatera fakultet',
      UNABLE_GET_FACULTY: 'Kunde inte hämta fakultet',
      UNABLE_LIST_FACULTY: 'Kunde inte lista fakulteter',
      FACULTY_NAME: 'Fakultet',
      FACULTY_DESCR: 'Beskrivning',
      IMPORT_SECTIONS: 'Importera sektioner'
    },
    APIKEYS: {
      DINTERO_SETTINGS: 'Dintero inställningar',
      SERVICE: 'Tjänst',
      API_KEY_DATA: 'Apinyckel data',
      API_SERVICE: 'Extern tjänst',
      PUBLIC_TOKEN: 'Publik Token',
      STRIPE_SETTINGS: 'Stripe inställningar',
      NEW: 'Ny Apinyckel',
      MUST_BE_SINCH_PHONE: 'Måste vara ett telefonnummer som är inställt i Sinch admin',
      SET_SERVICEPLAN_ID: 'Ange service plan ID från Sinch admin',
      CHECK_SINCH_ADMIN: 'Finns i Sinch admin',
      FETCH_ACCESS_TOKEN: 'Hämta Access-Token',
      SET_AFTER_SUCCESS_SPAR: 'Denna sätts efter lyckad autentifiering hos SPAR tjänsten',
      EXISTS_IN_SPAR_ADMIN: 'Finns i SPAR admin',
      UNABLE_GET: 'Kunde inte hämta Apinycklar'
    },
    DASHBOARD: {
      MEMBERS: 'Medlemmar',
      MEN: 'Män',
      WOMEN: 'Kvinnor',
      OTHERS: 'Övriga',
      RENEWALS: 'Förnyelser',
      TOTAL: 'Totalt',
      DONE: 'Klara',
      NOTDONE: 'Ej klara',
      LATEST_MEMBERS: 'Senaste medlemmar',
      MEMBER_JOINING: 'Medlemsförvärv',
      CHART_START_DATE: 'Från datum',
      CHART_END_DATE: 'Till datum',
      THINGS_TODO: 'Ni har saker att göra',
      TODO_TEXT: 'Glöm inte bort att slutföra det som behöver göras innan datumen passerat i listan nedan.',
      TODO: 'Att göra',
      BUDGET_LOW: 'Låg budget',
    },
    CAMPAIGNS: {
      ACTIVE: 'Aktiv',
      ACTIVE_TIP: 'Aktiverar kampanjen skarpt och kan då börja att användas av medlemmar.',
      ACTIVE_HEADER_TIP: 'Indikerar om kampanjen är aktiv eller ej.',
      ACTIVE_WARNING: 'Notera: Alla medlemmar som registrerar sig inom angivet tidsspann kommer att få denna rabatt på SAMTLIGA artiklar',
      MAX_USES: 'Max antal',
      MAX_USES_TIP: 'Maximala antalet koder eller användare som finns tillgängliga för att ta del av rabatten.',
      INVALID_MAX_USES: 'Ogiltigt max antal',
      NAME: 'Kampanj',
      NAME_TIP: 'Namn på kampanjen och är endast synligt för administratörer.',
      NAME_HEADER_TIP: 'Namn på kampanjen.',
      INVALID_NAME: 'Ogiltigt namn',
      AMOUNT: 'Antal',
      AMOUNT_HEADER_TIP: 'Antalet förbrukade rabatter respektive tillgängliga.',
      USE_CODE: 'Kräv kampanjkod',
      USE_CODE_TIP: 'Genom att aktivera "Kräv kampanjkod", så behöver användaren skriva in specifik kampanjkod för att få den eventuella rabatten. Är denna ruta ej ikryssad kommer rabatten appliceras enligt principen "först till kvarn" ut efter antalet satt i fältet Max antal.',
      VALID_CODE: 'Giltig kod',
      INVALID_CODE: 'Ogiltig kod',
      CODE: 'Kampanjkod',
      CODE_TIP: 'Val av kod som skall brukas av användaren för att kunna applicera en viss rabatt på ett köp.',
      CODE_HEADER_TIP: 'Rabattkoden.',
      CODE_INFO: 'Kod som ska matas in av medlem, minst 4 tecken',
      INVALID_PERCENT: 'Ogiltig procentsats',
      VALID_PERCENT: 'Giltig procentsats (1-100)',
      PERCENT_DISCOUNT: 'Procent rabatt',
      PERCENT_DISCOUNT_TIP: 'Antalet procent som kommer appliceras som rabatt på artikeln.',
      PERCENT_DISCOUNT_HEADER_TIP: 'Procent rabatt på artikeln.',
      INVALID_DATE: 'Ogiltigt datum',
      VALID_DATE: 'Giltigt datum',
      TO_DATE: 'Till datum',
      TO_DATE_TIP: 'Till det datum kampanjen skall vara aktiv.',
      TO_DATE_HEADER_TIP: 'Rabattkodens sista giltighetsdag.',
      DATE_DESCR: 'ÅÅÅÅ-MM-DD',
      FROM_DATE: 'Från datum',
      FROM_DATE_TIP: 'Från det datum kampanjen skall vara aktiv.',
      FROM_DATE_HEADER_TIP: 'Rabattkodens första giltighetsdag.',
      PRIORITY: 'Ordningsföljd/Prioritet',
      DESCR: 'Beskrivning',
      DESCR_TIP: 'Denna text visas endast för administratörer för att ge en förklaring kring anledningen till rabatten.',
      INVALID_DESCR: 'Ogiltig beskrivning',
      CAMPAIGNS: 'Kampanjer',
      NEW: 'Ny kampanj',
      DELETED: 'Kampanj borttagen',
      UPDATED: 'Kampanj uppdaterad',
      CREATED: 'Kampanj skapad',
      UNABLE_DELETE: 'Kunde inte radera kampanj',
      UNABLE_LIST: 'Kunde inte lista kampanjer',
      UNABLE_GET: 'Kunde inte hämta kampanj',
      UNABLE_UPDATE: 'Kunde inte uppdatera kampanj',
      UNABLE_CREATE: 'Kunde inte skapa kampanj',
      EDIT_ICON_TIP: 'Modifiera kampanj.',
      DELETE_ICON_TIP: 'Ta bort kampanj.'
    },

    FILES: {
      NEW_FILE: 'Ny fil',
      UNABLE_CREATE_FILE: 'Kunde inte skapa fil',
      SUCCESS_CREATE_FILE: 'Fil skapad',
      UNABLE_DOWNLOAD: 'Kunde inte ladda hem fil',
      UPLOADED: 'Filen laddades upp',
      MUST_BE_LESS: 'Filen måste vara mindre än 50MB',
      UNABLE_UPLOAD: 'Kunde inte ladda upp fil',
      MUST_BE_CSV: 'Filen måste vara CSV format',
      FILE_NAME: 'Filnamn',
      FILE_NAME_TIP: 'Namnet på filen som laddats upp.',
      DISPLAY_NAME: 'Visningsnamn',
      DISPLAY_NAME_TIP: 'Namnet som visas på medlemmars Min sida.',
      DATE: 'Datum',
      DATE_TIP: 'Datumet när filen laddades upp.',
      HANDLE: 'Hantera',
      HANDLE_TIP: 'Möjlighet till att hantera filer med olika funktioner.',
      COPY_LINK_ICON_TIP: 'Kopiera länk.',
      DOWNLOAD_FILE_ICON_TIP: 'Ladda ner fil.',
      EDIT_FILE_ICON_TIP: 'Redigera fil.',
      DELETE_FILE_ICON_TIP: 'Ta bort fil.',
      ACCESSABILITY: 'Tillgänglighet',
      IS_S3: 'Finns i S3',
      IS_S3_TIP: 'Om filen finns lagrad i S3 bucket (i Sverige)',
      PUBLIC_LINK: 'Publik länk',
      PUBLIC_LINK_TIP: 'Genom att kryssa i följande ruta blir filen som är uppladdad tillgänglig för alla som har länken.',
      MEMBER_VISIBLE: 'Synlig för medlem som äger filen på Mina sidor',
      MEMBER_ANY_VISIBLE: 'Synlig för alla medlemmar på Mina sidor',
      MEMBER_VISIBLE_TIP: 'Filen kommer bara synas för den medlem som äger eller har skapat filen.',
      MEMBER_ANY_VISIBLE_TIP: 'Filen kommer visas för samtliga medlemmar på Mina sidor',
      FILE_INFO: 'Filinformation',
      ONLY_FOR_MEMBER: 'Visas endast för medlemmen som äger filen'
    },

    MEMBERTYPES: {
      UNABLE_LIST_MEMBERTYPES: 'Kunde inte lista medlemstyper'
    },

    JOBS: {
      UNABLE_CREATE: 'Kunde inte skapa körning',
      UNABLE_LIST: 'Kunde inte lista körningar',
      UNABLE_GET: 'Kunde inte hämta körning',
      UNABLE_UPDATE: 'Kunde inte uppdatera körning',
      CREATED: 'Körning skapades',
      UPDATED: 'Körning uppdaterades',
      CREATED_WITH_COUNT: 'Körning skapades med antal: ',
      CREATE: 'Skapa körning'
    },
    REDEEMCODES: {
      REDEEMCODES: 'Kuponger',
      VALID_FOR_ARTICLE: 'Giltig för artikel',
      VALID_FOR_EVENT: 'Giltig för evenemang',
      MEMBERSHIP_IN_COMPANY: 'För medlemskap i förening',
      RECIPIENT: 'Mottagare',
      SEND_TO_MEMBER: 'Skicka till medlem',
      CREATE_SMS_EMAIL_INVITE: 'Skapa inbjudan via E-post/SMS',
      SELECTED_MEMBER: 'Vald medlem',
      TO_EMAIL: 'Till e-post',
      TO_MOBILE: 'Till mobiltelefon/SMS',
      PHONE_INFO: 'Använd + och landskod, även inom Sverige',
      EXPIRES_AT: 'Giltig till och med datum',
      DATE_INFO: 'ÅÅÅÅ-MM-DD',
      GENERATED_BY_SYSTEM: 'Genererat av system',
      MUST_HAVE_EMAIL: 'Måste ha e-post',
      MUST_SELECT_RECIPIENT_TYPE: 'Måste välja mottagartyp',
      MUST_SELECT_MEMBER: 'Måste välja medlem',
      MUST_SELECT_ARTICLE: 'Måste välja artikel',
      MUST_SET_EXPIRATION: 'Måste sätta giltighetsdatum'
    },
    SHOPITEM: {
      EXT_SHOP_ITEM: 'Förnyas till',
      CREATED_AT: 'Skapad',
      COMPANY: 'Förening',
      SEARCH_NAME: 'Sök namn på artikel',
      CURRENCY: 'Valuta',
      NAME: 'Namn på artikeln',
      VAT_FREE: 'Momsfritt',
      UNABLE_GET: 'Kunde inte hämta artikel',
      UNABLE_DELETE: 'Kunde inte radera artikel',
      UNABLE_LIST: 'Kunde inte lista artiklar',
      UNABLE_CREATE: 'Kunde inte skapa artikel',
      UNABLE_UPDATE: 'Kunde inte uppdatera artikel',
      DELETED: 'Artikeln raderades',
      UPDATED: 'Artikeln uppdaterades',
      CREATED: 'Artikeln skapades',
      UNABLE_LIST_SHOPITEMS: 'Kunde inte lista butiksartiklar',
      SELECT: 'Välj artikel',
      ARTICLE: 'Artikel',
      MISSING: 'Artikel saknas',
      ACTIVATE_STRIPE: 'Aktivera Stripe betalningar',
      STRIPE_ACTIVATION_INFO: 'Stripe Api nycklar måste sättas i "Apinycklar"',
      NO_MEMBERSHIPS: 'Föreningen saknar medlemskapsartiklar',
      SHOPITEMS: 'Butiksartiklar',
      NO_API_KEY: 'Det finns ingen API nyckel uppsatt för betalningar på huvudföreningen. För att betalningar ska kunna fungera krävs det att det finns korrekta nycklar uppsatta samt att avtal med betalningsleverantör har upprättats. Kontakta memlist support för att veta mer.',
      CONFIRM_DELETE: 'Är du säker på att du vill radera artikeln?',
      CREATE_FOR_ALL: 'Skapa för alla föreningar',
      PRESENTATION: 'Presentation',
      DESCR: 'Beskrivning',
      IMAGE: 'Bild på artikeln',
      UPLOAD_IMAGE: 'Ladda upp bild',
      PRIORITY: 'Ordningsföljd/Prioritet',
      PRICE_SEK: 'Prissättning',
      VAT_SEK: 'Moms',
      EX_VAT: 'Pris per styck ex moms',
      ACCOUNTING: 'Bokföring',
      BOOK_ACCT_VAT: 'Momskonto',
      BOOK_ACCT_ARTICLE: 'Artikelkonto',
      BOOK_PLACE: 'Kostnadsställe',
      PROJECT: 'Projekt',
      TAGS: 'Taggar',
      TAGS_INFO: 'Skriv in text och ENTER',
      MEMBERSHIP_SETTINGS: 'Medlemsinställningar',
      MEMBER_TYPE_ID: 'Medlemstyp',
      MIN_AGE: 'Lägsta tillåtna ålder',
      MAX_AGE: 'Högst tillåtna ålder',
      UNABLE_DUPLICATE: 'Kunde inte duplicera artikel',
      UNABLE_COPY: 'Kunde inte kopiera artikel',
      COPIED: 'Artikeln kopierades',
      DUPLICATED: 'Artikeln duplicerades',
      IMAGE_UPLOADED: 'Bilden laddades upp',
      UNABLE_IMAGE_UPLOAD: 'Kunde inte ladda upp bild',
      SAVE_TICKET: 'Spara biljett',
      SAVE: 'Spara artikel',
      COPY_TO_NEXT_PERIOD: 'Kopiera till nästa period',
      NEW: 'Ny artikel',
      NAME: 'Artikelnamn',
      AMOUNT: 'Summa',
      AMOUNT_VAT: 'Summa moms',
      VAT_PCT: 'Momssats',
      IS_ACTIVE: 'Aktiv (Kommer visas vid registrering)',
      IS_SHARED: 'Används av alla föreningar',
      IS_MEMBER_FEE: 'Är medlemsavgift',
      IS_EXTRA: 'Är merförsäljning',
      UNABLE_DELETE_ON_ORDERS: 'Kan inte radera artiklar som finns på ordrar eller medlemskap',
      CAMPAIGN_CODE: 'Kampanjkod',
      CAMPAIGN_CODE_TIP: 'Om du har en aktiv kampanjkod kan du ange den här för att applicera den eventuella rabatten.',
      APPLY_CAMPAIGN_CODE: 'Tillämpa',
      UNABLE_APPLY_CAMPAIGN_CODE: 'Kunde inte tillämpa kampanjekod.',
      CAMPAIGN_CODE_VALID: 'Giltig kampanjkod',
      CAMPAIGN_CODE_INVALID: 'Ogiltig kampanjkod',
      RECEIVER: 'Avser',
      RECEIVER_OPTION1: 'Förening',
      RECEIVER_OPTION2: 'Medlem',
      QUANTITY_METHOD: 'Beräkningssätt',
      QUANTITY_OPTION1: 'Per antal',
      QUANTITY_OPTION2: 'Per medlem',
    },

    SMS: {
      SMS_SENT: 'SMS skickat',
      UNABLE_SEND: 'Kunde inte skicka SMS'
    },
    REGISTRATION: {
      PAGES: 'Sidor',
      CONFIRM_DELETE_PAGE: 'Bekräfta att du vill ta bort sidan.',
      VERIFY_BANKID_TEXT: 'BankID signeringstext',
      VERIFY_BANKID: 'Verifiera med BankID (Endast om personnummer)',
      ALLOW_DUPLICATES: 'Tillåt att dubbletter registrerar sig (inloggningen kommer deaktiveras)',
      NEW_PAGE: 'Ny sida',
      CHECK_LADOK: 'Gör uppslag mot LADOK',
      ERROR_OCCURED: 'Ett fel uppstod, försök igen senare',
      LOGIN_URL: 'Login URL vid registrering',
      TOO_MANY_LOOKUPS: 'För många uppslag. Vänta 1 minut.',
      EMAIL_TAKEN: 'E-postadressen är redan registrerad',
      PERSONNR_TAKEN: 'Personnumret är redan registrerat',
      UNABLE_PROCEED: 'Kunde inte fortsätta',
      CONFIRM: 'Bekräfta',
      SELECTED_COMPANIES: 'Valda föreningar',
      UNABLE_GET_TERMS: 'Kunde inte hämta villkor',
      SINGLE_MEMBERSHIP: 'Enskilt medlemskap',
      FAMILY_MEMBERSHIP: 'Familjemedlemskap',
      PERSONNR_FORMAT: 'ÅÅÅÅMMDD1234',
      INPUT_PERSONNR: 'Skriv in ett giltigt personnummer',
      SELECTED_SKIP_PERSONNR: 'Du har valt att hoppa över personnummer',
      SKIP_PERSONNR: 'Jag vill ej uppge personnummer',
      NEW_FAMILY_MEMBER: 'Ny familjemedlem',
      NEXT_STEP: 'Nästa steg',
      PREV_STEP: 'Föregående',
      CREATE_ACCOUNT: 'Bli medlem',
      PROCEED_TO_PAYMENT: 'Gå till betalning',
      RENEW_ACCOUNT: 'Förnya medlemskap',
      SHOW_AT_REGISTRATION: 'Visa vid registrering',
      SHOW_AT_RENEWAL: 'Visa vid förnyelse',
      MUST_FULL_PERSONNR: 'Medlemmen måste fylla i fullständigt personnummer',
      DO_SPAR: 'Gör SPAR uppslag för underlätta registreringen (Kostnad tillkommer)',
      MANDATORY: 'Obligatoriska',
      DISPLAY: 'Visning',
      SELECT_LOCAL_COMPANY: 'Välj lokalförening',
      SELECT_REGION: 'Välj region',
      CAN_ALSO_JOIN: 'Du kan även gå med i dessa',
      UPLOAD_CSS: 'Ladda upp CSS fil',
      REMOVE_CSS: 'Ta bort CSS',
      ACTIVATED_FIELDS: 'Aktiverade fält',
      FIELDS_MANDATORY_INFO: 'Obligatoriska fält ställs in i Inställningar -> Medlemskrav'
    },
    ATTRIBUTES: {
      NONE: 'Inget',
      NAME: 'Attribut',
      UPDATED: 'Attributet uppdaterades',
      UNABLE_UPDATE: 'Kunde inte uppdatera attribut',
      CREATED: 'Attributet skapades',
      UNABLE_CREATE: 'Kunde inte skapa attribut',
      DELETED: 'Attributet togs bort',
      UNABLE_DELETE: 'Kunde inte ta bort attribut',
      COLUMN: 'Kolumn',
      VALUE: 'Värde',
      UNABLE_LIST: 'Kunde inte lista attribut',
      UNABLE_DELETE_GROUP: 'Kunde inte radera kategori',
      UNABLE_DELETE_GROUP_CONFLICT: 'Vissa medlemmar har dessa attribut satta',
      GROUP_DELETED: 'Kategorin raderades',
      HEADER: 'Attributkategorier',
      MULTIPLE_OPTIONS: 'Flervalsalternativ',
      MULTIPLE_OPTIONS_INFO: 'Kan flera alternativ väljas?',
      OTHER_OPTIONS: 'Andra alternativ',
      IS_DROPDOWN: 'Rullista',
      SHOW_AT_REGISTRATION: 'Visa vid registrering',
      SHOW_AT_PROFILE: 'Visa i mina sidor',
      MANDATORY: 'Obligatoriskt val',
      SINGLE_SELECTION: 'Enkelval',
      MULTI_SELECTION: 'Flerval',
      UNABLE_LIST_CATEGORIES: 'Kunde inte lista kategorier',
      CATEGORY_CREATED: 'Kategorin skapades',
      UNABLE_CATEGORY_CREATE: 'Kunde inte skapa kategori',
      CATEGORY_UPDATED: 'Kategorin uppdaterades',
      UNABLE_CATEGORY_UPDATE: 'Kunde inte uppdatera kategori',
      UNABLE_GET: 'Kunde inte hämta kategori',
      CATEGORY: 'Kategori',
      NEW: 'Nytt attribut',
      NEW_CATEGORY: 'Ny kategori',
      TOO_LONG: 'Namnet måste vara kortare än 64 tecken'
    },
    REGISTRATIONSETUP: {
      TIP_EXTRA_VISIBILITY_OPTION: 'Bestämmer hur extraval ska visas för medlemmar vid registrering',
      HEADER_EXTRA_VISIBILITY_OPTION: 'Extraval visningsläge',
      EXTRA_VISIBILITY_SEARCH: 'Sökbar',
      EXTRA_VISIBILITY_LIST: 'Lista',
      EXTRA_CHECKBOX: 'Flerval',
      EXTRA_RADIO: 'Enkelval',
      EXTRA_NONE: 'Inget extraval',
      COMPANY_NAME: 'Organisationsnamn',
      PRESELECTED: 'Förvalt',
      AREA_ALLOCATION: 'Områdestilldelning',
      EXTRA: 'Extraval',
      MEMBERSHIP: 'Medlemskap',
      SHOW: 'Visa',
      NOT_ASSIGNED: 'Ej tilldelat',
      ASSIGNED: 'Val({num})',

      TIP_LEVEL0_LABEL: 'Text att stå som rubrik för första valet i föreningsval',
      HEADER_LEVEL0_LABEL: 'Föreningsvalrubrik nivå 1 (Toppförening)',

      TIP_LEVEL1_LABEL: 'Text att stå som rubrik för andra valet i föreningsval',
      HEADER_LEVEL1_LABEL: 'Föreningsvalrubrik nivå 2',

      TIP_LEVEL2_LABEL: 'Text att stå som rubrik för tredje valet i föreningsval',
      HEADER_LEVEL2_LABEL: 'Föreningsvalrubrik nivå 3',

      TIP_LEVEL3_LABEL: 'Text att stå som rubrik för fjärde valet i föreningsval',
      HEADER_LEVEL3_LABEL: 'Föreningsvalrubrik nivå 4',

      TIP_EXTRA_LABEL: 'Text att stå vid EXTRA föreningsval',
      HEADER_EXTRA_LABEL: 'EXTRA Föreningsval rubrik',

      ACTIVATE_FAMILYREG: 'Aktivera familjeregistrering',
      PARENT_REAL_MEMBERS: 'Vårdnadshavare är äkta medlemmar',
      SUGGEST_AREA_MEMBERSHIP: 'Föreslå förening efter område',
      TIP_EXTRA_OPTION: 'Bestämmer hurvida man som medlem ska få kunna välja en eller flera extra alternativ vid registrering',
      HEADER_EXTRA_OPTION: 'Extra föreningsval',

      REGISTER_WIZARD: 'Registreringswizard',
      COMPANY_SELECT_V2: 'Organisationsval - v2',
      UNABLE_CREATE_PAGE: 'Kunde inte skapa sida',
      PAGE_CREATED: 'Sida skapades',
      PAGE_UPDATED: 'Sidan uppdaterades',
      UNABLE_UPDATE_PAGE: 'Kunde inte uppdatera sida',
      PAGE_DELETED: 'Sidan raderades',
      UNABLE_DELETE_PAGE: 'Kunde inte radera sida',
      UNABLE_DELETE_CSS: 'Kunde inte radera CSS',
      CSS_DELETED: 'CSS raderad',
      UNABLE_LIST_PAGES: 'Kunde inte lista sidor',
      PERSONNR: 'Personnummer',
      STUDENT: 'Student-Personnummer',
      DETAILS: 'Personuppgifter',
      PROPERTIES: 'Attribut',
      PARENTS: 'Vårdnadshavare',
      SHOP: 'Butik/Medlemskap',
      AREA_ALLOC: 'Föreningsväljare',
      CONFIRM: 'Bekräftelsesida',
      REG_FAMILY: 'Familjemedlemskap',
      ONEPAGER: 'Ensidesregistrering',
      UNABLE_SORT: 'Kunde inte sortera sidor'
    },
    SENDOUT: {
      REMAINING_SMS_BUDGET: 'Kvarvarande SMS Budget',
      CREATED: 'Utskick skapat',
      SENDOUT: 'Utskick',
      EMPTY_CREATED: 'Ett tomt utskick har skapats',
      UNABLE_CREATE: 'Kunde inte skapa utskick',
      DELETED: 'Utskicket raderades',
      UNABLE_DELETE: 'Kunde inte radera utskick',
      UNABLE_GET: 'Kunde inte hämta utskick',
      UNABLE_LIST: 'Kunde inte lista utskick',
      SELECT_TYPE: 'Välj medlemmar eller användare',
      STARTED: 'Utskicket har startat',
      UNABLE_START: 'Kunde inte starta utskick',
      UPDATED: 'Utskicket uppdaterades',
      UNABLE_UPDATED: 'Kunde inte uppdatera utskick',
      CONFIRM_DELETE: 'Är du säker på att du vill radera utskicket?',
      CONFIRM_START: 'Är du säker på att du vill starta utskicket?',
      SHOW_HISTORY: 'Visa historik',
      CREATING: 'Skapar utskick',
      UPDATING: 'Uppdaterar utskick',
      NEW_SENDOUT: 'Nytt utskick',
      OPENS: 'Opens',
      CLICKS: 'Clicks',
      BOUNCES: 'Bounce',
      DROPS: 'Drops',
      NAME_ON_SENDOUT: 'Namn på utskick',
      TYPE: 'Typ',
      NOT_ENOUGH_BUDGET: 'Ni har inte angett tillräckligt stor budget för att kunna genomföra detta utskick',
      SYS: 'System',
      DEF: 'Normal',
      IMP: 'Viktigt',
      EMAIL_SENDOUT: 'E-postutskick',
      SEND_A_TEST: 'Skicka ett test',
      EMAIL_SEND_TO: 'E-post att skicka till',
      PREVIEW_SEND: 'Skicka förhandsgranskning',
      SENDOUT_INFO: 'Avsändarinformation',
      FROM_NAME: 'Från namn',
      CHARACTERS_REMAINING: 'Tecken kvar',
      INFO_FROM_NAME: 'Kommer att stå som "Från avsändare" i e-post inkorgen',
      SET_SUBJECT: 'Sätt ämne',
      SCHEDULED_AT: 'Schemalägg utskick',
      SCHEDULED_AT_TOOLTIP: 'Schemalagd till',
      ACTIVATE_SCHEDULE_AT: 'Aktivera',
      SCHEDULED_AT_HELPTEXT: 'Utskicket kommer att skickas ut vid ovan angivna tidpunkt.',
      SUBJECT: 'Ämne',
      EMAILSUBJECT: 'Ämne i e-postinkorgen',
      PREVIEW_TEXT: 'Förhandsvisningstext',
      PREVIEW_INFO: 'Visar en förhandsvisningstext för vissa e-postklienter',
      SELECT_TEMPLATE_EMAIL: 'E-post: Välj mall',
      REVIEW: 'Granska',
      CREATE_NEW_TEMPLATE: 'Skapa ny mall',
      CORRECT_INFO: 'Alla fält ovan måste sättas korrekt innan utskicket kan starta',
      ALL_CORRECT: 'Alla fält för mallen är korrekt inställda',
      UNABLE_SELECT_TEMPLATE: 'Kunde inte välja mall',
      EMAIL_SENT: 'E-post skickat',
      UNABLE_SEND: 'Kunde inte skicka',
      WARNING_COST: 'Kostnad tillkommer med Memlist SMS taxa',
      SMS_SENDOUT: 'SMS utskick',
      SENDER: 'Avsändare',
      SENDER_ID_INFO: 'Ett ID istället för telefonnummer som avsändare. Ifall fältet lämnas blankt kommer det inbyggda telefonnummret att användas.',
      SMS_SELECT_TEMPLATE: 'SMS - välj mall',
      TEMPLATE_MUST_BE_SELECTED: 'En mall måste väljas',
      SMS_SENT: 'SMS skickades',
      FROM_ID: 'Från ID',
      SMS_TEMPLATE: 'SMS mall',
      SMS_SEND_TO: 'SMS skicka till',
      EMAIL_TEMPLATE: 'E-postmall'
    },
    SETTINGS: {
      PAYMENT_DAYS: 'Förfallodatum - dagar efter faktura skapas',
      ENABLED_EVENT_DINTERO: 'Aktivera onlinebetalningar för evenemang',
      ENABLE_SELECT_PAYMENT: 'Aktivera nya betalningsväljaren',
      UNABLE_GET: 'Kunde inte hämta föreningsinställningar',
      LOGIN: 'Inloggning',
      ALERT_INFO_WILL_REG: 'Ifall föreningen visas på registreringen och är vald så kommer denna förening att registreras som ett medlemskap för medlemmen',
      WILL_REG: 'Skapa medlemskap i denna förening ifall vald',
      CREATE_MEMBERSHIP: 'Skapa medlemskap',
      DISPLAY_DURING_REGISTRATION: 'Visa som val vid registrering',
      SETTINGS: 'Föreningsinställningar',
      UPDATED: 'Inställningar uppdaterade',
      UNABLE_GET: 'Kunde inte hämta inställningar',
      UNABLE_UPDATE: 'Kunde inte uppdatera inställningar',
      IS_FAITH: 'Är religöst samfund',
      FEE_PCT: 'Avgift procent (%)',
      ADM_FEE: 'Administrationsavgift kr (SEK)',
      MEM_PCT: 'Avgift procent per medlem (%)',
      MEM_FEE: 'Avgift kr per medlem (SEK)',
      PUBLIC: 'Offentligt',
      LINKS: 'Länkar',
      REGISTRATION: 'Registrering',
      BANK: 'Bank',
      MEMBER_PROFILE: 'Medlemsprofil',
      PAYMENT: 'Betalning',
      STRIPE: 'Stripe',
      FORTNOX: 'Fortnox',
      API_CONNECTION: 'API koppling',
      EVENTS: 'Evenemang',
      EDUCATIONS: 'Utbildningar',
      FAITH: 'Samfund',
      MEMBER_REQUIREMENTS: 'Medlemskrav',
      MEMBER_TYPES: 'Medlemstyper',
      EXTRA_DATA: 'Extradata',
      REQUIRES_HTTP: 'Måste innehålla http:// eller https://',
      MEMBER_REG_PAGE: 'Registreringssidan för medlemmar',
      MEMBER_LOGIN_PAGE: 'Loginsida för medlemmar',
      USER_LOGIN_PAGE: 'Loginsida för användare',
      PUBLIC_EVENTS_PAGE: 'Publik evenemangslista',
      MEMBER_LOGIN_ORG_PAGE: 'Loginsida medlemmar samtliga org.',
      MEMBER_REQ_PERSONNR: 'Födelsedatum',
      MEMBER_REQ_SSN: 'Svenskt personnummer',
      MEMBER_REQ_AGE: 'Ålder',
      MEMBER_REQ_SEX: 'Kön',
      MEMBER_REQ_ADDRESS: 'Adress (gatuadress, postnr, postort, land)',
      MEMBER_REQ_FULLNAME: 'För-/Efternamn',
      MEMBER_REQ_EMAIL: 'E-post',
      MEMBER_REQ_PHONE: 'Telefonnummer',
      MEMBER_REQ_EMAILPHONE: 'E-post eller mobiltelefon',
      MEMBER_REQ_PASSWORD: 'Lösenord',
      MEMBER_REQUIREMENTS_INFO: 'Alla nedanstående kategorier som kryssas i kommer att bli obligatoriska om dessa är valda i alla avseenden där kategorierna visas.',
      EMAILS: 'Epostadresser',
    },
    RENEWS: {
      SMS_COUNT_INFO: 'Antalet beräknas på mallens storlek gånger antalet mottagare',
      SELECT_SENDOUT_METHOD: 'Välj utskicksmetoder',
      MANUAL: 'Skapa nya',
      RENEWALS: 'Förnyelser',
      RENEW_MEMBERSHIPS: 'Förnya medlemskap',
      NEXT_PERIOD_NOT_SET: 'Nästa period är inte satt',
      CREATE_TO: 'Skapa förnyelse till period ',
      TO_PERIOD: 'Till period',
      TO_VFDT: 'Fråndatum',
      SENDOUT_METHOD: 'Utskick via',
      TIME_SENT: 'Tidpunkt skickat',
      DELETED: 'Förnyelse raderad',
      UNABLE_DELETE: 'Kunde inte radera förnyelse',
      ACTIVE: 'Inväntar svar',
      STARTED_LINK: 'Påbörjad av medlem',
      INVOICED: 'Fakturerad',
      DELETED: 'Avbrutet',
      DONE: 'Slutförd',
      SCHEDULED: 'Schemalagd',
      AWAITING_PAYMENT: 'Ej betalad',
      TIME_OUT: 'Ej besvarad',
      LINK_SENT_EMAIL: 'Förnyelse-länken har skickats via e-post.',
      UNABLE_LINK_SENT_EMAIL: 'Kunde inte skicka förnyelselänken via e-post',
      NEXT_PERIOD_ERROR: 'Systemet har ej ställt in nästa period korrekt. Gå till Perioder och se till att nästa period är satt',
      UNABLE_CREATE: 'Kunde inte skapa förnyelse',
      UNABLE_LIST: 'Kunde inte hämta förnyelser',
      UNABLE_GET: 'Kunde inte hämta förnyelse',
      SEND_SMS: 'Skicka SMS',
      SEND_EMAIL: 'Skicka e-post',
      CONFIRM_SEND_SMS: 'Vill du skicka SMS? Kostnad tillkommer.',
      CONFIRM_SEND_EMAIL: 'Vill du skicka e-post?',
      CLICK_TO_SEND_SMS: 'Klicka för att manuellt skicka förnyelselänk via SMS',
      CLICK_TO_SEND_EMAIL: 'Klicka för att manuellt skicka förnyelselänk via e-post',
      SMS_SEND_FAIL: 'SMS fel',
      SMS_DID_SEND: 'SMS skickades',
      SMS_TRY_SEND: 'SMS kommer skickas',
      SMS_DONT_SEND: 'SMS kommer ej skickas',
      EMAIL_SEND_FAIL: 'E-mail fel',
      EMAIL_DID_SEND: 'E-mail skickades',
      EMAIL_TRY_SEND: 'E-mail kommer skickas',
      EMAIL_DONT_SEND: 'E-mail kommer ej skickas',
      REMINDER_SMS_SEND_AT: 'Påminnelse via SMS kommer att skickas',
      REMINDER_EMAIL_SEND_AT: 'Påminnelse via e-post kommer att skickas',
      REMINDER_NO_MORE_SMS: 'Inga mer påminnelser kommer att skickas via SMS',
      REMINDER_NO_MORE_EMAIL: 'Inga mer påminnelser kommer att skickas via e-post',
      SUMMARY_1: 'Förnyelse skapad',
      SUMMARY_2: 'med status',
      SUMMARY_3: 'och betalstatus',
      CREATE: 'Skapa förnyelse',
      ACTIVATE_AUTORENEW: 'Aktivera autoförnyelse',
      SEND_TO_PARENT: 'Skicka till vårdnadshavares e-post ifall vårdnadshavare existerar på medlemmen',
      RENEW_TEMPLATE: 'Förnyelsemall',
      RENEW_TEMPLATE_SMS: 'Förnyelsemall SMS',
      SEND_AT_TIME: 'Skicka vid tidpunkt',
      CREATE_RENEW_AT: 'När förnyelsen ska skapas',
      BEFORE_LAST_DAY: 'Innan sista giltighetsdag',
      AFTER_LAST_DAY: 'Efter sista giltighetsdag',
      NUM_DAYS: 'Antal dagar',
      EMAIL_JOB: 'E-postkörning',
      SMS_JOB: 'SMSkörning',
      CREATE_INVOICES: 'Skapa fakturor',
      CONFIRM_EMAIL: 'Är du säker på att du vill skicka e-post för valda förnyelser?',
      CONFIRM_SMS: 'Är du säker på att du vill skicka SMS för valda förnyelser?',
      CREATED_TO: 'Skapad till',
      CREATED_FROM: 'Skapad från',
      UNABLE_COUNT: 'Kunde inte räkna',
      UNABLE_GENERATE_INVOICES: 'Kunde inte generera fakturor',
      INVOICE_JOB_CREATED: 'Fakturakörning skapad',
      INVOICES_GENERATED: 'Fakturor genererade',
      NO_INVOICES_GENERATED: 'Inga fakturor genererade',
      SELECT_AT_LEAST_ONE: 'Välj minst en förnyelse',
      HAS_EMAIL: 'Har e-postadress',
      MISSING_EMAIL: 'Saknar e-postadress',
      ALL_ACTIVE_RENEWS: 'Alla aktiva förnyelser för perioden',
      SELECTED_IN_TABLE: 'Valda förnyelser i tabellen',
      JOB_WILL_COST: 'Denna körning beräknas preliminärt att kosta',
      NUM_ESTIMATED: 'Antal beräknade',
      NUMBER_SENT: 'Antal skickade',
      UNABLE_CALC_SMS: 'Kunde inte beräkna SMSkostnad',
      NO_SELECTION: 'Inget urval valt',
      HANDLE_SELECTED_MEMBERS: 'Hantera medlemmar i sökresultat',
      LAST_RUN: 'Senaste körning',
      RENEW_INTO: 'Förnya in i period',
      NUM_REMIND: 'Antal påminnelser',
      DBR: 'Antal dagar att vänta tills påminnelse skickas',
      NUMBER_ESTIMATED: 'Beräknat antal',
      NUMBER_RENEWS: 'Antal förnyelser',
      NUMBER_SMS_IN_TEMPLATE: 'Antal SMS i mall',
      SMS_EXTRA_INFO: 'OBS! Antalet är ett estimat. Varibler i mallen kan ej beräknas exakt.',
      USE_TEMPLATE_EMAIL: 'Använd e-postmall',
      USE_TEMPLATE_SMS: 'Använd SMSmall',
      SEND_VIA_EMAIL: 'Skicka via e-mail',
      SEND_VIA_SMS: 'Skicka via SMS',
      IS_CONFIRM: 'Endast bekräftelse (gratis medlemskap)'
    },
    SEARCH: {
      CLEAR_SELECTED_SHOPITEMS: 'Rensa medlemskap',
      NUM_SELECTED_SHOPITEMS: 'Antal valda medlemskap',
      MORE_FILTERS: 'Fler filter',
      MAKE_A_SEARCH: 'Gör en sökning',
      MAKE_A_SELECTION: 'Gör ett urval',
      CONFIRM_SELECTED_SHOPITEMS: 'Bekräfta val',
      SELECT_SHOPITEMS: 'Sök medlemskap',
      ADVANCED_SEARCH: 'Avancerad Sök',
      ADVANCED_INFO: 'Avancerad sökfunktion, spara urval och exporter till Excel',
      SELECTION_NOT_FOUND: 'Kunde inte välja sökparametrar',
      UNABLE_SEARCH: 'Kunde inte utföra sökning',
      SELECT_SEARCH: 'Välj sökning',
      NUM_MEMBER_FOUND: 'Antal hittade: ',
      NO_SAVED: 'Inga sparade sökningar',
      SELECT_MEMBERS_PERSONS: 'Välj medlemmar eller personer',
      DELETED: 'Sökningen togs bort',
      UNABLE_DELETE: 'Kunde inte ta bort sökning',
      SELECT: 'Välj sökning',
      MAKE_SELECTION: 'Välj mottagare',
      DO_SEARCH: 'Utför sökning',
      MEMBERSHIP_FOR_DATE: 'Medlemskap existerar på datum',
      MEMBERSHIP_FROM: 'Medlemskapsdatum från',
      MEMBERSHIP_TO: 'Medlemskapsdatum till',
      SEARCH_MEMBERS: 'Sök medlemmar',
      ONLY_MISSING_EMAIL: 'Endast medlemmar som saknar e-post',
      SEARCH_USERS: 'Sök användare',
      ONLY_WITH_EMAIL: 'Endast medlemmar som har e-post (som faktura e-post, e-post och ev. vårdnadshavare)',
      ONLY_IN_STICKPROV: 'Endast medlemmar inom stickprov',
      ONLY_IN_PERIOD: 'Endast medlemmar för period',
      ONLY_WITH_ERRORS: 'Endast medlemmar med fel',
      ONLY_MISSING_PHONE: 'Endast medlemmar utan telefon',
      ONLY_MISSING_MEMBERSHIP: 'Endast medlemmar utan medlemskap',
      SEARCH_WITH_EVENT: 'Sök med evenemang',
      SAVE_SEARCH: 'Spara sökning som',
      SAVED_NAME: 'Namn på sparad sökning',
      CREATE_SELECTION: 'Skapa sökning/mottagarlista',
      LACK_COMPANY_PERMISSION: 'Du saknar tillgång till föreningen',
      MUST_SELECT: 'Du måste välja en sökning/urval',
      SEARCH_COUNT: 'Utför sökning för att beräkna antal',
      COUNT_SEARCHED: 'Antal i sökningen är',
      SEARCH_CREATED: 'Sökningen skapades',
      UNABLE_CREATE: 'Kunde inte skapa sökning',
      UNABLE_LIST_REGIONS: 'Kunde inte lista regioner',
      PAYMENT_STATUS: 'Betalstatus',
      SEARCH_ATTRIBUTE: 'Sök med attribut',
      NO_ATTRIBUTE: 'Inget attribut',
      TO_AGE: 'Till ålder',
      FROM_AGE: 'Från ålder',
      SEARCHED: 'Sökning utförd',
      UNABLE: 'Kunde inte söka',
      USER_SEARCH_INFO: 'Sök efter användare baserat på titeln',
      USERS_AS_DIRECTORS: 'Användare som sitter i styrelse',
      USERS_AS_ACCOUNTANTS: 'Användare som är revisor',
      UNABLE_LIST_TITLES: 'Kunde inte lista titlar',
      REG_BEFORE: 'Första registrering innan datum',
      REG_AFTER: 'Första registrering efter datum'
    },
    EVENTS: {
      TICKET_NOT_IN_EVENT: 'Köpt biljett finns ej på evenemanget',
      PARTICIPANT_MISSING: 'Deltagare saknas',
      TICKET_MISSING: 'Biljett saknas',
      CHANGE_EVENT: 'Ändra evenemang',
      EVENT: 'Evenemang',
      FREE: 'Gratis',
      UNABLE_JOIN_ANY_MEMBER: 'Evenemanget kräver att du är medlem valfri förening inom riksförbundet',
      UNABLE_JOIN_COMPANY_MEMBER_PAID: 'Evenemanget kräver ett aktivt/betalande medlemskap i föreningen som håller evenemanget',
      UNABLE_JOIN_ANY_MEMBER_PAID: 'Evenemanget kräver ett aktivt/betalande medlemskap inom riksförbundet',
      UNABLE_JOIN_MAX_TICKETS: 'Du har redan köpt maximalt antal tillåtna biljetter',

      TIP_URL_FORWARD: 'URL som deltagare ska skickas till efter registrering i ett evenemang',
      HEADER_URL_FORWARD: 'URL vidarebefordran efter evenemangregistrering',


      TIP_ENABLED_EVENT_DINTERO: 'Biljett kommer skickas när betalning har skett',
      HEADER_ENABLED_EVENT_DINTERO: 'Biljettbetalning',
      CHECKBOX_ENABLED_EVENT_DINTERO: 'Kräv betalning för biljett',
      CHECKBOX_SEND_CONFIRM: 'Skicka e-postbekräftelse till deltagare vid anmälan',
      HEADER_SEND_CONFIRM: 'Skicka bekräftelse',
      TIP_SEND_CONFIRM: 'När den här är ibockad kommer ett bekräftelsemail att skickas ut till deltagare och administratör efter att  deltagaren har registrerats.',
      CHECKBOX_SUBEVENTS: 'Visa underföreningarnas evenemang i listan över evenemang',
      HEADER_SUBEVENTS: 'Lista evenemang från underföreningar',
      TIP_SUBEVENTS: 'Aktiva evenemang skapade i underföreningar kommer att visas på den publika evenemangslistan.',
      ACCEPT_TERMS: 'Jag har läst och accepterat villkoren',
      ACCOUNTING: 'Bokföring',
      ADDRESS: 'Adress',
      ADDRESS_UNAVAILABLE: 'Adress ej tillgänglig',
      ADD_PARTICIPANT: 'Lägg till deltagare',
      ADD_TICKET: 'Lägg till biljett',
      BAN_FOR_NUM_DAYS: 'Varning gäller antal dagar',
      BAN_ORGS_WITH_NUM_MEMBERS_BANNED: 'Bannlys organisation med medlemmar med antal varningar',
      CATEGORIES: 'Kategorier',
      CATEGORY: 'Kategorisida',
      CHECKEDIN: 'Incheckade',
      CHECKED_IN: 'Incheckad',
      CHOOSE_TICKETS: 'Välj biljetter',
      CLOSE_SIGNUP: 'Stäng anmälan',
      COMMUNICATION: 'Kommunikation',
      CONFIRM_DELETE: 'Är du säker på att du vill radera evenemang?',
      COST_CENTER: 'Kostnadsställe',
      CREATE_OR_CHOOSE: 'Skapa eller välj existerande',
      CREATED_EMPTY : 'Ett tomt evenemang har skapats',
      DATE_TIME: 'Datum och Tid',
      DELETED: 'Evenemanget togs bort',
      DETAILS: 'Detaljer',
      EDIT_TICKET: 'Editera biljett',
      EMAIL_TEMPLATE: 'E-postmall för biljett',
      EMAIL_TEMPLATE_WARN: 'E-postmall för varning',
      EMAIL_TICKET: 'Ett mail kommer att skickas till din e-postadress med din biljett som du eventuellt kan komma att behöva visa upp vid tillträde till evenemanget.',
      END_TIME: 'Sluttid',
      ERROR_SIGN_UPP: 'Kunde inte anmäla till evenemang',
      ERROR_TRY_LATER: 'Ett fel uppstod, försök igen senare',
      EVENTS: 'Evenemang',
      EVENT_FULL: 'Evenemanget är fullt',
      EVENT_NAME: 'Namn på evenemang',
      EVENT_NOT_FULL: 'Evenemanget har platser kvar',
      EVENT_TIMESTAMPS: 'Tidpunkter',
      EXAMPLE_LAT: 'Exempel: 121.11',
      EXAMPLE_LNG: 'Exempel: 93.23',
      FACEBOOK_INFO: 'Läs mer information om eventet på Facebook',
      FACEBOOK_URL: 'Facebook URL',
      GENERATE_GOOGLE_MAPS: 'Generera google maps',
      GENERATE_GOOGLE_MAPS_ALERT: 'Google Maps har inte genererats för detta fysiska evenemang. Klicka på knappen nedan EFTER att du har angett adress, postnummer och postort.',
      GEO_COORDS: 'Geokoordinater',
      INFO_PARTICIPATION: 'Du kan inte ändra anmäld person på evenemang. Men du kan ändra kontaktuppgifterna på den anmälde.',
      INTERNAL_ERROR: 'Internt fel',
      INVOICE_RECIPIANT_TYPE: 'Fakturamottagare typ',
      IRL_EVENT_TEXT: 'Detta är ett fysiskt evenemang, generera Google Maps.',
      LAST_SIGNUP_DATETIME: 'Sista tidpunkt för anmälan',
      LINKS: 'Länkar',
      LOCATION: 'Plats',
      LOCATION_DESCRIPTION: 'Platsbeskrivning (frivilligt)',
      LOCATION_FOR_EVENT: 'Plats för evenemang',
      MAXIMUM_ORDER: 'Du får maximalt beställa',
      MAXIMUM_TICKETS: 'Max {max_tickets} biljetter per person',
      MAX_SIGNUPS: 'Antal platser',
      MAX_TICKETS_PER_PERSON: 'Max biljetter per person',
      MEMBERS_ONLY: 'Endast medlemmar',
      MEMBER_PERSON_ADDED: 'Medlem/person har lagts till på evenemang',
      MISSING_FIELDS: 'Fält saknas',
      MUST_ACCEPT: 'Anmälan måste acceptera villkor',
      MUST_FILL_ADDRESS: 'Du måste fylla i adressinformation först',
      NEW_EVENT: 'Nytt evenemang',
      NEW_TICKET: 'Ny biljett',
      NUM_SEATS: 'Platser',
      NUM_SIGNUPS: 'Anmälda',
      ONLINE: 'Online/Internet',
      OPEN_FOR_ALL: 'Öppet för alla',
      ANY_MEMBER: 'Kräver aktivt eller utgången medlemskap i valfri organisation i systemet',
      COMPANY_MEMBER_PAID: 'Kräver betalt medlemskap i den evenemangsskapande organisationen',
      ANY_MEMBER_PAID: 'Kräver betalt medlemskap i valfri organisation i systemet',
      OTHER_LINK: 'Annan URL',
      PARTICIPANTS: 'Deltagare',
      PARTICIPANT_TYPE: 'Deltagartyp',
      PERM_BAN_AFTER_NUM_WARN: 'Permenant bannlyst efter antal varningar',
      POSTAL_ADDRESS: 'Postort',
      PREVIOUS_EVENTS: 'Tidigare evenemang',
      PUBLISH_OPTIONS: 'Publiceringsalternativ',
      READ_MORE: 'Läs mer',
      REGISTERED: 'Du är anmäld',
      REMOVED_FROM_EVENT: 'Borttagen från evenemang',
      REMOVE_PARTICIPANT: 'Ta bort deltagare',
      REMOVE_PARTICIPANT_TEXT: 'Är du säker på att du vill ta bort deltagare?',
      SAME_AS_PARTICIPANT: 'Samma adress som deltagare/anmäld',
      SAVE_EVENT: 'Spara evenemang',
      SEARCH_NAME: 'Sök namn eller e-post',
      SEARCH_SELECT_MEMBER: 'Sök och välj medlem',
      SEARCH_SELECT_PERSON: 'Sök och välj person',
      SELECT_TICKET_TEMPLATE: 'Du måste välja E-postmall för biljett',
      SEND_EMAIL: 'Skicka e-post till administratör när ny anmälan skett',
      SEND_INVITATION: 'Skicka inbjudan till alla medlemmar',
      SEND_INVITATION_TIME: 'Skicka inbjudan vid tidpunkt',
      SEND_SMS_INVITATION: 'Skicka SMS inbjudan till alla medlemmar',
      SEND_TICKET: 'Skicka biljett',
      SEND_TICKET_AGAIN: 'Skicka biljett igen',
      ERROR_SEND_TICKET: 'Kunde inte skicka biljett',
      SUCCESS_SEND_TICKET: 'Biljett skickades',
      SIGNUPED: 'Anmälda',
      SIGNUPS: 'Anmälan',
      SIGNUP_DATE_EXPIRED: 'Datum för anmälan har passerat',
      SIGN_UP: 'Anmäl dig',
      SMS_TEMPLATE_INVITATION: 'SMS för inbjudan',
      START_TIME: 'Starttid',
      STREET_AND_POST: 'Gatuadress och ort',
      STRIKE_WARN: 'Utfärda varning om frånvarande',
      SUCCESS_GOOGLE_MAPS: 'Google Maps genererades',
      TAGS: 'Taggar',
      TERMS: 'Villkor',
      TERMS_TEXT: 'Villkorstext',
      TICKET: 'Biljett',
      TICKETS: 'Biljetter',
      TICKET_CONNECTED: 'Biljett lades till',
      TICKET_DELETED: 'Biljett raderades',
      TICKET_SELECT_ERROR: 'Biljett har redan skickats till anmälda, du kan inte ändra den.',
      TICKET_SEND: 'Skicka biljett när deltagare lagts till',
      TYPE_OF_TICKET: 'Biljettyp',
      UNABLE_ADD_MEMBER_PERSON: 'Kunde inte lägga till medlem/person på evenemang',
      UNABLE_CONNECT_TICKET: 'Kunde inte lägga till biljett',
      UNABLE_CREATE: 'Kunde inte skapa evenemang',
      UNABLE_DELETE: 'Kunde inte radera evenemang',
      UNABLE_DELETE_CONFLICT: 'Kunde inte radera för att det finns anmälda, ta bort alla anmälda först.',
      UNABLE_DELETE_TICKET: 'Kunde inte radera biljett',
      UNABLE_GET: 'Kunde inte hämta evenemang',
      UNABLE_GOOGLE_MAPS: 'Kunde inte generera Google Maps',
      UNABLE_LIST: 'Kunde inte lista evenemang',
      UNABLE_LIST_PARTICIPANTS: 'Kunde inte list anmälda',
      UNABLE_LIST_TICKETS: 'Kunde inte lista biljetter',
      UNABLE_REMOVE_PARTICIPANT: 'Kunde inte ta bort deltagare från evenemang',
      UNABLE_UPDATE: 'Kunde inte uppdatera evenemang',
      UPDATED: 'Evenemanget uppdaterades',
      VAT_ACCOUNT: 'Moms konto',
      VISIT_WEBPAGE: 'Besök denna webbplats för läsa mer om evenemanget',
      ZIPCODE: 'Postnummer',
      INACTIVE_MEMBERSHIP: 'Vänligen förnya ditt medlemskap och försök igen eller kontakta föreningens medlemsupport.',
      MEMBERS_ONLY: 'Inloggning som medlem krävs',
      EVENT_ONLINE: "Online/Internet",
      STATUS: 'Status',
      IS_CLOSED: 'Stängt/Startdatum passerat',
      IS_OPEN: 'Öppen'
    },
    EVENT_CATEGORY: {
      CONFIRM_DELETE: 'Är du säker på att du vill radera kategorin?',
      UNABLE_GET: 'Kunde inte hämta kategori',
      UNABLE_LIST: 'Kunde inte lista kategorier',
      CREATED: 'Kategori skapad',
      UPDATED: 'Kategori uppdaterad',
      DELETED: 'Kategori raderad',
      UNABLE_CREATE: 'Kunde inte skapa kategori',
      UNABLE_UPDATE: 'Kunde inte uppdatera kategori',
      UNABLE_DELETE: 'Kunde inte radera kategori',
      NEW: 'Ny kategori',
      EDIT: 'Redigera kategori',
      NON_PUBLIC_NAME: 'Namn (ej publikt)',
      LINK: 'Länk',
      HEADER: 'Rubrik',
      FORWARD_SUCCESS: 'URL efter lyckad anmälan',
      URL_INFO: 'http:// eller https:// måste inkluderas',
      PAGE_TEXT: 'Sidtext',
      LOWEST: 'Längst sortering',
      HIGHEST: 'Högst sortering'
    },
    TERMS: {
      CREATED_AT: 'Skapad',
      ACTIVE: 'Aktiv',
      NAME: 'Namn',
      TERMS: 'Användarvillkor',
      ACCEPT: 'Acceptera villkor',
      MISSING: 'Användarvillkor saknas',
      NEW: 'Nytt villkor',
      TERMS_TEXT_INFO: 'Denna text kommer att stå bredvid checkboxen. Texten för villkor/policy kommer visas i en popup när man klickar på texten mellan % taggarna. Exempel: Klicka %här% för att visa användarvillkoren.',
      CHECKBOX_TEXT: 'Checkbox text',
      TEXT: 'Villkorstext',
      UNABLE_GET: 'Kunde inte hämta villkor',
      UPDATED: 'Villkor uppdaterades',
      UNABLE_UPDATE: 'Kunde inte uppdatera villkor',
      CREATED: 'Villkor skapades',
      UNABLE_CREATE: 'Kunde inte skapa villkor',
      DELETED: 'Villkor raderat',
      UNABLE_DELETE: 'Kunde inte radera villkor'
    },
    BOOKING: {
      CREATED: 'Bokning skapades',
      SEND: 'En bokningsbekräftelse kommer skickas till din e-post',
      CONFIRM_BOOKING: 'Bekräfta bokning',
      SELECT_BOOKING: 'Välj kurstillfälle och ort nedan'
    },
    QUICKSEARCH: {
      UNABLE_SEARCH: 'Kunde inte söka efter medlem',
      UNABLE_SEARCH_PERSON: 'Kunde inte söka efter person',
      SEARCH_TERMS: 'Namn, personnummer, e-post, telefon...',
      QUICKSEARCH_INFO: 'Snabbsök efter medlem i alla föreningar och perioder'
    },

    MEMBER_PROPERTY: {
      HEADER_GROUP_ATTRIBUTES: 'Attribut inom kategorin',
      NEW_ATTRIBUTE: 'Nytt attribut',
      EDIT_ATTRIBUTE: 'Redigera attribut',
      NAME: 'Namn',
      PLACEHOLDER_DESCR: 'Beskrivande text om attributet',
      DESCR: 'Beskrivning',
      CONFIRM_DELETE: 'Är du säker på att du vill radera attributkategorin?'
    },
    SYSTEM: {
      INSTANCE_TIMEOUT_TEMPLATE_ID: 'Övervakning: Instans timeout mall',
      INSTANCE_RETURN_TEMPLATE_ID: 'Övervakning: Instans återansluten mall',
      ADMIN_INQUIRY_TEMPLATE_ID: 'Ny kundförfrågan: Administratör',
      CUSTOMER_CONFIRM_INQUIRY_TEMPLATE_ID: 'Ny kundförfrågan: Bekräftelse till kund',
    },
    DUPLICATES: {

      NUM_DUPLICATES: 'Antal dubbletter',

      MERGE_INFO1: 'Medlemsdata som ej behålls kommer att raderas',
      MERGE_INFO2: 'Alla medlemmar i tabellen nedan kommer att slås samman för respektive rad',
      MERGE_INFO3: 'Medlemskap som läggs över på medlem som behålls kommer att kontrolleras att det ej är en dubblett. Medlemskap med samma period men olika föreningar kommer att behållas. Medlemskap med samma period och förening kommer att beslutas enligt alternativen i "Medlemskap".',
      MERGE_INFO4: 'Observera att medlemmar kommer att raderas när denna körning har startats.',

      MEMBER_SKIP_OPTIONS: 'Exkludera medlemmar från körningen som saknar följande: ',
      MEMBER_SKIP_OPTION1: 'Förnamn',
      MEMBER_SKIP_OPTION2: 'Efternamn',
      MEMBER_SKIP_OPTION3: 'E-post',
      MEMBER_SKIP_OPTION4: 'Telefon',
      MEMBER_SKIP_OPTION5: 'Personnummer',

      MEMBERSHIP_MERGE_OPTIONS: 'Medlemskap',
      MEMBERSHIP_MERGE_OPTION1: 'Behåll nyaste medlemskapet vid period överlapp',
      MEMBERSHIP_MERGE_OPTION2: 'Behåll äldsta medlemskapet vid period överlapp',
      MEMBERSHIP_MERGE_OPTION3: 'Behåll medlemskapet som har betalats vid period överlapp',

      MEMBER_DATA_OPTIONS: 'Målmedlem',
      MEMBER_DATA_OPTION1: 'Behåll den nyaste medlemmen',
      MEMBER_DATA_OPTION2: 'Behåll den äldsta medlemmen',

      TRANSACTION_OPTIONS: 'Transaktionsdata',
      TRANSACTION_OPTION1: 'Behåll endast målmedlemmens transaktioner',
      TRANSACTION_OPTION2: 'Behåll transaktoner från alla medlemmar',
    },
    USER_PERMISSIONS: {
      UPDATED: 'Användarrättigheter uppdaterade',
      UNABLE_UPDATE: 'Kunde inte uppdatera användarrättigheter',
      UNABLE_LIST: 'Kunde inte lista användarrättigheter'
    },

    EXAMPLES: {
      EDITOR: 'Editor',
      TABLE: 'Tabell',
      NAME: 'Namn',
      NAME2: 'Namn2',
      DATE: 'Datum',
      NEW: 'Nytt exempel',
      WELCOME_ADMIN: 'Välkommen admin!',
      ON_SUBMIT: 'Formuläret sparades',
    },

    MEMBER_DETAILS: {
      PHONE: 'Mobiltelefon',
      LANDLINE: 'Hemtelefon',
      YYYY: 'ÅÅÅÅ',
      INVALID_PHONE: 'Felaktigt telefonnummer',
      INVALID_CO: 'Namnet måste vara minst två tecken',
      INVALID_DOB: 'Felaktigt födelsedatum',
      INVALID_EMAIL: 'Felaktig e-postadress',
      INVALID_EMAIL2: 'E-postadressen överensstämmer inte',
      INVALID_PASSWORD: 'Lösenordet behöver vara 6-30 tecken',
      INVALID_PASSWORD2: 'Lösenordet överensstämmer inte',
      TOOLTIP_FIRSTNAME: 'Plats för förnamn och eventuella mellannamn.',
      TOOLTIP_LASTNAME: 'Fält för efternamn.',
      TOOLTIP_COMPANY: 'Namn på företag.',
      TOOLTIP_DOB: 'Födelsedatum som ska skrivas in med år, månad och sist dag. T.ex. 19880120.',
      TOOLTIP_INTDOB: 'Födelsedatum som ska skrivas in med månad, dag och sist årtal.',
      TOOLTIP_ADDRESS: 'Gatuadressen och nummer.',
      TOOLTIP_ZIPCODE: 'Postnummer bestående av fem tecken, t.ex. 123 45.',
      TOOLTIP_POST: 'Orten som är kopplat till postnumret.',
      TOOLTIP_CO: 'Fält som används i adresser när du skickar ett brev eller paket till en person genom att använda någon annans adress eller adressen till ett företag.',
      TOOLTIP_COUNTRY: 'Det land som adressen är kopplad till',
      TOOLTIP_REGION: 'Geografisk region som ska vara kopplat till medföljande adress. Denna uppgift kan i de flesta fall fyllas i automatiskt genom postnummer, genomför inte detta behövs ett manuellt val.',
      TOOLTIP_COMMUNE: 'Geografisk kommun som ska vara kopplat till medföljande adress. Denna uppgift kan i de flesta fall fyllas i automatiskt genom postnummer, genomför inte detta behövs ett manuellt val.',
      TOOLTIP_PHONE: 'Det mobilnummer som ska vara kopplat till detta konto, skall förbigås av landskod, t.ex. +46701234567.',
      TOOLTIP_LANDLINE: 'Hemtelefon inklusive riktnummer.',
      TOOLTIP_EMAIL: 'En fungerande e-postadress som ska vara kopplat till detta konto, Skall innehålla ett @ och en punkt.',
      TOOLTIP_EMAIL2: 'Vänligen bekräfta e-postadressen i denna ruta genom att skriva in det en gång till.',
      TOOLTIP_PASSWORD: 'Lösenord som är kopplat till detta konto, skall bestå av 6-30 tecken.',
      TOOLTIP_PASSWORD2: 'Vänligen bekräfta lösenordet i denna ruta genom att skriva in det en gång till.',
      TOOLTIP_GENDER: 'Välj könsidentitet.',
      TOOLTIP_AVD: 'Fyll i vad för avdelning du tillhör.',
    },

    IMPORT: {

      WAIT_WHILE_AI_MAPPING: 'Vänligen vänta, vår AI mappar nu er data till vår',

      TIP_PARENT_COMPANY: 'Föreningar som saknar moderförening i excel kommer hamna som underförening till denna.',
      PARENT_COMPANY: 'Moderförening',
      TIP_TO_PERIOD: 'Medlemmar kommer importeras till denna period.',
      TO_PERIOD: 'Till period',
      TO_COMPANY: 'Till förening',
      TIP_TO_COMPANY: 'Medlemmar som saknas föreningsnummer i posterna kommer att importeras till denna förening.',
      TIP_PRIMARY_KEY: 'Ange den kolumn som ska identifiera posten. Den skall vara unik för varje post. I det fallet samma post delar denna nyckel kommer dessa att slås samman till en post.',
      PRIMARY_KEY: 'Primärnyckel',
      VERIFY_FIELDS: 'Kontrollera importfälten',
      SKIP_ALL: 'Hoppa över alla',
      ROW: 'Rad',
      CELL_HEADER: 'Rubrik',
      CELL_NUMBER: 'Cellnummer',
      MESSAGE: 'Meddelande',
      VALIDATION_ERROR: 'Ett fel uppstod vid validering av import',
      CREATE: 'Skapa ny',
      FILE_REQUIREMENTS_INFO: 'Innan ni laddar upp XLSX filen för import, säkerställ att följande krav uppfylls: ',
      FILE_REQUIREMENTS_1: 'Filen måste vara <strong>XLSX</strong> 2007 formatterad.',
      FILE_REQUIREMENTS_2: 'Samtliga kolumner/celler måste vara formatterade som text/strängar.',
      FILE_REQUIREMENTS_3: 'Filen <strong>måste minst</strong> innehålla kolumner för medlemsnummer, föreningsnummer och medlems butiksID.',
      FILE_REQUIREMENTS_4: 'Kontrollera att datumen är formatterade på följande vis: <strong>ÅÅÅÅ-MM-DD</strong>. Annat format kommer att importeras felaktigt.',
      FILE_REQUIREMENTS_5: 'Personnummer måste vara 12 siffror enligt följande: ÅÅÅÅMMDD-1234.',
      FILE_REQUIREMENTS_6: 'Vissa värden får inte överstiga ett visst antal tecken. <a href="https://memlist.se/docs/importera-medlemmar-genom-excel/">Denna sida</a> innehåller information om vad maxlängd för varje kolumn är.',
      FILE_REQUIREMENTS_7: 'Regioner och kommuner följer skatteverkets koder.',
      FILE_REQUIREMENTS_8: 'Telefonnummer skall ha landskod, exempel: +4671234567.',
      EXCEL_FIELD: 'Excelfält',
      MEMLIST_FIELD: 'Memlistfält',
      COLUMN: 'Kolumn',
      FILE_READ_ERROR: 'Kunde inte läsa filen',
      OPTIONS: 'Importalternativ',
      MAP_COLUMNS: 'Mappa kolumner',
      VALIDATION: 'Validering',
      FINALIZE: 'Importering',
      NOT_FOUND: 'Kunde inte hämta import',
      ERROR: 'Ett fel uppstod',
      TAB_INFO_HEADER: 'Import information',
      CREATED_AT: 'Tidpunkt skapat',
      BUTTON_MAP_COLUMNS: 'Mappa kolumner',
      BUTTON_VALIDATE: 'Validera',
      BUTTON_FINALIZE: 'Slutför',
      ACTIVE: 'Aktiva importer',
      HISTORY: 'Tidigare importer',
      STATUS: 'Status',
      NEW_IMPORT: 'Ny import',
      FILE: 'Importfil',
      VALIDATION_100_ROWS: 'Av optimeringskäl så valideras endast de första 100 raderna av importfilen.',
      UPLOAD_FILE: 'Ladda upp fil',
      INVALID_FILE: 'Ogiltig fil',
      TO_PERIOD: 'Till period',
      CREATED_BY: 'Skapad av',
      TAB_FINALIZE_HEADER: 'Slutför import',
      TAB_INFO_HEADER: 'Importinformation',
      TAB_MAP_COLUMNS_HEADER: 'Mappa kolumner',
      TAB_VALIDATION_HEADER: 'Filvalidering',
      BUTTON_FINALIZE: 'Slutför',
      BUTTON_VALIDATE: 'Kontrollera',
      BUTTON_MAP_COLUMNS: 'Mappa kolumner',
      UNABLE_DELETE: 'Kunde inte radera',
      COUNT_MAPPINGS: 'Antal mappade kolumner',
      FILENAME: 'Filnamn',
      RUNNING_VALIDATION: 'Importen valideras...',
      DONE_VALIDATION: 'Valideringen är avklarad',
      FAILURE_VALIDATION: 'Valideringen kunde inte slutföras',
      READY_TO_VALIDATE: 'Redo att validera',
      SUCCESS_FINALIZE: 'Importen är slutförd!',
      FAILURE_FINALIZE: 'Importen kunde inte slutföras',
      RUNNING_FINALIZE: 'Importen slutförs...',
      READY_TO_FINALIZE: 'Importen är redo att slutföras!',
      FINALIZE_IMPORT: 'Slutför import',
      UNABLE_UPLOAD: 'Kunde inte ladda upp fil',
      UNABLE_UPDATE: 'Kunde inte uppdatera import',
      CONTINUE: 'Fortsätt',
      STATUSES: {
        WAIT_MAP_COLUMNS: 'Mappa kolumner',
        FAIL_VALIDATION: 'Valideringsfel',
        FAIL_FINALIZE: 'Importfel',
        DONE_VALIDATION: 'Validering klar',
        DONE_MAP_COLUMNS: 'Mappa kolumner klar',
        WAIT_VALIDATION: 'Måste validera',
        WAIT_FINALIZE: 'Måste importera',
        RUN_FINALIZE: 'Import körs...',
        RUN_VALIDATION: 'Validering körs...',
        DONE_FINALIZE: 'Import avklarad'
      }
    }
  },

  TAB: {
    INVOICE_COMPANIES: {
      CREATE_INVOICES: 'Skapa ({count}) fakturor',
      NO_INVOICE_AFTER: 'Saknar faktura efter datum',
      HEADER_COMPANY: 'Förening',
      HEADER_MEMBER_COUNT: 'Antal medlemmar',
      HEADER_TOTAL_AMOUNT: 'Beräknad summa',
      HEADER_LAST_INVOICE: 'Senaste faktura',
      TIP_NO_INVOICE_AFTER: 'Filter för att lista alla organisationer som saknar en genererad faktura efter ett specifikt datum.',
      TIP_HEADER_COMPANY: 'Föreningens namn.',
      TIP_HEADER_MEMBER_COUNT: 'Antalet medlemmar per förening.',
      TIP_HEADER_TOTAL_AMOUNT: 'Totala summan som estimeras att aviseras.',
      TIP_HEADER_LAST_INVOICE: 'Datum när den senaste aviseringen har genererats.',
      CONFIRM_TITLE: 'Fakturakörning',
      CONFIRM_MESSAGE: 'Fakturorna genereras och kommer visas i fakturatabellen när körningen är avklarad.',
      CONFIRM_ERROR: 'Gick inte att starta körningen.',
      UNABLE_CREATE_INVOICES: 'Kunde inte skapa fakturor.',
      UNABLE_LIST_COMPANIES: 'Kunde inte lista föreningar.',
      UNABLE_LIST_SHOP_ITEMS: 'Kunde inte lista butiksartiklar.'
    },
    INVOICE_TEMPLATES: {
      DELETE_LOGO: 'Ta bort',
      CREATE_TITLE: 'Skapa fakturamall',
      UPDATE_TITLE: 'Uppdatera fakturamall',
      NEW_TEMPLATE: 'Ny mall',
      SETTINGS: 'Inställningar',
      SENDER: 'Avsändarfält',
      BOX: 'Grafisk box',
      BOX_TEXT: 'Text',
      BOX_BG: 'Bakgrundsfärg',
      BOX_FG: 'Textfärg',
      NAME: 'Namn',
      LANGUAGE: 'Språk',
      TYPE: 'Typ',
      SENDER_HEADER: 'Returadress text',
      HEADER: 'Sidhuvudtext',
      REMINDER: 'Påminnelse',
      REMINDER_FEE: 'Påminnelseavgift',
      UPLOAD_LOGO: 'Ladda upp logo',
      SENDER_WEBADDRESS: 'Webbadress',
      SENDER_PHONE: 'Kontakt telefonnummer',
      SENDER_ORGNUMBER: 'Organisationsnummer',
      SENDER_CONTACT: 'Kontaktperson',
      SENDER_EMAIL: 'Kontakt e-post',

      TYPES: {
        MEMBER: 'Avi för medlemmar',
        COMPANY: 'Faktura förening',
        MEMBER_RENEW: 'Förnyelse för medlemmar'
      },

      TYPE_OPTION1: 'Avi för medlemmar',
      TYPE_OPTION2: 'Avi för föreningar',
      HEADER_OPTION1: 'Avi',
      HEADER_OPTION2: 'Faktura',
      HEADER_OPTION3: 'Påminnelsefaktura',
      TH_NAME: 'NAMN',
      TH_CREATED_AT: 'SKAPAD',
      TIP_SENDER_HEADER: 'Text att visa ovan returadress på fakturan.',
      TIP_SETTINGS: 'Välj vilken mottagarinformation som skall vara synlig på aviseringen.',
      TIP_SENDER: 'Välj vilken avsändarinformation som skall vara synlig på aviseringen.',
      TIP_NAME: 'Sätter namnet på fakturamallen, Detta namn visas endast för administratörer och syns ej av föreningar eller medlemmar vid ett eventuellt fakturautskick.',
      TIP_LANGUAGE: 'Välj vilket språk som fakturan skall genereras på.',
      TIP_HEADER: 'Val av aviseringstyp.',
      TIP_TYPE: 'Val av typ av faktura. Avi för medlemmar - Skapar en faktura som går ut till en eller flera enskilda medlemmar. Faktura för förening - Skapar en faktura som går ut till föreningar.',
      TIP_REMINDER: 'Skapa påminnelsefaktura och sätt eventuellt en påminnelseavgift.',
      TIP_BOX: 'I denna sektion kan man ställa in utseendet på den grafiska boxen på fakturan.',
      TIP_BOX_TEXT: 'Definera innehåll i den grafiska boxen.',
      TIP_BOX_BG: 'Definera bakgrundsfärgen på den grafiska boxen. Välj en bakgrundsfärg som har bra kontrast till textfärgen.',
      TIP_BOX_FG: 'Definera textfärgen på den grafiska boxen. Välj en textfärg som har bra kontrast till bakgrundsfärgen.',
      TIP_USE_BPOST_IMAGE: 'Lägg in "BPosttidning" bild',
      USE_BPOST_IMAGE: 'Lägg in "BPosttidning"',
      INVALID_SENDER_HEADER: 'Ogiltig returadress text',
      INVALID_NAME: 'Ogiltigt namn',
      INVALID_LANGUAGE: 'Ogiltigt språk',
      INVALID_TYPE: 'Ogiltig typ',
      INVALID_HEADER: 'Ogiltig sidhuvudtext',
      INVALID_REMINDER_FEE: 'Ogiltig påminnelseavgift',
      INVALID_BOX_TEXT: 'Ogiltig text',
      CREATED: 'Fakturamall skapad.',
      DELETED: 'Fakturamall borttagen.',
      UPDATED: 'Fakturamall uppdaterad.',
      UPLOADED: 'Logotypfil uppladdad',
      UNABLE_CREATE: 'Kunde inte lista skapa fakturamall.',
      UNABLE_LIST: 'Kunde inte lista fakturamallar.',
      UNABLE_DELETE: 'Kunde inte ta bort fakturamall.',
      UNABLE_UPDATE: 'Kunde inte uppdatera fakturamall.',
      UNABLE_UPLOAD: 'Kunde inte ladda upp logotypfil.',
      UNABLE_PREVIEW: 'Kunde inte förhandsgranska fakturamall.',
    },
    SETTINGS_DNS: {
      TITLE: 'Giltiga postadresser för utskick',
      NEW_ADDRESS: 'Ny adress',
      INVALID_EMAIL: 'Ogiltig e-postadress',
      ADDED: 'Adressen tillagd',
      DELETED: 'Adressen borttagen',
      UNABLE_ADD: 'Kunde inte lägga till adress',
      UNABLE_DELETE: 'Kunde inte ta bort adress',
      UNABLE_LIST: 'Kunde inte lista adresser'
    },

    USER_TERMS: {
      NEW_TERM: 'Nytt villkor'
    }

  },

  QUICKSEARCH: {
    RESULTS: 'Resultat',
    NO_RESULTS: 'Inga resultat hittades'
  },

  ADVANCED_SEARCH: {
    SAVE_OPTIONS: 'Alternativ',
    PERIODS: 'Perioder',
    ALL_PERIODS: 'Alla perioder',
    SAVE_QUERY: 'Spara sökning',

    NO_RESULTS: 'Inga resultat',
    DO_AUTORENEW: 'Autoförnyelse',
    SECRET: 'Sekretess',
    ALL_COMPANIES: 'Alla föreningar',
    NO_ACTIVE_RENEWAL: 'Saknar aktiv förnyelse',
    HAS_ACTIVE_RENEWAL: 'Har aktiv förnyelse',
    RENEWAL: 'Förnyelse',

    UNABLE_EDIT: 'Denna rad kan inte redigeras',

    NUM_SELECTION: 'Antal mottagare: {count}',

    SELECT_RECIPIENTS: 'Mottagare: {count}',

    CREATE_QUERY: 'Spara sökning',

    NO_QUERIES: 'Inga sökningar hittades',

    QUERY_UPDATED: 'Sökning uppdaterad',
    UNABLE_QUERY_UPDATE: 'Kunde inte uppdatera sökning',

    QUERY_CREATED: 'Sökning skapad',
    UNABLE_QUERY_CREATE: 'Kunde inte skapa sökning',

    UNABLE_QUERY_LIST: 'Kunde inte lista sökningar',

    UNABLE_QUERY_DELETE: 'Kunde inte radera sökning',
    QUERY_DELETED: 'Sökning raderad',

    SELECT_SAVED_SEARCH: 'Välj sparad sökning',

    COMPANIES: 'Föreningar',

    SEARCH_QUERIES: 'Sparade sökningar',
    SELECT_QUERY: 'Välj sökning',
    QUERY_UPDATED: 'Sökning uppdaterad',

    NAME_REQUIRED: 'Namn krävs',

    SAVED: 'Sparad',
    UNABLE_TO_SAVE: 'Kunde inte spara',

    NO_RECORDS_IN_SELECTION: 'Inga rader att exportera',

    SHOW_MORE_FIELDS: 'Visa fler avancerade filter',
    HIDE_MORE_FIELDS: 'Dölj fler avancerade filter',

    MEMBERS: 'Medlemmar',
    USERS: 'Användare',
    PERSONS: 'Personer',


    PAYMENT_STATUS: 'Betalstatus',
    SEX: 'Juridiskt kön',
    PUBLIC_SEX: 'Eget kön',

    ALL: 'Alla',
    YES: 'Ja',
    NO: 'Nej',
    NONE: 'Ingen',
    MISSING: 'Saknas',
    EXISTS: 'Finns',

    PAID: 'Betalad',
    NOT_PAID: 'Ej betalad',

    MEMBER_TYPE: 'Medlemstyp',
    AGE: 'Ålder',
    AGE_FROM: 'Ålder från',
    AGE_TO: 'Ålder till',
    RDT: 'Registreringsdatum',
    EXIT_AT: 'Utträdesdatum',
    RDT_FROM: 'Från',
    RDT_TO: 'Till',

    SHOP_ITEM: 'Artikel',

    MISSING_MEMBERSHIP: 'Saknar medlemskap',
    MISSING_MEMBERSHIP_FROM: 'Från',
    MISSING_MEMBERSHIP_TO: 'Till',
    HAVE_MEMBERSHIP: 'Finns medlemskap',
    HAVE_MEMBERSHIP_FROM: 'Från',
    HAVE_MEMBERSHIP_TO: 'Till',

    DECEASED: 'Avliden',
    DEACTIVATED: 'Avslutad',
    ADDRESS: 'Gatuadress',
    ZIPCODE: 'Postnummer',
    COUNTRY: 'Finns land',
    COUNTRY_CODE: 'Land',
    PHONE: 'Telefon',
    EMAIL: 'Epost',
    HD: 'Huvudmedlem',
    PERSONNR: 'Personnummer',

    SAVE_SEARCH: 'Spara sökning',

    UNABLE_TO_SEARCH: 'Kunde inte utföra sökning',

    EVENTS: {
      NAME: 'Evenemang',
      HEADER: 'Välj evenemang',
      EVENT_NAME: 'Evenemangsnamn',
      COMPANY_NAME: 'Förening',
      SELECT_EVENT: 'Välj evenemang ({count})',
      ADD_EVENT: 'Lägg till evenemang',
      NONE_SELECTED: 'Inga evenemang valda'
    },
    MEMBERSHIPS: {
      NAME: 'Medlemskap',
      HEADER: 'Välj medlemskap',
      MEMBERSHIP_NAME: 'Medlemskapsartikel',
      COMPANY_NAME: 'Förening',
      SELECT_MEMBERSHIP: 'Välj medlemskap ({count})',
      ADD_MEMBERSHIP: 'Lägg till medlemskap',
      NONE_SELECTED: 'Inga medlemskap valda',
      INCLUDE_ANY: 'Minst ett medlemskap måste finnas',
      INCLUDE_ALL: 'Samtliga medlemskap måste finnas',
      INCLUDE_INFO: 'Inkluderingsalternativ',
    },
    ATTRIBUTES: {
      INCLUDE_ANY: 'Minst ett attribut måste finnas',
      INCLUDE_ALL: 'Samtliga attribut måste finnas',
      INCLUDE_INFO: 'Inkluderingsalternativ',
      NAME: 'Attribut',
      HEADER: 'Välj attribut att söka',
      GROUP_NAME: 'Kategori',
      PROP_NAME: 'Värde',
      SELECT_ATTRIBUTE: 'Välj attribut ({count})',
      ADD_ATTRIBUTE: 'Lägg till attribut',
      NONE_SELECTED: 'Inga attribut valda'
    },
    EDUCATIONS: {
      SEARCH_EDUCATION: 'Skriv in utbildningskod',
      CODE: 'Kod',
      EDUCATION: 'Utbildning',
      INCLUDE_ANY: 'Minst en utbildning måste finnas',
      INCLUDE_ALL: 'Samtliga utbildningar måste finnas',
      INCLUDE_INFO: 'Inkluderingsalternativ',
      NAME: 'Utbildning',
      HEADER: 'Välj utbildning att söka',
      SELECT_EDUCATION: 'Välj utbildning ({count})',
      ADD_EDUCATION: 'Lägg till utbildning',
      NONE_SELECTED: 'Inga utbildningar valda'
    },
  },

  MODAL: {
    INVOICE_CREATE: {
      TITLE: 'Skapa fakturor',
      INVOICE_COUNT: 'Totalt antal fakturor',
      PREVIEW_AS: 'Förhandsgranska som',
      TIP_INVOICE_COUNT: 'Antalet fakturor som kommer att genereras.',
      TIP_PREVIEW_AS: 'Förhandsgranska aviseringen som en av mottagarna.',
      TH_ARTICLE: 'ARTIKEL',
      TH_AMOUNT: 'KOSTNAD',
      TH_CURRENCY: 'VALUTA',
      TH_QUANTITY_TYPE: 'BERÄKNING',
      TH_QUANTITY: 'ANTAL',
      TH_VAT: 'MOMS',
      TH_TOT_VAT: 'TOT. MOMS',
      TH_TOT_AMOUNT: 'TOT.',
      TOT_VAT: 'Total moms',
      EXC_VAT: 'Totalt ex. moms',
      INC_VAT: 'Totalt inkl. moms',
      DUE_DATE: 'Förfallodatum',
      BOX_TEXT: 'Fakturatext',
      ADD_ITEM: '+ Lägg till',
      CREATE: 'Skapa ({count})',
      QUANTITY_TYPE_1: 'Per antal',
      QUANTITY_TYPE_2: 'Per medlem',
      TIP_TH_ARTICLE: 'Namnet på artiklen som kommer visas på fakturan.',
      TIP_TH_AMOUNT: 'Kostnad per artikel.',
      TIP_TH_CURRENCY: 'Valuta per artikel.',
      TIP_TH_QUANTITY_TYPE: 'Beräkningssätt per artikel.',
      TIP_TH_QUANTITY: 'Antal artiklar.',
      TIP_TH_VAT: 'Moms per artikel.',
      TIP_TH_TOT_VAT: 'Total moms.',
      TIP_TH_TOT_AMOUNT: 'Total summa.',
      TIP_TOT_VAT: 'Total moms.',
      TIP_EXC_VAT: 'Total summa exklusive moms.',
      TIP_INC_VAT: 'Total summa inklusive moms.',
      TIP_DUE_DATE: 'Åsidosätt det aktuella fördefinierade förfallodatumet (denna inställning finns under Inställningar/Betalning) genom att definiera ett nytt datum.',
      TIP_BOX_TEXT: 'Åsidosätt den aktuella fördefinierade fakturatexten (denna text sätts i fakturamallen under Fakturor/Mallar) genom att definiera en ny text.',
    }
  },

  COMPONENT: {
    EMAIL_VALID_DOMAIN: {
      INVALID: 'Ogiltig e-post, kontakta Memlist support.',
      UNABLE_LIST: 'Kunde inte lista giltiga e-postadresser.'
    },
  },

  COUNTRIES: [
    { key: 'NONE', value: 'Inget val' },
    { key: 'AF', value: 'Afghanistan' },
    { key: 'AL', value: 'Albanien' },
    { key: 'DZ', value: 'Algeriet' },
    { key: 'AS', value: 'Amerikanska Samoa' },
    { key: 'AD', value: 'Andorra' },
    { key: 'AO', value: 'Angola' },
    { key: 'AI', value: 'Anguilla' },
    { key: 'AQ', value: 'Antarktis' },
    { key: 'AG', value: 'Antigua and Barbuda' },
    { key: 'AR', value: 'Argentina' },
    { key: 'AM', value: 'Armenien' },
    { key: 'AW', value: 'Aruba' },
    { key: 'AU', value: 'Australien' },
    { key: 'AZ', value: 'Azerbaijan' },
    { key: 'BS', value: 'Bahamas' },
    { key: 'BH', value: 'Bahrain' },
    { key: 'BD', value: 'Bangladesh' },
    { key: 'BB', value: 'Barbados' },
    { key: 'BE', value: 'Belgien' },
    { key: 'BZ', value: 'Belize' },
    { key: 'BJ', value: 'Benin' },
    { key: 'BM', value: 'Bermuda' },
    { key: 'BT', value: 'Bhutan' },
    { key: 'BO', value: 'Bolivia, Plurinational State of' },
    { key: 'BQ', value: 'Bonaire, Sint Eustatius and Saba' },
    { key: 'BA', value: 'Bosnien Herzegovina' },
    { key: 'BW', value: 'Botswana' },
    { key: 'BV', value: 'Bouvet Island' },
    { key: 'BR', value: 'Brasilien' },
    { key: 'IO', value: 'Brittiska territoriet i Indiska oceanen' },
    { key: 'BN', value: 'Brunei Darussalam' },
    { key: 'BG', value: 'Bulgarien' },
    { key: 'BF', value: 'Burkina Faso' },
    { key: 'BI', value: 'Burundi' },
    { key: 'CV', value: 'Cape Verde' },
    { key: 'KY', value: 'Cayman Öarna' },
    { key: 'CF', value: 'Central Afrikanska Republiken' },
    { key: 'CL', value: 'Chile' },
    { key: 'CC', value: 'Cocos (Keeling) Islands' },
    { key: 'CO', value: 'Colombia' },
    { key: 'KM', value: 'Comoros' },
    { key: 'CK', value: 'Cook Islands' },
    { key: 'CR', value: 'Costa Rica' },
    { key: 'CW', value: 'Curaçao' },
    { key: 'CY', value: 'Cypern' },
    { key: 'DK', value: 'Danmark' },
    { key: 'DJ', value: 'Djibouti' },
    { key: 'DM', value: 'Dominica' },
    { key: 'DO', value: 'Dominican Republic' },
    { key: 'EC', value: 'Ecuador' },
    { key: 'EG', value: 'Egypten' },
    { key: 'SV', value: 'El Salvador' },
    { key: 'CI', value: 'Elfenbenskusten' },
    { key: 'GQ', value: 'Equatorial Guinea' },
    { key: 'ER', value: 'Eritrea' },
    { key: 'EE', value: 'Estland' },
    { key: 'ET', value: 'Etiopien' },
    { key: 'FK', value: 'Falklandsöarna' },
    { key: 'FJ', value: 'Fiji' },
    { key: 'PH', value: 'Filipinerna' },
    { key: 'FI', value: 'Finland' },
    { key: 'FR', value: 'Frankrike' },
    { key: 'GF', value: 'Franska Guiana' },
    { key: 'PF', value: 'Franska Polynesien' },
    { key: 'TF', value: 'Franska Södra Territorium' },
    { key: 'FO', value: 'Färöarna' },
    { key: 'AE', value: 'Förenade Arab Emiraterna' },
    { key: 'GA', value: 'Gabon' },
    { key: 'GM', value: 'Gambia' },
    { key: 'GE', value: 'Georgien' },
    { key: 'GH', value: 'Ghana' },
    { key: 'GI', value: 'Gibraltar' },
    { key: 'GR', value: 'Grekland' },
    { key: 'GD', value: 'Grenada' },
    { key: 'GL', value: 'Grönland' },
    { key: 'GP', value: 'Guadeloupe' },
    { key: 'GU', value: 'Guam' },
    { key: 'GT', value: 'Guatemala' },
    { key: 'GG', value: 'Guernsey' },
    { key: 'GN', value: 'Guinea' },
    { key: 'GW', value: 'Guinea-Bissau' },
    { key: 'GY', value: 'Guyana' },
    { key: 'HT', value: 'Haiti' },
    { key: 'HM', value: 'Heard Island and McDonald Islands' },
    { key: 'HN', value: 'Honduras' },
    { key: 'HK', value: 'Hong Kong' },
    { key: 'IN', value: 'Indien' },
    { key: 'ID', value: 'Indonesien' },
    { key: 'IQ', value: 'Irak' },
    { key: 'IR', value: 'Iran' },
    { key: 'IE', value: 'Irland' },
    { key: 'IS', value: 'Island' },
    { key: 'IM', value: 'Isle of Man' },
    { key: 'IL', value: 'Israel' },
    { key: 'IT', value: 'Italien' },
    { key: 'JM', value: 'Jamaica' },
    { key: 'JP', value: 'Japan' },
    { key: 'JE', value: 'Jersey' },
    { key: 'JO', value: 'Jordan' },
    { key: 'CX', value: 'Julön' },
    { key: 'KH', value: 'Kambodja' },
    { key: 'CM', value: 'Kamerun' },
    { key: 'CA', value: 'Kanada' },
    { key: 'KZ', value: 'Kazakhstan' },
    { key: 'KE', value: 'Kenyen' },
    { key: 'CN', value: 'Kinda' },
    { key: 'KI', value: 'Kiribati' },
    { key: 'CG', value: 'Kongo' },
    { key: 'CD', value: 'Kongo-Kinshasa ' },
    { key: 'KP', value: "Korea, Democratic People's Republic of" },
    { key: 'KR', value: 'Korea, Republic of' },
    { key: 'HR', value: 'Kroatien' },
    { key: 'CU', value: 'Kuba' },
    { key: 'KW', value: 'Kuwait' },
    { key: 'KG', value: 'Kyrgyzstan' },
    { key: 'LA', value: "Lao People's Democratic Republic" },
    { key: 'LS', value: 'Lesotho' },
    { key: 'LV', value: 'Lettland' },
    { key: 'LB', value: 'Libanon' },
    { key: 'LR', value: 'Liberia' },
    { key: 'LY', value: 'Libyen' },
    { key: 'LI', value: 'Liechtenstein' },
    { key: 'LT', value: 'Litauen' },
    { key: 'LU', value: 'Luxembourg' },
    { key: 'MO', value: 'Macao' },
    { key: 'MG', value: 'Madagascar' },
    { key: 'MK', value: 'Makedonien' },
    { key: 'MW', value: 'Malawi' },
    { key: 'MY', value: 'Malaysien' },
    { key: 'MV', value: 'Maldiverna' },
    { key: 'ML', value: 'Mali' },
    { key: 'MT', value: 'Malta' },
    { key: 'MA', value: 'Marocko' },
    { key: 'MH', value: 'Marshall Islands' },
    { key: 'MQ', value: 'Martinique' },
    { key: 'MR', value: 'Mauritania' },
    { key: 'MU', value: 'Mauritius' },
    { key: 'YT', value: 'Mayotte' },
    { key: 'MX', value: 'Mexiko' },
    { key: 'FM', value: 'Micronesia, Federated States of' },
    { key: 'MD', value: 'Moldavien' },
    { key: 'MC', value: 'Monaco' },
    { key: 'MN', value: 'Mongoliet' },
    { key: 'ME', value: 'Montenegro' },
    { key: 'MS', value: 'Montserrat' },
    { key: 'MZ', value: 'Mozambique' },
    { key: 'MM', value: 'Myanmar' },
    { key: 'NA', value: 'Namibia' },
    { key: 'NR', value: 'Nauru' },
    { key: 'NL', value: 'Nederländerna' },
    { key: 'NP', value: 'Nepal' },
    { key: 'NC', value: 'New Caledonia' },
    { key: 'NI', value: 'Nicaragua' },
    { key: 'NE', value: 'Niger' },
    { key: 'NG', value: 'Nigeria' },
    { key: 'NU', value: 'Niue' },
    { key: 'NF', value: 'Norfolk Island' },
    { key: 'NO', value: 'Norge' },
    { key: 'MP', value: 'Northern Mariana Islands' },
    { key: 'NZ', value: 'Nya Zealand' },
    { key: 'OM', value: 'Oman' },
    { key: 'PK', value: 'Pakistan' },
    { key: 'PW', value: 'Palau' },
    { key: 'PS', value: 'Palestine, State of' },
    { key: 'PA', value: 'Panama' },
    { key: 'PG', value: 'Papua New Guinea' },
    { key: 'PY', value: 'Paraguay' },
    { key: 'PE', value: 'Peru' },
    { key: 'PN', value: 'Pitcairn' },
    { key: 'PL', value: 'Polen' },
    { key: 'PT', value: 'Portugal' },
    { key: 'PR', value: 'Puerto Rico' },
    { key: 'QA', value: 'Qatar' },
    { key: 'RO', value: 'Rumänien' },
    { key: 'RW', value: 'Rwanda' },
    { key: 'RU', value: 'Ryssland' },
    { key: 'RE', value: 'Réunion' },
    { key: 'BL', value: 'Saint Barthélemy' },
    { key: 'SH', value: 'Saint Helena, Ascension and Tristan da Cunha' },
    { key: 'KN', value: 'Saint Kitts and Nevis' },
    { key: 'LC', value: 'Saint Lucia' },
    { key: 'MF', value: 'Saint Martin (French part)' },
    { key: 'PM', value: 'Saint Pierre and Miquelon' },
    { key: 'VC', value: 'Saint Vincent and the Grenadines' },
    { key: 'WS', value: 'Samoa' },
    { key: 'SM', value: 'San Marino' },
    { key: 'ST', value: 'Sao Tome and Principe' },
    { key: 'SA', value: 'Saudi Arabien' },
    { key: 'CH', value: 'Schweiz' },
    { key: 'SN', value: 'Senegal' },
    { key: 'RS', value: 'Serbien' },
    { key: 'SC', value: 'Seychelles' },
    { key: 'SL', value: 'Sierra Leone' },
    { key: 'SG', value: 'Singapore' },
    { key: 'SX', value: 'Sint Maarten (Dutch part)' },
    { key: 'SK', value: 'Slovakien' },
    { key: 'SI', value: 'Slovenien' },
    { key: 'SB', value: 'Solomon Islands' },
    { key: 'SO', value: 'Somalia' },
    { key: 'ZA', value: 'South Africa' },
    { key: 'GS', value: 'South Georgia and the South Sandwich Islands' },
    { key: 'ES', value: 'Spanien' },
    { key: 'LK', value: 'Sri Lanka' },
    { key: 'SD', value: 'Sudan' },
    { key: 'SR', value: 'Suriname' },
    { key: 'SJ', value: 'Svalbard and Jan Mayen' },
    { key: 'SE', value: 'Sverige' },
    { key: 'SZ', value: 'Swaziland' },
    { key: 'SS', value: 'Syd Sydan' },
    { key: 'SY', value: 'Syrien' },
    { key: 'TW', value: 'Taiwan, Province of China' },
    { key: 'TJ', value: 'Tajikistan' },
    { key: 'TZ', value: 'Tanzania, United Republic of' },
    { key: 'TD', value: 'Tchad' },
    { key: 'TH', value: 'Thailand' },
    { key: 'TL', value: 'Timor-Leste' },
    { key: 'CZ', value: 'Tjeckien' },
    { key: 'TG', value: 'Togo' },
    { key: 'TK', value: 'Tokelau' },
    { key: 'TO', value: 'Tonga' },
    { key: 'TT', value: 'Trinidad and Tobago' },
    { key: 'TN', value: 'Tunisien' },
    { key: 'TR', value: 'Turkiet' },
    { key: 'TM', value: 'Turkmenistan' },
    { key: 'TC', value: 'Turks and Caicos Islands' },
    { key: 'TV', value: 'Tuvalu' },
    { key: 'DE', value: 'Tyskland' },
    { key: 'GB', value: 'UK, Storbritannien' },
    { key: 'US', value: 'USA, Förenade Staterna' },
    { key: 'UG', value: 'Uganda' },
    { key: 'UA', value: 'Ukraina' },
    { key: 'HU', value: 'Ungern' },
    { key: 'UM', value: 'United States Minor Outlying Islands' },
    { key: 'UY', value: 'Uruguay' },
    { key: 'UZ', value: 'Uzbekistan' },
    { key: 'VU', value: 'Vanuatu' },
    { key: 'VA', value: 'Vatikanstaten' },
    { key: 'VE', value: 'Venezuela, Bolivarian Republic of' },
    { key: 'VN', value: 'Vietnam' },
    { key: 'VG', value: 'Virgin Islands, British' },
    { key: 'VI', value: 'Virgin Islands, U.S.' },
    { key: 'BY', value: 'Vitryssland' },
    { key: 'WF', value: 'Wallis and Futuna' },
    { key: 'EH', value: 'Western Sahara' },
    { key: 'YE', value: 'Yemen' },
    { key: 'ZM', value: 'Zambia' },
    { key: 'ZW', value: 'Zimbabwe' },
    { key: 'AX', value: 'Åland' },
    { key: 'AT', value: 'Österrike' }
  ],
  CALENDAR: {
    MONTHS: {
      JANUARY: 'Januari',
      FEBRUARY: 'Februari',
      MARS: 'Mars',
      APRIL: 'April',
      MAY: 'Maj',
      JUNE: 'Juni',
      JULY: 'Juli',
      AUGUST: 'Augusti',
      SEPTEMBER: 'September',
      OCTOBER: 'Oktober',
      NOVEMBER: 'November',
      DECEMBER: 'December'
    },
    SHORT_MONTHS: {
      JAN: 'Jan',
      FEB: 'Feb',
      MAR: 'Mar',
      APR: 'Apr',
      MAY: 'Maj',
      JUN: 'Jun',
      JUL: 'Jul',
      AUG: 'Aug',
      SEP: 'Sep',
      OCT: 'Okt',
      NOV: 'Nov',
      DEC: 'Dec'
    }
  },
  TRANSLATOR: {
    SELECT: "Välj ditt språk"
  },
  PUBLIC: {
    NO_ACCESS: {
      CONTINUE_TO_LOGIN: '<< Tillbaka till inloggning',
      HEADER: 'Ingen tillgång till förening',
      INFO_P: 'Ditt konto har ingen tilldelad föreningstillhörighet.',
      INFO1: 'Gruppen som din användare tillhör saknar tillgång till förening eller sida.',
      INFO2: 'Be administratören som bjöd in dig att lägga till rättigheter.'
    },
    NO_PAGE_ACCESS: {
      
      HEADER: 'Ingen tillgång till sidan',
      INFO_P: '',
      INFO1: 'Gruppen som din användare tillhör saknar tillgång till sidan.',
      INFO2: 'Be administratör att lägga till sidan i din grupptillhörighet.'
    },
    LOGIN: {
      INVALID_LOGIN_LINK: 'Denna länk är ogiltig. Kontakta aministratör för att få en ny länk.',
      DISABLED_LOGIN: 'Administratören har inaktiverat inloggning.',
    },
    FORM: {
      CUSTOM_DONATION_AMOUNT: 'Egen summa',
      CREATE_FAMILY_MEMBER: 'Lägg till familjemedlem',
      TOTAL: 'Total',
      CART: 'Varukorg',
      CART_EMPTY: 'Varukorgen är tom',
      MUST_SELECT_MEMBERSHIP: 'Du måste välja ett medlemskap',
      TO: 'till',
      ADD: 'Lägg till',
      REMOVE: 'Ta bort',
      PERSONNR_REQUIRED: 'Giltigt personummer krävs',
      MISSING_FIELDS: 'Fyll i saknade obligatoriska fält',
      INVALID_PERSONNR: 'Ogiltigt personnummer',
      PERSONNR: 'Personnummer',
      PLEASE_WAIT: 'Vänligen vänta',
      PLEASE_WAIT_INFO: 'Din förfrågan behandlas',
      CONFIRM_HEADER: 'Bekräftelsesida',
      INVALID_TEXT: 'Ogiltig text',
      FIRSTNAME: 'Förnamn',
      LASTNAME: 'Efternamn',
      EMAIL: 'E-post',
      EMAIL2: 'Repetera e-post',
      INVALID_EMAIL: 'Ogiltig e-post',
      EMAIL_MISMATCH: 'E-postadresserna matchar inte',
      PASSWORD: 'Lösenord',
      PASSWORD2: 'Repetera lösenord',
      PASSWORD_MISMATCH: 'Lösenorden matchar inte',
      INVALID_PASSWORD: 'Ogiltigt lösenord',
      INVALID_PHONE: 'Ogiltigt telefonnummer',
      PHONE: 'Telefon',
      LANDLINE: 'Fast telefon',
      POST: 'Postort',
      ZIPCODE: 'Postnummer',
      ADDRESS: 'Adress',
      INVALID_ADDRESS: 'Ogiltig adress',
      INVALID_POST: 'Ogiltig postort',
      INVALID_ZIPCODE: 'Ogiltigt postnummer',
      COMMUNE_CODE: 'Kommun',
      REGION_CODE: 'Region',
      COUNTRY: 'Land',
      INVALID_DOB: 'Ogiltigt födelsedatum',
      DOB: 'Födelsedatum ÅÅÅÅMMDD',
      CNAME: 'Företagsnamn',
      ORG_NUMBER: 'Organisationsnummer',
      INVALID_ORG_NUMBER: 'Ogiltigt organisationsnummer',
      AVD: 'Avdelning',
      INVOICE_FIELDS: 'Faktureringsuppgifter',
      MALE: 'Man',
      FEMALE: 'Kvinna',
      OTHER: 'Annat',
      PUBLIC_SEX: 'Kön',
    },
    INQUIRY: {
      OTHER_TEXT: 'Övriga upplysningar',
      CREATED_SUCCESSFULLY: 'Tack för din anmälan!',
      CREATED_SUCCESSFULLY_INFO: 'Vi kommer att granska din anmälan och återkoppla så snart vi kan.',
      
      CREATE_FAILURE: 'Ett fel uppstod!',
      CREATE_FAILURE_INFO: 'Vi ber er testa igen vid ett senare tillfälle eller kontakta supporten på support@memlist.se ',
      

      ORG_INFO: 'Information om er organisation',
      ORG_NAME: 'Organisationsnamn',
      INPUT_ORG_NAME: 'Ange namn på er organisation',
      INPUT_C_NAME: 'Ange namn på kontaktperson',
      C_NAME: 'Kontaktperson',
      ADMIN_EMAIL: 'Din e-postadress',
      INPUT_ADMIN_EMAIL: 'Ange en giltig e-post',
      INPUT_ADMIN_PHONE: 'Ange ditt telefonnummer',
      ADMIN_PHONE: 'Ditt telefonnummer',
      PHONE: 'Telefon',
      MISSING_FIELDS: 'Vänligen fyll i alla fält',
      ORG_SETUP: 'Organisationsuppsättning',
      ORG_TYPE: 'Organisationstyp',
      ORG_TYPES: {
        RIKS: 'Riksförbund',
        COMPANY: 'Företag',
        STUDENT: 'Studentkår',
        STUDENT_COMPANY: 'Studentförening',
        SPORTCLUB: 'Idrottsförening',
        INTEREST_CLUB: 'Intresseförening',
        UNION: 'Fackförening',
        COOP: 'Samfällighet',
        BRF: 'Bostadsrättsförening',
        STIFT: 'Stiftelse',
        MUCF: 'Ungdomsförbund (MUCF)',
        FAITH: 'Trossamfund'
      },
      ORG_STRUCTURE: 'Organisationsstruktur',
      ORG_STRUCTURES: {
        SINGLE: 'Enskild organisation',
        MULTI: 'Flera organisationer',
      },
      PERIOD: 'Medlemskapsperiod',
      PERIODS: {
        INFINITE: 'Löpande',
        PERIOD: 'Kalenderår',
        OTHER: 'Annat'
      },
      NUM_ORGS: 'Antal avdelningar/underorganisationer',
      HAS_PAYMENT: 'Vill ni ta betalt för medlemskap?',
      PAYMENT_METHODS: {
        SWISH: 'Swish',
        CREDITCARD: 'Kortbetalning',
        POST_INVOICE: 'Postfaktura',
        EMAIL_INVOICE: 'E-postfaktura',
        OTHER: 'Annat'
      },
      OTHER_SERVICES: 'Övriga funktioner av intresse',

      SERVICES: {
        SENDOUT: 'Utskick',
        TODO: 'Ärendehantering',
        EVENTS: 'Evenemangshantering',
        FORTNOX: 'Fortnox integration',
        SPAR: 'SPAR-uppslag',
        LADOK: 'LADOK',
        REGISTRATIONS: 'Medlemsansökningar',
        FILEUPLOADS: 'Filuppladdning för medlem',
        FILEMANAGEMENT: 'Filhantering',
        COUPONS: 'Kuponger',
        GRANT: 'Bidragshantering',
        DEACTIVATION: 'Utträdesansökningar av medlemmar',
        HISTORY: 'Historik/Logghantering'
      },
      SELECT_PAYMENT_METHODS: 'Välj betalningsmetoder',
      SEND_INQUIRY: 'Skicka förfrågan',
    },
    EVENT: {
      REGISTER_INFO: 'Registrera dina uppgifter',
      INVALID_PHONE: 'Ogiltig telefon',
      INVALID_EMAIL: 'Ogiltig epost'
    },
    REGISTRATION: {
      EXPECTED_ACTION_ERROR_HEADER: 'Felaktig inställning',
      EXPECTED_ACTION_ERROR: 'Denna registreringssida har felaktiga betalningsinställningar. Kontakta föreningen och meddela att betalningsmetoderna måste ställas in på korrekt sätt.',
      YOU_WILL_BE_MEMBER: 'Du kommer att bli medlem i',
      MEMBERSHIP_CREATED: 'Medlemskonto skapat!',
      MEMBERSHIP_CREATED_INFO: 'Ditt konto har skapats och en aktiveringslänk har skickats till din angivna e-postadress',
      UNABLE_CREATE_MEMBERSHIP: 'Ditt konto blev inte skapat. Försök igen senare.',
      UNABLE_LOAD: 'Registreringsformuläret kunde inte laddas. Länken är felaktig.',
      UNABLE_GET_WIZARD: 'Kunde inte hämta registreringswizard',
      MEMBERSHIP_PENDING: 'Vi granskar din ansökan',
      MEMBERSHIP_PENDING_INFO: 'Din medlemsansökan kommer att granskas innan vi kan godkänna er som medlem. Vänligen invänta ett godkännande via e-post.',
      ERROR_OCCURED: 'Ett fel uppstod',
      MEMBERSHIP_ERROR: 'Fel vid registrering',
      MEMBERSHIP_ERROR_MESSAGE: 'Det gick inte att registrera medlem. Försök igen senare eller kontakta er förening.'
    },
    DONATION: {
      DONATION: 'Donation',
      FROM_NAME: 'Ditt namn',
      DONATION_TO: 'Din donation till {name}',
      ENTER_AMOUNT: 'Ange summa',
      MESSAGE: 'Skriv ett frivilligt meddelande',
      START_PAYMENT: 'Fortsätt'
    },
    RENEW: {
      CONFIRM_MEMBERSHIP: 'Bekräfta medlemskap',
      MEMBERSHIPS: 'Medlemskap',
      COMPANY: 'Förening',
      PERIOD: 'Period',
      MEMBER_NOT_FOUND: 'Medlemsdata hittades ej',
      OLD_LINK: 'Aktiveringslänken är antingen felaktig eller för gammal.',
      UNABLE_CONFIRM: 'Kunde inte bekräfta',
      THANKS_CONFIRM: 'Tack för din bekräftelse!',
      REDIRECT: 'Omdirigeras till din profil...',
      ERROR_CONFIRM_INFO: 'Det gick inte att bekräfta dina medlemskap, försök igen senare eller kontakta administratör.',
      TRY_AGAIN_LATER: 'Kunde inte bekräfta. Försök senare.'
    },
    PAYMENT: {
      SELECT_PAYMENT: 'Välj betalmetod',
      NO_PAYMENT_METHODS: 'Inga betalningsmetoder är uppsatta',
      CONTACT_SUPPORT: 'Kontakta support',
      PAYMENT_TIMEOUT: 'Betalningen har tagit för lång tid att slutföra.',
      START_OVER: 'Gå tillbaka och försök igen.',
      CREDITCARD: 'Betalkort',
      CHANGE_RECIPIENT: 'Ändra betalningsmottagare',
      SEND_TO_EMAIL: 'Skicka kvitto till e-post',
      INVALID_TOKEN: 'Ogiltig Token',
      INVALID_SHOP_ORDER_ID: 'Ogiltigt OrderID',
      WAITING_FOR_PAYMENT: 'Summa att betala',
      PAYMENT_COMPLETE: 'Betalning klar',
      CLOSE_THIS_PAGE: 'Du kan stänga denna sida',
      PAYMENT_ERROR: 'Betalning misslyckades',
      LOADING_SWISH: 'Vänligen vänta, laddar Swish...',
      TOTAL_INC_VAT: 'Totalbelopp inkl moms',
      PAYMENT_FAILED: 'Betalning misslyckad',
      PAYMENT_SUCCESS: 'Betalning lyckades!',
      WAITING: 'Inväntar betalning...',
      TRY_LATER: 'Försök igen senare',

      REVIEW: 'Granska order',
      ITEMS: 'Artiklar',
      ITEM: 'Artikel',
      PRICE: 'Pris',
      TOTAL: 'Total',
      DISCOUNT: 'Rabatt',
      INVALID_ACTION: 'Ogiltig betalningsmetod',
      OPEN_SWISH: 'Betala nu',
      SCAN_QR_INFO: 'Öppna Swish appen och scanna koden nedan',
      INVOICE: 'Faktura',
      START_SWISH: 'Påbörja betalning',
      WRITE_YOUR_NUMBER: 'Skriv in ditt telefonnummer kopplat till swish',
      START_YOUR_SWISH_APP: 'Öppna din Swish app och signera betalningen',
      CANT_SEE_QR: 'Kan du inte se QR koden? Klicka här istället',
      APP_WONT_OPEN_CLICK_HERE: 'Öppnas inte appen? Klicka här istället',
      PAYER_ALIAS: 'Ditt swishnummer',
      LOADING_SHOP_ORDER: 'Laddar order'
    }
  },

  DONATION: {
    DONATIONS: 'Donationer',
    CREATE_DONATION: 'Skapa donation',
    UNABLE_LIST: 'Kunde inte lista donationer',
    UNABLE_DELETE: 'Kunde inte radera donation',
    DELETED: 'Donation raderad',
    BASIC: 'Grundläggande',
    ADVANCED: 'Avancerat',
    NAME: 'Rubrik',
    NAME_INFO: 'Donationens namn och visas överst på donationsrutan',
    NAME_REQUIRED: 'Rubrik krävs',
    DELETE_CONFIRM_TITLE: 'Bekräfta radering',
    DELETE_CONFIRM_MESSAGE: 'Är du säker på att du vill radera donationen?',
    DONATION_ITEMS: 'Donationsalternativ',
    DONATION_ITEMS_INFO: 'Ge er möjlighet att visa fördefinierade fasta belopp och valfritt eget belopp. Ni kan lägga till flera fasta belopp genom att klicka på "Lägg till alternativ".',
    ADD_ROW: '+ Lägg till alternativ',
    HOW_TO: 'Hur vill ni ta in era donationer',
    HOW_TO_INFO: 'Aktivera de metoder ni skall önska ge givarna möjlighet att välja. Minst en måste vara aktiverad.',
    ACTIVATE_ONE_TIME_DONATIONS: 'Aktivera engångsdonationer',
    ACTIVATE_MONTHLY_DONATIONS: 'Aktivera månadsgivare',
    DIRECT_LINK: 'Direktlänk',
    DIRECT_LINK_INFO: 'Denna länk leder till landningssidan för donationen och visar de inställningar ni gjort efter ni sparat donationens inställningar.',
    TYPE_FIXED: 'Fast belopp',
    TYPE_CUSTOM: 'Eget belopp',
    FIXED_AMOUNT: 'Belopp som visas',
    MIN_AMOUNT: 'Minsta tillåtna belopp',
    ITEM_TYPE: 'Typ',
    CODE_INTEGRATION: 'Kod för integration',
    CODE_INTEGRATION_INFO: 'Kod för att lägga till på er hemsida',
    
    CODE_INTEGRATION_BUTTON: 'Kod för knapp (popup)',
    CODE_INTEGRATION_BUTTON_INFO: 'Lägg in följande kod på er hemsida. Detta är en knapp som kommer visas och när man klickar på den så öppnas en popup där man som slutanvändare kan utföra en donation.',
    ACTIVATE_PERSON_DETAILS_PAGE: 'Aktivera personuppgifter',
    ACTIVATE_CONFIRM_PAGE: 'Aktivera bekräftelsesida',
    SELECT_FIXED: 'Välj belopp',
    SELECT_CUSTOM: 'Eget belopp',
    CONTINUE: 'Fortsätt',

    ONE_TIME: 'Engångsdonation',
    SUBSCRIPTION: 'Månadsgivare',
    SHOP_ITEM: 'Butiksartikel',
    SHOP_ITEM_INFO: 'Kopplad butiksartikel för att kunna spåra i orderhistorik samt för bokföring. Gå till "Butik/Medlemskap" och skapa en artikel och markera som "Är donation".',
    DONATION: 'Donation'
  }

};
