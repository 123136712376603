// Swedish
// use {{ $t('TRANSLATOR.SELECT') }}
export const locale = {
  ERRORS: {
    HEADERS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Шаблон приглашения не установлен',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'Необходимо указать ассоциации',
      ERR_TODO_SET_DATE: 'Дата не указана',
      ERR_TODO_MISSING_SUBTASKS: 'Не указано действие',
      ERR_REFUND_AMOUNT_ABOVE_0: 'Неверная сумма',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'Недопустимое действие',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Недопустимое действие',
      ERR_PENDING_PAYMENTS_MEMBER: 'Отсутствует платеж',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'Нет получателей',
      ERR_MEMBER_ALREADY_EXISTS: 'Участник уже существует',
      ERR_EMAIL_ALREADY_REGISTERED: 'Электронная почта уже зарегистрирована',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'Отсутствует период SKV',
      ERR_MEMBER_SET_REG: 'Неверное действие',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'Отсутствует членство',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Достигнут максимум билетов',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'Отсутствует членство',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Неизвестная ошибка',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'Только для участников',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'Неактивное членство',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Недопустимо',
      ERR_FORBIDDEN: 'Недопустимо',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'Отсутствует страница подтверждения',
      ERR_409_GROUP: 'Доступ к материнской ассоциации',
      ERR_RENEWS_HAVE_ERRORS: 'Ошибки в обновлениях',
      ERR_MULTIPLE_WORKSHEETS: 'Несколько листов',
      ERR_IMPORT_TOO_MANY_ROWS: 'Слишком много строк',
    },
    TEXTS: {
      ERR_INVITE_TEMPLATE_NOT_SET: 'Шаблон приглашения в систему не установлен. Перейдите в Система -> Шаблоны пользователей -> Приглашение в систему.',
      ERR_TODO_MUST_SET_COMPANY_IDS: 'Вы должны указать хотя бы одну ассоциацию, которая получит задачу.',
      ERR_TODO_SET_DATE: 'Вы должны указать дату начала и окончания задачи.',
      ERR_TODO_MISSING_SUBTASKS: 'Вы должны создать хотя бы одно действие в задаче.',
      ERR_REFUND_AMOUNT_ABOVE_0: 'Сумма возврата должна превышать 0 и быть меньше общей суммы заказа.',
      ERR_CANT_REMOVE_ALL_MEMBERSHIPS: 'У участника должно быть хотя бы одно членство.',
      ERR_CANT_REMOVE_MEMBERSHIP_WITH_INVOICE: 'Членство, связанное с накладной, не может быть удалено.',
      ERR_PENDING_PAYMENTS_MEMBER: 'Ваше членство не оплачено, поэтому вход в систему запрещен. Подождите не менее 5 минут и повторите регистрацию с оплатой.',
      ERR_NO_MEMBERS_IN_SENDOUT_QUERY: 'Не выбраны получатели. Выполните поиск, чтобы определить, кто получит рассылку.',
      ERR_MEMBER_ALREADY_EXISTS: 'Этот участник уже существует',
      ERR_EMAIL_ALREADY_REGISTERED: 'Пользователь с этим адресом электронной почты уже зарегистрирован',
      ERR_MEMBER_MISSING_SKV_PERIOD: 'У участника отсутствует период SKV',
      ERR_MEMBER_SET_REG: 'Установить участника как "текущую регистрацию" не разрешено',
      ERR_EVENTSIGNUP_ANY_MEMBER_PAID: 'Для покупки билета на это мероприятие необходимо иметь активное оплаченное членство. Продлите свое членство, чтобы продолжить.',
      ERR_EVENTSIGNUP_MAX_TICKETS: 'Вы достигли лимита на количество билетов, которые можно купить на этом мероприятии.',
      ERR_EVENTSIGNUP_COMPANY_MEMBER_PAID: 'Для участия в этом мероприятии необходимо иметь активное, оплаченное членство в ассоциации, проводящей мероприятие.',
      ERR_EVENTSIGNUP_UNDEFINED_ERROR: 'Произошла неожиданная ошибка. Свяжитесь с организатором мероприятия и приложите следующий текст для более быстрого разрешения: ',
      ERR_EVENTSIGNUP_MEMBERS_ONLY: 'Для участия в этом мероприятии необходимо быть членом',
      ERR_EVENTSIGNUP_INACTIVE_MEMBERSHIP: 'У вас отсутствует активное членство в вашем аккаунте.',
      ERR_EVENTSIGNUP_ANY_MEMBER: 'Участие в этом мероприятии запрещено.',
      ERR_FORBIDDEN: 'У вас недостаточно прав для выполнения этого действия.',
      ERR_MISSING_REQUIRED_PAGE_CONFIRMPAGE: 'Все формы должны иметь страницу подтверждения. Создайте страницу подтверждения для этой формы.',
      ERR_409_GROUP: 'Вы не можете удалить подассоциацию, если группа имеет доступ к материнской ассоциации.',
      ERR_RENEWS_HAVE_ERRORS: 'В таблице есть строки с ошибками (красные). Невозможно создать накладные на ошибочные обновления.',
      ERR_MULTIPLE_WORKSHEETS: 'Файл может содержать максимум 1 лист. Удалите скрытые или неактивные листы.',
      ERR_IMPORT_TOO_MANY_ROWS: 'Импорт может содержать максимум 100 000 строк. Удалите избыточные строки.',
    },
  },

  VALIDATIONS: {
    ENTER_PASSWORD: 'Введите пароль, не менее 6 символов'
  },

  BANKID: {
    SCAN_QR: 'Translate: Scan QR in BankID',
    TIMEOUT: 'Translate: Inloggningen tog för lång tid. Ladda om sidan.',
    COMPLETE: 'Translate: BankID Signerat!',
    FAILED: 'Translate: Fel från BankID',
    OPEN_BANKID: 'Translate: Öppna BankID',
  },

  AUTH: {
    ADMINISTRATOR: 'Администратор',
    MENU: {
      DONATIONS: 'Translate: Donation',
      CUSTOMERS: 'Translate: Kunder',
      MEMBERS: 'УЧАСТНИКИ',
      MEMBERS_CONTROL: 'Участники',
      HISTORY: 'Журнал событий',
      MEMBER_REGISTRATIONS: 'Заявки на членство',
      DASHBOARD: 'Панель управления',
      TODO: 'Управление задачами',
      ERROR: 'Отчет об ошибках',
      EVENT: 'Мероприятие',
      REPORTS: 'Отчеты',
      MEMBER_EDITOR: 'Редактирование участников',
      QUICKSEARCH: 'Быстрый поиск',
      ADV_SEARCH: 'Расширенный поиск',
      DUPLICATES: 'Дубликаты',
      SPAR: 'SPAR',
      IMPORT: 'Импорт',
      STICKPROV: 'Выборка',
      LADOK: 'LADOK',
      ATTRIBUTES: 'Атрибуты',
      RENEWS: 'Продления',
      RENEWS_DEPRECATED: 'Продления (Старая ссылка)',
      MUCF: 'MUCF',
      SETTINGS: 'Настройки',
      REGISTERSETUP: 'Мастер регистрации',
      FORMS: 'Формы',
      PERIODS: 'Периоды',
      USERS: 'Пользователи',
      SUBCOMPANY: 'Подассоциации',
      FILES: 'Файлы',
      SHOPITEM: 'Магазин',
      BOOK: 'Бухгалтерия',
      SENDOUT: 'Рассылка',
      TEMPLATES: 'Шаблоны',
      SUPPORT: 'Поддержка',
      BILLING: 'Расходы',
      COMPANIES: 'Ассоциации',
      LANGUAGE: 'Язык',
      SYSTEM: 'Система',
      IMPORTCOMPANIES: 'Импорт ассоциаций',
      APIKEYS: 'API: Ключи',
      PAYMENTS: 'Платежи',
      TRANSACTIONS: 'Транзакции',
      SMS: 'SMS',
      GRANT: 'Грант',
      PAGE: 'Страницы',
      REDOC: 'API: Документация',
      ADMIN: 'Администрирование',
      ORDERS: 'История заказов',
      INVOICES: 'Счета',
      FORTNOX_INVOICES: 'Fortnox Счета',
      COUPONS: 'Купоны (BETA)',
      JOBS: 'Запуски',
      EMAILS: 'Электронные события',
      EDUCATION: 'Образование',
      THS_INSTANCES: 'Экземпляры',
      THS_LOGS: 'THS Журналы',
      FAITH: 'Конфессия',
      COMMUNICATION: 'Коммуникация',
      NOTES: 'Заметки',
      USER_PERMISSIONS: 'Права пользователей',
      INSTRUMENT_TYPES: 'Типы инструментов',
      SWISH_MERCHANT: 'Swish Торговля',
      SUPPORT_ADMIN: 'Поддержка администратора',
      KVITTO: 'Translate: Receipt service'
    }
  },

  BOILERPLATE: {
    TEXTAREA: 'Источник модели Prisma',
    BOILERPLATE: 'Заготовка',
    PRISMA_BOILERPLATE: 'Заготовка Prisma',
  },

  DASHBOARD: {
    MEMBERS: 'Новые участники',
    MEMBERSHIPS: 'Членства',
    PERIOD_MEMBERSHIPS: 'Членства за выбранный период',
    DATE_MEMBERSHIPS: 'Членства за период',
    MUCF_6_25: 'MUCF 6-25 лет',
    MUCF_TOTAL: 'Общее количество MUCF',
    COUNT: 'Подсчет',
    SKV_INTAG: 'Translate: Skatteverket Intag'
  },

  MUCF: {
    MUCF_GUIDE: 'Руководство MUCF',
    SETTINGS: 'Настройки',
    SEARCH_COMPANY: 'Поиск ассоциации',
    NUM_ROWS: 'Количество строк'
  },

  MEMBER_EDUCATION: {
    CREATE_SOURCES: {
      LADOK: 'LADOK',
      ADMIN: 'Администратор',
      MEMBER: 'Участник'
    },
    CREATING: 'Присоединение образования',
    EDITING: 'Связанное образование',
    ID: 'Id',
    CREATED_AT: 'Дата присоединения',
    SOURCE: 'Источник',
    EDUCATION_ID: 'Образование',
    MEMBER_ID: 'Участник',
    PERIOD_ID: 'Период',
    HEADER: 'Образование участников',
    TAB_MEMBER_EDUCATION: 'Образование',
    TAB_SETTINGS: 'Настройки',
    CREATED: 'Созданное образование',
    DELETED: 'Удаленное образование',
    UPDATED: 'Обновленное образование',
    UNABLE_TO_LOAD: 'Не удалось загрузить образование',
    UNABLE_TO_CREATE: 'Не удалось создать образование',
    UNABLE_TO_UPDATE: 'Не удалось обновить образование',
    UNABLE_TO_DELETE: 'Не удалось удалить образование',
    NO_RECORDS_FOUND: 'Образование не найдено',
    CREATE_MEMBER_EDUCATION: 'Присоединить образование',
    ADDED: 'Образование присоединено',
    UNABLE_ADD: 'Не удалось присоединить образование',
  },

  SWISH_MERCHANT: {
    TEST_CREATED: 'Тест Swish создан',
    FINALIZE_SETUP: 'Завершите установку: ',
    CREATE_TEST: 'Создать тест Swish',
    AN_ERROR_OCCURED: 'Не удалось создать тест Swish',

    PAYER_NUMBER: 'Номер Swish',
    AMOUNT: 'Сумма',
    MESSAGE: 'Сообщение',
    USE_SWISH_NUMBER: 'Использовать номер Swish',
    USE_QR_CODE: 'Использовать QR-код',
    USE_LOCAL_APP: 'Открыть приложение локально',

    HEADER: 'Swish Торговля',
    UNABLE_LOAD_FILES: 'Не удалось загрузить файлы сертификатов',
    TAB_MERCHANTS: 'Аккаунты Swish Торговля',
    TAB_SETTINGS: 'Настройки',
    NO_RECORDS_FOUND: 'Записи не найдены',
    CREATE_MERCHANT: 'Создать Swish Торговлю',
    CREATED_AT: 'Дата создания',
    KEY_FILE: 'Файл ключа торговца (.key)',
    CERT_FILE: 'Файл сертификата торговца (.pem)',

    DELETED: 'Swish торговля удалена',

    PAYER_ALIAS: 'Номер Swish',
    PAYEE_ALIAS: 'Псевдоним Swish',
    PASSPHRASE: 'Пароль',
    STATUS: 'Статус',
    STATUSES: {
      PENDING: 'В ожидании',
      VALID_CERTS: 'Ожидание теста',
      OPERATIONAL: 'В работе',
      ERROR: 'Ошибка'
    }
  },


  VALIDATION: {
    REQUIRED: 'Поле обязательно для заполнения',
    EMAIL: 'Поле должно быть действительным адресом электронной почты',
    ADM_FEE: 'Административный сбор недействителен',
    MEM_FEE: 'Членский взнос недействителен',
  },
  HEADER: {
    FILTER: 'Фильтр',
    FILTER_HELP: 'Фильтр по организации или имени'
  },

  SPAR: {
    ID: 'ID',
    JOB_NAME: 'Название задачи'
  },

  FORM: {
    FORMS: 'Translate: Forms',
    REQUIRE_PARENTS_BELOW_AGE: 'Translate: Require parent below age',
    AGE_REQUIRED: 'Translate: Age required',
    VISIBILITY_OPTION: 'Настройки видимости',
    VISIBILITY_OPTIONS: {
      ALWAYS: 'Всегда',
      IF_FAMILY_MEMBERSHIP: 'Если выбран семейный абонемент',
    },
    IF_FAMILY_MEMBERSHIP_INFO: 'Внимание! Это требует, чтобы страница магазина/членства была перед этой страницей!',
    ASSIGN_SHOP_ITEM: 'Назначить товар/членство',
    ASSIGN_COMPANY: 'Назначить ассоциацию',
    ADDED_ATTRIBUTES: 'Назначенные атрибуты',
    ADD_ATTRIBUTE: 'Добавить атрибут',
    SELECT_ATTRIBUTES: 'Выбрать атрибуты',
    FORM_RULE_DELETED: 'Правило удалено',

    FORM_RULE_CREATED: 'Правило создано',
    FORM_RULE_UPDATED: 'Правило обновлено',
    RULES: 'Правила',

    STYLE: {
      BG: 'Цвет фона',
      BUTTON_COLOR: 'Translate: Button color',
    },
    FORM_VISIT_TYPE: 'Тип посещения',
    FORM_VISIT_TYPES: {
      FIRST: 'Первое посещение',
      STEP: 'Шаг',
      DONE_OK: 'Завершено - ОК',
      DONE_ERROR: 'Ошибка',
      DONE_WAIT_PAYMENT: 'Завершено - Ожидание оплаты',
    },
    TOTAL_VISITS: 'Всего посещений',
    TOTAL_DONE: 'Завершено',
    BAR_VISITORS: 'Посетители формы',
    CREATE_FORM_RULE: 'Создать правило',
    FORM_RULES: 'Правила',

    FORM_RULE_TYPE: 'Примененное правило',
    FORM_RULE_TYPES: {
      ASSIGN_MEMBERSHIP: 'Назначить членство (Ассоциация и товар)',
      ASSIGN_COMPANY: 'Назначить ассоциацию',
      ASSIGN_SHOP_ITEM: 'Назначить товар',
      ASSIGN_ATTRIBUTE: 'Назначить атрибут',
    },
    PRESELECTED_COMPANY: 'Предустановленная ассоциация (только если в форме отсутствует выбор ассоциации)',
    PRESELECTED_COMPANY_INFO: 'Если форма не содержит выбор ассоциации. В этом случае участник будет зарегистрирован в этой ассоциации.',
    PRESELECTED_SHOP_ITEM: 'Предустановленный товар/членство (только если в форме отсутствует выбор членства/магазина)',
    PRESELECTED_SHOP_ITEM_INFO: 'Если форма не содержит страницу магазина/членства. В этом случае участник получит этот товар.',
    APPEARANCE: 'Внешний вид',
    MERGE_TAG: 'Merge tag для шаблона',
    PAGE_NOT_CREATED: 'Страница не создана',
    COMPANY_ADMINISTER: 'Администрирование ассоциации',
    SELECTED_COMPANIES: 'Выбранные ассоциации ({count})',
    COMPANIES_WITH_MEMBERSHIPS: 'Ассоциации с членством ({count})',
    SELECT_COMPANIES: 'Настройки выбора ассоциации',
    VALIDATION: 'Проверка',
    PAGE_VALIDATIONS: {
      NULL: 'Ошибка',
      NO_GROUP_IDS: 'Вопросы отсутствуют',
      MISSING_FIELDS: 'Отсутствуют поля',
      NO_COMPANY_HIERARCHY: 'Отсутствуют настройки ассоциации',
      OK: 'ОК'
    },
    VISIBLE_FIELDS: 'Видимые поля',
    SELECT_QUESTIONS: 'Выбрать вопросы/атрибуты',
    PAGE_NAME: 'Название страницы',
    HTML: 'Описание (HTML)',
    FIELD_NAME: 'Поле',
    REQUIRED: 'Обязательно',
    SHOW: 'Показать',
    PAGE_CREATED: 'Страница создана',
    PAGE_UPDATED: 'Страница обновлена',
    PAGE_DELETED: 'Страница удалена',
    UNABLE_CREATE_PAGE: 'Не удалось создать страницу',
    UNABLE_UPDATE_PAGE: 'Не удалось обновить страницу',
    UNABLE_DELETE_PAGE: 'Не удалось удалить страницу',
    SETTINGS: 'Настройки',
    PAGES: 'Страницы',
    TEXTS_TAB: 'Тексты',
    ID: 'ID',
    REQUIRED_CONFIRM_PAGE_MISSING: 'Отсутствует страница подтверждения - Все формы должны иметь страницу подтверждения',
    CREATE: 'Новая форма',
    COPIED: 'Ссылка скопирована',
    LINK_URL: 'Публичная ссылка на форму',
    UNABLE_LIST: 'Не удалось отобразить формы',
    UNABLE_COPY: 'Не удалось скопировать ссылку',
    UNABLE_CREATE: 'Не удалось создать форму',
    UNABLE_DELETE: 'Не удалось удалить форму',
    UNABLE_LOAD: 'Не удалось загрузить форму',
    UNABLE_UPDATE: 'Не удалось обновить форму',
    DELETED: 'Форма удалена',
    CREATED: 'Форма создана',
    UPDATED: 'Форма обновлена',
    NAME: 'Название формы',
    CREATED_AT: 'Дата создания',
    DELETE_CONFIRM_TITLE: 'Удалить форму',
    DELETE_CONFIRM_MESSAGE: 'Вы уверены, что хотите удалить форму?',
    INDEX: 'Порядок',
    PAGE_DELETED: 'Страница удалена',
    NAME_REQUIRED: 'Требуется название',
    TYPE: 'Тип формы',
    TYPES: {
      REGISTER_MEMBER: 'Регистрация участника',
      RENEW_MEMBER: 'Продление членства',
      COMPANY: 'Регистрация ассоциации',
      EVENT: 'Регистрация на мероприятие',
    },
    CREATE_PAGE: 'Создать страницу',
    PAGE_TYPE: 'Тип страницы',
    PAGE_TYPES: {
      PERSONNR: 'Персональный номер',
      QUESTIONS: 'Опросник',
      PERSON_DETAILS: 'Персональные данные',
      FAMILY: 'Семья',
      PARENTS: 'Опекуны',
      COMPANY_SELECT: 'Выбор ассоциации/членства',
      COMPANY_DETAILS: 'Детали ассоциации',
      SHOP: 'Магазин',
      DONATION: 'Пожертвование',
      TICKETS: 'Билеты',
      PAYMENT: 'Оплата',
      CONFIRM: 'Подтверждение',
      BANKID_LOGIN: 'BankID Вход',
      BANKID_SIGN: 'BankID Подписание',
      LOGIN: 'Вход'
    },
    REQUIRED_TEXT: 'Обязательное поле',
    TEXTS: {
      SUCCESS_HEADER: 'Заголовок при успешной регистрации',
      SUCCESS_TEXT: 'Текст при успешной регистрации',
      FAILURE_HEADER: 'Заголовок при неудачной регистрации',
      FAILURE_TEXT: 'Текст при неудачной регистрации',
    }
  },

  MEMBERTYPE: {
    CREATE: 'Создать тип членства',
    CREATED: 'Тип членства создан',
    CREATED_AT: 'Дата создания',
    UNABLE_CREATE: 'Не удалось создать тип членства',
    UPDATED: 'Тип членства обновлен',
    UNABLE_UPDATE: 'Не удалось обновить тип членства',
    DELETED: 'Тип членства удален',
    UNABLE_DELETE: 'Не удалось удалить тип членства',
    NAME: 'Имя',
    NONE_FOUND: 'Типы членства не найдены',
  },

  MEMBER_EDITOR: {
    SHOW: 'Показать',
    SECTION_FIELD: 'Раздел/Поле',
    CREATE_MEMBER: 'Создать участника',
    EDIT_MEMBER: 'Редактировать участника',
    PERSONAL_DETAILS: 'Личные данные',
    CONTACT_INFORMATION: 'Контактная информация',
    INVOICE_ADDRESS: 'Адрес для выставления счета',
    STATUS: 'Статус',
    MEMBERSHIPS: 'Членства',
    EVENTS: 'Мероприятия',
    EDUCATIONS: 'Образования',
    FAMILY: 'Семья',
    PARENTS: 'Опекуны',
    FAITH: 'Конфессия',
    ATTRIBUTES: 'Атрибуты',
    FILES: 'Файлы',
    RENEWALS: 'Продления',
    INVOICES: 'Счета',
    PAYMENTS: 'Платежи',
    NOTES: 'Заметки',
    OPTIONS: 'Опции',
    OTHER: 'Другое'
  },

  USER: {
    
    NOT_LOGGED_IN: 'Не вошли в систему',
    YOU_ARE_NOT_LOGGED_IN: 'Ваша сессия завершилась. Вам нужно снова войти в систему.',
    GOTO_LOGIN: 'Перейти к входу',

    INTERNET_ERROR: 'Нет соединения',
    INTERNET_ERROR_INFO: 'Ваш браузер потерял соединение с сервисом. Перезагрузите страницу, чтобы попробовать снова.',

    SUPPORT_NEEDED_HEADER: 'Поддержка',
    SUPPORT_NEEDED_INFO: 'Мы рады помочь с проблемами или другими запросами. Вы посетили справочные тексты и документацию Memlists?',

    SUPPORT_NOT_IN_DOCUMENTATION: 'Нет документации по вашему запросу? Свяжитесь с нами здесь',

    NEW_VERSION_HEADER: 'Новая версия',
    NEW_VERSION_INFO: 'Доступна новая версия, пожалуйста, перезагрузите страницу, чтобы использовать новую версию.',

    GOTO_FEEDBACK: 'Перейти к форме',
    GOTO_RELEASE_NOTES: 'Перейти к примечаниям к версии',

    NEW_PATCH_NOTES: 'Доступны примечания к обновлению',
    PLEASE_READ_PATCH_NOTES: 'У нас есть список изменений в системе. Нажмите на ссылку ниже, чтобы просмотреть их.',

    SESSION_ENDED: 'Ваша сессия завершена',
    CLICK_TO_LOGIN_AGAIN: 'Нажмите здесь, чтобы войти снова',
    UPDATE_AVAILABLE: 'Доступно обновление. Нажмите кнопку ниже, чтобы перезагрузить страницу.',
    UPDATE_PAGE: 'Перезагрузить страницу',
    FEEDBACK_HEADER: 'Пожалуйста, дайте обратную связь!',
    PLEASE_PROVIDE_FEEDBACK: 'Нам нужна ваша помощь, чтобы стать лучше! Чтобы мы могли внедрить изменения, актуальные для вашей работы, пожалуйста, оставьте отзыв, нажав на ссылку ниже. Ваше участие очень важно для нас!',
    ADDRESS: 'Адрес',
    NUMBER_USERS_FOUND: 'Количество пользователей: ',
    SET_PAGES_FROM_COMPANY: 'Назначить страницы выбранной ассоциации',
    CLICK_COMPANY_INFO: 'Нажмите на название ассоциации для дополнительных опций',
    READ_INFO: 'Чтение - Может просматривать страницу, но не предоставлять доступ',
    ADMIN_INFO: 'Админ - Может предоставлять доступ другим',
    ACCESS_V2: 'Доступ v2',
    ACCESS_V3: 'Доступ v3',
    SET_ACCESS_ON_COMPANIES: '<< Назначить доступ к выбранным ассоциациям',
    SELECT_ALL_SUBCOMPANIES: 'Выбрать все подассоциации выбранной ассоциации',
    DESELECT_ALL_SUBCOMPANIES: 'Снять выделение со всех подассоциаций выбранной ассоциации',
    SHOW_ACCESS: 'Показать доступ',
    EDIT_ACCESS: 'Изменить доступ',
    ACCESS_HIERARCHY: 'Доступ v2(BETA)',
    NOTIFICATIONS_UPDATED: 'Настройки уведомлений обновлены',
    USERS: 'Пользователи',
    ADD_USER: 'Добавить пользователя',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить пользователя?',
    CREATE_ADD_EMAIL: 'Добавьте нового пользователя, указав его электронный адрес ниже.',
    INVITE: 'Пригласить',
    USER_DATA: 'Данные пользователя',
    ACCESS: 'Доступ',
    NOTIFICATIONS: 'Уведомления',
    ACCESS_INFO: 'Пользователь получит доступ к указанным страницам для каждой ассоциации. Если "Может назначать" отмечено, пользователь сможет предоставлять этот доступ другим пользователям.',
    NOTIFICATIONS_INFO: 'Следующие уведомления будут получать пользователи по ассоциации. Обратите внимание, что за отправленные SMS взимается плата.',
    ACCESS_GRANTED: 'Доступ пользователя создан',
    UNABLE_ACCESS_GRANT: 'Не удалось создать доступ пользователя',
    ACCESS_UPDATED: 'Доступ пользователя обновлен',
    UNABLE_ACCESS_UPDATE: 'Не удалось обновить доступ пользователя',
    DELETED: 'Пользователь удален',
    UNABLE_DELETE: 'Не удалось удалить пользователя',
    REMOVED_FROM_COMPANY: 'Пользователь удален из ассоциации',
    UNABLE_REMOVE_FROM_COMPANY: 'Не удалось удалить пользователя из ассоциации',
    UNABLE_LIST_ACCESS: 'Не удалось отобразить доступы',
    UNABLE_LIST_COMPANY: 'Не удалось отобразить ассоциации',
    UNABLE_LIST: 'Не удалось отобразить пользователей',
    UNABLE_CREATE: 'Не удалось создать пользователя',
    UPDATED: 'Пользователь обновлен',
    UNABLE_UPDATE: 'Не удалось обновить пользователя',
    INVALID_EMAIL: 'Неверный адрес электронной почты',
    EMAIL_TAKEN: 'Адрес электронной почты уже зарегистрирован',
    INVITATION_SENT: 'Приглашение отправлено',
    UNABLE_SEND_INVITATION: 'Не удалось отправить приглашение',
    EMAIL_TAKEN_INFO: 'Адрес электронной почты уже добавлен в систему. Чтобы пригласить в эту ассоциацию, используйте кнопку "Пригласить".',
    TITLE: 'Заголовок',
    IS_DIRECTOR: 'Является членом правления',
    IS_ACCOUNTANT: 'Является бухгалтером',
    DELETE: 'Удалить пользователя',
    NEW: 'Новый пользователь',
    INVITE_USER: 'Пригласить пользователя',
    NO_ACCESS: 'Пользователь не имеет доступа к ни одной странице и не может войти в систему',
    HAS_ACCESS: 'Имеет доступ',
    HAS_GRANT: 'Может назначать',
    IS_SUPERADMIN: 'Является суперадминистратором',
    SEARCH_COMPANY: 'Поиск ассоциации',
    MY_INFORMATION: 'Мои данные',
    NOTIFICATIONS: 'Уведомления',
    UNABLE_UPDATE_ACCESS: 'Не удалось обновить доступы',
    CANT_UPDATE_YOURSELF: 'Вы не можете назначить доступ себе',
    ADD_READ_ALL: 'Добавить права на чтение для всех',
    ADD_WRITE_ALL: 'Добавить права на запись для всех',
    REMOVE_READ_ALL: 'Удалить права на чтение для всех',
    REMOVE_WRITE_ALL: 'Удалить права на запись для всех',
    PAGES_ACCESS_INFO: 'На этой странице вы можете удалить или предоставить права всем пользователям',
    BEARER: 'Bearer token',
    BEARER_INFO: 'Используйте Bearer token ниже в качестве значения заголовка "Authorization". "Bearer <token>".',
    SCOPES: 'Scopes/права',
    SAVE_SCOPES: 'Сохранить права',
    API_KEY: 'API ключи',
    AREA_ALLOC: 'Распределение ассоциации',
    BILLING: 'Расходы',
    TRANSACTION: 'Транзакции',
    BOOK: 'Бухгалтерия',
    COMPANY_USER: 'Доступ к ассоциации',
    CARD: 'Членская карта',
    COMPANY: 'Ассоциация',
    CAMPAIGN: 'Кампания',
    EDUCATION: 'Образование',
    EMAIL: 'Электронная почта',
    EVENTS: 'Мероприятия',
    EVENT_CATEGORY: 'Категория мероприятия',
    EXTRA_DATA: 'Дополнительные данные',
    FILE: 'Файлы',
    FILEUPLOAD: 'Загрузка файла',
    FORTNOX: 'Fortnox',
    IMPORT: 'Импорт участников',
    INVOICE: 'Счет',
    JOB: 'Задача',
    LINK: 'Ссылка',
    LOCATION: 'Реестр адресов',
    MEMBER_COMPANY: 'Членство',
    MEMBER_EVENT: 'Участник мероприятия',
    MEMBER_PROPERTY: 'Атрибут участника',
    MEMBER_RENEW: 'Продление членства',
    MEMBER_STICKPROV: 'Выборка участников',
    MEMBER: 'Участник',
    PAGE: 'Страница',
    PAYMENT: 'История заказов',
    PERIOD: 'Период',
    PERSON: 'Человек',
    PROPERTY_GROUP: 'Категория атрибутов',
    REDEEM_CODE: 'Купон',
    SEARCH: 'Поиск',
    SENDOUT: 'Рассылка',
    SHOP_ITEM: 'Магазин',
    SHOP_ORDER: 'Заказ',
    SMS: 'SMS',
    SPAR: 'SPAR',
    STICKPROV: 'Выборка',
    STRIPE: 'Stripe',
    SYSTEM: 'Система',
    TEMPLATE: 'Шаблон',
    TODO_TASK: 'Задачи управления делами',
    TODO: 'Управление делами',
    TRACKING: 'Отслеживание',
    USER: 'Пользователь',
    TITLE: 'Заголовок',
    MEMBERTYPE: 'Тип членства',
    WEBHOOK: 'Webhook',
    AUTH_TOKEN: 'Ключи',
    UNABLE_LIST_AUTH: 'Не удалось отобразить ключи',
    AUTH_DELETED: 'Ключ удален',
    AUTH_CREATED: 'Ключ создан',
    SHOW_BEARER_INFO: 'Вам необходимо скопировать ключ сейчас. Если вы забудете скопировать ключ, то его нельзя будет показать позже.',
    SHOW_TOO_LATE: 'Ранее созданные ключи не могут быть отображены.',
    FULL_ACCESS: 'Предоставить полный доступ',
    ACCESS_GIVEN: 'Полный доступ OK',
    TAB_USERS: 'Пользователи',
    TAB_TERMS: 'Условия использования',
    TAB_GROUPS: 'Группы',
    TABLE_HEADER_TERMS: 'Условия использования',
    ACCEPTED_TERM: 'Принято',

    NOTIF_EMAIL: 'Уведомление по электронной почте',
    NOTIF_SMS: 'Уведомление по SMS',
    USER_ID: 'ID пользователя',
    FIRSTNAME: 'Имя',
    LASTNAME: 'Фамилия',
    EMAIL: 'Электронная почта',
    REFRESH_TOKEN: 'Refresh Token',
    CREATED_AT: 'Создано',
    VERIFY_PASSWORD: 'Введите пароль пользователя',
    INPUT_PASSWORD: 'Пароль',
    INVALID_PASSWORD: 'Неверный пароль',
    GET_TOKEN: 'Получить токен',
    ROLE: 'Роль',
    ROLES: {
      ADMIN: 'Администратор',
      OWNER: 'Владелец',
      USER: 'Пользователь',
      NONE: 'Нет'
    },
    DETAILS: 'Детали',
    TERMS: 'Условия использования',
    ID: 'ID пользователя',
    USER_ID: 'ID пользователя',
    NAME: 'Имя/Электронная почта',
    GROUP: 'Принадлежность к группе',
    CREATE: 'Создать пользователя',
    UNABLE_GET: 'Не удалось получить пользователя',
    UNABLE_LIST: 'Не удалось отобразить пользователей',
    UNABLE_DELETE: 'Не удалось удалить пользователя',
    UNABLE_CREATE: 'Не удалось создать пользователя',
    UNABLE_UPDATE: 'Не удалось обновить пользователя',
    UNABLE_UPDATE_PASSWORD: 'Не удалось обновить пароль',
    CREATED: 'Пользователь создан',
    UPDATED: 'Пользователь обновлен',
    DELETED: 'Пользователь удален',
    EMAIL: 'Электронная почта',
    PHONE: 'Телефон',
    FIRSTNAME: 'Имя',
    LASTNAME: 'Фамилия',
    PASSWORD_UPDATED: 'Пароль обновлен',
    PASSWORDS_MISMATCH: 'Пароли должны совпадать',
    PASSWORDS_6CHAR: 'Пароль должен быть не менее 6 символов',
    PASSWORD_INFO: 'Оставьте пустым, чтобы сохранить текущий пароль',
    PASSWORD_REPEAT: 'Повторите пароль',
    PASSWORD: 'Пароль',
    NEW_PASSWORD: 'Новый пароль',
    CONFIRM_PASSWORD: 'Подтвердите пароль',
    UPDATE_PASSWORD: 'Обновить пароль',
    CREATED_AT: 'Дата создания',
    PERSONNR: 'Персональный номер'
  },
  ATTRIBUTE: {
    PRIORITY: 'Приоритет',
    UNABLE_SAVE: 'Не удалось сохранить атрибут',
    FREE_TEXT: 'Свободный текст',
    IS_FREE_TEXT: 'Свободный текст',
    DESCR_PLACEHOLDER: 'Этот текст виден пользователю',
    DESCR: 'Видимый текст',
    NAME: 'Внутреннее имя',
    EDIT_ATTRIBUTE: 'Редактировать атрибут',
    SAVE: 'Сохранить атрибут',
    CREATED: 'Атрибут создан',
    UNABLE_CREATE: 'Не удалось создать атрибут',
    UNABLE_LIST: 'Не удалось отобразить атрибуты',
    FORM_INVALID: 'Форма недействительна',
    UPDATED: 'Атрибут обновлен',
    UNABLE_UPDATE: 'Не удалось обновить атрибут',
    DELETED: 'Атрибут удален',
    UNABLE_DELETE: 'Не удалось удалить атрибут',
    ALREADY_DEFINED: 'Атрибут уже существует у участника',
    TEXT_TOO_LONG: 'Текст должен быть меньше 32 символов'
  },
  HISTORY: {
    MEMBER: 'Участник',
    USER: 'Пользователь',
    HISTORY_TYPE: {
      LOGIN_FAIL: 'Неудачная попытка входа',
      LOGIN_SUCCESS: 'Успешный вход',
      SEARCH_MEMBERS: 'Поиск участников',
      SEARCH_USERS: 'Поиск пользователей',
      VIEW_MEMBER: 'Просмотр участника',
      VIEW_USER: 'Просмотр пользователя',
      EDIT_MEMBER: 'Редактирование участника',
      EDIT_USER: 'Редактирование пользователя',
      DELETE_MEMBER: 'Удаление участника',
      DELETE_USER: 'Удаление пользователя',
      CREATE_MEMBER: 'Создание участника',
      CREATE_USER: 'Создание пользователя',
    },
    FIELD: 'Поле',
    OLD_VALUE: 'Старое значение',
    NEW_VALUE: 'Новое значение',
    NO_DATA_FOUND: 'Данные не найдены',
    GROUP: 'Группа',
    DATA: 'Измененные данные',
    INSPECTOR: 'Инспектор события',
    HISTORY_SETTINGS_UPDATED: 'Настройки обновлены',
    UNABLE_UPDATE_HISTORY_SETTINGS: 'Не удалось обновить настройки',
    UNABLE_LOAD_HISTORY_SETTINGS: 'Не удалось загрузить настройки истории',
    NAME: 'Настройка',
    SETTINGS: 'Настройки',
    CREATE: 'Создать',
    VIEW: 'Просмотр',
    UPDATE: 'Обновить',
    DELETE: 'Удалить',
    LOGIN: 'Вход',
    ACTIVATE: 'Активировать',

    HISTORY: 'События',
    CREATED_AT: 'Время создания',
    TYPE: 'Тип',
    CREATED_BY: 'Выполнено',
    USERS: 'События пользователей',
    MEMBERS: 'События участников',
    USER: 'Админ',
    MEMBER: 'Участник',
    CREATED_BY_TYPE: 'Роль',
    NO_FOUND: 'События не найдены',
    NUM_ROWS: 'Количество событий',
    DETAILS: 'Детали',
    UNABLE_GET_USER: 'Не удалось получить пользователя',
    UNABLE_GET_MEMBER: 'Не удалось получить участника',
  },
  SETTINGS: {
    STRIPE_ACCESS_TOKEN: 'Stripe Access Key (SK)',
    STRIPE_PK_TOKEN: 'Stripe Publishable Key (PK)',
    INVALID_STRIPE_ACCESS_TOKEN: 'Недействительный Stripe Access Key, формат должен быть "sk_live_..."',
    INVALID_STRIPE_PK_TOKEN: 'Недействительный Stripe Publishable Key (PK), формат должен быть "pk_live_..."',
    ENABLED_STRIPE: 'Активировать Stripe',
    ENABLED_SWISH: 'Активировать Swish',
    ENABLED_INVOICE: 'Активировать оплату по счету',

    SWISH_SETTINGS: 'Настройки Swish',
    STRIPE_SETTINGS: 'Настройки Stripe',
    INVALID_SWISH_PAYEE_ALIAS: 'Недействительный номер Swish',
    SWISH_PAYEE_ALIAS: 'Номер Swish',
    VERIFY_SWISH_SETTINGS: 'Проверить настройки Swish',
    SWISH_TEST_SUCCESS: 'Проверка Swish успешна',
    SWISH_TEST_FAILURE: 'Проверка Swish не удалась',
    SWISH_OPERATIONAL: 'Swish работает',
    INVOICE_PAYMENTS: 'Оплата счетов'
  },
  EDUCATION: {
    STATUSES: {
      FINISHED: 'Завершено',
      RUN_FIRST_IMPORT: 'Проверка...',
      DONE_FIRST: 'Проверка завершена',
      FAIL_FIRST: 'Ошибка проверки',
      RUN_FIN_IMPORT: 'Импорт...',
      DONE_FINAL: 'Импорт завершен',
      FAIL_FINAL: 'Ошибка импорта',

      RUN_FAST_FIRST_IMPORT: 'Подготовка...',
      DONE_FAST_FIRST_IMPORT: 'Готово к импорту',
      RUN_FAST_FINAL_IMPORT: 'Импорт...',
      DONE_FAST_FINAL_IMPORT: 'Импорт завершен',
      FAIL_FAST_FIRST_IMPORT: 'Ошибка при подготовке',
      FAIL_FAST_FINAL_IMPORT: 'Ошибка при импорте',
      VALIDATING: 'Проверка...',
      VALIDATED_OK: 'Проверка пройдена',
      VALIDATED_ERROR: 'Ошибка проверки',

      OK: 'Готово',
      FAIL: 'Ошибка'
    },
    ACTIVATE_EDUCATIONS: 'Активировать образования',
    TYPE: 'Тип',
    ADDED: 'Образование добавлено',
    UNABLE_ADD: 'Не удалось добавить образование',
    UNABLE_DELETE: 'Не удалось удалить образование',
    TYPES: {
      NONE: 'Нет',
      PROGRAM: 'Программа',
      COURSE: 'Курс',
      FREE_COURSE: 'Свободный курс',
      UNKNOWN: 'Неизвестно'
    },
    NUM_ROWS: 'Количество строк',
    STUDENT_STATUS: 'Статус студента',
    STUDENT_STATUSES: {
      UNKNOWN: 'Не определено',
      NONE: 'Нет',
      STUDENT: 'Студент',
      NOT_STUDENT: 'Не студент'
    },
    EDUCATION: 'Образование',
    NO_EDUCATIONS_FOUND: 'Образования не найдены',
    EDUCATIONS: 'Образования',
    NAME: 'Имя',
    SHORTNAME: 'Короткое имя',
    UNABLE_GET: 'Не удалось получить образование',
    UNABLE_LIST: 'Не удалось отобразить образования',
    UNABLE_DELETE: 'Не удалось удалить образование',
    UNABLE_CREATE: 'Не удалось создать образование',
    UNABLE_UPDATE: 'Не удалось обновить образование',
    CREATE: 'Создать образование',
    UPDATE: 'Обновить образование',
    DELETE: 'Удалить образование',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить образование?',
    CONFIRM_DELETE_MULTIPLE: 'Вы уверены, что хотите удалить образования?',
    CREATE_SUCCESS: 'Образование создано',
    UPDATE_SUCCESS: 'Образование обновлено',
    DELETE_SUCCESS: 'Образование удалено',
    DELETE_MULTIPLE_SUCCESS: 'Образования удалены',
    CREATE_ERROR: 'Не удалось создать образование',
    UPDATE_ERROR: 'Не удалось обновить образование',
    DELETE_ERROR: 'Не удалось удалить образование',
    DELETE_MULTIPLE_ERROR: 'Не удалось удалить образования',
    SELECT_EDUCATION: 'Выбрать образование',
    SELECT_EDUCATION_PLACEHOLDER: 'Выберите образование...',
    SECTION: 'Раздел',
    PERIOD: 'Период',
    CODE: 'Код',
    LADOK_ROWS: 'Строки Ladok',
    STUDENT_ID: 'ID студента',
    COURSE_CODE: 'Код курса',
    PROGRAM_CODE: 'Код программы',
    FACULTY: 'Факультет',
    CREATED_AT: 'Дата создания',
    NO_LADOK_ROWS: 'Нет записей Ladok',
    LADOK_FILE_ROW_ID: 'ID строки Ladok',
    LADOK_FILE_ID: 'ID файла Ladok',
    NO_FOUND: 'Образования не найдены',
  },
  FILE: {
    UPLOADED: 'Файл загружен',
    UPLOAD_FILE: 'Загрузить',
    VISIBLE_MEMBER_TYPE: 'Видимо для типа участника на Моих страницах',
    MEMBER_TYPE_ANY: 'Никакой',
    HEADER: 'Файлы',
    FILE_ID: 'ID файла',
    FILENAME: 'Имя файла',
    SIZE_KB: 'Размер файла',
    CREATED_AT: 'Создано',
    CREATED_BY: 'Создано',
    UPLOAD_TO_FORTNOX: 'Загрузить в Fortnox',
    NO_FILES: 'Файлы не найдены',
    DELETED: 'Файл удален',
    UNABLE_DELETE: 'Не удалось удалить файл',
    DOWNLOAD: 'Скачать',
    UPDATED: 'Файл обновлен',
    UNABLE_UPDATE: 'Не удалось обновить файл',
    UPLOADED: 'Файл загружен',
    UNABLE_UPLOAD: 'Не удалось загрузить файл',
    CREATE: 'Загрузить',
    UNABLE_CREATE: 'Не удалось создать файл',
    UPLOAD: 'Загрузить',
    ALLOW_UPLOAD: 'Разрешить загрузку файлов',
  },
  SKV: {
    UPDATED: 'Статус Skatteverket обновлен',
    UNABLE_UPDATE: 'Не удалось обновить',
    SKV_STATUS: 'Статус Skatteverket',
    SKV_PERIOD: 'Период SKV',
    COMPANY: 'Конгрегация',
    MEMBER_COMPANY_UPDATED: 'Членство обновлено',
    UNABLE_UPDATE_MEMBER_COMPANY: 'Не удалось обновить членство',
    SKV_STATUSES: {
      IGNORE: 'Игнорировать',
      MINOR_IGNORE: 'Несовершеннолетний - Игнорировать',
      REGISTERED: 'Зарегистрирован',
      DELETED: 'Удален',
      PEN_REGISTER: 'Ожидание регистрации',
      PEN_DELETED: 'Ожидание исправления',
      ERR_PERSONNR: 'Неверный персональный номер',
      ERR_DUPLICATE: 'Ошибка - Дубликат',
    }
  },

  ACCESS: {

    TOKEN: {
      API_ACCESS: 'Доступ к API',
      REFRESH_TOKEN: 'Refresh Token',
      CREATED_AT: 'Создано'
    },

    GROUP: {
      NEW_GROUP: 'Новая группа',
      DETAILS: 'Детали',
      COMPANIES: 'Ассоциации',
      PERMISSIONS: 'Права',
      PAGES: 'Страницы',
      GROUPS: 'Группы',
      NAME: 'Название группы',
      GROUP_ID: 'ID группы',
      HAS_ACCESS: 'Доступ',
      ID: 'ID группы',
      CREATED_AT: 'Дата создания',
      CREATE: 'Создать группу',
      CREATED: 'Группа создана',
      UPDATED: 'Группа обновлена',
      DELETED: 'Группа удалена',
      USER_REMOVED: 'Пользователь удален из группы',
      UNABLE_CREATE: 'Не удалось создать группу',
      UNABLE_UPDATE: 'Не удалось обновить группу',
      UNABLE_DELETE: 'Не удалось удалить группу',
      UNABLE_GET: 'Не удалось получить группу',
      UNABLE_ADD_PAGE: 'Не удалось добавить страницу',
      UNABLE_REMOVE_PAGE: 'Не удалось удалить страницу',
      UNABLE_GET_PAGES: 'Не удалось получить страницы',
      UNABLE_GET_HIERARCHY: 'Не удалось получить иерархию',
      UNABLE_REMOVE_COMPANY: 'Не удалось удалить ассоциацию',
      UNABLE_REMOVE_USER: 'Не удалось удалить пользователя',
      UNABLE_ADD_COMPANY: 'Не удалось добавить ассоциацию',
    },

    PAGE: {
      NAME: 'Страница',
      ACCESS: 'Доступ',
      UNABLE_ADD: 'Не удалось добавить страницу',
      UNABLE_REMOVE: 'Не удалось удалить страницу',
      UNABLE_GET: 'Не удалось получить страницу',
    },

    PERMISSIONS: {
      NAME: 'Право',
      UNABLE_ADD: 'Не удалось добавить право',
      UNABLE_REMOVE: 'Не удалось удалить право',
      UNABLE_GET: 'Не удалось получить право',
      UNABLE_SET: 'Не удалось установить право',

      PERMISSION: {
        A: 'Админ',
        R: 'Чтение',
        N: 'Нет',
      },

      MEMBER: {
        NAME: 'Участники',
        C: 'Создать участника',
        R: 'Читать участника',
        U: 'Обновить участника',
        D: 'Удалить участника',
        S: 'Поиск участников',
        PUBLIC_ID: 'Изменить номер участника'
      },
      USER: {
        NAME: 'Пользователи',
        C: 'Создать пользователя',
        R: 'Читать пользователя',
        U: 'Обновить пользователя',
        D: 'Удалить пользователя'
      },
      EVENT: {
        NAME: 'Мероприятия',
        C: 'Создать мероприятие',
        R: 'Читать мероприятие',
        U: 'Обновить мероприятие',
        D: 'Удалить мероприятие',
        VIEW_PARTICIPANTS: 'Просмотр участников',
        EDIT_PARTICIPANTS: 'Изменить участников',
      },

      COMPANY: {
        NAME: 'Ассоциация',
        C: 'Создать ассоциацию',
        R: 'Читать ассоциацию',
        U: 'Обновить ассоциацию',
        D: 'Удалить ассоциацию'
      },
      BILLING: {
        NAME: 'Расходы',
        C: 'Создать расходы',
        R: 'Читать расходы',
        U: 'Обновить расходы',
        D: 'Удалить расходы'
      },
      CAMPAIGN: {
        NAME: 'Кампания',
        C: 'Создать кампанию',
        R: 'Читать кампанию',
        U: 'Обновить кампанию',
        D: 'Удалить кампанию'
      },
      COMPANY_PERIOD: {
        NAME: 'Период ассоциации',
        C: 'Создать период ассоциации',
        R: 'Читать период ассоциации',
        U: 'Обновить период ассоциации',
        D: 'Удалить период ассоциации'
      },
      EDUCATION: {
        NAME: 'Образование',
        C: 'Создать образование',
        R: 'Читать образование',
        U: 'Обновить образование',
        D: 'Удалить образование'
      },
      SENDOUT: {
        NAME: 'Рассылка',
        C: 'Создать рассылку',
        R: 'Читать рассылку',
        U: 'Обновить рассылку',
        D: 'Удалить рассылку'
      },
      FILE: {
        NAME: 'Файл',
        C: 'Создать файл',
        R: 'Читать файл',
        U: 'Обновить файл',
        D: 'Удалить файл'
      },
      IMPORT: {
        NAME: 'Импорт',
        C: 'Создать импорт',
        R: 'Читать импорт',
        U: 'Обновить импорт',
        D: 'Удалить импорт'
      },
      INVOICE: {
        NAME: 'Счет',
        C: 'Создать счет',
        R: 'Читать счет',
        U: 'Обновить счет',
        D: 'Удалить счет'
      },
      LADOK: {
        NAME: 'Ladok',
        C: 'Создать Ladok',
        R: 'Читать Ladok',
        U: 'Обновить Ladok',
        D: 'Удалить Ladok'
      },
      HISTORY: {
        NAME: 'История',
        C: 'Создать историю',
        R: 'Читать историю',
        U: 'Обновить историю',
        D: 'Удалить историю'
      },
      FORTNOX: {
        NAME: 'Fortnox',
        C: 'Создать Fortnox',
        R: 'Читать Fortnox',
        U: 'Обновить Fortnox',
        D: 'Удалить Fortnox'
      },
      MEMBER_COMPANY: {
        NAME: 'Членство',
        C: 'Создать членство',
        R: 'Читать членство',
        U: 'Обновить членство',
        D: 'Удалить членство'
      },
      MEMBER_RENEW: {
        NAME: 'Продления',
        C: 'Создать продление',
        R: 'Читать продление',
        U: 'Обновить продление',
        D: 'Удалить продление'
      },
      MEMBER_INSURANCE: {
        NAME: 'Страхование',
        C: 'Создать страхование',
        R: 'Читать страхование',
        U: 'Обновить страхование',
        D: 'Удалить страхование'
      },
      MEMBER_PROPERTY: {
        NAME: 'Атрибуты',
        C: 'Создать атрибут',
        R: 'Читать атрибут',
        U: 'Обновить атрибут',
        D: 'Удалить атрибут'
      },
      STICKPROV: {
        NAME: 'Выборка',
        C: 'Создать выборку',
        R: 'Читать выборку',
        U: 'Обновить выборку',
        D: 'Удалить выборку'
      },
      PERIOD: {
        NAME: 'Период',
        C: 'Создать период',
        R: 'Читать период',
        U: 'Обновить период',
        D: 'Удалить период'
      },
      PERSON: {
        NAME: 'Человек',
        C: 'Создать человека',
        R: 'Читать человека',
        U: 'Обновить человека',
        D: 'Удалить человека'
      },
      SEARCH: {
        NAME: 'Поиск',
        C: 'Создать поиск',
        R: 'Читать поиск',
        U: 'Обновить поиск',
        D: 'Удалить поиск'
      },
      SHOP_ITEM: {
        NAME: 'Товары',
        C: 'Создать товар',
        R: 'Читать товар',
        U: 'Обновить товар',
        D: 'Удалить товар'
      },
      SHOP_ORDER: {
        NAME: 'Заказы',
        C: 'Создать заказ',
        R: 'Читать заказ',
        U: 'Обновить заказ',
        D: 'Удалить заказ'
      },
      SMS: {
        NAME: 'SMS',
        C: 'Создать SMS',
        R: 'Читать SMS',
        U: 'Обновить SMS',
        D: 'Удалить SMS'
      },
      SKV: {
        NAME: 'Skatteverket',
        C: 'Создать Skatteverket',
        R: 'Читать Skatteverket',
        U: 'Обновить Skatteverket',
        D: 'Удалить Skatteverket'
      },
      TEMPLATE: {
        NAME: 'Шаблоны',
        C: 'Создать шаблон',
        R: 'Читать шаблон',
        U: 'Обновить шаблон',
        D: 'Удалить шаблон'
      },
      SYSTEM: {
        NAME: 'Система',
        C: 'Создать систему',
        R: 'Читать систему',
        U: 'Обновить систему',
        D: 'Удалить систему'
      },
      TODO: {
        NAME: 'Дела/задачи',
        C: 'Создать задачу',
        R: 'Читать задачу',
        U: 'Обновить задачу',
        D: 'Удалить задачу'
      },
      TRANSACTION: {
        NAME: 'Транзакция',
        C: 'Создать транзакцию',
        R: 'Читать транзакцию',
        U: 'Обновить транзакцию',
        D: 'Удалить транзакцию'
      },
      WEBHOOK: {
        NAME: 'Webhook',
        C: 'Создать webhook',
        R: 'Читать webhook',
        U: 'Обновить webhook',
        D: 'Удалить webhook'
      },
      API: {
        NAME: 'API',
        C: 'Создать API',
        R: 'Читать API',
        U: 'Обновить API',
        D: 'Удалить API'
      },
      TODO_TASK: {
        NAME: 'Дело',
        C: 'Создать дело',
        R: 'Читать дело',
        U: 'Обновить дело',
        D: 'Удалить дело'
      },
      BANKID: {
        NAME: 'BankID',
        C: 'Создать BankID',
        R: 'Читать BankID',
        U: 'Обновить BankID',
        D: 'Удалить BankID'
      },
      EMAIL: {
        NAME: 'Электронная почта',
        C: 'Создать электронную почту',
        R: 'Читать электронную почту',
        U: 'Обновить электронную почту',
        D: 'Удалить электронную почту'
      },
      INSTRUMENT: {
        NAME: 'Инструмент',
        C: 'Создать инструмент',
        R: 'Читать инструмент',
        U: 'Обновить инструмент',
        D: 'Удалить инструмент'
      },
      INVOICE_TEMPLATE: {
        NAME: 'Шаблон счета',
        C: 'Создать шаблон счета',
        R: 'Читать шаблон счета',
        U: 'Обновить шаблон счета',
        D: 'Удалить шаблон счета'
      },
      MEMBER_TYPE: {
        NAME: 'Тип членства',
        C: 'Создать тип членства',
        R: 'Читать тип членства',
        U: 'Обновить тип членства',
        D: 'Удалить тип членства'
      },
      PAYMENT: {
        NAME: 'Платеж',
        C: 'Создать платеж',
        R: 'Читать платеж',
        U: 'Обновить платеж',
        D: 'Удалить платеж'
      },
      REDEEM_CODE: {
        NAME: 'Код погашения',
        C: 'Создать код погашения',
        R: 'Читать код погашения',
        U: 'Обновить код погашения',
        D: 'Удалить код погашения'
      },
      SAMFUND: {
        NAME: 'Конгрегация',
        C: 'Создать конгрегацию',
        R: 'Читать конгрегацию',
        U: 'Обновить конгрегацию',
        D: 'Удалить конгрегацию'
      },
      SPAR: {
        NAME: 'SPAR',
        C: 'Создать SPAR',
        R: 'Читать SPAR',
        U: 'Обновить SPAR',
        D: 'Удалить SPAR'
      },
      TITLE: {
        NAME: 'Титулы',
        C: 'Создать титул',
        R: 'Читать титул',
        U: 'Обновить титул',
        D: 'Удалить титул'
      },
      TRACKING: {
        NAME: 'Отслеживание',
        C: 'Создать отслеживание',
        R: 'Читать отслеживание',
        U: 'Обновить отслеживание',
        D: 'Удалить отслеживание'
      },
      CARD: {
        NAME: 'Карта',
        C: 'Создать карту',
        R: 'Читать карту',
        U: 'Обновить карту',
        D: 'Удалить карту'
      },
      COMPANY_TERM: {
        NAME: 'Условия компании',
        C: 'Создать условия компании',
        R: 'Читать условия компании',
        U: 'Обновить условия компании',
        D: 'Удалить условия компании'
      },
      BOOK: {
        NAME: 'Бухгалтерия',
        C: 'Создать бухгалтерию',
        R: 'Читать бухгалтерию',
        U: 'Обновить бухгалтерию',
        D: 'Удалить бухгалтерию'
      },
      REGISTRATION: {
        NAME: 'Регистрация',
        C: 'Создать регистрацию',
        R: 'Читать регистрацию',
        U: 'Обновить регистрацию',
        D: 'Удалить регистрацию'
      },
      AUTH: {
        NAME: 'Аутентификация',
        C: 'Создать аутентификацию',
        R: 'Читать аутентификацию',
        U: 'Обновить аутентификацию',
        D: 'Удалить аутентификацию'
      },
      ACCESS: {
        NAME: 'Доступ',
        C: 'Создать доступ',
        R: 'Читать доступ',
        U: 'Обновить доступ',
        D: 'Удалить доступ'
      },
      THS: {
        NAME: 'THS',
        C: 'Создать THS',
        R: 'Читать THS',
        U: 'Обновить THS',
        D: 'Удалить THS'
      },
      SERVER: {
        NAME: 'Сервер',
        C: 'Создать сервер',
        R: 'Читать сервер',
        U: 'Обновить сервер',
        D: 'Удалить сервер'
      }
    },

    TERMS: {
      ACCEPTED_AT: 'Дата принятия',
      NAME: 'Условия использования',
      NO_TERMS: 'Условия не приняты'
    }
  },
  BOOK: {
    STATUS: 'Статус',
    ERROR: 'Сообщение об ошибке',
    CREATED_AT: 'Создано',
    SEARCH_VOUCHER: 'Поиск квитанции',
    ADD_ROW: 'Добавить строку',
    REMOVE_ROW: 'Удалить строку',
    BOOKIT: 'Бухгалтерия',
    CONFIRM_DELETE: 'Подтвердите удаление квитанции',
    CHECK_FORTNOX_STATUS: 'Проверка статуса Fortnox...',
    FORTNOX_NOT_CONNECTED: 'Fortnox не подключен к этой ассоциации, поэтому невозможно создать квитанцию в Fortnox',
    FORTNOX_IS_CONNECTED: 'Fortnox подключен',
    VOUCHERS: 'Квитанции',
    BOOKED: 'Забронировано',
    NOT_BOOKED: 'Не забронировано',
    UPLOAD_DINTERO_FILE: 'Загрузить файл Dintero',
    CREATE_VOUCHER: 'Создать квитанцию',
    VOUCHER_NUMBER: 'Номер квитанции',
    MESSAGE: 'Сообщение',
    ROWS: 'Строки',
    ACCOUNT: 'Счет',
    COST_CENTER: 'Центр затрат',
    PROJECT: 'Проект',
    DEBIT: 'Дебет',
    CREDIT: 'Кредит',
    DESCRIPTION: 'Описание',
    VOUCHER_CREATED: 'Квитанция создана',
    UNABLE_CREATE_VOUCHER: 'Не удалось создать квитанцию',
    VOUCHER_UPDATED: 'Квитанция обновлена',
    UNABLE_UPDATE_VOUCHER: 'Не удалось обновить квитанцию',
  },

  FORTNOX: {
    FORTNOX: 'Fortnox',
    ENABLE_BGMAX_FORTNOX_INFO: 'Транзакции в файле BGMax будут учтены в Fortnox. Только транзакции, связанные с OCR для счета Memlist, будут учтены в Fortnox.',
    ENABLE_BGMAX_FORTNOX: 'Учет транзакций из BGMax',
    UNABLE_CHECK_STATUS: 'Не удалось проверить статус для Fortnox',
    API_SETTINGS_SAVED: 'API-данные Fortnox сохранены',
    UNABLE_UPDATE_API: 'Не удалось обновить подключение API',
    DELETED_API_CONNECTION: 'Подключение к Fortnox API удалено',
    UNABLE_GET_VOUCHER_SERIES: 'Не удалось получить серии квитанций Fortnox',
    ACCOUNTING_METHOD: 'Метод учета',
    API_ERROR: 'Произошла ошибка с подключением к Fortnox',
    IS_CONNECTED: 'Fortnox подключен к этой ассоциации',
    PAYMENT_ACCOUNT: 'Счет для платежа (четыре цифры)',
    FEE_ACCOUNT: 'Счет для комиссий Dintero (четыре цифры)',
    VOUCHER_SERIES: 'Серия квитанций (одна буква)',
    LEV_ACCOUNT: 'Счет кредиторской задолженности (метод учета по счетам)',
    CASH_METHOD: 'Кассовый метод',
    INVOICING_METHOD: 'Метод учета по счетам',
    ENABLE_AUTO_BOOK_INFO: 'Автоматическое ведение учета выполняется после проведения транзакции с вашим банком посредством так называемого "Settlement". Эта транзакция будет содержать сумму платежа за вычетом комиссии. Это означает, что создаваемая квитанция будет содержать СТОИМОСТЬ ТОВАРА ЗА ВЫЧЕТОМ КОМИССИИ.',
    ENABLE_AUTO_BOOK: 'Активировать автоматическое ведение учета',
    BUNDLE_BOOKS_INFO: 'Эта опция означает, что все транзакции будут объединены в одну квитанцию. Если флажок снят, каждая транзакция станет отдельной квитанцией.',
    BUNDLE_BOOKS: 'Объединить транзакции в одной квитанции',
    ENABLE_FORTNOX_INVOICE_INFO: 'Счета Fortnox создаются через пункт меню "Счета Fortnox"',
    ENABLE_FORTNOX_INVOICE: 'Активировать счета Fortnox',
    DELETE_CONNECTION: 'Удалить подключение',
    AUTHORIZE_INSTRUCTIONS: 'Чтобы подключить Fortnox к этой ассоциации, авторизуйте нашу интеграцию, нажав кнопку ниже и войдите, если будет предложено',
    AUTHORIZE: 'Авторизовать Fortnox',
  },

  DUPLICATE: {
    TAB_HEADER: 'Дубликаты',
    MERGE_MEMBER_OPTION: 'Опция слияния участников: ',
    MERGE_MEMBERS_INFORMATION: 'Участники будут объединены по следующему правилу. Если у участников отсутствуют данные, они будут сохранены. Например: независимо от выбранной опции, если Berit Nilsson существует дважды как участник, и у первого отсутствует электронная почта, но она есть у второго, электронная почта будет сохранена в конечном участнике.',
    MERGE_MEMBER_OPTIONS: {
      KEEP_NEWEST_CREATED: 'Сохранить новейшего участника',
      KEEP_OLDEST_CREATED: 'Сохранить старейшего участника',
    },
    MERGE_MEMBERSHIP_INFORMATION: 'Членства от других участников будут перенесены на конечного участника. Следующее правило определяет, какие членства следует сохранить.',
    MERGE_MEMBERSHOP_OPTION: 'Опция слияния членств: ',
    MERGE_MEMBERSHIP_OPTIONS: {
      KEEP_ALL: 'Сохранить все членства',
      REMOVE_EXACT_DUPLICATES: 'Удалить членства, которые идентичны по сроку действия, ассоциации, периоду, статусу оплаты и статье.',
    },
    EXCEPTIONS: 'Исключения',
    CONFLICT_INFORMATION: 'Некоторые строки участников могут вызвать конфликты при объединении. Если одно из этих условий истинно, этот участник будет исключен из выполнения: ',
    CONFLICT_INFORMATION1: 'Если есть активные продления для нескольких участников. Только одно продление может быть активным для одного участника.',
    CONFLICT_INFORMATION2: 'Если один участник является главным участником, а другой не является главным участником.',
  },

  SCANQR: {
    REDIRECTING: 'Перенаправление...',
    NO_TOKEN: 'Токен отсутствует',
    NO_MEMBER: 'Участник не найден'
  },

  SYSTEM: {
    ONLY_THS: 'Только для THS',
    UPDATED: 'Система обновлена',
    UNABLE_UPDATE: 'Не удалось обновить систему',
    TEMPLATE: {
      INVITE_BY_EMAIL_TEMPLATE: 'Приглашение в систему',
      CONTACT_FORM: 'Контактная форма',
      STICKPROV_SMS: 'Контрольная выборка SMS',
      STICKPROV_EMAIL: 'Контрольная выборка E-mail',
      SUBCOMPANY_REQUEST: 'Заявка на подключение для заявителя',
      SUBCOMPANY_ACCEPT: 'Подтверждение подключения для заявителя',
      SUBCOMPANY_REJECT: 'Отклонение подключения для заявителя',
      PARENT_COMPANY_REQUEST: 'Заявка на подключение для главной ассоциации',
      GRANT_FINALIZED: 'Для заявителя: Заявка завершена',
      GRANT_MUST_HANDIN: 'Для заявителя: Необходимо предоставить квитанции для субсидии',
      GRANT_REJECT: 'Для заявителя: Отклонена заявка на субсидию',
      GRANT_ACCEPT: 'Для заявителя: Одобрена заявка на субсидию',
      GRANT_CREATED: 'Для главной ассоциации: Заявка на субсидию создана',
      GRANT_HAS_HANDIN: 'Для главной ассоциации: Ассоциация заявителя сдала квитанции',
      GRANT_DELETED: 'Для главной ассоциации: Ассоциация заявителя удалила заявку',
      EVENT_JOINED: 'Когда участник/человек присоединился к мероприятию',
      MEMBER_JOINED: 'Участник создан',
      MEMBER_RENEW_PERIOD_SMS: 'SMS для продления членства',
      MEMBER_RENEW_REMIND_SMS: 'Напоминание о продлении членства SMS',
      MEMBER_RENEW_CONFIRM_SMS: 'Подтверждение продления членства SMS',
      MEMBER_RENEW_PERIOD_EMAIL: 'E-mail для продления членства',
      MEMBER_RENEW_REMIND_EMAIL: 'Напоминание о продлении членства E-mail',
      MEMBER_RENEW_CONFIRM_EMAIL: 'Подтверждение продления членства E-mail',
      PAYMENT_REMINDER: 'Напоминание об оплате для участника',
      PAYMENT_RECIEVED: 'Оплата участника получена',
      PAYMENT_FAIL: 'Неудачная оплата участника',
      INVITE_NONMEMBER_FROM_EVENT: 'Приглашение для нек-участника стать участником через мероприятие',
      REDEEM_CODE_EMAIL: 'Купон с кодом (E-mail)',
      MEMBER_APPLICATION_PENDING_TEMPLATE_ID: 'Заявка на членство: Обработка (E-mail)',
      MEMBER_APPROVED_EMAIL_TEMPLATE_ID: 'Заявка на членство: Одобрено (E-mail)',
      MEMBER_REJECTED_EMAIL_TEMPLATE_ID: 'Заявка на членство: Отклонено (E-mail)',
      ADMIN_EXIT_REQUESTED_TEMPLATE_ID: 'Админ: Уведомление о запрошенном выходе (E-mail)',
    }
  },

  RENEW_WIZARD: {
    UNABLE_GET_DATA: 'Не удалось получить данные. Возможно, вы уже продлили свое членство?',
    ERROR_NOT_RENEWED1: 'Ваш аккаунт не был продлен. Попробуйте позже.',
    ERROR_ALREADY_RENEWED1: 'Ваше членство уже продлено - эта ссылка теперь недействительна',
    MEMBERSHIP_RENEWED: 'Ваше членство продлено',
    MEMBERSHIP_RENEWED_HEADER: 'Продлено',
    ALREADY_RENEWED: 'Уже продлено'
  },

  COMPANY_PERMISSION: {
    GROUP: 'Группа',
    USER: 'Пользователь',

    ASSIGNED_GROUP: 'Назначено',
    NAME: 'Имя',
    READ: 'Чтение',
    ADMIN: 'Админ',
    YES: 'Да',
    NO: 'Нет',
    PAGES: 'Страницы',
    ASSIGNED: 'Доступ({num})',
    NOT_ASSIGNED: 'Нет доступа',
    PERMISSIONS: 'Права'
  },

  LADOK: {
    MEMBER: 'Translate: member',

    PERIOD: 'Период',
    
    STATUS: 'Translate: Status',
    STATUSES: {
      MEMBER: 'Translate: Medlem',
      NOT_MEMBER: 'Translate: Not medlem',
      ALL: 'Translate: All'
    },
    SEARCH_INPUT: 'Поиск',
    SEARCH: 'LADOK поиск',
    SEARCH_HEADER: 'Поиск',
    SEARCH_PLACEHOLDER: 'StudentId, Номер участника, Персональный номер...',

    KURSKOD: 'Код курса',
    PROGRAMKOD: 'Код программы',

    FILE_ERRORS: {
      NOT_ENOUGH_CAPTIONS: 'Недостаточно колонок',
      MISSING_HEADER: 'Отсутствует колонка с именем',
      INVALID_PERSONNR: 'Недействительный персональный номер',
      NO_DELIMITER: 'Отсутствует разделитель, колонки должны быть разделены символом ;',
      NO_ROWS: 'Нет строк в файле'
    },
    UNABLE_CREATE: 'Не удалось создать LADOK запуск',
    UNABLE_START: 'Не удалось запустить LADOK',
    CREATED: 'LADOK запуск создан',
    STARTED: 'LADOK запуск запущен',
    UNABLE_UPLOAD: 'Не удалось загрузить файл',
    UNKNOWN_ERROR: 'Произошла неизвестная ошибка',
    ERROR_IN_FILE: 'Ошибка в файле LADOK',
    ERROR_REASON: 'Сообщение об ошибке: ',
  },

  COMPANY: {
    POSTMARK_INBOUND_EMAIL: 'Входящая электронная почта',
    COUNT: '#',
    SELECTED_COMPANY: 'Выбранная ассоциация',
    SELECT_TO_EDIT: 'Нажмите на ассоциацию ниже, чтобы редактировать',
    NEW: 'Новая ассоциация',
    EDIT: 'Редактировать ассоциацию',
    CREATE: 'Создать ассоциацию',
    UPDATE: 'Обновить ассоциацию',
    DELETE: 'Удалить ассоциацию',
    COMPANY_NAME: 'Название ассоциации',
    CNUM_ALREADY_EXISTS: 'Ассоциация с номером {cnum} уже существует',
    COMPANY_HIERARCHY: 'Иерархия ассоциаций',
    YOU_ARE_EDITING: 'Вы редактируете ассоциацию {company}',
    YOU_ARE_CREATING: 'Вы собираетесь создать подассоциацию для {company}',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить ассоциацию?',
    DELETE_HEADER_CONFIRM: 'Подтвердите удаление',
    SUBCOMPANIES: 'Подассоциации',
    COMPANIES: 'Ассоциации',
    COMPANY: 'Ассоциация',
    REG_DATE: 'Дата регистрации',
    VFDT: 'С даты',
    VTDT: 'До даты',
    ADD_MEMBERSHIP: 'Добавить членство',
    COMPANY_ID: 'ID ассоциации',
    NAME: 'Имя',
    SHORTNAME: 'Короткое имя',
    UPLOAD_LOGO: 'Загрузить логотип',
    CNUM: 'Номер ассоциации',
    TYPE: 'Тип',
    ORG_NUMBER: 'Орг. номер',
    VAT: 'Номер НДС',
    MPID: 'Номер членства',
    PC_ID: 'ID родительской ассоциации',
    STREET: 'Адрес',
    CO: 'C/o',
    ZIPCODE: 'Почтовый индекс',
    POST: 'Почтовое отделение',
    REGION_CODE: 'Регион',
    COMMUNE_CODE: 'Коммуна',
    EMAIL: 'Электронная почта (не публичная)',
    PHONE: 'Телефон (не публичный)',
    LANDLINE: 'Стационарный телефон',
    C_NAME: 'Контактное имя',
    C_EMAIL: 'Контактная электронная почта',
    C_PHONE: 'Контактный телефон',
    WEB: 'Веб-сайт',
    MUCF_INCLUDE: 'Включить в расчеты MUCF',
    PCINC: 'Статистика будет включена в родительскую ассоциацию',
    RIKS: 'Национальная ассоциация',
    DEFAULT: 'Ассоциация',
    STUDENT: 'Студенческий союз',
    WORKGROUP: 'Рабочая группа',
    UNABLE_GET: 'Не удалось получить ассоциацию',
    UNABLE_GET_NOTES: 'Не удалось получить заметки',
    UNABLE_LIST: 'Не удалось отобразить ассоциации',
    UNABLE_DELETE: 'Не удалось удалить ассоциацию',
    UNABLE_CREATE: 'Не удалось создать ассоциацию',
    UNABLE_UPDATE: 'Не удалось обновить ассоциацию',
    UNABLE_UPDATE_NOTES: 'Не удалось обновить заметки',
    CREATED: 'Ассоциация создана',
    UPDATED: 'Ассоциация обновлена',
    UPDATED_NOTES: 'Заметки обновлены',
    DELETED: 'Ассоциация удалена',
    ALL_COMPANIES: 'Все ассоциации',
    CONFIRM_DELETE: 'Подтвердите удаление',
    CONFIRM_DELETE_MESSAGE: 'Вы уверены, что хотите удалить ассоциацию?',
    RENEWABLE: 'Продлить членство на следующий период',
    THS_NOTES: 'Заметки THS',
    ACCOUNT_NO: 'Номер счета',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Адрес+Почтовый индекс+Почтовое отделение',
    BANK_NAME: 'Банк',
    BG: 'Банковский счет',
    PG: 'Счет Plusgiro',
    CREATED_AT: 'Дата создания',
    IBAN: 'IBAN',
    PC_CNUM: 'Родительская ассоциация',
    SKIP: 'Пропустить',
    SWIFT_BIC: 'SWIFT/BIC',
    'ZIPCODE+POSTADDRESS': 'Почтовый индекс+Почтовое отделение',
    CLEARING_NO: 'Номер клиринга',
    SWISH: 'Swish',
    INVOICE_HEADING: 'Адрес для выставления счета',
    INVOICE_CO: 'C/o',
    INVOICE_ADDRESS: 'Адрес',
    INVOICE_ZIPCODE: 'Почтовый индекс',
    INVOICE_POST: 'Почтовое отделение',
    INVOICE_COUNTRY: 'Страна',
    INVOICE_EMAIL: 'Электронная почта',
  },
  
  CUSTOMER: {
    CREATE: 'Translate: Create customer',
    CUSTOMERS: 'Translate: Customers',
    LOGO: 'Translate: logo',
    NAME: 'Translate: Customer',
    CREATED_AT: 'Translate: Created',
    DELETE_CONFIRM_TITLE: 'Translate: Delete customer',
    DELETE_CONFIRM_MESSAGE: 'Translate: Confirm that you want to delete the customer',
    CONTACT_NAME: 'Translate: Kontaktnamn',
    ADMIN_PHONE: 'Translate: Administratörs telefon',
    ADMIN_EMAIL: 'Translate: Administratörs e-post',
    ROOT_COMPANY_ID: 'Translate: Huvudförening',
    ID: 'ID',
    CONTACT_INFORMATION: 'Translate: Contact information',
    CUSTOMER_EDITOR: 'Translate: Customer editor',
    BASIC_INFORMATION: 'Translate: Basic information'
  },

  ACCOUNT: {
    NO_BANKID_DATA: 'Недействительные данные BankID',
    INVALID_BANKID_TOKEN: 'Недействительный токен BankID',
    INVALID_LINK2: 'Эта ссылка недействительна.',
    TAB_BANKID: 'BankID',
    TAB_EMAIL: 'Электронная почта/Пароль',
    NO_USER: 'Пользователь не найден',
    NO_USER_INFO: 'Пользователя с этим персональным номером нет. Обратитесь к администратору.',
    START_BANKID_SIGN: 'Подтвердите свою личность',
    MOBILE_BANKID: 'Мобильный BankID на другом устройстве',
    OPEN_BANKID: 'Открыть BankID',
    BANKID_ERROR: 'Ошибка BankID',
    START_BANKID_QR: 'Запустите приложение BankID на телефоне и нажмите на иконку QR-кода для сканирования QR-кода на этой странице.',
    LOGIN_BANKID: 'Войти с помощью BankID',
    LOGGING_IN: 'Вход...',
    NO_ACCESS_HEADER: 'Нет доступа',
    NO_ACCESS_INFO1: 'У вашего аккаунта нет доступа к ни одной странице.',
    NO_ACCESS_INFO2: 'Попросите администратора добавить вас хотя бы на одну страницу в ассоциации, чтобы продолжить.',
    MY_PROFILE: 'Мой профиль',
    LOGIN: 'Войти',
    LOGOUT: 'Выйти',
    LACKS_ACCESS: 'Вы вошли в систему, но у вас нет доступа к ни одной странице. Попросите администратора предоставить вам доступ хотя бы к одной странице, чтобы продолжить.',
    INVALID_EMAIL_PASSWORD: 'Неверная электронная почта или пароль',
    PASSWORD_RULE_NOT_MET: 'Недействительный пароль',
    TO_LOGIN: 'К странице входа',
    PASSWORD_WAS_CHANGED: 'Пароль обновлен',
    PASSWORDS_MISMATCH: 'Пароли должны совпадать',
    PASSWORDS_6CHAR: 'Пароль должен содержать минимум 6 символов',
    UNABLE_RESET: 'Не удалось сбросить пароль',
    RESET: 'Пароль был сброшен',
    UPDATE_PASSWORD: 'Обновить пароль',
    RESET_CLICK: 'Нажмите, чтобы отправить ссылку',
    LINK_SENT: 'Ссылка отправлена',
    LINK_SENT_INFO: 'Проверьте свою электронную почту для получения инструкций',
    ENTER_EMAIL: 'Введите электронную почту для получения инструкций',
    RENEW_YOUR_MEMBERSHIP: 'Продлите свое членство',
    YOU_CAN_RENEW: 'Теперь вы можете продлить свое членство',
    RENEW_MEMBERSHIP: 'Продлить членство',
    INVALID_LINK: 'Недействительная ссылка для входа. Обратитесь к своей ассоциации, чтобы запросить правильную ссылку.'
  },
  ONBOARDING: {
    CREATE: 'Создать',
    NAME: 'Название онбординга',
    CREATE_ONBOARDING: 'Создать онбординг',
    NEW_STEP: 'Новый шаг',
    NEW_GROUP: 'Новая группа',
    GROUP_NAME: 'Название группы',

    CREATED: 'Онбординг создан',
    STEP_CREATED: 'Шаг онбординга создан',
    UNABLE_CREATE: 'Не удалось создать онбординг',
    UNABLE_CREATE_STEP: 'Не удалось создать шаг онбординга',

    UPDATED: 'Онбординг обновлен',
    UNABLE_UPDATE: 'Не удалось обновить онбординг',
    UNABLE_UPDATE_STEP: 'Не удалось обновить шаг онбординга',
    UNABLE_DELETE: 'Не удалось удалить онбординг',
    UNABLE_DELETE_STEP: 'Не удалось удалить шаг онбординга',

    CREATE_GROUP: 'Создать группу',
    HEADER: 'Онбординг',
    STATUSES: {
      PENDING: 'Ожидание',
      BLOCKED: 'Заблокировано',
      SKIP: 'Пропустить',
      DELETED: 'Удалено',
      DONE: 'Завершено',
    },
    DEPARTMENTS: {
      SALES: 'Продажи',
      SUPPORT: 'Поддержка',
      TECH: 'Техника',
      CUSTOMER: 'Клиент',
    }
  },
  SHOP_ITEM: {
    IS_DONATION_INFO: 'Translate: Donation article which can be used publicly',
    IS_DONATION: 'Translate: Is donation',
    
    SHOW_INACTIVE: 'Показать неактивные',
    AMOUNT: 'Цена',
    AMOUNT_VAT: 'НДС',
    UNABLE_UPDATE: 'Не удалось обновить статью',
    UPDATED: 'Статья обновлена',
    IS_ACTIVE: 'Активен',
    ERROR: 'Статус',
    ERRORS: {
      NO_PERIOD_TYPE: 'Тип периода',
      NO_PERIOD: 'Отсутствует период',
      OK: 'OK',
      NO_NEXT_PERIOD: 'Нет следующего периода',
      NO_NEXT_SHOP_ITEM: 'Нет следующей статьи',
    },
    MUST_CREATE_FIRST: 'Статья должна быть создана перед загрузкой изображения',

    NEXT_ARTICLE_INFO: 'Важно! Если вы используете продления, необходимо установить следующую статью и период членства.',

    PRIORITY_INFO: 'Это определяет порядок отображения статей при регистрации. 0 - самый верх, 1 - ниже 0, 2 - ниже 1 и так далее.',
    IS_ACTIVE_INFO: 'Активная статья всегда будет отображаться при регистрации, если эта ассоциация выбрана участником.',

    IS_DEFAULT_INFO: 'Предустановленная статья необходима, если участнику не предлагается выбор членства при регистрации. Если вы решите скрыть страницу членства/магазина при регистрации, участник не сможет выбрать статью, поэтому этот флажок должен быть установлен.',

    RENEWAL: 'Настройки продления',

    VISIBILITY: 'Видимость',

    PERIOD_TYPE: 'Тип периода членства',

    ERROR_DEFAULT_MUST_NOT_COST: 'Статья по умолчанию не должна иметь установленную цену',
    ERROR_CREATE_WITH_NAME: 'Необходимо указать название',
    ERROR_PERIOD_TYPE_MUST_BE_SET: 'Необходимо указать тип периода',
    ERROR_ONLY_MEMBERSHIPS_ALLOWED: 'Допустимы только членства',
    ERROR_MEMBERSHIP_NO_PERIOD_TYPE: 'Статья членства должна иметь установленный тип периода',
    ERROR_NUM_DAYS_MUST_SET: 'Количество дней должно быть больше 0 для дневного членства',
    ERROR_INTERVAL_MUST_SET: 'Интервал дат недействителен',
    ERROR_INTERVAL_DATE_FROM_TO: 'Интервал дат должен быть "от" < "до"',
    ERROR_MISSING_PERIODS: 'Отсутствуют периоды для периодического членства',

    CREATING: 'Создание статьи',
    UPDATING: 'Обновление статьи',

    FROM_DATE: 'Дата начала',
    TO_DATE: 'Дата окончания',

    CREATE_FOR_NEXT: 'Создать для следующего периода',
    NEXT_SHOP_ITEM_ID: 'Следующая статья для продления',
    NEXT_PERIOD_ID: 'Следующий период для продления',
    DAYS: 'Членство действительно в течение количества дней',
    PERIODS: 'Членство действительно в течение количества периодов',
    RANGE: 'Членство действительно в интервале дат',
    IS_DEFAULT: 'Предустановлен для "{company_name}"',
    IS_FAMILY: 'Это членский взнос для семьи',
    PERIOD: {
      INFO: 'Членский взнос относится к следующим периодам',
      NAME: 'Название',
      SELECT: 'Выбрать',
      FROM: 'Период с',
      TO: 'Период по'
    },
    TYPE: {
      UNKNOWN: 'Неизвестно',
      REGISTER: 'Регистрация',
      RENEW: 'Продление',
      TICKET: 'Билет',
      COMPANY: 'Ассоциация',
      MEMBER: 'Член',
      REDEEM_CODE: 'Купон',
      STRIPE_INVOICE: 'Счет Stripe'
    }
  },
  INVOICE: {
    CREATE_BGMAX: 'Новый файл BGMax',
    MISSING_ACCOUNT_NO: 'Необходимо указать номер счета для создания файла BGMax',
    DOWNLOADING: 'Счет загружается...',
    CAN_INVOICE_ERROR: 'Можно отправить только "Неоплаченные" счета.',
    TEXT: 'Свободный текст',
    MATCH_DELETED: 'Строка BGMax удалена',
    UNABLE_DELETE_MATCH: 'Не удалось удалить строку BGMax',
    CONFIRM_DELETE: 'Вы уверены, что хотите аннулировать выбранный счет? Это изменит статус счета на аннулированный.',
    DELETED: 'Счет аннулирован',
    UNABLE_DELETE: 'Не удалось аннулировать счет',
    PAYMENT_CREATED: 'Платеж создан',
    UNABLE_CREATE_PAYMENT: 'Не удалось создать платеж',
    TOTAL_SEK: 'Итого',
    AMOUNT_PAID: 'Оплаченная сумма',
    PAID_AT: 'Дата оплаты',
    SUM: 'Сумма',
    MARK_PAID_MEMBERSHIP: 'Отметить членство как оплаченное',
    MUST_SELECT_TEMPLATE: 'Необходимо выбрать шаблон',
    TEMPLATE_MISSING_INVOICE_PDF_LINK: 'Шаблон не содержит тега %INVOICE_PDF_LINK%, не удается отправить.',
    EMAIL_SENT: 'Электронное письмо отправлено',
    UNABLE_SEND_EMAIL: 'Не удалось отправить электронное письмо',
    SEND_EMAIL: 'Отправить счет по электронной почте',
    NO_SHOP_ITEM: 'Ошибка: Нет товара',
    SHOP_ITEM: 'Товар',
    AMOUNT: 'Сумма',
    INVALID_AMOUNT: 'Недопустимая сумма',
    NO_UNPAID_MEMBERSHIPS: 'Не найдены неоплаченные членства',
    SELECT_MEMBERSHIPS: 'Выберите членства для выставления счета',
    SELECT_MEMBERSHIPS_INFO: '',
    CREATING: 'Создание счета',
    EDITING: 'Редактирование счета',
    INVOICE_TEMPLATE: 'Шаблон счета',
    CREATE: 'Создать счет',
    CREATED: 'Счет создан',
    DELETE: 'Удалить счет',
    NUM_ROWS: 'Количество строк',
    UPDATE_STATUS: 'Обновить статус',
    PAYMENT_STATUS: 'Статус платежа',
    PRINTED: 'Распечатано',
    INVOICE: 'Счет',
    INVOICES: 'Счета',
    NO_INSURANCES: 'Нет страховок',
    NO_INVOICES: 'Нет счетов',
    DOWNLOAD: 'Скачать',
    CREATED_AT: 'Создано',
    INVOICE_NUMBER: 'Номер счета',
    INVOICE_DATE: 'Дата счета',
    DUE_DATE: 'Срок оплаты',
    AMOUNT: 'Сумма',
    STATUS: 'Статус',
    IS_PAID: 'Статус оплаты',

    NONE_FOUND: 'Счета не найдены',
    ORDERBY: 'Сортировка',
    ZIPCODE: 'Почтовый индекс',
    INVOICE_NO: 'Номер счета',
    MATCHED: 'Соответствие',
    UNABLE_MATCH: 'Не удалось сопоставить платеж',
    EDIT_BGMAX: 'Редактировать Bgmax',
    INVOICE_ID: 'Номер счета',
    RECIPIENT: 'Получатель',
    INVOICE: 'Счет',
    PAYMENT: 'Платеж',
    MATCH_PAYMENT_INFO: 'Следующий платеж будет сопоставлен с нижеприведенным счетом',
    MATCH_INVOICE: 'Сопоставить платеж',
    MATCH_OCR_INFO: 'В таблице ниже вы можете выбрать, к какому счету относится транзакция, нажав кнопку (+).',
    CREATED_AT: 'Дата создания',
    FIND_MATCH: 'Выберите счет, соответствующий транзакции',
    FIND_INVOICE: 'Найти счет',
    MANUAL_FIX_INVOICE_ID: 'Сопоставить с номером счета: ',
    AMOUNT: 'Сумма',
    ERROR: 'Ошибка',
    STATUS: 'Статус',
    STATUSES: {
      NONE: 'Нет',
      PENDING_SEND: 'Доставка',
      WAITING_PAYMENT: 'Ожидание оплаты',
      PAID: 'Оплачено',
      DELETED: 'Аннулировано'
    },
    BGMAX_ERROR_ERROR: {
      UNMATCHED: 'Нет совпадений',
      ALREADY_PAID: 'Уже оплачено',
      UNKNOWN: 'Неизвестно'
    },
    BGMAX_ERROR_STATUS: {
      NONE: 'Не обработано',
      MATCHED: 'Совпало',
      DELETED: 'Удалено',
      IGNORE: 'Игнорировано'
    },
    PAYMENT_STATUS: 'Статус платежа',
    PAYMENT_STATUSES: {
      LEGACY: 'Недействительный',
      FREE: 'Бесплатно',
      NOT_PAID: 'Неоплачено',
      PARTIALLY_PAID: 'Частично оплачено',
      PAID_IN_FULL: 'Полностью оплачено',
      REFUND: 'Возвращено'
    },
    TAB_INVOICES: 'СЧЕТА',
    TAB_BGMAX: 'BGMAX',
    TAB_BGMAX_ERRORS: 'BGMAX ОШИБКИ',
    TAB_COMPANIES: 'АСсоциации',
    TAB_TEMPLATES: 'ШАБЛОНЫ',
    TAB_SETTINGS: 'НАСТРОЙКИ',
    UNABLE_DOWNLOAD: 'Не удалось скачать счет',
    RECREATED: 'Счет/Ави был пересоздан',
    UNABLE_BGMAX_ERROR_GET: 'Не удалось получить ошибку BGMAX',
    UNABLE_RECREATE: 'Не удалось пересоздать счет/Ави',
    UNABLE_CREATE: 'Не удалось создать счет/Ави',
    CREATED: 'Счет/Ави создан',
    OCR: 'OCR',
    DUE_DATE: 'Срок оплаты',
    CREATE: 'Создать счет/Ави',
    INVOICE_CREATE_SHOP_ITEM_MISSING_INFO: 'В магазине отсутствует товар для одного или нескольких выбранных членств, необходимо установить товар перед созданием счета/Ави.',
    INVOICE_CREATE_DIFFERENT_PERIODS: 'Выбранные членства имеют разные периоды, они должны совпадать, чтобы продолжить.',
    MESSAGE: 'Текст в счете',
    GENERATION: 'Генерация счета',
    HAS_EMAIL: 'Имеет электронную почту',
    MISSING_EMAIL: 'Отсутствует электронная почта',
    MARK_PRINTED_INFO: 'Будет запущен процесс для создания PDF-файлов счетов. После завершения процесса файл будет доступен в разделе "Файлы".',
    NOT_MARK_PRINTED_INFO: 'Этот процесс создаст только "счета" для продлений в соответствии с параметрами.',
    CREATE_PDF: 'Создать PDF-файл (счета будут отмечены как "распечатано")',
    NUM_SELECTED: 'Количество выбранных',
    PAYMENT_STATUS: 'Статус платежа',
    RENEW_STATUS: 'Статус продления',
    CREATED_TO: 'Создано до',
    CREATED_FROM: 'Создано с',
    CREATE_FOR_FILTERS: 'Создать по фильтрам',
    CREATE_FOR_SELECTION: 'Создать по выбранным',
    COUNT_SELECTED: 'Количество выбранных счетов',
    JOB_INFO: 'Этот процесс создаст отдельные PDF-файлы, которые затем будут объединены в один большой PDF. Счета в процессе будут отмечены как "Распечатанные" и не смогут быть включены в будущие процессы. После завершения файл будет доступен в разделе "Файлы" в меню слева.',
    PAID: 'Оплачено',
    WAIT: 'Неоплачено',
    LATE: 'Просрочено',
    PAID_AT: 'Дата оплаты',
    PRINTED: 'Распечатано',
    TOTAL_SEK: 'Итого',
    MEMBER: 'Член',
    NUMBER: 'Номер счета',
    SELECTED: 'Выбранный',
    NOT_PRINTED: 'Не распечатано',
    PARTIALLY_PAID: 'Частично оплачено',
    NOT_PAID: 'Неоплачено',
    STATUS: 'Статус',
    CREATE_STRIPE_INVOICE: 'Создать счет Stripe',
    RUN_JOB: 'Запустить процесс',
    EMAIL_SENDOUT: 'Отправка по электронной почте',
    EMAIL_SENDOUT_INFO: 'Все результаты в таблице получат электронное письмо с выбранным шаблоном.',
    REMINDER_DUE_DATE: 'Новая дата оплаты (только напоминание)',
    EMAIL_TEMPLATE: 'Шаблон электронной почты',
    UNABLE_LIST_TEMPLATES: 'Не удалось отобразить шаблоны',
    INVALID_TEMPLATE: 'Недействительно, необходимо выбрать шаблон электронной почты',
    UNABLE_GET_COUNT: 'Не удалось получить количество счетов',
    CREATE_PDF: 'Создать PDF',
    PDF_TITLE: 'Создать PDF-файл',
    PDF_TEMPLATES: 'Шаблоны',
    INVALID_PDF_TEMPLATE: 'Недействительно, необходимо выбрать шаблон PDF',
    UNABLE_LIST_TEMPLATES: 'Не удалось отобразить шаблоны',
    RECIPIENT: 'Получатель',
    INVOICE_TEMPLATE: 'Шаблон счета (PDF)',
    INVOICING: 'Выставление счетов',
    CONFIRM_DELETE: 'Подтвердите аннулирование счета',
    JOB_RESULT: 'Результат процесса',
    JOB_RESULT_BAD_TEXT: 'Не удалось создать электронную почту для {num} счетов.',
    JOB_RESULT_GOOD_TEXT: 'Успешно создана электронная почта для {num} счетов.'
  },
  EXPORT: {
    MEMBER_LETTERS_CREATED: 'Создан процесс. В зависимости от количества участников в результате это может занять много времени. Когда файл будет готов, его можно будет скачать в разделе "Файлы".',
    EXPORT: 'Экспортировать',
    EXCEL: 'Excel',
    LETTER_LABELS: 'Ярлыки',
    PRINT_LABELS: 'Распечатать ярлыки'
  },
  EVENT: {
    QUESTIONS: 'Вопросы',
    EVENT: 'Мероприятие',
    FROM_DATETIME: 'С',
    TO_DATETIME: 'До',
    VAT_OPTION: 'Опции отображения НДС',
    VAT_OPTIONS: {
      INC_VAT: 'Вкл. НДС',
      EX_VAT: 'Без НДС'
    }
  },
  MEMBER_EVENT: {
    EVENT_NAME: 'Мероприятие',
    SIGNUP: 'Регистрация',
    NO_FOUND: 'Мероприятия не найдены'
  },
  MEMBERSHIP: {
    PERIOD: 'Период',
    CREATED: 'Членство создано',
    UPDATED: 'Членство обновлено',
    DELETED: 'Членство удалено',
    UNABLE_CREATE: 'Не удалось создать членство',
    UNABLE_UPDATE: 'Не удалось обновить членство',
    UNABLE_DELETE: 'Не удалось удалить членство',
    UNABLE_GET: 'Не удалось получить членство',
    CREATE: 'Создать членство',
    UPDATE: 'Обновить членство',
    DELETE: 'Удалить членство',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить членство?',
    SHOP_ITEM: 'Товар',
    CREATING: 'Создание членства',
    EDITING: 'Редактирование членства',
    EDIT_MEMBERSHIP: 'Редактировать членство',
    MEMBERSHIPS: 'Членства',
    NO_MEMBERSHIPS_FOUND: 'Членства не найдены',
    VFDT: 'Действительно с',
    VTDT: 'Действительно до',
    REG_AT: 'Дата регистрации',
    IS_PAID: 'Оплачено'
  },
  PAYMENT: {
    PAYMENTS: 'Платежи',
    ARTICLE: 'Товар',
    TYPE: 'Тип',
    CREATED_AT: 'Создано',
    AMOUNT: 'Сумма',
    STATUS: 'Статус',
    RECEIPT: 'Квитанция',
    DOWNLOAD: 'Скачать'
  },

  MEMBER_RENEW: {
    ACTIVATE_ALL: 'Translate: Autorenew on all',
    DEACTIVATE_ALL: 'Translate: No autorenew on all',
    AUTORENEW_SETTINGS: 'Translate: Autorenew',
    SET_AUTORENEW_ALL: 'Translate: Activate/Deactivate autorenew on all members',
    SENDOUT_SETTINGS: 'Translate: Sendouts and templates',

    
    INVOICE_ERROR1: 'Ошибка выставления счета 1: Невозможно создать счет, так как отсутствуют членства для продления',
    INVOICE_ERROR2: 'Ошибка выставления счета 2: Невозможно создать счет, так как отсутствует статья членства',
    INVOICE_ERROR3: 'Ошибка выставления счета 3: Невозможно создать счет, так как у статьи членства для продления не установлена "Следующая статья для продления"',

    PAYMENT_STATUS: 'Статус платежа',
    TO_PERIOD_INFINITE: 'Бессрочно',

    SENDOUT_METHODS: {
      EMAIL: 'Эл. почта',
      SMS: 'СМС'
    },
    METHOD: 'Метод',
    SENDOUT_NONE_FOUND: 'Не найдено рассылок',

    CONFIRM_CREATE_RENEWAL_BATCH: 'Вы уверены, что хотите создать продление для членов в результатах поиска? Ссылки для продления будут отправлены с использованием выбранных методов рассылки.',
    COUNT_MEMBERS: 'Количество членов: {count}',
    COUNT_MEMBERS_TO_RENEW: 'Количество членов',

    RENEWAL_TYPE_INFO: '"Продления": мастер регистрации и оплата. "Подтверждение": простая форма без необходимости оплаты.',

    EMAIL_TEMPLATE: 'Шаблон электронной почты',
    SMS_TEMPLATE: 'Шаблон СМС',

    UNABLE_CREATE_BATCH: 'Не удалось создать задачу продления',
    BATCH_CREATED: 'Задача продления создана',

    CREATE_RENEW_BATCH: 'Создать продления из результатов ({count})',

    CREATING_BATCH: 'Создание ручной задачи',
    EDITING_BATCH: 'Редактирование ручной задачи',

    CREATE_RENEWS: 'Создать продления',

    SMS_EMAIL_SET_INFO: 'Члены в результатах поиска получат ссылку для продления, когда вы нажмете "Подтвердить продление"',

    PERIOD_FROM: 'Продлить с периода',
    PERIOD_TO: 'Продлить до периода',

    MEMBERS_TO_RENEW_INFO: 'Здесь создается задача продления для членов, отображаемых в таблице. Член, получивший рассылку, сможет кликнуть на ссылку и продлить членство напрямую.',
    SHORT_LINK: 'Ссылка на продление',
    INFINITE_NOT_PERIOD_DAYS: 'Бессрочные периоды требуют установленных "Количество дней" членства.',
    UNPAID_MEMBERSHIPS: 'У члена есть неоплаченные членства',
    ALREADY_HAVE_PERIOD: 'Уже существует активное продление для {period}.',
    DO_SEND: 'Отправить',
    DONT_SEND: 'Не отправлять',
    SEND_FAIL: 'Произошла ошибка',
    TRY_SEND: 'Отправка в процессе',
    EMAIL_OPTION: 'Отправить эл. письмо',
    SMS_OPTION: 'Отправить СМС',
    FROM_PERIOD: 'С периода',
    TO_PERIOD: 'До периода',
    STATUS: 'Статус',
    RENEW_TYPES: {
      AUTOMATIC: 'Автоматически',
      MANUAL_SINGLE: 'Администратор - Индивидуально',
      MANUAL_BATCH: 'Администратор - Пакетная обработка'
    },
    CREATED: 'Продление создано',
    UNABLE_CREATE: 'Не удалось создать продление членства',
    DELETED: 'Продление удалено',
    UNABLE_DELETE: 'Не удалось удалить продление',
    SCHEDULED_AT: 'Запланировано на',
    INVALID_DATE: 'Недопустимая дата',
    UNABLE_GET_SHOP_ITEMS: 'Не удалось получить магазинные статьи',
    CREATE_RENEW: 'Создать продление',
    RENEWAL_TYPE: 'Тип продления',
    IS_CONFIRM: 'Это подтверждение',
    IS_RENEWAL: 'Это продление',
    STATUSES: {
      SCHEDULED: 'Запланировано',
      ACTIVE: 'Ожидание ответа',
      STARTED_LINK: 'Начато членом',
      INVOICED: 'Выставлен счет',
      TIME_OUT: 'Не отвечено',
      AWAITING_PAYMENT: 'Ожидает оплаты',
      DONE: 'Продлено - Завершено',
      DELETED: 'Удалено - Завершено'
    },
    SOURCE: 'Источник',
    SOURCES: {
      MEMBER_REQUEST: '',
      NONE: 'Нет',
      MANUAL_SINGLE: 'Админ - Ручное',
      MANUAL_BATCH: 'Админ - Пакетная обработка',
      AUTOMATIC: 'Автоматически',
      MEMBER_REQUEST: 'Запрос члена'
    },
    DETAILS: 'Детали',
    SENDOUTS: 'Рассылки',
    PERIOD_TYPE_INFINITE: 'Бессрочно',
    PERIOD_TYPE_PERIOD: 'Календарный год',
    WIZARD: {
      HEADER: 'Мастер продления',
      INTRO_HEADER: 'Введение',
      INTRO_INFO1: 'Мастер продления поможет вам настроить параметры продления для вашей организации.',
      INTRO_INFO2: 'Чтобы начать, нажмите кнопку ниже.',
      PERIOD_HEADER: 'Период',
      PERIOD_INFO1: 'Ваша организация настроена следующим образом относительно периодов: {type}',
      PERIOD_INFINITE_INFO: 'Когда член продлевает свое членство, это может произойти в любое время года, и членство будет действовать в течение времени, установленного для членства.',
      PERIOD_PERIOD_INFO: 'Календарный год означает, что когда член продлевает свое членство, оно будет действовать с 1 января до 31 декабря следующего года.',
      PERIOD_INFO2: 'Перейти к следующему шагу',
      SELECTED_PERIOD_INFO: 'Продление происходит с {from_period} до {to_period}. Если вы хотите продлить на другой период, измените период в верхней части страницы.',

      EMAIL_OPTION_HEADER: 'Электронная почта',
      EMAIL_OPTION_INFO1: 'Хотите ли вы, чтобы члену было отправлено электронное письмо, когда членство подходит к концу?',
      EMAIL_OPTION_YES: 'Да, отправить электронное письмо',
      EMAIL_OPTION_NO: 'Нет, не отправлять электронное письмо',

      EMAIL_NUM_REMINDERS_INFO: 'Укажите, сколько электронных писем будет отправлено в виде напоминаний в дополнение к первому электронному письму.',
      EMAIL_NUM_REMINDERS: 'Количество напоминаний по электронной почте',

      EMAIL_TEMPLATE_INFO: 'Укажите, какое электронное письмо (шаблон) будет отправлено члену.',
      EMAIL_TEMPLATE_REMINDER_INFO: 'Укажите, какое электронное письмо (шаблон) будет отправлено члену в качестве напоминания.',

      EMAIL_NUM_DAYS_WAIT: 'Сколько дней подождать перед отправкой напоминаний?',

      SMS_OPTION_HEADER: 'СМС',
      SMS_OPTION_INFO1: 'Хотите ли вы, чтобы члену было отправлено СМС, когда членство подходит к концу?',
      SMS_OPTION_YES: 'Да, отправить СМС',
      SMS_OPTION_NO: 'Нет, не отправлять СМС',

      SMS_NUM_REMINDERS_INFO: 'Укажите, сколько СМС будет отправлено в виде напоминаний в дополнение к первому СМС.',
      SMS_NUM_REMINDERS: 'Количество напоминаний по СМС',

      SMS_TEMPLATE_INFO: 'Укажите, какое СМС (шаблон) будет отправлено члену.',
      SMS_TEMPLATE_REMINDER_INFO: 'Укажите, какое СМС (шаблон) будет отправлено члену в качестве напоминания.',

      SMS_NUM_DAYS_WAIT: 'Количество дней до отправки напоминания по СМС',

      INVOICE_OPTION_HEADER: 'Счет',
      INVOICE_OPTION_INFO1: 'Хотите ли вы, чтобы членам был отправлен счет?',

      INVOICE_OPTION_YES_DIRECT: 'Да, создать счет сразу, когда придет время продления',
      INVOICE_OPTION_YES_LATER: 'Да, но счет не должен создаваться, пока не будут отправлены электронные и СМС напоминания, и член не продлит в течение установленного времени',
      INVOICE_OPTION_NO: 'Нет, счет не требуется',

      INVOICE_NUM_REMINDERS_INFO: 'Укажите, сколько напоминаний будет отправлено в дополнение к первому счету.',
      INVOICE_NUM_REMINDERS: 'Количество напоминаний о счете',
      INVOICE_TEMPLATE_INFO: 'Выберите шаблон счета',
      INVOICE_TEMPLATE_REMINDER_INFO: 'Выберите шаблон счета для напоминания',
      INVOICE_REMINDER_NUM_DAYS_WAIT: 'Сколько дней подождать перед отправкой напоминаний о счете?',

      INVOICE_SEND_OPTION_INFO: 'Хотите ли вы, чтобы Memlist занимался отправкой счета? Если вы хотите сделать это самостоятельно, ни исходный счет, ни автоматические напоминания не будут отправлены.',
      INVOICE_SEND_OPTION_OPTION_YES: 'Да, Memlist отправит счет согласно действующему прайс-листу.',
      INVOICE_SEND_OPTION_OPTION_NO: 'Нет, мы хотим самостоятельно распечатать и отправить счета.',

      INVOICE_DIRECT_DANGER_HEADER: 'Возможны проблемы!',
      INVOICE_DIRECT_DANGER_INFO: 'Вы выбрали, что счет должен быть создан сразу, когда вы также будете отправлять электронную почту и СМС с призывом к оплате онлайн. Это означает, что если член заплатит онлайн и получит счет, может возникнуть двойная оплата членства.',

      MEMBERSHIP_OPTION_HEADER: 'Членство',
      MEMBERSHIP_OPTION_INFO1: 'Для того чтобы член мог продлить свое членство, активное членство должно быть связано со следующим членством. Таблица ниже показывает все членства, связанные с членами, и к каким они относятся. Если членство не имеет связи, продление не удастся.',
      MEMBERSHIP_OPTION_INFO2: 'В таблице ниже вы можете создать следующее членство, связанное с предыдущим. Однако, чтобы убедиться, что все значения установлены правильно, перейдите в "Магазин" и установите все необходимые параметры.',

      MEMBERSHIP_CURRENT_NAME: 'Текущее членство',
      MEMBERSHIP_NEXT_NAME: 'Следующее членство',
      MEMBERSHIP_COMPANY_NAME: 'Организация',

      MEMBERSHIP_CURRENT_AMOUNT: 'Цена текущего членства',
      MEMBERSHIP_NEXT_AMOUNT: 'Цена следующего членства',

      NEXT_MISSING: 'Не установлено',
      CREATE_NEXT: 'Создать следующее членство',

      SCHEDULE_HEADER: 'Расписание',
      SCHEDULE_INFO: 'Здесь вы можете настроить расписание для продлений. Можно выбрать автоматическое создание продлений перед истечением срока действия членства, либо создать продления в определенный момент времени.',
      SCHEDULE_OPTION_AUTORENEW: 'Автоматически создавать продление для каждого члена индивидуально перед истечением срока членства',
      SCHEDULE_OPTION_SCHEDULE: 'Создавать продления для этого периода в заданное время',
      SCHEDULE_AT_DATE: 'Укажите дату, когда продления должны быть созданы'
    },
    ACTIVE_RENEWAL: 'Активное продление',
    RENEWALS: 'Продления',
    EDITING: 'Редактирование продления',
    CREATING: 'Создание продления',
    NO_RENEWS: 'Продлений нет',
    FORCE_RUN_AUTORENEW: 'Принудительно запустить автоматическое продление',
    UNABLE_FORCE_RUN_AUTORENEW: 'Не удалось принудительно запустить автоматическое продление',
    CONFIRM_RENEW: 'Подтвердить продление',
    ARE_YOU_SURE_DELETE: 'Вы уверены, что хотите удалить продление?',
    CONFIRM: 'Подтвердить',
    UNABLE_GET_RENEWS_FOR_EMAIL_BATCH: 'Не удалось получить список продлений для пакетной обработки электронной почты',
    UNABLE_GET_RENEWS_FOR_SMS_BATCH: 'Не удалось получить список продлений для пакетной обработки СМС'
  },

  SENDOUT_TEMPLATE: {
    CREATE: 'Translate: Create template connection',
    TYPE: 'Translate: Type',
    CREATED_AT: 'Translate: Created at',
    TEMPLATE_ID: 'Translate: Template'
  },

  KVITTO: {
    INCOMING_EMAILS: 'Translate: Inkommande e-post',
    INCOMING_EMAIL: {
      ORIGINAL_EMAIL: 'Translate: Original sender',
      ORIGIN_NAME: 'Translate: Original name',
      FROM_EMAIL: 'Translate: From email',
      FROM_NAME: 'Translate: From name',
      TO: 'Translate: To',
      CREATED_AT: 'Translate: Created at',
      TEXT_BODY: 'Translate: Text',
      FROM: 'Translate: From',
      MESSAGE_ID: 'Translate: Message ID',
      CUSTOMER_ID: 'Translate: Customer ID',
      CC: 'Translate: CC',
      SUBJECT: 'Translate: Subject',
      ID: 'Translate: ID',
    },

    MY_LIST: 'Translate: My list',
    HEADER: 'Translate: receipt service',
    NEW_TRANSACTION_FILE: 'Translate: New transactions file',
    FILE_UPLOAD_SUCCESS: 'Translate: File upload success',
    FILE_UPLOAD_FAILURE: 'Translate: File upload failure',
    ACCOUNTS: 'Translate: Accounts',
    USERS: 'Translate: Users',
    FILES: 'Translate: Transaction files',
    RECEIPTS: 'Translate: Receipts',
    NEW_KVITTO: 'Translate: New receipt',
    NEW_ACCOUNT: 'Translate: New account',
    NONE_ASSIGNED: 'Translate: None assigned',
    ACCOUNT: {
      NAME: 'Translate: Name of account',
      NAME_TIP: 'Translate: Name of the account for easier separation',
      ASSIGNED_USER_ID: 'Translate: Assigned',
      ASSIGNED_USER_ID_TIP: 'Translate: Assigned user on the account',
      EXTERNAL_ID: 'Translate: External ID',
    },
    SEARCH_STR: 'Translate: Search text',
    STATUS: 'Translate: Status',
    STATUSES: {
      NONE: 'Translate: None',
      MAPPED: 'Translate: Mapped',
      BOOKED: 'Translate: Booked',
      URGENT_NEED_MAPPING: 'Translate: Urgent! Need mapping',
      URGENT_NEED_BOOKED: 'Translate: Urgent! Need booking',
      CLOSED: 'Translate: Closed',
      SKIPPED: 'Translate: Skipped'
    }
  },

  MEMBER: {
    DEACTIVATE_REASON: 'Причина деактивации (SPAR)',

    EMIGRATED: 'Эмигрировал',
    INVALID_DATE: 'Недействительная дата',
    SUPPRESSION_REMOVED: 'Подавление удалено',
    UNABLE_REMOVE_SUPPRESSION: 'Не удалось удалить подавление',
    REMOVE_SUPPRESSION_DONE: 'Почтовое подавление удалено',
    REMOVE_SUPPRESSION: 'Подать заявку на удаление подавления',
    INFO_REMOVE_SUPPRESSION: 'Введите адрес электронной почты, с которого нужно удалить подавление новостной рассылки',
    
    DELETE_STRING: 'УДАЛИТЬ',
    IS_MEMBER: 'Является членом',
    IS_PERSON: 'Является лицом',
    PRINT_DEACTIVATE_LETTER: 'Распечатать письмо об отключении',
    PRINT_DEACTIVATE_LETTER_INFO: 'Создать письмо об отключении для члена. Может быть создано только если членство завершено.',
    MOBILE: 'Мобильный телефон',
    NO_MEMBER: 'Не является членом',
    NO_PARENTS_FOUND: 'Родители не найдены',
    PARENTS: 'Родители',
    DELETED: 'Член удален',
    EXIT_REQUESTED: 'Запрос на выход',
    ERROR_REQUEST_EXIT: 'Не удалось запросить выход',
    EXIT_CONFIRM_POPUP_TITLE: 'Подтвердите выход',
    EXIT_CONFIRMED_MESSAGE: 'Выход подтвержден',
    REQUEST_EXIT: 'Запросить выход',
    PENDING_EXIT: 'Выход обрабатывается',
    EXIT_REJECTED_MESSAGE: 'Запрос на выход отклонен',
    CONFIRM_REQUEST_EXIT: 'Настоящим я хочу выйти из организации.',
    NO_MEMBERS_SELECTED: 'Члены не выбраны',
    SELECT_MEMBERS_TO_DELETE: 'Выберите членов для удаления',
    UNABLE_DELETE_MEMBERS: 'Не удалось удалить членов',
    MEMBERS_DELETED: 'Члены удалены',
    DONT_SHOW_AGAIN: 'Больше не показывать',
    POPUP: 'Всплывающее окно',
    LOGIN: 'Вход для членов',
    ENABLE_LOGIN_EMAIL: 'Включить вход по электронной почте',
    ENABLE_LOGIN_BANKID: 'Включить вход с помощью BankID',
    PRICE_PER_LOGIN: 'Стоимость за вход через BankID (см. прайс-лист на <a href="https://memlist.se/logga-in-med-bankid">memlist.se/logga-in-med-bankid</a>)',
    UNABLE_TO_LOGIN_DUE_TO_SETTINGS: 'Члены не могут войти, поскольку не включены методы входа.',

    ADD_MEMBERSHIP: 'Добавить членство в других организациях',
    SELECT_COMPANIES: 'Выберите организации для членства в {period}. Организации, в которых вы уже являетесь членом, не отображаются.',
    BECOME_MEMBER: 'Стать членом',

    CREATED_AT: 'Дата создания',
    UNSET_REG: 'Принудительно зарегистрирован',
    REG: 'Регистрация в процессе',
    REG_INFO: 'Член не завершил свою регистрацию',

    PROFILE_SECTIONS: 'Отображаемые таблицы:',
    FILES: 'Файлы',
    ORDERS: 'История заказов',
    DOB_INVALID_FEEDBACK: 'Используйте формат ГГГГ-ММ-ДД',
    RENEW_NOW_INFO: 'Ваше членство можно обновить!',
    RENEW_NOW: 'Обновить сейчас!',

    MUST_CREATE_BEFORE_SPAR: 'Член должен быть создан до запроса SPAR',
    SPAR_LOOKUP: 'SPAR',
    DO_SPAR_LOOKUP: 'Сделать запрос SPAR',
    PENDING_PAYMENT_REG: 'Членство в процессе регистрации, ожидается оплата',
    SWESSN: 'Действительный номер социального страхования',
    SECRET: 'Секретность',
    SPARCODEID: 'Код SPAR',
    BANKID_VERIFY_PERSONNR_MISMATCH: 'Номер социального страхования отличается между BankID и введенной формой.',
    VERIFY_WITH_BANKID: 'Подтвердить с помощью BankID',
    START_BANKID_QR: 'Запустите приложение BankID на вашем телефоне и нажмите на значок QR-кода для сканирования кода на этой странице.',
    INVALID_RESET_LINK: 'Ссылка недействительна или просрочена',
    CONFIRM_DELETE: 'Подтвердить удаление',
    CONFIRM_DELETE_MESSAGE: 'Вы уверены, что хотите удалить члена?',
    DELETE_ALL: 'Удалить всех',
    CONFIRM_DELETE_ALL: 'Внимание: Это действие удалит всех членов, включая всю историю. Это действие необратимо. Вы уверены, что хотите продолжить?',
    DELETE_SELECTED: 'Удалить выбранные',
    CONFIRM_DELETE_SELECTED: 'Вы уверены, что хотите удалить {count} членов?',
    UNABLE_DELETE_MESSAGE: 'Не удалось удалить члена',
    MEMBER_UPDATED: 'Член обновлен',
    UPDATED_AT: 'Обновлено',
    SKV_PERIOD_ID: 'Период Skatteverket',
    INVOICE_CO: 'C/o для счета',
    REG_AT: 'Первая регистрация',
    DEACTIVATED: 'Деактивирован',
    DECEASED: 'Умер',
    DO_AUTORENEW: 'Автоматически обновлять',
    NO_FAMILY_MEMBERS: 'Нет членов семьи',
    FAMILY: 'Члены семьи',
    UNABLE_GET_NUM_MEMBERS: 'Не удалось получить количество членов',
    DELETE_SELECTED: 'Удалить выбранные',
    DELETE_ALL: 'Удалить всех',
    LANG: 'Язык',
    CREATE: 'Создать члена',
    LEGAL_SEX: 'Юридический пол',
    NUM_ROWS: 'Количество строк',
    MEMBERS: 'Члены',
    CREATE_MEMBER: 'Создать члена',
    MEMBERS_PURGE: 'Очистка членов',
    CREATE_BEFORE_EDIT: 'Создайте члена перед редактированием',
    NO_FILES: 'Нет файлов',
    NO_PARENTS: 'Нет родителей',
    STUDENT_ID: 'ID студента',
    STUDENT_STATUS: 'Статус студента',
    INVOICE_ADDRESS_HEADER: 'Адрес для счета',
    ADDRESS_HEADER: 'Адрес',
    CONTACT: 'Контактная информация',
    DETAILS: 'Информация о члене',
    SAVED: 'Информация сохранена',
    STATUS: 'Статус члена',
    STATUSES: {
      MEMBER: 'Член',
      REJECTED: 'Отклонен',
      PENDING: 'Ожидание',
      PENDING_EXIT: 'Ожидает выхода',
      EXITED: 'Вышел'
    },
    SAMFUND: 'Настройки общины',
    MEMBER_ID: 'ID в базе данных',
    PROFILE_SETTINGS_INFO: 'Выбранные поля данных ниже будут отображаться на странице профиля члена и могут быть обновлены членом.',
    MEMBERSHIPS: 'Членства',
    RENEW_ON_TOP_ONLY: 'Обновления можно создавать только из главной организации.',
    DEACTIVATE_NOW: 'Отключить немедленно',
    ACTIVATE_NOW: 'Активировать немедленно',
    DEACTIVATE_AT_INFO: 'Если вы укажете дату деактивации, членство будет отключено в выбранную дату. Если дата не указана, членство будет отключено немедленно.',
    DEACTIVATE_UPDATE: 'Обновить статус активации',
    ACTIVATION_UPDATED: 'Статус активации обновлен',
    UNABLE_UPDATE_ACTIVATE: 'Не удалось обновить статус активации',
    DEACTIVATE_AT: 'Дата выхода',
    DEACTIVATE: 'Отключить члена',
    ACTIVATE: 'Активировать члена',
    INVOICE_HEADER: 'Адрес для счета',
    INVOICES: 'Счета',
    NO_GUARDIANS: 'Нет связанных родителей.',
    YOUR_PARENTS: 'Ваши родители',
    PARENTS_INFO: 'Родители, связанные с вашей учетной записью.',
    FAMILY_INFO: 'Члены семьи - это люди, связанные с вашей учетной записью и разделяющие ваши привилегии.',
    YOUR_FAMILY: 'Ваша семья',
    DOWNLOAD_INFORMATION: 'Скачать вашу личную информацию',
    YOUR_PROFILE: 'Ваш профиль',
    UPDATE_INFO: 'Обновить ваш профиль',
    EVENT: 'Событие',
    FROM: 'С',
    TO: 'До',
    ACTIVE_EVENTS: 'Активные события',
    NO_EVENTS_FOUND: 'События не найдены',
    NO_ORDERS_TABLE: 'Заказы не найдены',
    CONTACT: 'Контактная информация',
    SYS: 'Получать системные сообщения',
    DEF: 'Получать информационные рассылки',
    IMP: 'Получать важные сообщения',
    NOTIFICATIONS: 'Уведомления',
    SHOW_CARD: 'Показать членскую карту',
    HIDE_CARD: 'Скрыть членскую карту',
    CARD: 'Членская карта',
    NAME: 'Имя',
    PERS_ORGNR: 'Персональный/Организационный номер',
    PERSONNR: 'Номер социального страхования',
    PERSONNR_INFO: 'Номер социального страхования (ГГГГММДД-1234)',
    DOUBLE_SSN_INFO: 'Номер социального страхования уже зарегистрирован!',
    PASSWORD: 'Пароль',
    REPEAT_PASSWORD: 'Повторите пароль',
    NEW_PASSWORD: 'Новый пароль',
    CONFIRM_PASSWORD: 'Подтвердите пароль',
    COMPANY_NAME: 'Название компании',
    ORG_NUMBER: 'Организационный номер',
    ORG_NUMBER_INFO: 'Орг номер: ХХУУЗЗ1234',
    EMAIL: 'Электронная почта',
    REPEAT_EMAIL: 'Повторите электронную почту',
    FIRSTNAME: 'Имя',
    LASTNAME: 'Фамилия',
    PHONE: 'Телефон',
    ADDRESS: 'Улица',
    ZIPCODE: 'Почтовый индекс',
    POSTAL: 'Город',
    CO: 'C/o',
    COMMUNE: 'Коммуна',
    REGION: 'Регион',
    INVOICE: 'Счет',
    MEMBER: 'Член',
    PERSON: 'Человек (не член)',
    INVOICE_INFO: 'Информация о счете',
    MEMBER_CREATED: 'Член создан',
    MEMBER_UPDATED: 'Член обновлен',
    MEMBER_DELETED: 'Член удален',
    UNABLE_CREATE_MEMBER: 'Не удалось создать члена',
    UNABLE_UPDATE_MEMBER: 'Не удалось обновить члена',
    UNABLE_DELETE_MEMBER: 'Не удалось удалить члена',
    CREATE_MEMBER: 'Создать члена',
    MEMBER_DATA: 'Данные о члене',
    LEAVE_EMPTY_AUTO: 'Оставьте пустым для автоматической генерации',
    MEMBER_NUMBER: 'Номер члена',
    PUBLIC_ID: 'Публичный номер',
    PUBLIC_ID_SHORT: 'Номер',
    UPDATE_FROM_SPAR: 'Обновить из SPAR',
    DEFINED_GENDER: 'Определенный пол',
    MEMBER_DEACTIVATED: 'Членство завершено',
    MEMBER_DECEASED: 'Член умер',
    HD: 'Главный член',
    TYPE: 'Тип членства',
    AUTORENEW: 'Автоматическое обновление',
    MOBILE: 'Мобильный телефон',
    LANDLINE: 'Стационарный телефон',
    EMAIL_ALREADY_REGISTERED: 'Электронная почта уже зарегистрирована в системе. Чтобы пригласить этот адрес в организацию, используйте кнопку "Пригласить"',
    UPDATE_PASSWORD: 'Обновить пароль',
    UPDATE_PASSWORD_INFO: 'Сначала создайте члена, затем обновите пароль',
    DEPARTMENT: 'Отдел',
    COUNTRY: 'Страна',
    INVOICE_ADDRESS_INFO: 'Адрес для выставления счета',
    FAMILY_DATA: 'Данные о семье',
    GUARDIANS: 'Опекуны',
    ATTRIBUTE: 'Атрибут',
    OTHER_INFO: 'Другая информация',
    FIRST_REGISTER: 'Первая регистрация',
    EDUCATIONS_INFO: 'Ваши связанные образовательные программы',
    YOUR_EDUCATIONS: 'Ваше образование',
    SPURL: 'URL для проверки',
    MEMBERSHIP: 'Членство',
    COPY_INFO: 'Скопировать в другую организацию и период',
    EDUCATIONS: 'Образование',
    RENEWALS: 'Обновления',
    INVOICES: 'Счета',
    TRANSACTIONS: 'Транзакции',
    NOTES: 'Заметки',
    OPTONS: 'Опции',
    SEND_RESET_PASSWORD: 'Отправить сброс пароля (электронная почта)',
    SEND_MEMBER_JOINED_EMAIL: 'Отправить приветственное письмо (Шаблон "Член создан")',
    RESET_PASSWORD_INFO: 'Отправить письмо для сброса пароля?',
    RESET_PASSWORD: 'Сбросить пароль',
    RESET_RENEWALS: 'Сбросить настройки обновления',
    LOGIN_AS_MEMBER: 'Войти как член',
    DOWNLOAD_GDPR: 'Скачать ваши данные (GDPR)',
    CREATE_INVOICE_FROM_MEMBERSHIPS_INFO: 'Создать новый счет за членство. Все членства со статусом "Нет" для оплаты будут включены в счет. ВНИМАНИЕ! Это не связано с обновлениями.',
    CREATE_INVOICE: 'Создать счет',
    DELETE_FROM_SYSTEM: 'Полностью удалить из системы',
    CONVERT: 'Преобразовать',
    CONVERT_MEMBER_TO_PERSON: 'Преобразовать члена в лицо',
    WARN_MANDATORY: 'Внимание! Некоторые обязательные поля не заполнены!',
    OK_MANDATORY: 'Все обязательные требования к членству выполнены.',
    PASSWORD_AT_LEAST: 'Минимум 6 символов',
    PASSWORDS_DIFFERS: 'Пароли не совпадают',
    PASSWORD_UPDATED: 'Пароль обновлен',
    UNABLE_PASSWORD_UPDATE: 'Не удалось обновить пароль',
    DO_SPAR: 'Сделать запрос SPAR',
    UNABLE_DO_SPAR: 'Не удалось сделать запрос SPAR',
    SPAR_DISABLED: 'SPAR не активирован',
    NO_FAMILY: 'Нет связанных членов семьи',
    NO_EDUCATIONS: 'Нет связанных образовательных программ',
    FAMILY_REMOVED: 'Член семьи удален',
    UNABLE_FAMILY_REMOVE: 'Не удалось удалить члена семьи',
    FAMILY_SAVED: 'Член семьи сохранен',
    UNABLE_FAMILY_SAVE: 'Не удалось сохранить члена семьи',
    UNABLE_FAMILY_ADD: 'Не удалось добавить члена семьи',
    UNABLE_REMOVE_MEMBERSHIP: 'Не удалось удалить членство',
    MEMBERSHIP_REMOVED: 'Членство удалено',
    NON_MEMBER: 'Не является членом',
    CNAME: 'Название компании',
    RDT: 'Дата регистрации',
    EXIT_AT: 'Дата выхода',
    AVD: 'Отдел',
    INVOICE_FIRSTNAME: 'Имя для счета',
    INVOICE_LASTNAME: 'Фамилия для счета',
    INVOICE_EMAIL: 'Электронная почта для счета',
    INVOICE_PHONE: 'Телефон для счета',
    INVOICE_ADDRESS: 'Улица для счета',
    INVOICE_POST: 'Город для счета',
    INVOICE_ZIPCODE: 'Почтовый индекс для счета',
    INVOICE_COUNTRY: 'Страна для счета',
    COUNTRY: 'Страна',
    AGE: 'Возраст',
    IS_HD: 'Является главным членом',
    NOT_HD: 'Не является главным членом',
    OPTIONS: 'Опции',
    PUBLIC_SEX: 'Определенный пол',
    LADOK: 'Статус LADOK',
    FMT_PERSONNR: 'Форматированный номер социального страхования',
    DOB: 'Дата рождения',
    DOB_INFO: 'ГГГГММДД',
    DAY: 'День',
    MONTH: 'Месяц',
    YEAR: 'Год',
    INTERNATIONAL_DOB: 'Используйте 3 отдельных поля (День, Месяц, Год)',
    SEX: 'Юридический пол',
    POST: 'Город',
    OPTIONS: 'Опции',
    RESET_PASSWORD_SENT: 'Ссылка для сброса пароля отправлена на электронную почту',
    PARENT_CREATED: 'Опекун создан',
    UNABLE_CREATE_PARENT: 'Не удалось создать опекуна',
    PARENT_REMOVED: 'Опекун удален',
    UNABLE_REMOVE_PARENT: 'Не удалось удалить опекуна',
    NO_PARENT_ID: 'Не удалось найти ID опекуна',
    RENEW_RESET: 'Обновление сброшено',
    FORGET_PASSWORD: 'Забыли пароль',
    FORGET_PASSWORD_INFO: 'Введите свою электронную почту для получения инструкции по восстановлению пароля',
    EMAIL_SENT: 'Письмо отправлено',
    STRIPE_ID: 'Stripe ID',
    UPDATE_STRIPE: 'Обновить Stripe Клиента',
    CREATE_STRIPE: 'Создать Stripe Клиента',
    STRIPE_CREATED: 'Stripe Клиент создан',
    STRIPE_UPDATED: 'Stripe Клиент обновлен',
    STRIPE_MISSING_INFO: 'Для создания счетов в Stripe необходимо создать Stripe Клиента',
    STRIPE_INVOICE_CREATED: 'Stripe Счет создан',
    STRIPE_INVOICE_UNABLE_DELETE: 'Не удалось удалить Stripe счет',
    STRIPE_INVOICE_DELETED: 'Stripe счет удален',
    STRIPE_INVOICE_PUBLISHED: 'Stripe счет отправлен',
    STRIPE_INVOICE_UNABLE_PUBLISH: 'Не удалось отправить Stripe счет',
    IN_PERSONNR: 'Номер социального страхования',
    FID: 'ID семьи',
    'FIRSTNAME+LASTNAME': 'Имя+Фамилия',
    VFDT: 'Действительно с',
    VTDT: 'Действительно до',
    PAID_AT: 'Дата оплаты',
    TRANSACTION: 'Транзакция',
    SKIP: 'Пропустить',
    MEMBERSHIP_ITEM_ID: 'ID товара членства',
    SHOP_ITEM_ID: 'ID товара/членства',
    IS_PARENT: 'Является опекуном',
    SOURCE: 'Источник',
    COMMUNE_CODE: 'Код коммуны',
    CNUM: 'Номер организации',
    'ADDRESS+ZIPCODE+POSTADDRESS': 'Адрес+Почтовый индекс+Город',
    REGION_CODE: 'Код региона',
    'LASTNAME+FIRSTNAME': 'Фамилия+Имя',
    PAYING: 'Оплачивает',
    IS_PAID: 'Оплачено (1 или 0)',
    PARENT_ID: 'ID опекуна',
    'ZIPCODE+POSTADDRESS': 'Почтовый индекс+Город',
    UNABLE_GET_PERMISSIONS: 'Не удалось получить права доступа пользователя',
    UNABLE_LIST_FAMILY: 'Не удалось перечислить членов семьи',
    ADD_FAMILY_MEMBER: 'Член семьи добавлен',
    UNABLE_ADD_FAMILY_MEMBER: 'Не удалось добавить члена семьи',
    UNABLE_ADD_MEMBER_IN_ANOTHER_FAMILY: 'Член семьи уже есть в другой семье',
    UPDATE_HD: 'Главный член обновлен',
    UNABLE_UPDATE_HD: 'Не удалось обновить главного члена',
    DELETE_HD_NOT_ALLOWED: 'Нельзя удалить главного члена, связанного с другими членами семьи!',
    HEAD_MEMBER_INFO: 'Главного члена можно изменить в данных семьи',
    FAMILY_MEMBERS: 'Члены семьи',
    ATTRIBUTES: 'Translate: Attributes',
  },
  SAMPLE: {
    SAMPLE: 'Проверка',
    SAMPLES: 'Проверки',
    NO_SAMPLES: 'Нет проверок',
    SAMPLE_ID: 'ID проверки',
    NAME: 'Имя',
    CREATED_AT: 'Создан',
    UNABLE_GET: 'Не удалось получить проверку',
    UNABLE_LIST: 'Не удалось перечислить проверки',
    UNABLE_CREATE: 'Не удалось создать проверку',
    UNABLE_UPDATE: 'Не удалось обновить проверку',
    UNABLE_DELETE: 'Не удалось удалить проверку',
    CREATED: 'Проверка создана',
    UPDATED: 'Проверка обновлена',
    DELETED: 'Проверка удалена',
    CREATE: 'Создать проверку',
    UPDATE: 'Обновить проверку',
    DELETE: 'Удалить проверку',
    UNABLE_CONFIRM: 'Не удалось подтвердить проверку',
    UNABLE_REJECT: 'Не удалось отклонить проверку',
    UNABLE_LIST_MEMBERS: 'Не удалось перечислить членов',
    START: 'Начать проверку',
    UNABLE_START: 'Не удалось начать проверку',
    STARTED: 'Проверка начата',
    STOP: 'Остановить проверку',
    UNABLE_STOP: 'Не удалось остановить проверку',
    STOPPED: 'Проверка остановлена',
    UNABLE_CREATE_EXCEL: 'Не удалось создать Excel',
    DO_MANUAL: 'Ручное',
    STATUS: 'Статус',
    ANSWER_YES: 'Ответить да',
    ANSWER_NO: 'Ответить нет',
    STATUSES: {
      CON: 'Подтверждено',
      REJ: 'Не член',
      NO_ANS: 'Нет ответа'
    },
    COMPANIES_INCLUDED: 'Организации, включенные в проверку',
    SAMPLE_ALREADY_EXISTS: 'Проверка уже существует в организации',
    SHOW_HISTORY: 'Показать историю',
    DEADLINE: 'Дедлайн',
    SHORT_NUM_ANSWERS: '# Ответы',
    SHORT_NUM_MEMBERS: '# Члены',
    INTRO: 'Введение',
    COMPANY_SELECTION: 'Выбор организации',
    SELECTION: 'Группа выборки',
    OPTIONS: 'Опции',
    END_DATE: 'Дата окончания',
    TEMPLATES: 'Шаблоны',
    CONFIRM: 'Подтвердить',
    CREATE_INFO: 'Вы собираетесь создать новую проверку. Следуйте инструкциям и заполните всю необходимую информацию. Проверка не будет активна, пока она не будет создана и не будет запущена.',
    SELECT_COMPANY_INFO: 'Выберите организации, которые будут включены в проверку. Вы также можете указать, что каждая организация должна получить индивидуальную проверку.',
    OPTION_SPLIT: 'Все отмеченные организации получат индивидуальные проверки',
    SAMPLE_SIZE: 'Укажите, какой процент членов должен быть включен в проверку',
    NUM_MEMBERS: 'Количество членов',
    PCT_MEMBERS: 'Процент членов',
    OPTIONS_INFO: 'Члены, включенные в проверку, получат ссылку для подтверждения своего членства. Укажите, как система должна отправлять эти ссылки.<br />Если вы выберете SMS и/или электронную почту, будет отправлено 3 напоминания каждому члену, не ответившему, после чего можно будет провести ручной обзвон оставшихся членов.',
    ONLY_DO_MANUAL: 'Только ручной обзвон',
    END_DATE_INFO: 'Укажите дату окончания проверки. После этой даты никакие рассылки не будут производиться.',
    TEMPLATES_FOR_SENDOUT: 'Шаблоны для рассылки',
    SENDOUT_EMAIL_INFO: 'Для проверки указано, что должно быть отправлено электронное письмо. Выберите, какой шаблон будет отправлен членам.',
    PREVIEW: 'Предпросмотр',
    SENDOUT_SMS_INFO: 'Для проверки указано, что должно быть отправлено SMS. Выберите, какой шаблон будет отправлен членам.',
    FINAL_STEP_INFO: 'Проверка будет создана, когда вы нажмете "Создать проверку". Обратите внимание, что автоматические рассылки не начнутся, пока вы активно не выберете "Начать проверку".',
    UNABLE_CALC_MEMBERS: 'Не удалось рассчитать количество членов'
  },
  OTHER: {
    INFO: 'Здесь появятся ваши конкретные запросы'
  },

  PERSON: {
    PERSON_CREATED: 'Человек создан',
    PERSON_UPDATED: 'Человек обновлен',
    UNABLE_CREATE_PERSON: 'Не удалось создать человека',
    UNABLE_UPDATE_PERSON: 'Не удалось обновить человека',
    UNABLE_GET: 'Не удалось получить данные человека',
  },
  LANGUAGE: {
    SWEDISH: 'Шведский',
    ENGLISH: 'Английский',
    ARABIC: 'Арабский',
    BOSNIAN: 'Боснийский',
    RUSSIAN: 'Русский'
  },
  INSTANCES: {
    INSTANCES: 'Экземпляры',
    INSTANCE: 'Экземпляр',
    SELECT_INSTANCE: 'Выбрать экземпляр',
    CREATE_TO_INSTANCE: 'Создать в экземпляре'
  },
  COMMON: {
    PAID_SHORT: 'Translate: Paid',
    LOADING: 'Загрузка...',
    SESSION_EXPIRES_IN: 'Выйдет через {days} дней, {hours}:{minutes}:{seconds}',
    WRITE_CONFIRM_TEXT_INFO: 'Это дополнительная мера безопасности. Введите следующий текст в поле ниже: ',
    WRITE_CONFIRM_TEXT: 'Введите текст для подтверждения',
    CREATE_SERVICE: 'Создать сервис',
    SERVICE_CREATED: 'Сервис создан',
    SERVICE_DOMAIN_INFO: 'Для создания сервиса необходимо добавить домен в oderland. Укажите домен на 46.21.97.83 в Zone editor в cPanel.',
    PERIOD_TYPE: 'Тип периода',
    PERIOD_TYPES: {
      INFINITE: 'Бессрочно',
      PERIOD: 'Период'
    },
    INSTANCES: 'Экземпляры',
    INVALID_FORM: 'Неверная форма',
    ID: 'ID',
    THANK_YOU: 'Спасибо!',
    ABORT: 'Прервать',
    FORM_VALIDATION_FAILED: 'Форма не соответствует требованиям валидации, проверьте поля',
    ARE_YOU_SURE: 'Вы уверены, что хотите продолжить?',
    SELECT_ALL: 'Выбрать все',
    SEND_EMAIL: 'Отправить электронное письмо',
    NUM_RECORDS: 'Количество записей',
    COPIED_TO_CLIPBOARD: 'Скопировано в буфер обмена',
    UNABLE_COPY_CLIPBOARD: 'Не удалось скопировать в буфер обмена',
    UPLOAD: 'Загрузить',
    GENERATE: 'Сгенерировать',
    NEW: 'Создать новый',
    EDIT: 'Редактировать',
    FEMALE: 'Женщина',
    MALE: 'Мужчина',
    UNABLE_GET_PAGE: 'Не удалось получить страницу',
    SMS: 'SMS',
    UNABLE_DOWNLOAD: 'Не удалось загрузить файл',
    SUCCESS: 'Успех',
    ERROR_LOADING_DATA: 'Не удалось загрузить данные',
    CONNECT: 'Подключить',
    SELECT: 'Выбрать',
    NUM_ROWS: 'Количество строк',
    ERROR_OCCURED: 'Произошла ошибка',
    REMOVE: 'Удалить',
    COPY_LINK: 'Копировать ссылку',
    SV: 'Шведский',
    EN: 'Английский',
    LANG: 'Язык',
    IS_BOOKED: 'Забронирован',
    SERVICE: 'Сервис',
    MODULE: 'Модуль',
    PAID_AT: 'Оплачено',
    COST_SEK: 'Стоимость',
    SAVE_CHANGES: 'Сохранить изменения',
    COPIED: 'Скопировано',
    COST: 'Стоимость',
    BACK: 'Назад',
    PAYMENT_STATUS: 'Статус оплаты',
    DESCR: 'Описание',
    SETTINGS: 'Настройки',
    CONFIRM: 'Подтвердить',
    SHOW: 'Показать',
    HIDE: 'Скрыть',
    EXPORT_XLSX: 'Экспортировать XLSX',
    EXPORT_CSV: 'Экспортировать CSV',
    YES: 'Да',
    NO: 'Нет',
    ERROR: 'Ошибка',
    OK: 'ОК',
    LACK_PERMISSION: 'Недостаточно прав доступа',
    ACTIONS: 'Действия',
    CANCEL: 'Отмена',
    NAME: 'Имя',
    PRICE_SEK: 'Цена',
    SELECT: 'Выбрать',
    SELECTED: 'Выбрано',
    CREATE_NEW: 'Создать новый',
    UPDATE: 'Обновить',
    CREATE: 'Создать',
    MAN: 'Мужчина',
    WOMAN: 'Женщина',
    OTHER: 'Другое',
    UNKNOWN: 'Неизвестно',
    DONT_WANT: 'Не хочу указывать',
    PAID: 'Оплачено',
    NOT_PAID: 'Не оплачено',
    LADOK_STUDENT_STATUS: {
      S: 'Пропустить',
      Y: 'Активный студент',
      N: 'Неактивный студент',
      P: 'Ожидание'
    },
    ACTIVE_STUDENT: 'Активный студент',
    NO_STUDENT: 'Не студент',
    WAITING: 'Ожидание',
    MISSING: 'Отсутствует',
    DELETE: 'Удалить',
    SEARCH: 'Поиск',
    ADD: 'Добавить',
    COLUMN: 'Колонка',
    VALUE: 'Значение',
    COPY: 'Копировать',
    SEND: 'Отправить',
    RESET: 'Сбросить',
    LOGIN: 'Войти',
    DOWNLOAD: 'Загрузить',
    CLOSE: 'Закрыть',
    AN_ERROR_OCCURED: 'Произошла ошибка',
    TRY_AGAIN_LATER: 'Попробуйте позже',
    EXCEL: 'Excel',
    UNABLE_EXPORT: 'Не удалось экспортировать',
    EXPORT_SUCCESS: 'Экспорт выполнен успешно',
    TOTAL: 'Итого',
    UNABLE_REGIONS: 'Не удалось получить регионы',
    UNABLE_COMMUNES: 'Не удалось получить коммуны',
    CREATED_FROM: 'Создан от',
    CREATED_TO: 'Создан до',
    UNABLE_GET_DATA: 'Не удалось получить данные',
    CREATED: 'Создан',
    WAIT_PAYMENT: 'Ожидание оплаты',
    PARTIALLY_PAID: 'Частично оплачено',
    PAID_IN_FULL: 'Полностью оплачено',
    MEMBER_PERSON: 'Член/Лицо',
    CREATED_AT: 'Создан',
    SELECTION: 'Выбор',
    CONTINUE: 'Продолжить',
    DATE: 'Дата',
    INVOICE_INFORMATION: 'Информация о счете',
    ACTIVE: 'Активный',
    NAME: 'Имя',
    TYPE: 'Тип',
    MANDATORY: 'Обязательно',
    SAVE: 'Сохранить',
    SEND_EMAIL: 'Отправить электронное письмо',
    SEND_SMS: 'Отправить SMS',
    LATE: 'Поздно',
    BYPOST: 'Письмо/Уведомление',
    STATUS: 'Статус',
    ALL: 'Все',
    CLEAR: 'Очистить',
    EXPORT: 'Экспортировать',
    MANAGE: 'Управлять',
    UNABLE_UPDATE: 'Не удалось обновить',
    UPDATED: 'Обновлено',
    UNABLE_CREATE: 'Не удалось создать',
    CREATED: 'Создан',
    UNABLE_DELETE: 'Не удалось удалить',
    DELETED: 'Удалено',
    INVALID_EMAIL: 'Недопустимый адрес электронной почты',
    INVALID_ROLE: 'Недопустимая роль',
    INVALID_GROUP: 'Недопустимая группа',
    JOB_ESTIMATE_COST: 'Оценка стоимости работы',
    AVAILABLE_BUDGET: 'Доступный бюджет',
    EXPORT_SUCCESS: 'Экспорт успешно выполнен',
    UPDATED_AT: 'Обновлено в',
    CREATE: 'Создать',
    UPDATE: 'Обновить',
    DONE: 'Готово',
    GENERAL_SUCCESS: 'Действие успешно выполнено',
    NOT_SET: 'Не установлено',
    NUM_ROWS: 'Количество строк',
    SETTLED: 'Завершено',
    IMPORT: 'Импортировать',
    IS_PAID: 'Оплачено',
    PUBLISH: 'Опубликовать',
    SETTINGS: 'Настройки',
    HELP: 'Помощь',
    CLICK_HELP: 'Нажмите здесь для помощи',
    EMAIL: 'Электронная почта',
    MEMBER_NUMBER: 'Номер члена',
    NOT_ALLOWED: 'Не разрешено',
    REQUIRED_FIELDS_INFO: 'Поля, помеченные {asterix}, обязательны.',
    PREVIEW: 'Предпросмотр',
    MANDATORY: 'Обязательно',
    QUANTITY: 'Количество'
  },
  REPORT: {
    MUST_SELECT_MONTH: 'Необходимо выбрать месяц'
  },
  PERIOD: {
    SKV_PERIOD_INTAG: 'Период приема SKV',
    MDT_FROM: 'Член зарегистрирован с',
    MDT_TO: 'Член зарегистрирован до',
    ACTIVE_PERIODS: 'Активные периоды',
    PERIODS: 'Периоды',
    PERIOD: 'Период',
    NAME_OF_PERIOD: 'Название периода',
    FROM: 'С даты',
    TO: 'До даты',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить период?',
    CONFIRM: 'Подтвердить',
    CREATED: 'Период создан',
    UNABLE_CREATE: 'Не удалось создать период',
    UNABLE_OVERLAP: 'Не удалось создать период: Периоды не должны пересекаться',
    FILL_IN_ALL_FIELDS: 'Пожалуйста, заполните все поля',
    UPDATED: 'Период обновлен',
    UNABLE_UPDATE: 'Не удалось обновить период',
    DELETED: 'Период удален',
    UNABLE_DELETE: 'Не удалось удалить период',
    NEXT_PERIOD: 'Следующий период',
    NEW: 'Новый период',
    YEAR: 'Год',
    UNABLE_LIST: 'Не удалось перечислить периоды',
    ID: 'Id',
    SELECT_PERIOD_AND_COMPANY: 'Выберите период и организацию',
    NAME: 'Период'
  },
  JOB: {
    DELETED: 'Translate: job deleted',
    
    JOB_RUNNING: 'Текущая задача',
    PROGRESS: 'В процессе',
    ID: 'ID задачи',
    JOBS: 'Задачи',
    JOB: 'Задача',
    NAME: 'Название',
    CREATED_AT: 'Создана',
    FINISHED_AT: 'Завершена',
    NUM_PROCESSED: 'Обработано количество',
    NUM_SUCCESS: 'Количество успешных',
    NUM_FAIL: 'Количество неудачных',
    UNABLE_CREATE: 'Не удалось создать задачу',
    UNABLE_LIST: 'Не удалось перечислить задачи',
    UNABLE_GET: 'Не удалось получить задачу',
    UNABLE_UPDATE: 'Не удалось обновить задачу',
    CREATED: 'Задача создана',
    UPDATED: 'Задача обновлена',
    CREATED_WITH_COUNT: 'Задача создана с количеством строк: ',
    CREATE: 'Создать новую задачу',
    STOP: 'Остановить задачу',
    STATUS: 'Статус',
    ERROR_MESSAGE: 'Сообщение об ошибке',
    RESULT: 'Результат',
    NUM_TOTAL: 'Всего количество',
    NUM_PROCESSED: 'Количество обработанных',
    NUM_OK: 'OK строки',
    NUM_FAIL: 'Ошибочные строки',
    LADOK_RUN: 'Запуск Ladok',
    UPLOAD_NEW_LADOK: 'Загрузить новый файл Ladok',
    LADOK_INFO: 'Обратите внимание, что запуск Ladok будет выполнен для выбранного периода вверху страницы',
    UNABLE_STOP: 'Не удалось остановить задачу',
    STOPPED: 'Задача остановлена',

    RESULTS: {
      PENDING: 'Ожидание',
      SUCCESS: 'Завершено',
      FAILED: 'Неудачно',
      SKIPPED: 'Пропущено',
      ERROR: 'Произошла ошибка',
      CANCELLED: 'Отменено',
      CANCEL_REQUESTED: 'Отмена запрошена'
    },
    STATUSES: {
      DRAFT: 'Черновик',
      START: 'Ожидание запуска',
      RUNNING: 'Выполняется',
      FINISHED: 'Завершено',
      DELETED: 'Удалено',
      CANCELLED: 'Отменено',
      CANCEL_REQUESTED: 'Отмена запрошена'
    }
  },

  MEMBER_COMPANY: {
    DUPLICATE_MEMBERSHIPS: 'Translate: Membership already exists in company and period',
    UNABLE_LIST: 'Не удалось перечислить членства',
    UPDATED: 'Членство обновлено',
    UNABLE_UPDATE: 'Не удалось обновить членство',
    CREATED: 'Членство отсутствует',
    UNABLE_CREATE: 'Не удалось создать членство',
    DELETED: 'Членство удалено',
    UNABLE_DELETE: 'Не удалось удалить членство'
  },

  TEMPLATE: {
    INVALID_EMAILS: 'Reply-to и От e-mail должны быть действительными адресами электронной почты и иметь проверенные домены. Если вы хотите добавить проверенный домен, свяжитесь с support@memlist.se',
    NEW: 'Новый шаблон',
    SHOW_ALL: 'Показать все',
    TEMPLATES: 'Шаблоны',
    NAME: 'Название шаблона',
    UNABLE_LIST: 'Не удалось перечислить шаблоны',
    EMAIL_EDITOR: 'Редактор электронной почты',
    FROM_EMAIL: 'От e-mail',
    INVALID_EMAIL: 'Неверный e-mail',
    EMAIL_MUST_BE_VALID: 'Адрес электронной почты должен быть действительным',
    CREATED: 'Шаблон создан',
    UNABLE_CREATE: 'Не удалось создать шаблон',
    UPDATED: 'Шаблон обновлен',
    UNABLE_UPDATE: 'Не удалось обновить шаблон',
    DELETED: 'Шаблон удален',
    UNABLE_DELETE: 'Не удалось удалить шаблон',
    UNABLE_GET: 'Не удалось получить шаблон',
    UNABLE_LIST: 'Не удалось перечислить шаблоны',
    DOMAIN_OK: 'Домен ОК',
    DNS_MISSING: 'DNS отсутствует',
    CONTACT_SUPPORT: 'Свяжитесь с поддержкой',
    VALIDATION: 'Проверка',
    COPY_TO: 'Копировать в',
    SYSTEM: 'Система',
    IS_SMS: 'Это SMS',
    IS_EMAIL: 'Это e-mail',
    SMS_MESSAGE: 'Сообщение SMS',
    NUM_SMS: 'Количество SMS',
    TEXT: 'Текстовое сообщение',
    NOTICE: 'Обратите внимание, что количество слов в версии HTML и в текстовой версии не должно сильно различаться. Если количество слов слишком отличается, письмо будет классифицировано как спам.',
    EDIT: 'Редактировать шаблон',
    SYNC_INSTANCE: 'Синхронизировать экземпляр',
    SYNCED: 'Шаблон синхронизирован',
    UNABLE_SYNC: 'Не удалось синхронизировать с экземпляром',
    UNABLE_COPY: 'Не удалось скопировать шаблон',
    COPIED: 'Шаблон скопирован',
    CONFIRM_DELETE: 'Подтвердите удаление шаблона',
    DESIGNER: 'Дизайнер',
    UPDATE_CLEARTEXT: 'Обновить текст из дизайна',
    EXPORT_PDF: 'Экспортировать PDF'
  },

  GRANT: {
    SUM_APPLICATION: 'Общая сумма субсидии (SEK)',
    BUDGET: 'Бюджет',
    AMOUNT_LEFT_IN_APPLICATION: 'Вы можете запросить до {amount} крон',
    DESCRIPTION_PLACEHOLDER: 'Дайте как можно более подробное описание вашей заявки',
    DESCRIPTION: 'Подробное описание заявки',
    NUM_PARTICIPANTS: 'Количество участников (если мероприятие)',
    NAME: 'Название заявки',
    NAME_PLACEHOLDER: 'Название заявки',
    CONFIRM_PUBLISH: 'Заявка будет отправлена в головную организацию - вы уверены, что хотите продолжить?',
    STARTTIME: 'Время начала',
    ENDTIME: 'Время окончания',

    FROM_DATETIME: 'Дата начала проекта',
    TO_DATETIME: 'Дата окончания проекта',
    PUBLISHED_HEADER: 'Заявка отправлена',
    PUBLISHED_HEADER_INFO: 'Ваша заявка отправлена на рассмотрение в головную организацию.',
    CREATED_APPLICATION: 'Заявка создана',
    UPDATED_APPLICATION: 'Заявка обновлена',
    UNABLE_CREATE_APPLICATION: 'Не удалось создать заявку',
    UNABLE_UPDATE_APPLICATION: 'Не удалось обновить заявку',
    UNABLE_GET_COMPANY: 'Не удалось получить информацию об организации для субсидии',
    INCORRECT_SUM: 'Вы не можете запросить эту сумму. Она должна быть меньше или равна',
    INCORRECT_BALANCE: 'Доходы и расходы не сбалансированы, разница: ',
    SEND_APPLICATION: 'Отправить заявку',
    NOT_PUBLISHED_WARNING: 'Заявка не опубликована, головная организация ее не видит'
  },
  SUPPORT: {
    UNHANDLED: 'Необработанные',
    ACTIVE: 'Активные',
    ARCHIVE: 'Архив',
    EMAILS: 'Электронные адреса',
    SUMMARY: 'Сводка',
    IS_DEDICATED: 'Посвященный',
    IS_THS: 'ThinkingHat',
    MESSAGE: {
      SENT_BY: 'Отправлено',
      MESSAGE: 'Сообщение',
      SEND_AT: 'Дата отправки',
      CREATE: 'Новый ответ',
      STATUS: 'Статус',
      STATUSES: {
        NONE: 'Нет',
        UNREAD: 'Непрочитано',
        PENDING_SEND: 'Ожидание отправки',
        UNHANDLED: 'Необработано',
        REPLIED: 'Отвечено',
        DELETED: 'Удалено'
      }
    },
    TICKET: {
      REPLY_TEXT: 'Текст ответа',
      DETAILS: 'Детали',
      MESSAGES: 'Сообщения',
      CREATE: 'Создать тикет',
      CREATED: 'Тикет создан',
      UPDATED: 'Тикет обновлен',
      DELETED: 'Тикет удален',
      UNABLE_DELETE: 'Не удалось удалить тикет',
      CREATED_AT: 'Дата создания',
      ASSIGNED_USER: 'Назначенный пользователь',
      NAME: 'Заголовок',
      DESCRIPTION: 'Описание',
      TYPE: 'Тип',
      UNASSIGNED: 'Не назначено',
      ORIGINAL_SENDER: 'Исходный отправитель',
      TYPES: {
        UNDETERMINED: 'Не определено',
        SALES_CONTACT: 'Контакт по продажам',
        BUGREPORT: 'Отчет об ошибке',
        EDUCATION: 'Вопрос',
        SUGGESTION: 'Предложение',
        INQUIRY: 'Запрос'
      },
      STATUS: 'Статус',
      STATUSES: {
        NONE: 'Нет',
        UNHANDLED: 'Необработано',
        TODO: 'Сделать',
        REPLY_SENT: 'Ответ отправлен',
        NEW_MESSAGE: 'Новое сообщение',
        RESOLVED: 'Решено',
        CLOSED: 'Закрыто',
        TENTATIVE: 'Предварительно'
      },
      MESSAGE_TYPE: 'Тип сообщения',
      MESSAGE_TYPES: {
        NONE: 'Нет',
        UNREAD: 'Непрочитано',
        UNHANDLED: 'Необработано',
        REPLIED: 'Отвечено',
        DELETED: 'Удалено'
      }
    }
  },

  TODO: {
    UNABLE_GET_WIZARD: 'Не удалось получить мастер задач',
    TODO_WIZARD_COMPLETED: 'Отправка задачи завершена.',
    START_DATE: 'Дата начала',
    END_DATE: 'Дата окончания',
    I_AM_FINISHED_WITH_TASK: 'Я закончил с этой задачей',
    I_AM_NOT_FINISHED_WITH_TASK: 'Я НЕ закончил с этой задачей',
    CONFIRM_STATUS_TASK: 'Подтвердите статус этой задачи',
    TODO_WIZARD_HEADER: 'Задачи для выполнения',
    UPLOAD_FILE: 'Загрузить файл',
    WIZARD_NOT_DONE_STATUS: 'Должно быть установлено как завершено',
    WIZARD_NO_FILES_UPLOADED: 'Файлы отсутствуют',
    NO_FILES_UPLOADED: 'Файлы не загружены',
    SUBTASK_UPDATED: 'Задача обновлена',
    PUBLISH_SUCCESS_HEADER: 'Опубликовано',
    PUBLISH_SUCCESS_INFO: 'Задача опубликована!',
    CONFIRM_PUBLISH: 'Опубликовать',
    CONFIRM_PUBLISH_INFO: 'Вы уверены, что хотите опубликовать задачу?',
    NEW: 'Новая задача',
    CLOSE: 'Закрыть задачу',
    DELETE: 'Удалить задачу',
    UNABLE_GET: 'Не удалось получить задачи',
    UNABLE_DELETE_SUBTASK: 'Не удалось удалить задачу',
    SUBTASK_DELETED: 'Задача удалена',
    PUBLISHED: 'Задача опубликована',
    UNABLE_PUBLISH: 'Не удалось опубликовать задачу',
    HEADER: 'Управление задачами',
    CREATED: 'Задача создана',
    UPDATED: 'Задача обновлена',
    UNABLE_CREATE: 'Не удалось создать задачу',
    UNABLE_UPDATE: 'Не удалось обновить задачу',
    WAIT: 'ОЖИДАНИЕ',
    DRAFT: 'ЧЕРНОВИК',
    NOT: 'НЕ ЗАВЕРШЕНО',
    DONE: 'ЗАВЕРШЕНО',
    CN: 'ПОДТВЕРЖДЕНО',
    DEL: 'УДАЛЕНО',
    ACC: 'ПРИНЯТО',
    REJ: 'ОТКЛОНЕНО',
    BAC: 'БЭКЛОГ',
    FUT: 'БУДУЩЕЕ',
    UNREAD: 'НЕПРОЧИТАНО',
    READ: 'ПРОЧИТАНО',
    DOC: 'ЗАКРЫТО',
    FROM_DATETIME: 'Дата с',
    TO_DATETIME: 'Дата до',
    PUBLISHED_INFO: 'Задача опубликована и теперь видна выбранным организациям',
    NOT_PUBLISHED_INFO: 'Задача не опубликована, подорганизации ее не видят',
    PUBLISH: 'Опубликовать',
    CONFIRM_PUBLISH: 'Подтвердить публикацию',
    ARE_YOU_SURE_PUBLISH: 'Вы уверены, что хотите опубликовать задачу?',
    COMPANIES: 'Организации',
    NAME: 'Название задачи',
    DESCR: 'Описание',
    SEND_TO_ADMINS_ON_STATUS: 'Отправить электронное письмо администраторам при обновлении статуса',
    NO_SUBTASKS_INFO: 'В задаче нет подзадач! Вам нужно добавить хотя бы одну подзадачу, чтобы создать задачу.',
    AT_LEAST_1_SUBTASK_INFO: 'Должна быть как минимум одна подзадача.',
    MISSING_FROM_DATE: 'Дата начала отсутствует',
    MISSING_TO_DATE: 'Дата окончания отсутствует',
    SUBTASK_DELETED: 'Подзадача удалена',
    UNABLE_DELETE_SUBTASK: 'Не удалось удалить подзадачу',
    UNABLE_GET: 'Не удалось получить задачу',
    UNABLE_GET_SUBTASKS: 'Не удалось получить подзадачи'
  },

  SENDOUT: {
    NOT_AVAILABLE: 'Недоступно',
    NUM_OK: 'OK',
    NUM_FAIL: 'Ошибка',
    UPDATED: 'Рассылка обновлена',
    UNABLE_UPDATE: 'Не удалось обновить рассылку',
    NO_RECIPIENTS_ERROR: 'Нет получателей для рассылки'
  },

  SHOP_ORDER: {
    STRIPE_DASHBOARD_LINK: 'Translate: Stripe admin link',
    DELETED: 'Заказ удален',
    REFUND_SWISH: 'Возврат через Swish',
    REFUND_STRIPE: 'Возврат через Stripe',
    SWISH_REQUEST_ID: 'ID запроса Swish',
    NO_REFUND_METHOD: 'Возврат недоступен',
    UPDATED: 'Заказ обновлен',
    STRIPE_ID: 'Stripe ID',
    SWISH_ID: 'Swish ID',
    DINTERO_TRANSACTION_ID: 'Dintero ID',
    REFUND_AMOUNT_ERROR: 'Сумма возврата не может быть больше суммы платежа',
    REFUND_SUCCESS: 'Возврат выполнен успешно',
    REFUND_FAILED: 'Возврат не выполнен',
    MAKE_REFUND: 'Сделать возврат',
    REFUNDED_AMOUNT: 'Возвращенная сумма',
    NOT_REFUNDED: 'Не возвращено',
    NUM_ROWS: 'Количество строк',
    CREATED_AT: 'Дата создания',
    PAYMENT_AMOUNT: 'Сумма платежа',
    DINTERO_ID: 'Dintero ID',
    DINTERO_TRANSACTION_DATE: 'Дата транзакции Dintero',
    STATUS: 'Статус',
    DETAILS: 'Детали',
    CREATING: 'Создание заказа',
    EDITING: 'Редактирование заказа',
    PAYMENT_METHOD: 'Способ оплаты',
    NONE_FOUND: 'Заказы не найдены',
    TYPE: 'Тип',
    PAYMENT_STATUS: 'Статус оплаты',
    REFUND_REASON: 'Причина возврата',
    REFUND_ID: 'ID возврата',
    REFUND_AMOUNT: 'Сумма возврата',
    REFUND_AT: 'Дата возврата',
    REFUND: 'Возврат',
    CREATE_REFUND: 'Создать возврат',
    ITEMS: 'Товары',
    PAYMENT_STATUSES: {
      LEGACY: 'Недействительно',
      FREE: 'Бесплатно',
      NOT_PAID: 'Не оплачено',
      PARTIALLY_PAID: 'Частично оплачено',
      PAID_IN_FULL: 'Оплачено полностью',
      REFUND: 'Возвращено'
    },
    STATUS: 'Статус заказа',
    STATUSES: {
      NONE: 'Нет',
      CREATED: 'Создано',
      WAIT_PAYMENT: 'Ожидает оплаты',
      PARTIALLY_PAID: 'Частично оплачено',
      PAID_IN_FULL: 'Оплачено полностью',
      DELETED: 'Удалено',
      FAILED: 'Не удалось',
      DONE: 'Завершено',
      CANCELLED: 'Отменено'
    },
    PAYMENT_METHODS: {
      DINTERO: 'Dintero',
      INVOICE: 'Счет-фактура',
      FORTNOX_INVOICE: 'Счет Fortnox',
      STRIPE: 'Stripe',
      bgmax: 'BGMax',
      UNKNOWN: 'Неизвестно',
      REDEEMCODE: 'Купон',
      FREE: 'Бесплатно',
      SWISH: 'Swish'
    },
    TYPES: {
      UNKNOWN: 'Неизвестно',
      REGISTER: 'Регистрация',
      RENEW: 'Продление',
      TICKET: 'Билет',
      COMPANY: 'Компания',
      MEMBER: 'Участник',
      REDEEM_CODE: 'Купон',
      STRIPE_INVOICE: 'Stripe'
    },

    ALREAD_PAID: 'Заказ уже оплачен',
    FOR_MEMBER: 'Для участника',
    PAID_AT: 'Дата оплаты',
    DATE: 'Дата',
    PRODUCT: 'Товар',
    AMOUNT: 'Цена',
    TOTAL_AMOUNT: 'Общая сумма',
    FILTER: 'Фильтр',
    ANY_DATE: 'Любая дата',
    ANY_STATUS: 'Любой статус',
    COUNT: 'Количество заказов',
    CREATED_FROM: 'Создано с',
    CREATED_TO: 'Создано до',
    BASED_ON_FILTER: 'На основе фильтра',
    SELECTED_IN_TABLE: 'Выбранные заказы в таблице',
    UNABLE_LIST_ORDER: 'Не удалось вывести список заказов',
    CONFIRM_DELETE: 'Вы уверены, что хотите удалить платеж?',
    LINKTO: 'Ссылка на страницу оплаты',
    UNABLE_DELETE: 'Не удалось удалить платеж',
    SHOPORDER_DELETED: 'Платеж удален',
    UNABLE_FETCH: 'Не удалось получить платеж',
    ORDER_CREATED: 'Платеж создан',
    UNABLE_ORDER_CREATE: 'Не удалось создать платеж',
    ORDER_UPDATED: 'Платеж обновлен',
    UNABLE_ORDER_UPDATE: 'Не удалось обновить платеж',
    SEND_LINK: 'Отправить ссылку',
    SEARCH_PARAMS: 'Номер заказа, OCR, имя или электронная почта',
    UNABLE_GET_NUM_ORDERS: 'Не удалось получить количество заказов',
    CLIPBOARD_COPY: 'Скопировано в буфер обмена',
    UNABLE_CLIPBOARD_COPY: 'Не удалось скопировать в буфер обмена',
    PAY_REF: 'Платежная ссылка',
    INVALID_LINK: 'Эта ссылка недействительна',
    ERROR_GET: 'Произошла ошибка при получении вашего заказа',
    NO_ORDERS: 'У вас нет ожидающих платежей',
    CANCEL_IN_SECONDS: 'Заказ будет отменен через {seconds} секунд',
    TIMEOUT: 'Транзакция заняла слишком много времени и была отменена.',
    LATE: 'Задержка',
    PAID: 'Оплачено',
    WAIT: 'Не оплачено',
    ARTICLE: 'Товар',
    TOTAL: 'Итого',
    AMOUNT: 'Стоимость',
    COUNT: 'Количество',
    PAY_NOW: 'Оплатить сейчас',
    SAVE_INVOICE: 'Сохранить счет-фактуру',
    PAYMENTS: 'Платежи'
  },

  BILLING: {
    REMAINING: 'Translate: Remaining budget',
    ADMINISTRATE: 'Translate: Change >>'
  },


  COMPANY_BILLING: {
    CREATE: 'Создать',
    CREATED_AT: 'Создано',
    UPDATED_AT: 'Обновлено',
    PAID_AT: 'Оплачено',
    SCHEDULED_AT: 'Запланировано',
    FROM_DATE: 'Дата начала',
    TO_DATE: 'Дата окончания',
    DUE_DATE: 'Срок оплаты',
    TITLE: 'Заголовок',
    DESCRIPTION: 'Описание',
    STATUS: 'Статус',
    STATUSES: {
      SCHEDULED: 'Запланировано',
      SENT: 'Отправлено',
      LATE: 'Просрочено',
      PAID_FULL: 'Оплачено полностью',
      PAID_PARTIAL: 'Частично оплачено',
      DISPUTE: 'Спор',
      REFUNDED: 'Возвращено',
    },

    BILLING_SERVICE: 'Услуга',
    BILLING_SERVICES: {
      MEMLIST: 'Memlist',
      SMS_USAGE: 'SMS (ретроактивно)',
      SMS_PREPURCHASE: 'SMS (предоплата)',
      SPAR_USAGE: 'SPAR (ретроактивно)',
      SPAR_PREPURCHASE: 'SPAR (предоплата)',
      SUPPORT: 'Поддержка',
      MEMBERCARD: 'Членская карта',
      DEVELOPMENT: 'Разработка'
    },
    ALL: 'Все',
    CREATED: 'Доход клиента создан',
    UPDATED: 'Доход клиента обновлен',
    DELETED: 'Доход клиента удален',

    UNABLE_CREATE: 'Не удалось создать доход клиента',
    UNABLE_UPDATE: 'Не удалось обновить доход клиента',
    UNABLE_GET: 'Не удалось получить доход клиента',
    UNABLE_LIST: 'Не удалось вывести список доходов клиента'
  },

  COMPANY_PERIOD: {
    MUST_SELECT_FILE_AND_PERIOD: 'Необходимо выбрать период и файл',
    SKV_ADVANCE_IMPORTED_AT: 'Дата импорта аванса SKV',
    SKV_SETTLED_IMPORTED_AT: 'Дата импорта окончательной выплаты SKV',

    UPDATED_AT: 'Последнее обновление',
    ADVANCE_PAYOUT_AMOUNT: 'Выплачено',
    ADVANCE_PAYOUT_AMOUNT_UPDATED: 'Сумма авансового платежа обновлена',
    UNABLE_ADVANCE_PAYOUT_AMOUNT_UPDATE: 'Не удалось обновить сумму авансового платежа',
    CREATE_NEW_REGISTER_FILE: 'Создать запись для финансового года',
    CREATE_CORRECTIONS_FILE: 'Создать файл исправлений',
    CNUM: 'Номер объединения',
    MEMBER_COUNT: 'Количество участников',
    SKV_INCOME_AMOUNT: 'Предварительный доход',
    ADVANCE_INCOME_AMOUNT: 'Предварительный доход',
    SETTLED_AMOUNT: 'Окончательный доход',
    SETTLED_INCOME_AMOUNT: 'Окончательный доход',

    CALCULATE_SETTLED: 'Рассчитать окончательный расчет',
    CALCULATE_ADVANCE: 'Рассчитать аванс',
    ADVANCE_CALCULATED: 'Аванс рассчитан',
    ADVANCE_INFO_HEADER: 'Аванс {period}',
    SETTLED_INFO_HEADER: 'Окончательный расчет {period}',
    NO_ROWS_FOUND: 'Строки не найдены',
    NO_COMPANY_PERIODS: 'Нет финансовых годов для {period} для какого-либо объединения',
    CREATE_FOR_ALL: 'Создать финансовый год для {period} для всех объединений',
    NEW: 'Новый финансовый год',
    UNABLE_LIST: 'Не удалось вывести список финансовых годов',
    UNABLE_GET: 'Не удалось получить финансовый год',
    UNABLE_CREATE: 'Не удалось создать финансовый год',
    UNABLE_UPDATE: 'Не удалось обновить финансовый год',
    UNABLE_DELETE: 'Не удалось удалить финансовый год',
    CREATED: 'Финансовый год создан',
    UPDATED: 'Финансовый год обновлен',
    DELETED: 'Финансовый год удален',
    ACTIVE: 'Активный',
    COMPANY_NAME: 'Объединение',
    SHORTNAME: 'Короткое имя',
    ORG_NUMBER: 'Орг. номер',
    POSTADDRESS: 'Почтовый адрес',
    PHONE: 'Телефон',
    EMAIL: 'Электронная почта',
    MISSING_PREVIOUS_PERIOD: 'Невозможно создать финансовый год для {period}, так как отсутствует период 2 года назад',
    A1_NUM_MEMBERS: 'A1 Количество участников',
    A2_SUM_BASIS_FEE: 'A2 Базовая сумма, кр',
    A3_FEE_PER_MEMBER: 'A3 Ставка за участника, %',
    A4_ADVANCE_FROM_SKV: 'A4 Аванс от налогового управления (A2 x A3), кр',
    A5_ADM_FEE_MEMBER: 'A5 Административный сбор за участника, кр',
    A6_PCT_FEE_SUBCOMPANY: 'A6 Ставка для объединения, %',
    A7_FEE_TO_TOPCOMPANY: 'A7 Взнос в головное объединение ((A1 x A5) + (A4 x A6)), кр',
    A8_ADVANCE_PAYOUT: 'A8 Аванс, кр',
    B1_NUM_MEMBERS: 'B1 Количество участников',
    B2_SUM_BASIS_FEE: 'B2 Базовая сумма, кр',
    B3_FEE_PER_MEMBER: 'B3 Ставка за участника, %',
    B4_SETTLED_CALCULATED_SUM: 'B4 Окончательная сумма (B2 x B3), кр',
    B5_ADM_FEE_MEMBER: 'B5 Административный сбор за участника, кр',
    B6_PCT_FEE_SUBCOMPANY: 'B6 Ставка для объединения, %',
    B7_FEE_TO_TOPCOMPANY: 'B7 Взнос в головное объединение ((B1 x B5) + (B4 x B6)), кр',
    B8_SETTLED_SUM: 'B8 Удержано: аванс, кр',
    B9_DIFFERENCE: 'B9 К возврату +, К оплате -, (B4 - B7 - B8), кр',
    ADVANCE_CALCULATED_AT: 'Дата расчета аванса',
    SETTLED_CALCULATED_AT: 'Дата окончательного расчета'
  },


  PAGES: {

    MEMBER_REGISTRATIONS: {
      UNABLE_GET_SYSTEM_SETTINGS: 'Не удалось получить системные настройки',
      APPLICATION_TEMPLATES: 'Шаблоны заявок',
      REQUESTS: 'Заявки',
      EXITS: 'Заявки на выход',
      EXITS_HISTORY: 'История выходов',
      HISTORY: 'История заявок',
      CHECKBOX_REG_APPROVAL: 'Регистрация участников требует одобрения',
      TIP_REG_APPROVAL: 'Активируйте, чтобы заявки на участие требовали одобрения',
      HEADER_REG_APPROVAL: 'Заявки на участие',

      CHECKBOX_EXIT_APPROVAL: 'Участники должны быть одобрены перед выходом',
      TIP_EXIT_APPROVAL: 'Активируйте, чтобы участники требовали одобрения перед выходом',
      HEADER_EXIT_APPROVAL: 'Заявки на выход',

      CONFIRM_REJECT_EXIT: 'Подтвердите отклонение выхода',
      CONFIRM_REJECT_EXIT_MESSAGE: 'Вы хотите отклонить заявку на выход?',
      CONFIRM_ACCEPT_MESSAGE: 'При одобрении этой заявки заявитель станет одобренным участником в системе.',
      CONFIRM_REJECT_MESSAGE: 'Если вы отклоните эту заявку, все данные будут удалены. <br/><br/>Обратите внимание: Возврат возможных сборов должен быть произведен до отклонения заявки на членство.',
      CONFIRM_ACCEPT: 'Одобрить заявку на членство',
      CONFIRM_REJECT: 'Отклонить заявку на членство',
      UNABLE_UPDATE: 'Не удалось обновить заявку на членство',
      UNABLE_LIST: 'Не удалось вывести список заявок на членство',
      UNABLE_DOWNLOAD: 'Не удалось загрузить заявку на членство',
    },

    MEMBER_JOBS: {
      SELECTED: 'Выбранные',
      ACCEPTED_AT: 'Дата одобрения',
      ACCEPTED_BY: 'Одобрено',
      CREATE_NEW: 'Создать для периода',
      ERROR_RUNNING_JOB: 'Не удалось выполнить задачу',
      UNABLE_LOAD: 'Не удалось загрузить',
    },
    LOGIN: {
      TIP_REDIRECT: 'Перенаправить незарегистрированных пользователей на другую страницу',
      HEADER_REDIRECT: 'Перенаправление незарегистрированных пользователей',

      MUST_HAVE_LOGIN_OPTION: 'Необходимо выбрать хотя бы один способ входа',
      HEADER_EMAILPASSWORD: 'Вход с помощью электронной почты и пароля',
      TIP_EMAILPASSWORD: 'Активируйте для входа с электронной почтой и паролем.',
      CHECKBOX_EMAILPASSWORD: 'Электронная почта и пароль',
      HEADER_BANKID: 'Вход с помощью BankID',
      TIP_BANKID: 'Активируйте для входа с помощью BankID.',
      CHECKBOX_BANKID: 'BankID (QR и локальное приложение)',
      CONTACT_SUPPORT_BANKID: 'Свяжитесь с support@memlist.se, чтобы разблокировать эту функцию.'
    },

    REPORTS: {
      REPORTS: 'Отчеты',
      DOWNLOAD_SALES: 'Скачать отчет по продажам',
      DOWNLOAD_MUCF_1: 'Отчет MUCF 1',
      DOWNLOAD_MUCF_2: 'Отчет MUCF 2',
      DOWNLOAD_MUCF_3: 'Отчет MUCF 3',
      DOWNLOAD_MONTH_REPORT: 'Отчет по членствам',
      MUST_SELECT_INTERVAL: 'Необходимо выбрать интервал',
      FROM_DATE: 'Дата начала',
      TO_DATE: 'Дата окончания',
      FOR_YEAR: 'За год'
    },
    PAGE: {
      PAGES: 'Страницы/Модули',
      PAGE: 'Страница',
      ON: 'Вкл',
      SUPERADMIN: 'Суперадмин',
      ACTIVATED: 'Страница активирована',
      REMOVED: 'Страница удалена',
      UNABLE_LIST: 'Не удалось получить страницы'
    },
    FAITH: {
      DEACTIVATE_INFO: 'Помните, что необходимо создать файл исправлений и отправить его в налоговую службу.',
      SKV_INCLUDE_MINORS: 'Включать несовершеннолетних в файлы налоговой службы',
      IMPORT_ERROR_MESSAGE: 'Сообщение об ошибке',
      IMPORT_ERROR_ROW: 'Строка',
      IMPORT_FILE_ERRORS: {
        COMPANY_NOT_FOUND: 'Объединение {company} не найдено',
        COMPANY_PERIOD_NOT_FOUND: 'Объединение {company} не имеет финансового года {period}',
        YEAR_MISMATCH: 'Выбранный период и год в файле не совпадают',
        INVALID_DATE_FORMAT: 'Год в файле не удалось распознать',
        ADVANCE_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Translate: Förskottsperioden finns ej för slutavräkningsperioden',
        ADVANCE_NOT_CALCULATED_FOR_SETTLED_COMPANY_PERIOD_NOT_FOUND: 'Translate: Förskottsperioden saknar uträkning för slutavräkningsperioden',
      },
      COMPANY_PERIOD_NOT_EXISTS: 'Нет финансового года для периода {period} для объединения {company}',
      MOVE_REG_AFTER: 'Переместить вновь зарегистрированных участников на следующий период после даты',
      ADVANCE_CALCULATED: 'Аванс рассчитан',
      UNABLE_CALCULATE_ADVANCE: 'Не удалось рассчитать аванс',
      UNABLE_CALCULATE_SETTLED: 'Не удалось выполнить окончательный расчет',
      SETTLED_CALCULATED: 'Окончательный расчет выполнен',

      REGISTRATIONS: 'Регистрация',
      CORRECTIONS: 'Исправления',

      TIP_FEES: 'Сборы для объединений (подразделений) настраиваются здесь глобально',
      HEADER_FEES: 'Сборы для объединений',

      FEE_PCT: 'Сбор в процентах (%)',
      ADM_FEE: 'Административный сбор, кр (SEK)',
      MEM_PCT: 'Сбор в процентах за участника (%)',
      MEM_FEE: 'Сбор за участника, кр (SEK)',
      GDPR_CONTACT_NAME: 'Имя ответственного за защиту данных GDPR',
      GDPR_CONTACT_EMAIL: 'Эл. почта ответственного за защиту данных GDPR',

      MUST_BE_ENGLISH_FLOAT: 'Должно быть английское число с плавающей точкой (точка, не запятая)',
      AMOUNT_PAID_TO_COMPANY_INFO: 'Сумма, выплаченная за {period} объединению {company}',
      AMOUNT_PAID: 'Выплачено',

      TIP_INTAG: 'Настройки для импорта в налоговую службу',
      HEADER_INTAG: 'Файл импорта в налоговую службу',

      TIP_REDOVISNINGSRAKNING: 'Свойства файла для отчетного счета',
      HEADER_REDOVISNINGSRAKNING: 'Отчетный счет',
      REDOVISNINGSRAKNING: {
        SIGNED_NAME: 'Подписано',
        INVALID_SIGNED_NAME: 'Недействительное имя',
        UNABLE_CREATE: 'Не удалось создать отчетный счет',
        LEFT_HEADER: 'Левый заголовок',
        NEW_LINE_INFO: 'Добавьте \\n для новой строки',
        INVALID_LEFT_HEADER: 'Недействительный левый заголовок',
        RIGHT_HEADER: 'Правый заголовок',
        INVALID_RIGHT_HEADER: 'Недействительный правый заголовок',
      },
      SETTINGS: 'Настройки',
      SUMMARY: 'Обзор',
      IMPORT: 'Импорт',
      EXPORT: 'Экспорт',
      REPORTS: 'Отчеты',
      GENERATE: 'Сгенерировать',
      FILE_TYPE: 'Тип файла',
      FOR_PERIOD: 'Для периода',
      GENERATED_AT: 'Сгенерировано',
      USER_NAME: 'Пользователь',
      HISTORY: 'История',
      GENERATE_FILE: 'Сгенерировать новый файл',
      UNABLE_LOAD_FILES: 'Не удалось загрузить список файлов',
      SKV_EXPORT_REGISTRATIONS: 'Налоговая служба: Импорт участников',
      SKV_EXPORT_INCOME_PERSONNR_ADVANCE: 'Налоговая служба: Экспорт дохода - Аванс',
      SKV_EXPORT_INCOME_PERSONNR_SETTLED: 'Налоговая служба: Экспорт дохода - Окончательный расчет',
      SKV_IMPORT_INCOME_PERSONNR_ADVANCE: 'Налоговая служба: Импорт дохода - Аванс',
      SKV_IMPORT_INCOME_PERSONNR_SETTLED: 'Налоговая служба: Импорт дохода - Окончательный расчет',
      SKV_EXPORT_CORRECTIONS: 'Налоговая служба: Файл исправлений',
      UPLOAD_SKATTEVERKET_FILE: 'Загрузить файл налоговой службы',
      FILE_IMPORTED: 'Файл для импорта создан',
      UNABLE_FILE_IMPORT: 'Не удалось загрузить файл для импорта',
      UPLOAD_FILE: 'Загрузить файл',
      NO_FILES: 'Файлы отсутствуют',
      UNABLE_EXPORT_FILE: 'Не удалось экспортировать файл',
      NAME_MISSING: 'Имя не указано',
      FILE_EXPORTED: 'Файл экспортирован'
    },
    EDUCATIONS: {
      ALREADY_EXISTS: 'Курс уже существует',
      FACULTIES: 'Факультеты',
      EDUCATIONS: 'Образование',
      PROGRAM_CODE: 'Код программы',
      IMPORT_EDUCATIONS: 'Импортировать образование',
      ADD: 'Добавить',
      SECTION: 'Секция',
      SECTION_COMPANY: 'Секция/Объединение',
      NUM_ROWS: 'Количество строк: ',
      FACULTY: 'Факультет',
      CODE: 'Код',
      PROGRAM: 'Программа',
      COURSE: 'Курс',
      FREE_COURSE: 'Свободный курс',
      NEW: 'Новое образование',
      DELETED: 'Образование удалено',
      UNABLE_DELETE: 'Не удалось удалить образование',
      CREATED: 'Образование создано',
      UNABLE_CREATE: 'Не удалось создать образование',
      UPDATED: 'Образование обновлено',
      UNABLE_UPDATE: 'Не удалось обновить образование',
      UNABLE_GET: 'Не удалось получить образование',
      UNABLE_LIST: 'Не удалось вывести список образований',
      ERROR_COMPANY: 'Необходимо указать секцию/объединение',
      ERROR_FACULTY: 'Необходимо указать факультет',
      ERROR_TYPE: 'Необходимо указать тип',
      ERROR_CODE: 'Необходимо указать код',
      ERROR_NAME: 'Необходимо указать название',
      NAME: 'Название образования',
      TYPE: 'Тип',
      TYPE_NONE: 'Нет',
      TYPE_PROGRAM: 'Программа',
      TYPE_COURSE: 'Курс',
      TYPE_FREE_COURSE: 'Свободный курс',
      NEW_FACULTY: 'Новый факультет',
      FACULTY_DELETED: 'Факультет удален',
      UNABLE_FACULTY_DELETE: 'Не удалось удалить факультет',
      FACULTY_CREATED: 'Факультет создан',
      UNABLE_FACULTY_CREATE: 'Не удалось создать факультет',
      FACULTY_UPDATED: 'Факультет обновлен',
      UNABLE_FACULTY_UPDATE: 'Не удалось обновить факультет',
      UNABLE_GET_FACULTY: 'Не удалось получить факультет',
      UNABLE_LIST_FACULTY: 'Не удалось вывести список факультетов',
      FACULTY_NAME: 'Факультет',
      FACULTY_DESCR: 'Описание',
      IMPORT_SECTIONS: 'Импортировать секции'
    },
    APIKEYS: {
      DINTERO_SETTINGS: 'Настройки Dintero',
      SERVICE: 'Сервис',
      API_KEY_DATA: 'Данные API ключа',
      API_SERVICE: 'Внешний сервис',
      PUBLIC_TOKEN: 'Публичный токен',
      STRIPE_SETTINGS: 'Настройки Stripe',
      NEW: 'Новый API ключ',
      MUST_BE_SINCH_PHONE: 'Должен быть номер телефона, настроенный в Sinch admin',
      SET_SERVICEPLAN_ID: 'Укажите ID плана обслуживания из Sinch admin',
      CHECK_SINCH_ADMIN: 'Находится в Sinch admin',
      FETCH_ACCESS_TOKEN: 'Получить Access-Token',
      SET_AFTER_SUCCESS_SPAR: 'Устанавливается после успешной аутентификации в сервисе SPAR',
      EXISTS_IN_SPAR_ADMIN: 'Присутствует в SPAR admin',
      UNABLE_GET: 'Не удалось получить API ключи'
    },
    DASHBOARD: {
      MEMBERS: 'Участники',
      MEN: 'Мужчины',
      WOMEN: 'Женщины',
      OTHERS: 'Другие',
      RENEWALS: 'Продления',
      TOTAL: 'Всего',
      DONE: 'Завершено',
      NOTDONE: 'Не завершено',
      LATEST_MEMBERS: 'Последние участники',
      MEMBER_JOINING: 'Присоединение участников',
      CHART_START_DATE: 'Дата начала',
      CHART_END_DATE: 'Дата окончания',
      THINGS_TODO: 'У вас есть дела, которые нужно сделать',
      TODO_TEXT: 'Не забудьте завершить то, что необходимо сделать до указанных ниже дат.',
      TODO: 'Список дел',
      BUDGET_LOW: 'Translate: Budget low',
    },
    CAMPAIGNS: {
      ACTIVE: 'Активна',
      ACTIVE_TIP: 'Активирует кампанию, и она станет доступна для участников.',
      ACTIVE_HEADER_TIP: 'Указывает, активна ли кампания.',
      ACTIVE_WARNING: 'Обратите внимание: Все участники, зарегистрировавшиеся в указанный период, получат эту скидку на ВСЕ товары.',
      MAX_USES: 'Максимальное количество',
      MAX_USES_TIP: 'Максимальное количество кодов или пользователей, которые могут воспользоваться скидкой.',
      INVALID_MAX_USES: 'Недопустимое максимальное количество',
      NAME: 'Кампания',
      NAME_TIP: 'Название кампании, видимое только администраторам.',
      NAME_HEADER_TIP: 'Название кампании.',
      INVALID_NAME: 'Недопустимое название',
      AMOUNT: 'Количество',
      AMOUNT_HEADER_TIP: 'Количество использованных скидок и доступных.',
      USE_CODE: 'Требовать промокод',
      USE_CODE_TIP: 'Если активировать "Требовать промокод", пользователь должен ввести определенный промокод для получения скидки. Если эта опция не активирована, скидка будет применяться по принципу "кто первый, тот и получает" до достижения максимального количества.',
      VALID_CODE: 'Действительный код',
      INVALID_CODE: 'Недействительный код',
      CODE: 'Промокод',
      CODE_TIP: 'Код, который пользователь должен использовать для применения скидки на покупку.',
      CODE_HEADER_TIP: 'Промокод.',
      CODE_INFO: 'Код, который должен ввести участник, минимум 4 символа',
      INVALID_PERCENT: 'Недопустимый процент',
      VALID_PERCENT: 'Действительный процент (1-100)',
      PERCENT_DISCOUNT: 'Скидка в процентах',
      PERCENT_DISCOUNT_TIP: 'Количество процентов, которое будет применяться в качестве скидки на товар.',
      PERCENT_DISCOUNT_HEADER_TIP: 'Скидка в процентах на товар.',
      INVALID_DATE: 'Недопустимая дата',
      VALID_DATE: 'Действительная дата',
      TO_DATE: 'До даты',
      TO_DATE_TIP: 'Дата, до которой кампания будет активна.',
      TO_DATE_HEADER_TIP: 'Последний день действия промокода.',
      DATE_DESCR: 'ГГГГ-ММ-ДД',
      FROM_DATE: 'С даты',
      FROM_DATE_TIP: 'Дата, с которой кампания будет активна.',
      FROM_DATE_HEADER_TIP: 'Первый день действия промокода.',
      PRIORITY: 'Порядок/Приоритет',
      DESCR: 'Описание',
      DESCR_TIP: 'Этот текст виден только администраторам для объяснения причины скидки.',
      INVALID_DESCR: 'Недопустимое описание',
      CAMPAIGNS: 'Кампании',
      NEW: 'Новая кампания',
      DELETED: 'Кампания удалена',
      UPDATED: 'Кампания обновлена',
      CREATED: 'Кампания создана',
      UNABLE_DELETE: 'Не удалось удалить кампанию',
      UNABLE_LIST: 'Не удалось вывести список кампаний',
      UNABLE_GET: 'Не удалось получить кампанию',
      UNABLE_UPDATE: 'Не удалось обновить кампанию',
      UNABLE_CREATE: 'Не удалось создать кампанию',
      EDIT_ICON_TIP: 'Редактировать кампанию.',
      DELETE_ICON_TIP: 'Удалить кампанию.'
    },

    FILES: {
      NEW_FILE: 'Новый файл',
      UNABLE_CREATE_FILE: 'Не удалось создать файл',
      SUCCESS_CREATE_FILE: 'Файл создан',
      UNABLE_DOWNLOAD: 'Не удалось скачать файл',
      UPLOADED: 'Файл загружен',
      MUST_BE_LESS: 'Файл должен быть меньше 50 МБ',
      UNABLE_UPLOAD: 'Не удалось загрузить файл',
      MUST_BE_CSV: 'Файл должен быть в формате CSV',
      FILE_NAME: 'Имя файла',
      FILE_NAME_TIP: 'Имя загруженного файла.',
      DISPLAY_NAME: 'Отображаемое имя',
      DISPLAY_NAME_TIP: 'Имя, которое отображается на странице "Моя страница" участников.',
      DATE: 'Дата',
      DATE_TIP: 'Дата загрузки файла.',
      HANDLE: 'Управление',
      HANDLE_TIP: 'Возможность управлять файлами различными функциями.',
      COPY_LINK_ICON_TIP: 'Копировать ссылку.',
      DOWNLOAD_FILE_ICON_TIP: 'Скачать файл.',
      EDIT_FILE_ICON_TIP: 'Редактировать файл.',
      DELETE_FILE_ICON_TIP: 'Удалить файл.',
      ACCESSABILITY: 'Доступность',
      IS_S3: 'Находится в S3',
      IS_S3_TIP: 'Если файл хранится в S3 bucket (в Швеции)',
      PUBLIC_LINK: 'Публичная ссылка',
      PUBLIC_LINK_TIP: 'Если установить этот флажок, загруженный файл будет доступен всем, у кого есть ссылка.',
      MEMBER_VISIBLE: 'Видим для участника, владеющего файлом, на странице "Моя страница"',
      MEMBER_ANY_VISIBLE: 'Видим для всех участников на странице "Моя страница"',
      MEMBER_VISIBLE_TIP: 'Файл будет виден только участнику, который владеет или создал его.',
      MEMBER_ANY_VISIBLE_TIP: 'Файл будет виден всем участникам на странице "Моя страница"',
      FILE_INFO: 'Информация о файле',
      ONLY_FOR_MEMBER: 'Видно только для участника, владеющего файлом'
    },

    MEMBERTYPES: {
      UNABLE_LIST_MEMBERTYPES: 'Не удалось вывести список типов участников'
    },

    JOBS: {
      UNABLE_CREATE: 'Не удалось создать задачу',
      UNABLE_LIST: 'Не удалось вывести список задач',
      UNABLE_GET: 'Не удалось получить задачу',
      UNABLE_UPDATE: 'Не удалось обновить задачу',
      CREATED: 'Задача создана',
      UPDATED: 'Задача обновлена',
      CREATED_WITH_COUNT: 'Задача создана с количеством: ',
      CREATE: 'Создать задачу'
    },
    REDEEMCODES: {
      REDEEMCODES: 'Купоны',
      VALID_FOR_ARTICLE: 'Действителен для товара',
      VALID_FOR_EVENT: 'Действителен для мероприятия',
      MEMBERSHIP_IN_COMPANY: 'Для членства в объединении',
      RECIPIENT: 'Получатель',
      SEND_TO_MEMBER: 'Отправить участнику',
      CREATE_SMS_EMAIL_INVITE: 'Создать приглашение по электронной почте/SMS',
      SELECTED_MEMBER: 'Выбранный участник',
      TO_EMAIL: 'На электронную почту',
      TO_MOBILE: 'На мобильный/SMS',
      PHONE_INFO: 'Используйте + и код страны, даже внутри Швеции',
      EXPIRES_AT: 'Действителен до даты',
      DATE_INFO: 'ГГГГ-ММ-ДД',
      GENERATED_BY_SYSTEM: 'Сгенерировано системой',
      MUST_HAVE_EMAIL: 'Должен быть адрес электронной почты',
      MUST_SELECT_RECIPIENT_TYPE: 'Необходимо выбрать тип получателя',
      MUST_SELECT_MEMBER: 'Необходимо выбрать участника',
      MUST_SELECT_ARTICLE: 'Необходимо выбрать товар',
      MUST_SET_EXPIRATION: 'Необходимо установить дату истечения'
    },
    SHOPITEM: {
      NEXT_SHOP_ITEM: 'Продляется до',
      CREATED_AT: 'Создан',
      COMPANY: 'Объединение',
      SEARCH_NAME: 'Поиск по имени товара',
      CURRENCY: 'Валюта',
      NAME: 'Название товара',
      VAT_FREE: 'Без НДС',
      UNABLE_GET: 'Не удалось получить товар',
      UNABLE_DELETE: 'Не удалось удалить товар',
      UNABLE_LIST: 'Не удалось вывести список товаров',
      UNABLE_CREATE: 'Не удалось создать товар',
      UNABLE_UPDATE: 'Не удалось обновить товар',
      DELETED: 'Товар удален',
      UPDATED: 'Товар обновлен',
      CREATED: 'Товар создан',
      UNABLE_LIST_SHOPITEMS: 'Не удалось вывести список товаров в магазине',
      SELECT: 'Выбрать товар',
      ARTICLE: 'Товар',
      MISSING: 'Товар отсутствует',
      ACTIVATE_STRIPE: 'Активировать платежи Stripe',
      STRIPE_ACTIVATION_INFO: 'Ключи API Stripe должны быть установлены в "API ключи"',
      NO_MEMBERSHIPS: 'Объединение не имеет товаров для членства',
      SHOPITEMS: 'Товары магазина',
      NO_API_KEY: 'Нет установленного API ключа для платежей в основном объединении. Для работы платежей необходимо, чтобы были установлены правильные ключи и заключен договор с поставщиком платежей. Свяжитесь с поддержкой memlist для получения дополнительной информации.',
      CONFIRM_DELETE: 'Вы уверены, что хотите удалить товар?',
      CREATE_FOR_ALL: 'Создать для всех объединений',
      PRESENTATION: 'Презентация',
      DESCR: 'Описание',
      IMAGE: 'Изображение товара',
      UPLOAD_IMAGE: 'Загрузить изображение',
      PRIORITY: 'Порядок/Приоритет',
      PRICE_SEK: 'Ценообразование',
      VAT_SEK: 'НДС',
      EX_VAT: 'Цена за штуку без НДС',
      ACCOUNTING: 'Бухгалтерия',
      BOOK_ACCT_VAT: 'Счет НДС',
      BOOK_ACCT_ARTICLE: 'Счет статьи',
      BOOK_PLACE: 'Место затрат',
      PROJECT: 'Проект',
      TAGS: 'Теги',
      TAGS_INFO: 'Введите текст и нажмите ENTER',
      MEMBERSHIP_SETTINGS: 'Настройки членства',
      MEMBER_TYPE_ID: 'Тип участника',
      MIN_AGE: 'Минимальный допустимый возраст',
      MAX_AGE: 'Максимальный допустимый возраст',
      UNABLE_DUPLICATE: 'Не удалось дублировать товар',
      UNABLE_COPY: 'Не удалось скопировать товар',
      COPIED: 'Товар скопирован',
      DUPLICATED: 'Товар дублирован',
      IMAGE_UPLOADED: 'Изображение загружено',
      UNABLE_IMAGE_UPLOAD: 'Не удалось загрузить изображение',
      SAVE_TICKET: 'Сохранить билет',
      SAVE: 'Сохранить товар',
      COPY_TO_NEXT_PERIOD: 'Копировать на следующий период',
      NEW: 'Новый товар',
      NAME: 'Название товара',
      AMOUNT: 'Сумма',
      AMOUNT_VAT: 'Сумма НДС',
      VAT_PCT: 'Ставка НДС',
      IS_ACTIVE: 'Активен (будет отображаться при регистрации)',
      IS_SHARED: 'Используется всеми объединениями',
      IS_MEMBER_FEE: 'Членский взнос',
      IS_EXTRA: 'Дополнительная продажа',
      UNABLE_DELETE_ON_ORDERS: 'Невозможно удалить товары, которые есть в заказах или членствах',
      CAMPAIGN_CODE: 'Промокод',
      CAMPAIGN_CODE_TIP: 'Если у вас есть активный промокод, вы можете ввести его здесь для применения возможной скидки.',
      APPLY_CAMPAIGN_CODE: 'Применить',
      UNABLE_APPLY_CAMPAIGN_CODE: 'Не удалось применить промокод.',
      CAMPAIGN_CODE_VALID: 'Действительный промокод',
      CAMPAIGN_CODE_INVALID: 'Недействительный промокод',
      RECEIVER: 'Касается',
      RECEIVER_OPTION1: 'Объединение',
      RECEIVER_OPTION2: 'Участник',
      QUANTITY_METHOD: 'Метод расчета',
      QUANTITY_OPTION1: 'По количеству',
      QUANTITY_OPTION2: 'На участника',
    },

    SMS: {
      SMS_SENT: 'СМС отправлено',
      UNABLE_SEND: 'Не удалось отправить СМС'
    },
    REGISTRATION: {
      PAGES: 'Страницы',
      CONFIRM_DELETE_PAGE: 'Подтвердите удаление страницы.',
      VERIFY_BANKID_TEXT: 'Текст для проверки BankID',
      VERIFY_BANKID: 'Проверить с помощью BankID (Только если есть личный номер)',
      ALLOW_DUPLICATES: 'Translate: Allow duplicates to register (this will disable the login)',
      NEW_PAGE: 'Новая страница',
      CHECK_LADOK: 'Проверить в LADOK',
      ERROR_OCCURED: 'Произошла ошибка, попробуйте позже',
      LOGIN_URL: 'URL входа при регистрации',
      TOO_MANY_LOOKUPS: 'Слишком много запросов. Подождите 1 минуту.',
      EMAIL_TAKEN: 'Электронная почта уже зарегистрирована',
      PERSONNR_TAKEN: 'Личный номер уже зарегистрирован',
      UNABLE_PROCEED: 'Не удалось продолжить',
      CONFIRM: 'Подтвердить',
      SELECTED_COMPANIES: 'Выбранные организации',
      UNABLE_GET_TERMS: 'Не удалось получить условия',
      SINGLE_MEMBERSHIP: 'Индивидуальное членство',
      FAMILY_MEMBERSHIP: 'Семейное членство',
      PERSONNR_FORMAT: 'ГГГГММДД1234',
      INPUT_PERSONNR: 'Введите действительный личный номер',
      SELECTED_SKIP_PERSONNR: 'Вы выбрали пропустить личный номер',
      SKIP_PERSONNR: 'Я не хочу указывать личный номер',
      NEW_FAMILY_MEMBER: 'Новый член семьи',
      NEXT_STEP: 'Следующий шаг',
      PREV_STEP: 'Предыдущий',
      CREATE_ACCOUNT: 'Стать участником',
      PROCEED_TO_PAYMENT: 'Перейти к оплате',
      RENEW_ACCOUNT: 'Продлить членство',
      SHOW_AT_REGISTRATION: 'Показать при регистрации',
      SHOW_AT_RENEWAL: 'Показать при продлении',
      MUST_FULL_PERSONNR: 'Участник должен заполнить полный личный номер',
      DO_SPAR: 'Выполнить запрос в SPAR для облегчения регистрации (дополнительная стоимость)',
      MANDATORY: 'Обязательные',
      DISPLAY: 'Отображение',
      SELECT_LOCAL_COMPANY: 'Выберите локальную организацию',
      SELECT_REGION: 'Выберите регион',
      CAN_ALSO_JOIN: 'Вы также можете присоединиться к этим',
      UPLOAD_CSS: 'Загрузить файл CSS',
      REMOVE_CSS: 'Удалить CSS',
      ACTIVATED_FIELDS: 'Активированные поля',
      FIELDS_MANDATORY_INFO: 'Обязательные поля устанавливаются в Настройки -> Требования к членству'
    },
    ATTRIBUTES: {
      NONE: 'Translate: None',
      NAME: 'Атрибут',
      UPDATED: 'Атрибут обновлен',
      UNABLE_UPDATE: 'Не удалось обновить атрибут',
      CREATED: 'Атрибут создан',
      UNABLE_CREATE: 'Не удалось создать атрибут',
      DELETED: 'Атрибут удален',
      UNABLE_DELETE: 'Не удалось удалить атрибут',
      COLUMN: 'Колонка',
      VALUE: 'Значение',
      UNABLE_LIST: 'Не удалось вывести список атрибутов',
      UNABLE_DELETE_GROUP: 'Не удалось удалить категорию',
      UNABLE_DELETE_GROUP_CONFLICT: 'Некоторые участники имеют установленные эти атрибуты',
      GROUP_DELETED: 'Категория удалена',
      HEADER: 'Категории атрибутов',
      MULTIPLE_OPTIONS: 'Множественный выбор',
      MULTIPLE_OPTIONS_INFO: 'Можно выбрать несколько вариантов?',
      OTHER_OPTIONS: 'Другие варианты',
      IS_DROPDOWN: 'Выпадающий список',
      SHOW_AT_REGISTRATION: 'Показать при регистрации',
      SHOW_AT_PROFILE: 'Translate: Show in profile',
      MANDATORY: 'Обязательный выбор',
      SINGLE_SELECTION: 'Одинарный выбор',
      MULTI_SELECTION: 'Множественный выбор',
      UNABLE_LIST_CATEGORIES: 'Не удалось вывести список категорий',
      CATEGORY_CREATED: 'Категория создана',
      UNABLE_CATEGORY_CREATE: 'Не удалось создать категорию',
      CATEGORY_UPDATED: 'Категория обновлена',
      UNABLE_CATEGORY_UPDATE: 'Не удалось обновить категорию',
      UNABLE_GET: 'Не удалось получить категорию',
      CATEGORY: 'Категория',
      NEW: 'Новый атрибут',
      NEW_CATEGORY: 'Новая категория',
      TOO_LONG: 'Название должно быть короче 64 символов'
    },
    REGISTRATIONSETUP: {
      TIP_EXTRA_VISIBILITY_OPTION: 'Определяет, как дополнительные параметры будут отображаться для участников при регистрации',
      HEADER_EXTRA_VISIBILITY_OPTION: 'Режим отображения дополнительных параметров',
      EXTRA_VISIBILITY_SEARCH: 'Поиск',
      EXTRA_VISIBILITY_LIST: 'Список',
      EXTRA_CHECKBOX: 'Множественный выбор',
      EXTRA_RADIO: 'Одинарный выбор',
      EXTRA_NONE: 'Нет дополнительных параметров',
      COMPANY_NAME: 'Название организации',
      PRESELECTED: 'Предварительно выбран',
      AREA_ALLOCATION: 'Распределение по области',
      EXTRA: 'Дополнительный выбор',
      MEMBERSHIP: 'Членство',
      SHOW: 'Показать',
      NOT_ASSIGNED: 'Не назначено',
      ASSIGNED: 'Выбрано({num})',

      TIP_LEVEL0_LABEL: 'Текст, отображаемый в качестве заголовка для первого выбора при выборе организации',
      HEADER_LEVEL0_LABEL: 'Заголовок выбора организации уровня 1 (Верхняя организация)',

      TIP_LEVEL1_LABEL: 'Текст, отображаемый в качестве заголовка для второго выбора при выборе организации',
      HEADER_LEVEL1_LABEL: 'Заголовок выбора организации уровня 2',

      TIP_LEVEL2_LABEL: 'Текст, отображаемый в качестве заголовка для третьего выбора при выборе организации',
      HEADER_LEVEL2_LABEL: 'Заголовок выбора организации уровня 3',

      TIP_LEVEL3_LABEL: 'Текст, отображаемый в качестве заголовка для четвертого выбора при выборе организации',
      HEADER_LEVEL3_LABEL: 'Заголовок выбора организации уровня 4',

      TIP_EXTRA_LABEL: 'Текст, отображаемый для дополнительного выбора организации',
      HEADER_EXTRA_LABEL: 'Заголовок дополнительного выбора организации',

      ACTIVATE_FAMILYREG: 'Активировать семейную регистрацию',
      PARENT_REAL_MEMBERS: 'Родители являются действительными участниками',
      SUGGEST_AREA_MEMBERSHIP: 'Предложить организацию по области',
      TIP_EXTRA_OPTION: 'Определяет, сможет ли участник выбрать один или несколько дополнительных параметров при регистрации',
      HEADER_EXTRA_OPTION: 'Дополнительный выбор организации',

      REGISTER_WIZARD: 'Мастер регистрации',
      COMPANY_SELECT_V2: 'Выбор организации - v2',
      UNABLE_CREATE_PAGE: 'Не удалось создать страницу',
      PAGE_CREATED: 'Страница создана',
      PAGE_UPDATED: 'Страница обновлена',
      UNABLE_UPDATE_PAGE: 'Не удалось обновить страницу',
      PAGE_DELETED: 'Страница удалена',
      UNABLE_DELETE_PAGE: 'Не удалось удалить страницу',
      UNABLE_DELETE_CSS: 'Не удалось удалить CSS',
      CSS_DELETED: 'CSS удален',
      UNABLE_LIST_PAGES: 'Не удалось вывести список страниц',
      PERSONNR: 'Личный номер',
      STUDENT: 'Студенческий номер',
      DETAILS: 'Личные данные',
      PROPERTIES: 'Атрибуты',
      PARENTS: 'Родители',
      SHOP: 'Магазин',
      AREA_ALLOC: 'Выбор организации',
      CONFIRM: 'Подтверждение',
      REG_FAMILY: 'Семейное членство',
      ONEPAGER: 'Одностраничная регистрация',
      UNABLE_SORT: 'Не удалось отсортировать страницы'
    },
    SENDOUT: {
      REMAINING_SMS_BUDGET: 'Translate: Remaining SMS budget',
      CREATED: 'Рассылка создана',
      SENDOUT: 'Рассылка',
      EMPTY_CREATED: 'Создана пустая рассылка',
      UNABLE_CREATE: 'Не удалось создать рассылку',
      DELETED: 'Рассылка удалена',
      UNABLE_DELETE: 'Не удалось удалить рассылку',
      UNABLE_GET: 'Не удалось получить рассылку',
      UNABLE_LIST: 'Не удалось вывести список рассылок',
      SELECT_TYPE: 'Выберите участников или пользователей',
      STARTED: 'Рассылка начата',
      UNABLE_START: 'Не удалось запустить рассылку',
      UPDATED: 'Рассылка обновлена',
      UNABLE_UPDATED: 'Не удалось обновить рассылку',
      CONFIRM_DELETE: 'Вы уверены, что хотите удалить рассылку?',
      CONFIRM_START: 'Вы уверены, что хотите запустить рассылку?',
      SHOW_HISTORY: 'Показать историю',
      CREATING: 'Создание рассылки',
      UPDATING: 'Обновление рассылки',
      NEW_SENDOUT: 'Новая рассылка',
      OPENS: 'Открытия',
      CLICKS: 'Клики',
      BOUNCES: 'Отскоки',
      DROPS: 'Падения',
      NAME_ON_SENDOUT: 'Имя рассылки',
      TYPE: 'Тип',
      NOT_ENOUGH_BUDGET: 'У вас недостаточно бюджета для выполнения этой рассылки',
      SYS: 'Система',
      DEF: 'Обычный',
      IMP: 'Важный',
      EMAIL_SENDOUT: 'Email-рассылка',
      SEND_A_TEST: 'Отправить тест',
      EMAIL_SEND_TO: 'Email для отправки',
      PREVIEW_SEND: 'Отправить предварительный просмотр',
      SENDOUT_INFO: 'Информация об отправителе',
      FROM_NAME: 'От имени',
      CHARACTERS_REMAINING: 'Осталось символов',
      INFO_FROM_NAME: 'Будет указано как "От отправителя" в почтовом ящике',
      SET_SUBJECT: 'Установить тему',
      SCHEDULED_AT: 'Запланировать рассылку',
      SCHEDULED_AT_TOOLTIP: 'Запланировано на',
      ACTIVATE_SCHEDULE_AT: 'Активировать',
      SCHEDULED_AT_HELPTEXT: 'Рассылка будет отправлена в указанный момент времени.',
      SUBJECT: 'Тема',
      EMAILSUBJECT: 'Тема в почтовом ящике',
      PREVIEW_TEXT: 'Текст предварительного просмотра',
      PREVIEW_INFO: 'Показывает текст предварительного просмотра для некоторых почтовых клиентов',
      SELECT_TEMPLATE_EMAIL: 'Email: Выберите шаблон',
      REVIEW: 'Просмотр',
      CREATE_NEW_TEMPLATE: 'Создать новый шаблон',
      CORRECT_INFO: 'Все поля выше должны быть установлены правильно, прежде чем рассылка может быть запущена',
      ALL_CORRECT: 'Все поля шаблона настроены правильно',
      UNABLE_SELECT_TEMPLATE: 'Не удалось выбрать шаблон',
      EMAIL_SENT: 'Email отправлен',
      UNABLE_SEND: 'Не удалось отправить',
      WARNING_COST: 'Дополнительная стоимость по тарифам Memlist SMS',
      SMS_SENDOUT: 'SMS-рассылка',
      SENDER: 'Отправитель',
      SENDER_ID_INFO: 'ID вместо номера телефона как отправителя. Если поле оставлено пустым, будет использоваться встроенный номер телефона.',
      SMS_SELECT_TEMPLATE: 'SMS - выбрать шаблон',
      TEMPLATE_MUST_BE_SELECTED: 'Должен быть выбран шаблон',
      SMS_SENT: 'СМС отправлено',
      FROM_ID: 'От ID',
      SMS_TEMPLATE: 'Шаблон SMS',
      SMS_SEND_TO: 'SMS отправить на',
      EMAIL_TEMPLATE: 'Email-шаблон'
    },
    SETTINGS: {
      PAYMENT_DAYS: 'Срок оплаты - дни после создания счета',
      ENABLED_EVENT_DINTERO: 'Активировать онлайн-платежи для мероприятий',
      ENABLE_SELECT_PAYMENT: 'Активировать новый выбор платежа',
      UNABLE_GET: 'Не удалось получить настройки организации',
      LOGIN: 'Вход',
      ALERT_INFO_WILL_REG: 'Если организация отображается при регистрации и выбрана, она будет зарегистрирована как членство для участника',
      WILL_REG: 'Создать членство в этой организации, если выбрано',
      CREATE_MEMBERSHIP: 'Создать членство',
      DISPLAY_DURING_REGISTRATION: 'Показать при регистрации',
      SETTINGS: 'Настройки организации',
      UPDATED: 'Настройки обновлены',
      UNABLE_GET: 'Не удалось получить настройки',
      UNABLE_UPDATE: 'Не удалось обновить настройки',
      IS_FAITH: 'Это религиозное объединение',
      FEE_PCT: 'Процент взноса (%)',
      ADM_FEE: 'Административный взнос (SEK)',
      MEM_PCT: 'Процент взноса с члена (%)',
      MEM_FEE: 'Взнос на члена (SEK)',
      PUBLIC: 'Общественный',
      LINKS: 'Ссылки',
      REGISTRATION: 'Регистрация',
      BANK: 'Банк',
      MEMBER_PROFILE: 'Профиль члена',
      PAYMENT: 'Оплата',
      STRIPE: 'Stripe',
      FORTNOX: 'Fortnox',
      API_CONNECTION: 'API-соединение',
      EVENTS: 'События',
      EDUCATIONS: 'Образование',
      FAITH: 'Община',
      MEMBER_REQUIREMENTS: 'Требования к членству',
      MEMBER_TYPES: 'Типы членства',
      EXTRA_DATA: 'Дополнительные данные',
      REQUIRES_HTTP: 'Должно содержать http:// или https://',
      MEMBER_REG_PAGE: 'Страница регистрации членов',
      MEMBER_LOGIN_PAGE: 'Страница входа для членов',
      USER_LOGIN_PAGE: 'Страница входа для пользователей',
      PUBLIC_EVENTS_PAGE: 'Публичный список событий',
      MEMBER_LOGIN_ORG_PAGE: 'Страница входа для членов всех организаций',
      MEMBER_REQ_PERSONNR: 'Дата рождения',
      MEMBER_REQ_SSN: 'Шведский персональный номер',
      MEMBER_REQ_AGE: 'Возраст',
      MEMBER_REQ_SEX: 'Пол',
      MEMBER_REQ_ADDRESS: 'Адрес (улица, индекс, город, страна)',
      MEMBER_REQ_FULLNAME: 'Имя/Фамилия',
      MEMBER_REQ_EMAIL: 'Электронная почта',
      MEMBER_REQ_PHONE: 'Номер телефона',
      MEMBER_REQ_EMAILPHONE: 'Электронная почта или мобильный телефон',
      MEMBER_REQ_PASSWORD: 'Пароль',
      MEMBER_REQUIREMENTS_INFO: 'Все выбранные ниже категории будут обязательными, если они выбраны во всех аспектах, где категории отображаются.',
      EMAILS: 'Электронные адреса',
    },
    RENEWS: {
      SMS_COUNT_INFO: 'Количество рассчитывается исходя из размера шаблона, умноженного на количество получателей',
      SELECT_SENDOUT_METHOD: 'Выберите методы отправки',
      MANUAL: 'Создать новые',
      RENEWALS: 'Продления',
      RENEW_MEMBERSHIPS: 'Продлить членство',
      NEXT_PERIOD_NOT_SET: 'Следующий период не установлен',
      CREATE_TO: 'Создать продление на период ',
      TO_PERIOD: 'На период',
      TO_VFDT: 'Дата начала',
      SENDOUT_METHOD: 'Отправка через',
      TIME_SENT: 'Время отправки',
      DELETED: 'Продление удалено',
      UNABLE_DELETE: 'Не удалось удалить продление',
      ACTIVE: 'Ожидает ответа',
      STARTED_LINK: 'Начато участником',
      INVOICED: 'Счет выставлен',
      DELETED: 'Отменено',
      DONE: 'Завершено',
      SCHEDULED: 'Запланировано',
      AWAITING_PAYMENT: 'Не оплачено',
      TIME_OUT: 'Не отвечено',
      LINK_SENT_EMAIL: 'Ссылка для продления отправлена по электронной почте.',
      UNABLE_LINK_SENT_EMAIL: 'Не удалось отправить ссылку для продления по электронной почте',
      NEXT_PERIOD_ERROR: 'Система неправильно установила следующий период. Перейдите в Периоды и убедитесь, что следующий период установлен',
      UNABLE_CREATE: 'Не удалось создать продление',
      UNABLE_LIST: 'Не удалось получить список продлений',
      UNABLE_GET: 'Не удалось получить продление',
      SEND_SMS: 'Отправить SMS',
      SEND_EMAIL: 'Отправить e-mail',
      CONFIRM_SEND_SMS: 'Вы уверены, что хотите отправить SMS? Будет взиматься плата.',
      CONFIRM_SEND_EMAIL: 'Вы уверены, что хотите отправить e-mail?',
      CLICK_TO_SEND_SMS: 'Нажмите, чтобы вручную отправить ссылку для продления через SMS',
      CLICK_TO_SEND_EMAIL: 'Нажмите, чтобы вручную отправить ссылку для продления по электронной почте',
      SMS_SEND_FAIL: 'Ошибка отправки SMS',
      SMS_DID_SEND: 'SMS отправлено',
      SMS_TRY_SEND: 'SMS будет отправлено',
      SMS_DONT_SEND: 'SMS не будет отправлено',
      EMAIL_SEND_FAIL: 'Ошибка отправки e-mail',
      EMAIL_DID_SEND: 'E-mail отправлено',
      EMAIL_TRY_SEND: 'E-mail будет отправлено',
      EMAIL_DONT_SEND: 'E-mail не будет отправлено',
      REMINDER_SMS_SEND_AT: 'Напоминание по SMS будет отправлено',
      REMINDER_EMAIL_SEND_AT: 'Напоминание по электронной почте будет отправлено',
      REMINDER_NO_MORE_SMS: 'Больше напоминаний по SMS отправлено не будет',
      REMINDER_NO_MORE_EMAIL: 'Больше напоминаний по электронной почте отправлено не будет',
      SUMMARY_1: 'Продление создано',
      SUMMARY_2: 'со статусом',
      SUMMARY_3: 'и статусом оплаты',
      CREATE: 'Создать продление',
      ACTIVATE_AUTORENEW: 'Активировать автопродление',
      SEND_TO_PARENT: 'Отправить на электронную почту опекуна, если он указан у участника',
      RENEW_TEMPLATE: 'Шаблон продления',
      RENEW_TEMPLATE_SMS: 'Шаблон продления SMS',
      SEND_AT_TIME: 'Отправить в определенное время',
      CREATE_RENEW_AT: 'Когда продление должно быть создано',
      BEFORE_LAST_DAY: 'До последнего дня',
      AFTER_LAST_DAY: 'После последнего дня',
      NUM_DAYS: 'Количество дней',
      EMAIL_JOB: 'Отправка электронной почты',
      SMS_JOB: 'Отправка SMS',
      CREATE_INVOICES: 'Создать счета',
      CONFIRM_EMAIL: 'Вы уверены, что хотите отправить e-mail для выбранных продлений?',
      CONFIRM_SMS: 'Вы уверены, что хотите отправить SMS для выбранных продлений?',
      CREATED_TO: 'Создано до',
      CREATED_FROM: 'Создано от',
      UNABLE_COUNT: 'Не удалось посчитать',
      UNABLE_GENERATE_INVOICES: 'Не удалось создать счета',
      INVOICE_JOB_CREATED: 'Создано задание по выставлению счетов',
      INVOICES_GENERATED: 'Счета созданы',
      NO_INVOICES_GENERATED: 'Счета не созданы',
      SELECT_AT_LEAST_ONE: 'Выберите хотя бы одно продление',
      HAS_EMAIL: 'Есть электронная почта',
      MISSING_EMAIL: 'Отсутствует электронная почта',
      ALL_ACTIVE_RENEWS: 'Все активные продления на период',
      SELECTED_IN_TABLE: 'Выбранные продления в таблице',
      JOB_WILL_COST: 'Эта операция предварительно оценивается в',
      NUM_ESTIMATED: 'Количество оценено',
      NUMBER_SENT: 'Количество отправлено',
      UNABLE_CALC_SMS: 'Не удалось рассчитать стоимость SMS',
      NO_SELECTION: 'Отсутствует выбор',
      HANDLE_SELECTED_MEMBERS: 'Управление участниками в результатах поиска',
      LAST_RUN: 'Последний запуск',
      RENEW_INTO: 'Продлить в период',
      NUM_REMIND: 'Количество напоминаний',
      DBR: 'Количество дней до отправки напоминания',
      NUMBER_ESTIMATED: 'Предполагаемое количество',
      NUMBER_RENEWS: 'Количество продлений',
      NUMBER_SMS_IN_TEMPLATE: 'Количество SMS в шаблоне',
      SMS_EXTRA_INFO: 'Внимание! Число является оценкой. Переменные в шаблоне не могут быть рассчитаны точно.',
      USE_TEMPLATE_EMAIL: 'Использовать шаблон e-mail',
      USE_TEMPLATE_SMS: 'Использовать шаблон SMS',
      SEND_VIA_EMAIL: 'Отправить по электронной почте',
      SEND_VIA_SMS: 'Отправить через SMS',
      IS_CONFIRM: 'Только подтверждение (бесплатное членство)'
    },
    SEARCH: {
      CLEAR_SELECTED_SHOPITEMS: 'Очистить членство',
      NUM_SELECTED_SHOPITEMS: 'Количество выбранных членств',
      MORE_FILTERS: 'Больше фильтров',
      MAKE_A_SEARCH: 'Выполнить поиск',
      MAKE_A_SELECTION: 'Сделать выбор',
      CONFIRM_SELECTED_SHOPITEMS: 'Подтвердить выбор',
      SELECT_SHOPITEMS: 'Найти членство',
      ADVANCED_SEARCH: 'Расширенный поиск',
      ADVANCED_INFO: 'Расширенная функция поиска, сохранение выборки и экспорт в Excel',
      SELECTION_NOT_FOUND: 'Не удалось выбрать параметры поиска',
      UNABLE_SEARCH: 'Не удалось выполнить поиск',
      SELECT_SEARCH: 'Выбрать поиск',
      NUM_MEMBER_FOUND: 'Найдено: ',
      NO_SAVED: 'Нет сохраненных поисков',
      SELECT_MEMBERS_PERSONS: 'Выбрать членов или лиц',
      DELETED: 'Поиск удален',
      UNABLE_DELETE: 'Не удалось удалить поиск',
      SELECT: 'Выбрать поиск',
      MAKE_SELECTION: 'Выбрать получателей',
      DO_SEARCH: 'Выполнить поиск',
      MEMBERSHIP_FOR_DATE: 'Членство существует на дату',
      MEMBERSHIP_FROM: 'Дата начала членства',
      MEMBERSHIP_TO: 'Дата окончания членства',
      SEARCH_MEMBERS: 'Поиск членов',
      ONLY_MISSING_EMAIL: 'Только члены без электронной почты',
      SEARCH_USERS: 'Поиск пользователей',
      ONLY_WITH_EMAIL: 'Только члены с электронной почтой (как для счета, так и для основной почты)',
      ONLY_IN_STICKPROV: 'Только члены в выборке',
      ONLY_IN_PERIOD: 'Только члены на период',
      ONLY_WITH_ERRORS: 'Только члены с ошибками',
      ONLY_MISSING_PHONE: 'Только члены без телефона',
      ONLY_MISSING_MEMBERSHIP: 'Только члены без членства',
      SEARCH_WITH_EVENT: 'Поиск с мероприятием',
      SAVE_SEARCH: 'Сохранить поиск как',
      SAVED_NAME: 'Имя сохраненного поиска',
      CREATE_SELECTION: 'Создать выборку/список получателей',
      LACK_COMPANY_PERMISSION: 'У вас нет доступа к организации',
      MUST_SELECT: 'Вы должны выбрать поиск/выборку',
      SEARCH_COUNT: 'Выполнить поиск для подсчета количества',
      COUNT_SEARCHED: 'Количество в поиске',
      SEARCH_CREATED: 'Поиск создан',
      UNABLE_CREATE: 'Не удалось создать поиск',
      UNABLE_LIST_REGIONS: 'Не удалось получить список регионов',
      PAYMENT_STATUS: 'Статус оплаты',
      SEARCH_ATTRIBUTE: 'Поиск с атрибутом',
      NO_ATTRIBUTE: 'Нет атрибута',
      TO_AGE: 'До возраста',
      FROM_AGE: 'От возраста',
      SEARCHED: 'Поиск выполнен',
      UNABLE: 'Не удалось выполнить поиск',
      USER_SEARCH_INFO: 'Поиск пользователей по названию',
      USERS_AS_DIRECTORS: 'Пользователи, являющиеся директорами',
      USERS_AS_ACCOUNTANTS: 'Пользователи, являющиеся бухгалтерами',
      UNABLE_LIST_TITLES: 'Не удалось получить список должностей',
      REG_BEFORE: 'Первая регистрация до даты',
      REG_AFTER: 'Первая регистрация после даты'
    },
    EVENTS: {
      TICKET_NOT_IN_EVENT: 'Приобретенный билет не найден на мероприятии',
      PARTICIPANT_MISSING: 'Участник отсутствует',
      TICKET_MISSING: 'Билет отсутствует',
      CHANGE_EVENT: 'Изменить мероприятие',
      EVENT: 'Мероприятие',
      FREE: 'Бесплатно',
      UNABLE_JOIN_ANY_MEMBER: 'Для участия в мероприятии требуется членство в любой организации',
      UNABLE_JOIN_COMPANY_MEMBER_PAID: 'Для участия в мероприятии требуется активное/оплаченное членство в организации',
      UNABLE_JOIN_ANY_MEMBER_PAID: 'Для участия в мероприятии требуется активное/оплаченное членство',
      UNABLE_JOIN_MAX_TICKETS: 'Вы уже приобрели максимально допустимое количество билетов',

      TIP_URL_FORWARD: 'URL, на который будет перенаправлен участник после регистрации на мероприятие',
      HEADER_URL_FORWARD: 'URL-перенаправление после регистрации на мероприятие',

      TIP_ENABLED_EVENT_DINTERO: 'Билет будет отправлен после оплаты',
      HEADER_ENABLED_EVENT_DINTERO: 'Оплата билета',
      CHECKBOX_ENABLED_EVENT_DINTERO: 'Требуется оплата билета',
      CHECKBOX_SEND_CONFIRM: 'Отправить подтверждение по электронной почте участнику при регистрации',
      HEADER_SEND_CONFIRM: 'Отправить подтверждение',
      TIP_SEND_CONFIRM: 'При активации этой опции подтверждающее письмо будет отправлено участнику и администратору после регистрации.',
      CHECKBOX_SUBEVENTS: 'Показать мероприятия дочерних организаций в списке мероприятий',
      HEADER_SUBEVENTS: 'Список мероприятий от дочерних организаций',
      TIP_SUBEVENTS: 'Активные мероприятия, созданные в дочерних организациях, будут отображаться в публичном списке мероприятий.',
      ACCEPT_TERMS: 'Я прочитал и согласен с условиями',
      ACCOUNTING: 'Бухгалтерия',
      ADDRESS: 'Адрес',
      ADDRESS_UNAVAILABLE: 'Адрес недоступен',
      ADD_PARTICIPANT: 'Добавить участника',
      ADD_TICKET: 'Добавить билет',
      BAN_FOR_NUM_DAYS: 'Запрет на количество дней',
      BAN_ORGS_WITH_NUM_MEMBERS_BANNED: 'Запретить организацию с участниками, имеющими количество предупреждений',
      CATEGORIES: 'Категории',
      CATEGORY: 'Категория',
      CHECKEDIN: 'Зарегистрированные',
      CHECKED_IN: 'Зарегистрирован',
      CHOOSE_TICKETS: 'Выбрать билеты',
      CLOSE_SIGNUP: 'Закрыть регистрацию',
      COMMUNICATION: 'Связь',
      CONFIRM_DELETE: 'Вы уверены, что хотите удалить мероприятие?',
      COST_CENTER: 'Центр затрат',
      CREATE_OR_CHOOSE: 'Создать или выбрать существующее',
      CREATED_EMPTY: 'Создано пустое мероприятие',
      DATE_TIME: 'Дата и время',
      DELETED: 'Мероприятие удалено',
      DETAILS: 'Детали',
      EDIT_TICKET: 'Редактировать билет',
      EMAIL_TEMPLATE: 'Шаблон письма для билета',
      EMAIL_TEMPLATE_WARN: 'Шаблон письма для предупреждения',
      EMAIL_TICKET: 'На ваш адрес электронной почты будет отправлено письмо с билетом, который, возможно, потребуется предъявить для участия в мероприятии.',
      END_TIME: 'Время окончания',
      ERROR_SIGN_UPP: 'Не удалось зарегистрироваться на мероприятие',
      ERROR_TRY_LATER: 'Произошла ошибка, попробуйте позже',
      EVENTS: 'Мероприятия',
      EVENT_FULL: 'Мероприятие заполнено',
      EVENT_NAME: 'Название мероприятия',
      EVENT_NOT_FULL: 'В мероприятии есть свободные места',
      EVENT_TIMESTAMPS: 'Временные метки',
      EXAMPLE_LAT: 'Пример: 121.11',
      EXAMPLE_LNG: 'Пример: 93.23',
      FACEBOOK_INFO: 'Подробнее о мероприятии на Facebook',
      FACEBOOK_URL: 'URL Facebook',
      GENERATE_GOOGLE_MAPS: 'Создать Google Maps',
      GENERATE_GOOGLE_MAPS_ALERT: 'Google Maps не создан для этого мероприятия. Нажмите кнопку ниже ПОСЛЕ того, как введете адрес, индекс и город.',
      GEO_COORDS: 'Геокоординаты',
      INFO_PARTICIPATION: 'Вы не можете изменить зарегистрированного участника мероприятия. Но вы можете изменить контактные данные участника.',
      INTERNAL_ERROR: 'Внутренняя ошибка',
      INVOICE_RECIPIANT_TYPE: 'Тип получателя счета',
      IRL_EVENT_TEXT: 'Это физическое мероприятие, создайте Google Maps.',
      LAST_SIGNUP_DATETIME: 'Последняя дата и время регистрации',
      LINKS: 'Ссылки',
      LOCATION: 'Место проведения',
      LOCATION_DESCRIPTION: 'Описание места (необязательно)',
      LOCATION_FOR_EVENT: 'Место проведения мероприятия',
      MAXIMUM_ORDER: 'Максимальное количество заказов',
      MAXIMUM_TICKETS: 'Макс. {max_tickets} билетов на человека',
      MAX_SIGNUPS: 'Количество мест',
      MAX_TICKETS_PER_PERSON: 'Максимальное количество билетов на человека',
      MEMBERS_ONLY: 'Только для членов',
      MEMBER_PERSON_ADDED: 'Член/участник добавлен на мероприятие',
      MISSING_FIELDS: 'Отсутствуют поля',
      MUST_ACCEPT: 'Регистрация должна быть с принятием условий',
      MUST_FILL_ADDRESS: 'Вы должны сначала заполнить адресную информацию',
      NEW_EVENT: 'Новое мероприятие',
      NEW_TICKET: 'Новый билет',
      NUM_SEATS: 'Количество мест',
      NUM_SIGNUPS: 'Зарегистрированные',
      ONLINE: 'Онлайн/Интернет',
      OPEN_FOR_ALL: 'Открыто для всех',
      ANY_MEMBER: 'Требуется активное или истекшее членство в любой организации в системе',
      COMPANY_MEMBER_PAID: 'Требуется оплаченное членство в организации, создающей мероприятие',
      ANY_MEMBER_PAID: 'Требуется оплаченное членство в любой организации в системе',
      OTHER_LINK: 'Другая ссылка',
      PARTICIPANTS: 'Участники',
      PARTICIPANT_TYPE: 'Тип участника',
      PERM_BAN_AFTER_NUM_WARN: 'Постоянный запрет после количества предупреждений',
      POSTAL_ADDRESS: 'Почтовый адрес',
      PREVIOUS_EVENTS: 'Предыдущие мероприятия',
      PUBLISH_OPTIONS: 'Опции публикации',
      READ_MORE: 'Подробнее',
      REGISTERED: 'Вы зарегистрированы',
      REMOVED_FROM_EVENT: 'Удален из мероприятия',
      REMOVE_PARTICIPANT: 'Удалить участника',
      REMOVE_PARTICIPANT_TEXT: 'Вы уверены, что хотите удалить участника?',
      SAME_AS_PARTICIPANT: 'Тот же адрес, что и у участника',
      SAVE_EVENT: 'Сохранить мероприятие',
      SEARCH_NAME: 'Поиск имени или электронной почты',
      SEARCH_SELECT_MEMBER: 'Найти и выбрать члена',
      SEARCH_SELECT_PERSON: 'Найти и выбрать лицо',
      SELECT_TICKET_TEMPLATE: 'Вы должны выбрать шаблон электронной почты для билета',
      SEND_EMAIL: 'Отправить электронное письмо администратору при новой регистрации',
      SEND_INVITATION: 'Отправить приглашение всем участникам',
      SEND_INVITATION_TIME: 'Отправить приглашение в определенное время',
      SEND_SMS_INVITATION: 'Отправить SMS-приглашение всем участникам',
      SEND_TICKET: 'Отправить билет',
      SEND_TICKET_AGAIN: 'Отправить билет снова',
      ERROR_SEND_TICKET: 'Не удалось отправить билет',
      SUCCESS_SEND_TICKET: 'Билет отправлен',
      SIGNUPED: 'Зарегистрированные',
      SIGNUPS: 'Регистрация',
      SIGNUP_DATE_EXPIRED: 'Дата регистрации истекла',
      SIGN_UP: 'Зарегистрироваться',
      SMS_TEMPLATE_INVITATION: 'SMS для приглашения',
      START_TIME: 'Время начала',
      STREET_AND_POST: 'Улица и город',
      STRIKE_WARN: 'Выдать предупреждение о неявке',
      SUCCESS_GOOGLE_MAPS: 'Google Maps создан',
      TAGS: 'Теги',
      TERMS: 'Условия',
      TERMS_TEXT: 'Текст условий',
      TICKET: 'Билет',
      TICKETS: 'Билеты',
      TICKET_CONNECTED: 'Билет добавлен',
      TICKET_DELETED: 'Билет удален',
      TICKET_SELECT_ERROR: 'Билет уже отправлен зарегистрированным, вы не можете его изменить.',
      TICKET_SEND: 'Отправить билет, когда участник добавлен',
      TYPE_OF_TICKET: 'Тип билета',
      UNABLE_ADD_MEMBER_PERSON: 'Не удалось добавить члена/участника на мероприятие',
      UNABLE_CONNECT_TICKET: 'Не удалось добавить билет',
      UNABLE_CREATE: 'Не удалось создать мероприятие',
      UNABLE_DELETE: 'Не удалось удалить мероприятие',
      UNABLE_DELETE_CONFLICT: 'Не удалось удалить, так как есть зарегистрированные, сначала удалите всех зарегистрированных.',
      UNABLE_DELETE_TICKET: 'Не удалось удалить билет',
      UNABLE_GET: 'Не удалось получить мероприятие',
      UNABLE_GOOGLE_MAPS: 'Не удалось создать Google Maps',
      UNABLE_LIST: 'Не удалось получить список мероприятий',
      UNABLE_LIST_PARTICIPANTS: 'Не удалось получить список участников',
      UNABLE_LIST_TICKETS: 'Не удалось получить список билетов',
      UNABLE_REMOVE_PARTICIPANT: 'Не удалось удалить участника из мероприятия',
      UNABLE_UPDATE: 'Не удалось обновить мероприятие',
      UPDATED: 'Мероприятие обновлено',
      VAT_ACCOUNT: 'НДС счет',
      VISIT_WEBPAGE: 'Посетите этот веб-сайт, чтобы узнать больше о мероприятии',
      ZIPCODE: 'Почтовый индекс',
      INACTIVE_MEMBERSHIP: 'Пожалуйста, обновите свое членство и попробуйте снова или свяжитесь с поддержкой членов организации.',
      MEMBERS_ONLY: 'Требуется вход для членов',
      EVENT_ONLINE: "Онлайн/Интернет",
      STATUS: 'Статус',
      IS_CLOSED: 'Закрыто/Дата начала прошла',
      IS_OPEN: 'Открыто'
    },
    EVENT_CATEGORY: {
      CONFIRM_DELETE: 'Вы уверены, что хотите удалить категорию?',
      UNABLE_GET: 'Не удалось получить категорию',
      UNABLE_LIST: 'Не удалось перечислить категории',
      CREATED: 'Категория создана',
      UPDATED: 'Категория обновлена',
      DELETED: 'Категория удалена',
      UNABLE_CREATE: 'Не удалось создать категорию',
      UNABLE_UPDATE: 'Не удалось обновить категорию',
      UNABLE_DELETE: 'Не удалось удалить категорию',
      NEW: 'Новая категория',
      EDIT: 'Редактировать категорию',
      NON_PUBLIC_NAME: 'Название (не публичное)',
      LINK: 'Ссылка',
      HEADER: 'Заголовок',
      FORWARD_SUCCESS: 'URL после успешной регистрации',
      URL_INFO: 'http:// или https:// должен быть включен',
      PAGE_TEXT: 'Текст страницы',
      LOWEST: 'Низкая сортировка',
      HIGHEST: 'Высокая сортировка'
    },
    TERMS: {
      CREATED_AT: 'Создан',
      ACTIVE: 'Активен',
      NAME: 'Имя',
      TERMS: 'Условия использования',
      ACCEPT: 'Принять условия',
      MISSING: 'Условия отсутствуют',
      NEW: 'Новые условия',
      TERMS_TEXT_INFO: 'Этот текст будет отображаться рядом с чекбоксом. Текст условий/политики будет отображаться во всплывающем окне при нажатии на текст между тегами %. Пример: Нажмите %здесь%, чтобы просмотреть условия использования.',
      CHECKBOX_TEXT: 'Текст чекбокса',
      TEXT: 'Текст условий',
      UNABLE_GET: 'Не удалось получить условия',
      UPDATED: 'Условия обновлены',
      UNABLE_UPDATE: 'Не удалось обновить условия',
      CREATED: 'Условия созданы',
      UNABLE_CREATE: 'Не удалось создать условия',
      DELETED: 'Условия удалены',
      UNABLE_DELETE: 'Не удалось удалить условия'
    },
    BOOKING: {
      CREATED: 'Бронирование создано',
      SEND: 'Подтверждение бронирования будет отправлено на ваш адрес электронной почты',
      CONFIRM_BOOKING: 'Подтвердить бронирование',
      SELECT_BOOKING: 'Выберите дату и место курса ниже'
    },
    QUICKSEARCH: {
      UNABLE_SEARCH: 'Не удалось найти участника',
      UNABLE_SEARCH_PERSON: 'Не удалось найти человека',
      SEARCH_TERMS: 'Имя, номер, электронная почта, телефон...',
      QUICKSEARCH_INFO: 'Быстрый поиск участников по всем организациям и периодам'
    },

    MEMBER_PROPERTY: {
      HEADER_GROUP_ATTRIBUTES: 'Атрибуты в категории',
      NEW_ATTRIBUTE: 'Новый атрибут',
      EDIT_ATTRIBUTE: 'Редактировать атрибут',
      NAME: 'Название',
      PLACEHOLDER_DESCR: 'Описание атрибута',
      DESCR: 'Описание',
      CONFIRM_DELETE: 'Вы уверены, что хотите удалить категорию атрибутов?'
    },
    SYSTEM: {
      INSTANCE_TIMEOUT_TEMPLATE_ID: 'Мониторинг: Шаблон таймаута',
      INSTANCE_RETURN_TEMPLATE_ID: 'Мониторинг: Шаблон возврата',
      ADMIN_INQUIRY_TEMPLATE_ID: 'Новый запрос клиента: Администратор',
      CUSTOMER_CONFIRM_INQUIRY_TEMPLATE_ID: 'Новый запрос клиента: Подтверждение клиенту'
    },
    DUPLICATES: {

      NUM_DUPLICATES: 'Translate: Antal dubbletter',

      MERGE_INFO1: 'Данные участников, которые не сохранятся, будут удалены',
      MERGE_INFO2: 'Все участники в таблице ниже будут объединены для каждой строки',
      MERGE_INFO3: 'Участники, которые перемещаются к сохраняемому участнику, будут проверены на наличие дубликатов. Участники с одним и тем же периодом, но разными организациями, сохранятся. Участники с одинаковым периодом и организацией будут рассмотрены согласно параметрам "Членство".',
      MERGE_INFO4: 'Обратите внимание, что участники будут удалены после запуска этой операции.',

      MEMBER_SKIP_OPTIONS: 'Исключить участников из операции, если отсутствуют следующие данные:',
      MEMBER_SKIP_OPTION1: 'Имя',
      MEMBER_SKIP_OPTION2: 'Фамилия',
      MEMBER_SKIP_OPTION3: 'Электронная почта',
      MEMBER_SKIP_OPTION4: 'Телефон',
      MEMBER_SKIP_OPTION5: 'Номер',

      MEMBERSHIP_MERGE_OPTIONS: 'Членство',
      MEMBERSHIP_MERGE_OPTION1: 'Сохранить самое новое членство при пересечении периодов',
      MEMBERSHIP_MERGE_OPTION2: 'Сохранить самое старое членство при пересечении периодов',
      MEMBERSHIP_MERGE_OPTION3: 'Сохранить оплачиваемое членство при пересечении периодов',

      MEMBER_DATA_OPTIONS: 'Целевой участник',
      MEMBER_DATA_OPTION1: 'Сохранить самого нового участника',
      MEMBER_DATA_OPTION2: 'Сохранить самого старого участника',

      TRANSACTION_OPTIONS: 'Транзакционные данные',
      TRANSACTION_OPTION1: 'Сохранить только транзакции целевого участника',
      TRANSACTION_OPTION2: 'Сохранить транзакции всех участников'
    },
    USER_PERMISSIONS: {
      UPDATED: 'Права пользователя обновлены',
      UNABLE_UPDATE: 'Не удалось обновить права пользователя',
      UNABLE_LIST: 'Не удалось перечислить права пользователей'
    },

    EXAMPLES: {
      EDITOR: 'Редактор',
      TABLE: 'Таблица',
      NAME: 'Имя',
      NAME2: 'Имя2',
      DATE: 'Дата',
      NEW: 'Новый пример',
      WELCOME_ADMIN: 'Добро пожаловать, администратор!',
      ON_SUBMIT: 'Форма сохранена'
    },

    MEMBER_DETAILS: {
      PHONE: 'Мобильный телефон',
      LANDLINE: 'Домашний телефон',
      YYYY: 'ГГГГ',
      INVALID_PHONE: 'Неверный номер телефона',
      INVALID_CO: 'Имя должно содержать не менее двух символов',
      INVALID_DOB: 'Неверная дата рождения',
      INVALID_EMAIL: 'Неверный адрес электронной почты',
      INVALID_EMAIL2: 'Адреса электронной почты не совпадают',
      INVALID_PASSWORD: 'Пароль должен содержать 6-30 символов',
      INVALID_PASSWORD2: 'Пароли не совпадают',
      TOOLTIP_FIRSTNAME: 'Поле для имени и возможных вторых имен.',
      TOOLTIP_LASTNAME: 'Поле для фамилии.',
      TOOLTIP_COMPANY: 'Название компании.',
      TOOLTIP_DOB: 'Дата рождения в формате год, месяц, день. Например, 19880120.',
      TOOLTIP_INTDOB: 'Дата рождения в формате месяц, день и год.',
      TOOLTIP_ADDRESS: 'Улица и номер дома.',
      TOOLTIP_ZIPCODE: 'Почтовый индекс, состоящий из пяти символов, например, 12345.',
      TOOLTIP_POST: 'Город, связанный с почтовым индексом.',
      TOOLTIP_CO: 'Поле, используемое в адресах при отправке письма или посылки через другого человека или компанию.',
      TOOLTIP_COUNTRY: 'Страна, к которой относится адрес',
      TOOLTIP_REGION: 'Географический регион, связанный с адресом. Может автоматически определяться по почтовому коду.',
      TOOLTIP_COMMUNE: 'Географический муниципалитет, связанный с адресом. Может автоматически определяться по почтовому коду.',
      TOOLTIP_PHONE: 'Номер мобильного телефона для этого аккаунта, должен содержать код страны, например, +46701234567.',
      TOOLTIP_LANDLINE: 'Домашний телефон, включая код города.',
      TOOLTIP_EMAIL: 'Действующий адрес электронной почты, связанный с этим аккаунтом, должен содержать символ @ и точку.',
      TOOLTIP_EMAIL2: 'Подтвердите адрес электронной почты, введя его еще раз.',
      TOOLTIP_PASSWORD: 'Пароль для этого аккаунта, должен содержать 6-30 символов.',
      TOOLTIP_PASSWORD2: 'Подтвердите пароль, введя его еще раз.',
      TOOLTIP_GENDER: 'Выберите пол.',
      TOOLTIP_AVD: 'Укажите, к какому отделению вы принадлежите.'
    },

    IMPORT: {
      WAIT_WHILE_AI_MAPPING: 'Пожалуйста, подождите, наш ИИ сопоставляет ваши данные с нашей',

      TIP_PARENT_COMPANY: 'Организации, у которых отсутствует родительская организация в Excel, будут добавлены как дочерние организации к этой.',
      PARENT_COMPANY: 'Родительская организация',
      TIP_TO_PERIOD: 'Участники будут импортированы в этот период.',
      TO_PERIOD: 'В период',
      TO_COMPANY: 'В организацию',
      TIP_TO_COMPANY: 'Участники, у которых отсутствует номер организации в записях, будут импортированы в эту организацию.',
      TIP_PRIMARY_KEY: 'Укажите колонку, идентифицирующую запись. Она должна быть уникальной для каждой записи. Если записи имеют одинаковый ключ, они будут объединены в одну.',
      PRIMARY_KEY: 'Первичный ключ',
      VERIFY_FIELDS: 'Проверить поля импорта',
      SKIP_ALL: 'Пропустить все',
      ROW: 'Строка',
      CELL_HEADER: 'Заголовок',
      CELL_NUMBER: 'Номер ячейки',
      MESSAGE: 'Сообщение',
      VALIDATION_ERROR: 'Произошла ошибка при проверке импорта',
      CREATE: 'Создать новый',
      FILE_REQUIREMENTS_INFO: 'Перед загрузкой файла XLSX для импорта убедитесь, что выполнены следующие требования:',
      FILE_REQUIREMENTS_1: 'Файл должен быть <strong>XLSX</strong> формата 2007 года.',
      FILE_REQUIREMENTS_2: 'Все столбцы/ячейки должны быть отформатированы как текст/строки.',
      FILE_REQUIREMENTS_3: 'Файл <strong>должен как минимум</strong> содержать столбцы с номерами участников, номерами организаций и идентификаторами магазинов.',
      FILE_REQUIREMENTS_4: 'Убедитесь, что даты отформатированы как: <strong>ГГГГ-ММ-ДД</strong>. Другие форматы будут импортированы некорректно.',
      FILE_REQUIREMENTS_5: 'Номер должен содержать 12 цифр в формате: ГГГГММДД-1234.',
      FILE_REQUIREMENTS_6: 'Некоторые значения не могут превышать определенное количество символов. <a href="https://memlist.se/docs/importera-medlemmar-genom-excel/">На этой странице</a> есть информация о максимальной длине для каждого столбца.',
      FILE_REQUIREMENTS_7: 'Регионы и муниципалитеты следуют кодам налоговой службы.',
      FILE_REQUIREMENTS_8: 'Телефонные номера должны содержать код страны, например: +4671234567.',
      EXCEL_FIELD: 'Поле Excel',
      MEMLIST_FIELD: 'Поле Memlist',
      COLUMN: 'Столбец',
      FILE_READ_ERROR: 'Не удалось прочитать файл',
      OPTIONS: 'Опции импорта',
      MAP_COLUMNS: 'Сопоставить столбцы',
      VALIDATION: 'Валидация',
      FINALIZE: 'Импортировать',
      NOT_FOUND: 'Не удалось получить импорт',
      ERROR: 'Произошла ошибка',
      TAB_INFO_HEADER: 'Информация об импорте',
      CREATED_AT: 'Создан',
      BUTTON_MAP_COLUMNS: 'Сопоставить столбцы',
      BUTTON_VALIDATE: 'Проверить',
      BUTTON_FINALIZE: 'Завершить',
      ACTIVE: 'Активные импорты',
      HISTORY: 'История импорта',
      STATUS: 'Статус',
      NEW_IMPORT: 'Новый импорт',
      FILE: 'Файл импорта',
      VALIDATION_100_ROWS: 'Из соображений оптимизации проверяются только первые 100 строк файла импорта.',
      UPLOAD_FILE: 'Загрузить файл',
      INVALID_FILE: 'Недействительный файл',
      TO_PERIOD: 'В период',
      CREATED_BY: 'Создано',
      TAB_FINALIZE_HEADER: 'Завершить импорт',
      TAB_INFO_HEADER: 'Информация об импорте',
      TAB_MAP_COLUMNS_HEADER: 'Сопоставить столбцы',
      TAB_VALIDATION_HEADER: 'Проверка файла',
      BUTTON_FINALIZE: 'Завершить',
      BUTTON_VALIDATE: 'Проверить',
      BUTTON_MAP_COLUMNS: 'Сопоставить столбцы',
      UNABLE_DELETE: 'Не удалось удалить',
      COUNT_MAPPINGS: 'Количество сопоставленных столбцов',
      FILENAME: 'Имя файла',
      RUNNING_VALIDATION: 'Идет проверка импорта...',
      DONE_VALIDATION: 'Проверка завершена',
      FAILURE_VALIDATION: 'Проверка не завершена',
      READY_TO_VALIDATE: 'Готово к проверке',
      SUCCESS_FINALIZE: 'Импорт завершен!',
      FAILURE_FINALIZE: 'Не удалось завершить импорт',
      RUNNING_FINALIZE: 'Завершение импорта...',
      READY_TO_FINALIZE: 'Импорт готов к завершению!',
      FINALIZE_IMPORT: 'Завершить импорт',
      UNABLE_UPLOAD: 'Не удалось загрузить файл',
      UNABLE_UPDATE: 'Не удалось обновить импорт',
      CONTINUE: 'Продолжить',
      STATUSES: {
        WAIT_MAP_COLUMNS: 'Сопоставить столбцы',
        FAIL_VALIDATION: 'Ошибка валидации',
        FAIL_FINALIZE: 'Ошибка импорта',
        DONE_VALIDATION: 'Проверка завершена',
        DONE_MAP_COLUMNS: 'Сопоставление столбцов завершено',
        WAIT_VALIDATION: 'Необходимо проверить',
        WAIT_FINALIZE: 'Необходимо импортировать',
        RUN_FINALIZE: 'Запуск импорта...',
        RUN_VALIDATION: 'Запуск проверки...',
        DONE_FINALIZE: 'Импорт завершен'
      }
    },
  },
  TAB: {
    INVOICE_COMPANIES: {
      CREATE_INVOICES: 'Создать ({count}) счета',
      NO_INVOICE_AFTER: 'Отсутствует счет после даты',
      HEADER_COMPANY: 'Общество',
      HEADER_MEMBER_COUNT: 'Количество членов',
      HEADER_TOTAL_AMOUNT: 'Ожидаемая сумма',
      HEADER_LAST_INVOICE: 'Последний счет',
      TIP_NO_INVOICE_AFTER: 'Фильтр для отображения всех организаций, у которых не был создан счет после указанной даты.',
      TIP_HEADER_COMPANY: 'Название общества.',
      TIP_HEADER_MEMBER_COUNT: 'Количество членов на общество.',
      TIP_HEADER_TOTAL_AMOUNT: 'Общая сумма, которая предполагается выставить.',
      TIP_HEADER_LAST_INVOICE: 'Дата последнего выставленного счета.',
      CONFIRM_TITLE: 'Выполнение выставления счета',
      CONFIRM_MESSAGE: 'Счета будут созданы и появятся в таблице счетов после завершения выполнения.',
      CONFIRM_ERROR: 'Не удалось запустить выполнение.',
      UNABLE_CREATE_INVOICES: 'Не удалось создать счета.',
      UNABLE_LIST_COMPANIES: 'Не удалось отобразить список обществ.',
      UNABLE_LIST_SHOP_ITEMS: 'Не удалось отобразить список товаров магазина.'
    },
    INVOICE_TEMPLATES: {
      DELETE_LOGO: 'Удалить',
      CREATE_TITLE: 'Создать шаблон счета',
      UPDATE_TITLE: 'Обновить шаблон счета',
      NEW_TEMPLATE: 'Новый шаблон',
      SETTINGS: 'Настройки',
      SENDER: 'Поле отправителя',
      BOX: 'Графический блок',
      BOX_TEXT: 'Текст',
      BOX_BG: 'Цвет фона',
      BOX_FG: 'Цвет текста',
      NAME: 'Название',
      LANGUAGE: 'Язык',
      TYPE: 'Тип',
      SENDER_HEADER: 'Текст обратного адреса',
      HEADER: 'Текст заголовка страницы',
      REMINDER: 'Напоминание',
      REMINDER_FEE: 'Плата за напоминание',
      UPLOAD_LOGO: 'Загрузить логотип',
      SENDER_WEBADDRESS: 'Веб-адрес',
      SENDER_PHONE: 'Контактный телефон',
      SENDER_ORGNUMBER: 'Организационный номер',
      SENDER_CONTACT: 'Контактное лицо',
      SENDER_EMAIL: 'Контактная электронная почта',

      TYPES: {
        MEMBER: 'Счет для членов',
        COMPANY: 'Счет для общества',
        MEMBER_RENEW: 'Обновление для членов'
      },

      TYPE_OPTION1: 'Счет для членов',
      TYPE_OPTION2: 'Счет для обществ',
      HEADER_OPTION1: 'Счет',
      HEADER_OPTION2: 'Фактура',
      HEADER_OPTION3: 'Напоминание о счете',
      TH_NAME: 'ИМЯ',
      TH_CREATED_AT: 'СОЗДАНО',
      TIP_SENDER_HEADER: 'Текст, который будет отображаться над обратным адресом на счете.',
      TIP_SETTINGS: 'Выберите, какую информацию получателя следует отображать на счете.',
      TIP_SENDER: 'Выберите, какую информацию об отправителе следует отображать на счете.',
      TIP_NAME: 'Установите название шаблона счета. Это имя видно только администраторам и не отображается для обществ или членов при отправке счета.',
      TIP_LANGUAGE: 'Выберите язык, на котором будет создан счет.',
      TIP_HEADER: 'Выберите тип уведомления.',
      TIP_TYPE: 'Выберите тип счета. Счет для членов - создает счет для одного или нескольких членов. Счет для общества - создает счет для обществ.',
      TIP_REMINDER: 'Создайте напоминание о счете и, если нужно, установите плату за напоминание.',
      TIP_BOX: 'В этом разделе можно настроить внешний вид графического блока на счете.',
      TIP_BOX_TEXT: 'Определите содержимое графического блока.',
      TIP_BOX_BG: 'Установите цвет фона графического блока. Выберите цвет, который будет контрастировать с цветом текста.',
      TIP_BOX_FG: 'Установите цвет текста графического блока. Выберите цвет, который будет контрастировать с цветом фона.',
      TIP_USE_BPOST_IMAGE: 'Добавить изображение "BPosttidning"',
      USE_BPOST_IMAGE: 'Добавить "BPosttidning"',
      INVALID_SENDER_HEADER: 'Недопустимый текст обратного адреса',
      INVALID_NAME: 'Недопустимое имя',
      INVALID_LANGUAGE: 'Недопустимый язык',
      INVALID_TYPE: 'Недопустимый тип',
      INVALID_HEADER: 'Недопустимый заголовок страницы',
      INVALID_REMINDER_FEE: 'Недопустимая плата за напоминание',
      INVALID_BOX_TEXT: 'Недопустимый текст',
      CREATED: 'Шаблон счета создан.',
      DELETED: 'Шаблон счета удален.',
      UPDATED: 'Шаблон счета обновлен.',
      UPLOADED: 'Файл логотипа загружен',
      UNABLE_CREATE: 'Не удалось создать шаблон счета.',
      UNABLE_LIST: 'Не удалось отобразить список шаблонов счетов.',
      UNABLE_DELETE: 'Не удалось удалить шаблон счета.',
      UNABLE_UPDATE: 'Не удалось обновить шаблон счета.',
      UNABLE_UPLOAD: 'Не удалось загрузить файл логотипа.',
      UNABLE_PREVIEW: 'Не удалось просмотреть шаблон счета.'
    },
    SETTINGS_DNS: {
      TITLE: 'Допустимые адреса для рассылок',
      NEW_ADDRESS: 'Новый адрес',
      INVALID_EMAIL: 'Недопустимый адрес электронной почты',
      ADDED: 'Адрес добавлен',
      DELETED: 'Адрес удален',
      UNABLE_ADD: 'Не удалось добавить адрес',
      UNABLE_DELETE: 'Не удалось удалить адрес',
      UNABLE_LIST: 'Не удалось отобразить список адресов'
    },

    USER_TERMS: {
      NEW_TERM: 'Новое условие'
    }

  },

  QUICKSEARCH: {
    RESULTS: 'Результаты',
    NO_RESULTS: 'Результаты не найдены'
  },

  ADVANCED_SEARCH: {
    SAVE_OPTIONS: 'Translate: Alternativ',
    PERIODS: 'Translate: Perioder',
    ALL_PERIODS: 'Translate: Alla perioder',
    SAVE_QUERY: 'Translate: Spara sökning',
    
    NO_RESULTS: 'Translate: Inga resultat',
    DO_AUTORENEW: 'Автообновление',
    SECRET: 'Конфиденциальность',
    ALL_COMPANIES: 'Все общества',
    NO_ACTIVE_RENEWAL: 'Нет активного обновления',
    HAS_ACTIVE_RENEWAL: 'Есть активное обновление',
    RENEWAL: 'Обновление',

    UNABLE_EDIT: 'Эту строку нельзя редактировать',

    NUM_SELECTION: 'Количество получателей: {count}',

    SELECT_RECIPIENTS: 'Получатели: {count}',

    CREATE_QUERY: 'Сохранить поиск',

    NO_QUERIES: 'Поиски не найдены',

    QUERY_UPDATED: 'Поиск обновлен',
    UNABLE_QUERY_UPDATE: 'Не удалось обновить поиск',

    QUERY_CREATED: 'Поиск создан',
    UNABLE_QUERY_CREATE: 'Не удалось создать поиск',

    UNABLE_QUERY_LIST: 'Не удалось отобразить список поисков',

    UNABLE_QUERY_DELETE: 'Не удалось удалить поиск',
    QUERY_DELETED: 'Поиск удален',

    SELECT_SAVED_SEARCH: 'Выбрать сохраненный поиск',

    COMPANIES: 'Общества',

    SEARCH_QUERIES: 'Сохраненные поиски',
    SELECT_QUERY: 'Выбрать поиск',
    QUERY_UPDATED: 'Поиск обновлен',

    NAME_REQUIRED: 'Требуется имя',

    SAVED: 'Сохранено',
    UNABLE_TO_SAVE: 'Не удалось сохранить',

    NO_RECORDS_IN_SELECTION: 'Нет строк для экспорта',

    SHOW_MORE_FIELDS: 'Показать дополнительные фильтры',
    HIDE_MORE_FIELDS: 'Скрыть дополнительные фильтры',

    MEMBERS: 'Члены',
    USERS: 'Пользователи',
    PERSONS: 'Люди',

    PAYMENT_STATUS: 'Статус оплаты',
    SEX: 'Юридический пол',
    PUBLIC_SEX: 'Личный пол',

    ALL: 'Все',
    YES: 'Да',
    NO: 'Нет',
    NONE: 'Ни один',
    MISSING: 'Отсутствует',
    EXISTS: 'Существует',

    PAID: 'Оплачено',
    NOT_PAID: 'Не оплачено',

    MEMBER_TYPE: 'Тип члена',
    AGE: 'Возраст',
    AGE_FROM: 'Возраст от',
    AGE_TO: 'Возраст до',
    RDT: 'Дата регистрации',
    EXIT_AT: 'Дата выхода',
    RDT_FROM: 'От',
    RDT_TO: 'До',

    SHOP_ITEM: 'Товар',

    MISSING_MEMBERSHIP: 'Отсутствует членство',
    MISSING_MEMBERSHIP_FROM: 'От',
    MISSING_MEMBERSHIP_TO: 'До',
    HAVE_MEMBERSHIP: 'Имеется членство',
    HAVE_MEMBERSHIP_FROM: 'От',
    HAVE_MEMBERSHIP_TO: 'До',

    DECEASED: 'Умер',
    DEACTIVATED: 'Деактивирован',
    ADDRESS: 'Адрес',
    ZIPCODE: 'Почтовый индекс',
    COUNTRY: 'Страна',
    COUNTRY_CODE: 'Код страны',
    PHONE: 'Телефон',
    EMAIL: 'Электронная почта',
    HD: 'Главный член',
    PERSONNR: 'Личный номер',

    SAVE_SEARCH: 'Сохранить поиск',

    UNABLE_TO_SEARCH: 'Не удалось выполнить поиск',

    EVENTS: {
      NAME: 'Событие',
      HEADER: 'Выберите событие',
      EVENT_NAME: 'Название события',
      COMPANY_NAME: 'Общество',
      SELECT_EVENT: 'Выбрать событие ({count})',
      ADD_EVENT: 'Добавить событие',
      NONE_SELECTED: 'События не выбраны'
    },
    MEMBERSHIPS: {
      NAME: 'Членство',
      HEADER: 'Выберите членство',
      MEMBERSHIP_NAME: 'Товар членства',
      COMPANY_NAME: 'Общество',
      SELECT_MEMBERSHIP: 'Выбрать членство ({count})',
      ADD_MEMBERSHIP: 'Добавить членство',
      NONE_SELECTED: 'Членства не выбраны',
      INCLUDE_ANY: 'Должно быть хотя бы одно членство',
      INCLUDE_ALL: 'Должны быть все членства',
      INCLUDE_INFO: 'Опции включения',
    },
    ATTRIBUTES: {
      INCLUDE_ANY: 'Должен быть хотя бы один атрибут',
      INCLUDE_ALL: 'Должны быть все атрибуты',
      INCLUDE_INFO: 'Опции включения',
      NAME: 'Атрибут',
      HEADER: 'Выберите атрибут для поиска',
      GROUP_NAME: 'Категория',
      PROP_NAME: 'Значение',
      SELECT_ATTRIBUTE: 'Выбрать атрибут ({count})',
      ADD_ATTRIBUTE: 'Добавить атрибут',
      NONE_SELECTED: 'Атрибуты не выбраны'
    },
    EDUCATIONS: {
      SEARCH_EDUCATION: 'Введите код обучения',
      CODE: 'Код',
      EDUCATION: 'Обучение',
      INCLUDE_ANY: 'Должно быть хотя бы одно обучение',
      INCLUDE_ALL: 'Должны быть все обучения',
      INCLUDE_INFO: 'Опции включения',
      NAME: 'Обучение',
      HEADER: 'Выберите обучение для поиска',
      SELECT_EDUCATION: 'Выбрать обучение ({count})',
      ADD_EDUCATION: 'Добавить обучение',
      NONE_SELECTED: 'Обучения не выбраны'
    },
  },
  MODAL: {
    INVOICE_CREATE: {
      TITLE: 'Создать счета',
      INVOICE_COUNT: 'Общее количество счетов',
      PREVIEW_AS: 'Предварительный просмотр как',
      TIP_INVOICE_COUNT: 'Количество счетов, которые будут сгенерированы.',
      TIP_PREVIEW_AS: 'Просмотрите уведомление как один из получателей.',
      TH_ARTICLE: 'СТАТЬЯ',
      TH_AMOUNT: 'СТОИМОСТЬ',
      TH_CURRENCY: 'ВАЛЮТА',
      TH_QUANTITY_TYPE: 'РАСЧЕТ',
      TH_QUANTITY: 'КОЛИЧЕСТВО',
      TH_VAT: 'НДС',
      TH_TOT_VAT: 'ВСЕГО НДС',
      TH_TOT_AMOUNT: 'ВСЕГО',
      TOT_VAT: 'Общий НДС',
      EXC_VAT: 'Всего без НДС',
      INC_VAT: 'Всего с НДС',
      DUE_DATE: 'Срок оплаты',
      BOX_TEXT: 'Текст счета',
      ADD_ITEM: '+ Добавить',
      CREATE: 'Создать ({count})',
      QUANTITY_TYPE_1: 'За количество',
      QUANTITY_TYPE_2: 'За члена',
      TIP_TH_ARTICLE: 'Название статьи, которое будет отображаться в счете.',
      TIP_TH_AMOUNT: 'Стоимость за статью.',
      TIP_TH_CURRENCY: 'Валюта за статью.',
      TIP_TH_QUANTITY_TYPE: 'Метод расчета за статью.',
      TIP_TH_QUANTITY: 'Количество статей.',
      TIP_TH_VAT: 'НДС за статью.',
      TIP_TH_TOT_VAT: 'Общий НДС.',
      TIP_TH_TOT_AMOUNT: 'Общая сумма.',
      TIP_TOT_VAT: 'Общий НДС.',
      TIP_EXC_VAT: 'Общая сумма без НДС.',
      TIP_INC_VAT: 'Общая сумма с НДС.',
      TIP_DUE_DATE: 'Переопределите текущую предопределенную дату оплаты (эта настройка находится в разделе Настройки/Оплата), установив новую дату.',
      TIP_BOX_TEXT: 'Переопределите текущий предопределенный текст счета (этот текст устанавливается в шаблоне счета в разделе Счета/Шаблоны), установив новый текст.',
    }
  },

  COMPONENT: {
    EMAIL_VALID_DOMAIN: {
      INVALID: 'Недействительный адрес электронной почты, свяжитесь с поддержкой Memlist.',
      UNABLE_LIST: 'Не удалось получить список действительных адресов электронной почты.'
    },
  },

  COUNTRIES: [
    { key: 'NONE', value: 'Нет выбора' },
    { key: 'AF', value: 'Афганистан' },
    { key: 'AL', value: 'Албания' },
    { key: 'DZ', value: 'Алжир' },
    { key: 'AS', value: 'Американское Самоа' },
    { key: 'AD', value: 'Андорра' },
    { key: 'AO', value: 'Ангола' },
    { key: 'AI', value: 'Ангилья' },
    { key: 'AQ', value: 'Антарктида' },
    { key: 'AG', value: 'Антигуа и Барбуда' },
    { key: 'AR', value: 'Аргентина' },
    { key: 'AM', value: 'Армения' },
    { key: 'AW', value: 'Аруба' },
    { key: 'AU', value: 'Австралия' },
    { key: 'AZ', value: 'Азербайджан' },
    { key: 'BS', value: 'Багамы' },
    { key: 'BH', value: 'Бахрейн' },
    { key: 'BD', value: 'Бангладеш' },
    { key: 'BB', value: 'Барбадос' },
    { key: 'BE', value: 'Бельгия' },
    { key: 'BZ', value: 'Белиз' },
    { key: 'BJ', value: 'Бенин' },
    { key: 'BM', value: 'Бермуды' },
    { key: 'BT', value: 'Бутан' },
    { key: 'BO', value: 'Боливия, Многонациональное Государство' },
    { key: 'BQ', value: 'Бонайре, Синт-Эстатиус и Саба' },
    { key: 'BA', value: 'Босния и Герцеговина' },
    { key: 'BW', value: 'Ботсвана' },
    { key: 'BV', value: 'Остров Буве' },
    { key: 'BR', value: 'Бразилия' },
    { key: 'IO', value: 'Британская территория в Индийском океане' },
    { key: 'BN', value: 'Бруней-Даруссалам' },
    { key: 'BG', value: 'Болгария' },
    { key: 'BF', value: 'Буркина-Фасо' },
    { key: 'BI', value: 'Бурунди' },
    { key: 'CV', value: 'Кабо-Верде' },
    { key: 'KY', value: 'Каймановы острова' },
    { key: 'CF', value: 'Центральноафриканская Республика' },
    { key: 'CL', value: 'Чили' },
    { key: 'CC', value: 'Кокосовые (Килинг) острова' },
    { key: 'CO', value: 'Колумбия' },
    { key: 'KM', value: 'Коморские острова' },
    { key: 'CK', value: 'Острова Кука' },
    { key: 'CR', value: 'Коста-Рика' },
    { key: 'CW', value: 'Кюрасао' },
    { key: 'CY', value: 'Кипр' },
    { key: 'DK', value: 'Дания' },
    { key: 'DJ', value: 'Джибути' },
    { key: 'DM', value: 'Доминика' },
    { key: 'DO', value: 'Доминиканская Республика' },
    { key: 'EC', value: 'Эквадор' },
    { key: 'EG', value: 'Египет' },
    { key: 'SV', value: 'Сальвадор' },
    { key: 'CI', value: 'Кот-д\'Ивуар' },
    { key: 'GQ', value: 'Экваториальная Гвинея' },
    { key: 'ER', value: 'Эритрея' },
    { key: 'EE', value: 'Эстония' },
    { key: 'ET', value: 'Эфиопия' },
    { key: 'FK', value: 'Фолклендские острова' },
    { key: 'FJ', value: 'Фиджи' },
    { key: 'PH', value: 'Филиппины' },
    { key: 'FI', value: 'Финляндия' },
    { key: 'FR', value: 'Франция' },
    { key: 'GF', value: 'Французская Гвиана' },
    { key: 'PF', value: 'Французская Полинезия' },
    { key: 'TF', value: 'Французские Южные территории' },
    { key: 'FO', value: 'Фарерские острова' },
    { key: 'AE', value: 'Объединенные Арабские Эмираты' },
    { key: 'GA', value: 'Габон' },
    { key: 'GM', value: 'Гамбия' },
    { key: 'GE', value: 'Грузия' },
    { key: 'GH', value: 'Гана' },
    { key: 'GI', value: 'Гибралтар' },
    { key: 'GR', value: 'Греция' },
    { key: 'GD', value: 'Гренада' },
    { key: 'GL', value: 'Гренландия' },
    { key: 'GP', value: 'Гваделупа' },
    { key: 'GU', value: 'Гуам' },
    { key: 'GT', value: 'Гватемала' },
    { key: 'GG', value: 'Гернси' },
    { key: 'GN', value: 'Гвинея' },
    { key: 'GW', value: 'Гвинея-Бисау' },
    { key: 'GY', value: 'Гайана' },
    { key: 'HT', value: 'Гаити' },
    { key: 'HM', value: 'Остров Херд и острова Макдональд' },
    { key: 'HN', value: 'Гондурас' },
    { key: 'HK', value: 'Гонконг' },
    { key: 'IN', value: 'Индия' },
    { key: 'ID', value: 'Индонезия' },
    { key: 'IQ', value: 'Ирак' },
    { key: 'IR', value: 'Иран' },
    { key: 'IE', value: 'Ирландия' },
    { key: 'IS', value: 'Исландия' },
    { key: 'IM', value: 'Остров Мэн' },
    { key: 'IL', value: 'Израиль' },
    { key: 'IT', value: 'Италия' },
    { key: 'JM', value: 'Ямайка' },
    { key: 'JP', value: 'Япония' },
    { key: 'JE', value: 'Джерси' },
    { key: 'JO', value: 'Иордания' },
    { key: 'CX', value: 'Остров Рождества' },
    { key: 'KH', value: 'Камбоджа' },
    { key: 'CM', value: 'Камерун' },
    { key: 'CA', value: 'Канада' },
    { key: 'KZ', value: 'Казахстан' },
    { key: 'KE', value: 'Кения' },
    { key: 'CN', value: 'Китай' },
    { key: 'KI', value: 'Кирибати' },
    { key: 'CG', value: 'Конго' },
    { key: 'CD', value: 'Конго-Киншаса' },
    { key: 'KP', value: 'КНДР (Северная Корея)' },
    { key: 'KR', value: 'Республика Корея (Южная Корея)' },
    { key: 'HR', value: 'Хорватия' },
    { key: 'CU', value: 'Куба' },
    { key: 'KW', value: 'Кувейт' },
    { key: 'KG', value: 'Киргизия' },
    { key: 'LA', value: 'Лаос' },
    { key: 'LS', value: 'Лесото' },
    { key: 'LV', value: 'Латвия' },
    { key: 'LB', value: 'Ливан' },
    { key: 'LR', value: 'Либерия' },
    { key: 'LY', value: 'Ливия' },
    { key: 'LI', value: 'Лихтенштейн' },
    { key: 'LT', value: 'Литва' },
    { key: 'LU', value: 'Люксембург' },
    { key: 'MO', value: 'Макао' },
    { key: 'MG', value: 'Мадагаскар' },
    { key: 'MK', value: 'Македония' },
    { key: 'MW', value: 'Малави' },
    { key: 'MY', value: 'Малайзия' },
    { key: 'MV', value: 'Мальдивы' },
    { key: 'ML', value: 'Мали' },
    { key: 'MT', value: 'Мальта' },
    { key: 'MA', value: 'Марокко' },
    { key: 'MH', value: 'Маршалловы Острова' },
    { key: 'MQ', value: 'Мартиника' },
    { key: 'MR', value: 'Мавритания' },
    { key: 'MU', value: 'Маврикий' },
    { key: 'YT', value: 'Майотта' },
    { key: 'MX', value: 'Мексика' },
    { key: 'FM', value: 'Микронезия' },
    { key: 'MD', value: 'Молдавия' },
    { key: 'MC', value: 'Монако' },
    { key: 'MN', value: 'Монголия' },
    { key: 'ME', value: 'Черногория' },
    { key: 'MS', value: 'Монтсеррат' },
    { key: 'MZ', value: 'Мозамбик' },
    { key: 'MM', value: 'Мьянма' },
    { key: 'NA', value: 'Намибия' },
    { key: 'NR', value: 'Науру' },
    { key: 'NL', value: 'Нидерланды' },
    { key: 'NP', value: 'Непал' },
    { key: 'NC', value: 'Новая Каледония' },
    { key: 'NI', value: 'Никарагуа' },
    { key: 'NE', value: 'Нигер' },
    { key: 'NG', value: 'Нигерия' },
    { key: 'NU', value: 'Ниуэ' },
    { key: 'NF', value: 'Остров Норфолк' },
    { key: 'NO', value: 'Норвегия' },
    { key: 'MP', value: 'Северные Марианские Острова' },
    { key: 'NZ', value: 'Новая Зеландия' },
    { key: 'OM', value: 'Оман' },
    { key: 'PK', value: 'Пакистан' },
    { key: 'PW', value: 'Палау' },
    { key: 'PS', value: 'Палестина' },
    { key: 'PA', value: 'Панама' },
    { key: 'PG', value: 'Папуа - Новая Гвинея' },
    { key: 'PY', value: 'Парагвай' },
    { key: 'PE', value: 'Перу' },
    { key: 'PN', value: 'Питкэрн' },
    { key: 'PL', value: 'Польша' },
    { key: 'PT', value: 'Португалия' },
    { key: 'PR', value: 'Пуэрто-Рико' },
    { key: 'QA', value: 'Катар' },
    { key: 'RO', value: 'Румыния' },
    { key: 'RW', value: 'Руанда' },
    { key: 'RU', value: 'Россия' },
    { key: 'RE', value: 'Реюньон' },
    { key: 'BL', value: 'Сен-Бартелеми' },
    { key: 'SH', value: 'Острова Святой Елены, Вознесения и Тристан-да-Кунья' },
    { key: 'KN', value: 'Сент-Китс и Невис' },
    { key: 'LC', value: 'Сент-Люсия' },
    { key: 'MF', value: 'Сен-Мартен (Французская часть)' },
    { key: 'PM', value: 'Сен-Пьер и Микелон' },
    { key: 'VC', value: 'Сент-Винсент и Гренадины' },
    { key: 'WS', value: 'Самоа' },
    { key: 'SM', value: 'Сан-Марино' },
    { key: 'ST', value: 'Сан-Томе и Принсипи' },
    { key: 'SA', value: 'Саудовская Аравия' },
    { key: 'CH', value: 'Швейцария' },
    { key: 'SN', value: 'Сенегал' },
    { key: 'RS', value: 'Сербия' },
    { key: 'SC', value: 'Сейшельские острова' },
    { key: 'SL', value: 'Сьерра-Леоне' },
    { key: 'SG', value: 'Сингапур' },
    { key: 'SX', value: 'Синт-Мартен (Нидерландская часть)' },
    { key: 'SK', value: 'Словакия' },
    { key: 'SI', value: 'Словения' },
    { key: 'SB', value: 'Соломоновы Острова' },
    { key: 'SO', value: 'Сомали' },
    { key: 'ZA', value: 'Южная Африка' },
    { key: 'GS', value: 'Южная Георгия и Южные Сандвичевы острова' },
    { key: 'ES', value: 'Испания' },
    { key: 'LK', value: 'Шри-Ланка' },
    { key: 'SD', value: 'Судан' },
    { key: 'SR', value: 'Суринам' },
    { key: 'SJ', value: 'Шпицберген и Ян-Майен' },
    { key: 'SE', value: 'Швеция' },
    { key: 'SZ', value: 'Свазиленд' },
    { key: 'SS', value: 'Южный Судан' },
    { key: 'SY', value: 'Сирия' },
    { key: 'TW', value: 'Тайвань, провинция Китая' },
    { key: 'TJ', value: 'Таджикистан' },
    { key: 'TZ', value: 'Танзания, Объединенная Республика' },
    { key: 'TD', value: 'Чад' },
    { key: 'TH', value: 'Таиланд' },
    { key: 'TL', value: 'Тимор-Лесте' },
    { key: 'CZ', value: 'Чехия' },
    { key: 'TG', value: 'Того' },
    { key: 'TK', value: 'Токелау' },
    { key: 'TO', value: 'Тонга' },
    { key: 'TT', value: 'Тринидад и Тобаго' },
    { key: 'TN', value: 'Тунис' },
    { key: 'TR', value: 'Турция' },
    { key: 'TM', value: 'Туркменистан' },
    { key: 'TC', value: 'Острова Теркс и Кайкос' },
    { key: 'TV', value: 'Тувалу' },
    { key: 'DE', value: 'Германия' },
    { key: 'GB', value: 'Великобритания' },
    { key: 'US', value: 'США' },
    { key: 'UG', value: 'Уганда' },
    { key: 'UA', value: 'Украина' },
    { key: 'HU', value: 'Венгрия' },
    { key: 'UM', value: 'Малые отдаленные острова США' },
    { key: 'UY', value: 'Уругвай' },
    { key: 'UZ', value: 'Узбекистан' },
    { key: 'VU', value: 'Вануату' },
    { key: 'VA', value: 'Ватикан' },
    { key: 'VE', value: 'Венесуэла, Боливарианская Республика' },
    { key: 'VN', value: 'Вьетнам' },
    { key: 'VG', value: 'Виргинские Острова, Британские' },
    { key: 'VI', value: 'Виргинские Острова, США' },
    { key: 'BY', value: 'Беларусь' },
    { key: 'WF', value: 'Уоллис и Футуна' },
    { key: 'EH', value: 'Западная Сахара' },
    { key: 'YE', value: 'Йемен' },
    { key: 'ZM', value: 'Замбия' },
    { key: 'ZW', value: 'Зимбабве' },
    { key: 'AX', value: 'Аландские острова' },
    { key: 'AT', value: 'Австрия' }
  ],
  CALENDAR: {
    MONTHS: {
      JANUARY: 'Январь',
      FEBRUARY: 'Февраль',
      MARS: 'Март',
      APRIL: 'Апрель',
      MAY: 'Май',
      JUNE: 'Июнь',
      JULY: 'Июль',
      AUGUST: 'Август',
      SEPTEMBER: 'Сентябрь',
      OCTOBER: 'Октябрь',
      NOVEMBER: 'Ноябрь',
      DECEMBER: 'Декабрь'
    },
    SHORT_MONTHS: {
      JAN: 'Янв',
      FEB: 'Фев',
      MAR: 'Мар',
      APR: 'Апр',
      MAY: 'Май',
      JUN: 'Июн',
      JUL: 'Июл',
      AUG: 'Авг',
      SEP: 'Сен',
      OCT: 'Окт',
      NOV: 'Ноя',
      DEC: 'Дек'
    }
  },
  TRANSLATOR: {
    SELECT: "Выберите ваш язык"
  },
  PUBLIC: {
    NO_ACCESS: {
      CONTINUE_TO_LOGIN: '<< Назад к входу',
      HEADER: 'Нет доступа к объединению',
      INFO_P: 'Ваш аккаунт не связан ни с каким объединением.',
      INFO1: 'Группа, к которой принадлежит ваш пользователь, не имеет доступа к объединению или странице.',
      INFO2: 'Попросите администратора, который вас пригласил, добавить права доступа.'
    },
    NO_PAGE_ACCESS: {
      HEADER: 'Нет доступа к странице',
      INFO_P: '',
      INFO1: 'Группа, к которой принадлежит ваш пользователь, не имеет доступа к странице.',
      INFO2: 'Попросите администратора добавить страницу в вашу групповую принадлежность.'
    },
    LOGIN: {
      INVALID_LOGIN_LINK: 'Эта ссылка недействительна. Свяжитесь с администратором, чтобы получить новую ссылку.',
      DISABLED_LOGIN: 'Администратор отключил вход.'
    },
    FORM: {
      CUSTOM_DONATION_AMOUNT: 'Собственная сумма',
      CREATE_FAMILY_MEMBER: 'Добавить члена семьи',
      TOTAL: 'Итого',
      CART: 'Корзина',
      CART_EMPTY: 'Корзина пуста',
      MUST_SELECT_MEMBERSHIP: 'Вы должны выбрать членство',
      TO: 'до',
      ADD: 'Добавить',
      REMOVE: 'Удалить',
      PERSONNR_REQUIRED: 'Требуется действительный идентификационный номер',
      MISSING_FIELDS: 'Заполните обязательные поля',
      INVALID_PERSONNR: 'Недействительный идентификационный номер',
      PERSONNR: 'Идентификационный номер',
      PLEASE_WAIT: 'Пожалуйста, подождите',
      PLEASE_WAIT_INFO: 'Ваш запрос обрабатывается',
      CONFIRM_HEADER: 'Страница подтверждения',
      INVALID_TEXT: 'Недопустимый текст',
      FIRSTNAME: 'Имя',
      LASTNAME: 'Фамилия',
      EMAIL: 'Эл. почта',
      EMAIL2: 'Повторите эл. почту',
      INVALID_EMAIL: 'Недействительная эл. почта',
      EMAIL_MISMATCH: 'Адреса эл. почты не совпадают',
      PASSWORD: 'Пароль',
      PASSWORD2: 'Повторите пароль',
      PASSWORD_MISMATCH: 'Пароли не совпадают',
      INVALID_PASSWORD: 'Недействительный пароль',
      INVALID_PHONE: 'Недействительный телефон',
      PHONE: 'Телефон',
      LANDLINE: 'Стационарный телефон',
      POST: 'Почтовый город',
      ZIPCODE: 'Почтовый индекс',
      ADDRESS: 'Адрес',
      INVALID_ADDRESS: 'Недействительный адрес',
      INVALID_POST: 'Недействительный почтовый город',
      INVALID_ZIPCODE: 'Недействительный почтовый индекс',
      COMMUNE_CODE: 'Коммуна',
      REGION_CODE: 'Регион',
      COUNTRY: 'Страна',
      INVALID_DOB: 'Недействительная дата рождения',
      DOB: 'Дата рождения ГГГГММДД',
      CNAME: 'Название компании',
      ORG_NUMBER: 'Регистрационный номер',
      INVALID_ORG_NUMBER: 'Недействительный регистрационный номер',
      AVD: 'Отделение',
      INVOICE_FIELDS: 'Данные для выставления счета',
      MALE: 'Мужской',
      FEMALE: 'Женский',
      OTHER: 'Другое',
      PUBLIC_SEX: 'Пол'
    },
    INQUIRY: {
      OTHER_TEXT: 'Дополнительная информация',
      CREATED_SUCCESSFULLY: 'Спасибо за вашу заявку!',
      CREATED_SUCCESSFULLY_INFO: 'Мы рассмотрим вашу заявку и свяжемся с вами как можно скорее.',
      CREATE_FAILURE: 'Произошла ошибка!',
      CREATE_FAILURE_INFO: 'Пожалуйста, попробуйте позже или свяжитесь с поддержкой по адресу support@memlist.se',
      ORG_INFO: 'Информация об организации',
      ORG_NAME: 'Название организации',
      INPUT_ORG_NAME: 'Введите название вашей организации',
      INPUT_C_NAME: 'Введите имя контактного лица',
      C_NAME: 'Контактное лицо',
      ADMIN_EMAIL: 'Ваш адрес эл. почты',
      INPUT_ADMIN_EMAIL: 'Введите действующий адрес эл. почты',
      INPUT_ADMIN_PHONE: 'Введите ваш номер телефона',
      ADMIN_PHONE: 'Ваш номер телефона',
      PHONE: 'Телефон',
      MISSING_FIELDS: 'Пожалуйста, заполните все поля',
      ORG_SETUP: 'Настройка организации',
      ORG_TYPE: 'Тип организации',
      ORG_TYPES: {
        RIKS: 'Национальная федерация',
        COMPANY: 'Компания',
        STUDENT: 'Студенческий совет',
        STUDENT_COMPANY: 'Студенческое объединение',
        SPORTCLUB: 'Спортивный клуб',
        INTEREST_CLUB: 'Клуб по интересам',
        UNION: 'Профсоюз',
        COOP: 'Кооператив',
        BRF: 'Жилищное объединение',
        STIFT: 'Фонд',
        MUCF: 'Молодежное объединение (MUCF)',
        FAITH: 'Религиозная община'
      },
      ORG_STRUCTURE: 'Структура организации',
      ORG_STRUCTURES: {
        SINGLE: 'Отдельная организация',
        MULTI: 'Несколько организаций'
      },
      PERIOD: 'Период членства',
      PERIODS: {
        INFINITE: 'Бессрочный',
        PERIOD: 'Календарный год',
        OTHER: 'Другое'
      },
      NUM_ORGS: 'Количество отделений/подразделений',
      HAS_PAYMENT: 'Хотите ли вы взимать плату за членство?',
      PAYMENT_METHODS: {
        SWISH: 'Swish',
        CREDITCARD: 'Кредитная карта',
        POST_INVOICE: 'Почтовый счет',
        EMAIL_INVOICE: 'Счет по эл. почте',
        OTHER: 'Другое'
      },
      OTHER_SERVICES: 'Другие интересующие функции',
      SERVICES: {
        SENDOUT: 'Рассылка',
        TODO: 'Управление задачами',
        EVENTS: 'Управление мероприятиями',
        FORTNOX: 'Интеграция Fortnox',
        SPAR: 'Поиск SPAR',
        LADOK: 'LADOK',
        REGISTRATIONS: 'Заявки на членство',
        FILEUPLOADS: 'Загрузка файлов для члена',
        FILEMANAGEMENT: 'Управление файлами',
        COUPONS: 'Купоны',
        GRANT: 'Управление грантами',
        DEACTIVATION: 'Заявки на выход из членства',
        HISTORY: 'История/Управление логами'
      },
      SELECT_PAYMENT_METHODS: 'Выберите способы оплаты',
      SEND_INQUIRY: 'Отправить запрос'
    },
    EVENT: {
      REGISTER_INFO: 'Зарегистрируйте свои данные',
      INVALID_PHONE: 'Недействительный телефон',
      INVALID_EMAIL: 'Недействительная эл. почта'
    },
    REGISTRATION: {
      EXPECTED_ACTION_ERROR_HEADER: 'Неправильная настройка',
      EXPECTED_ACTION_ERROR: 'На этой странице регистрации неверные настройки оплаты. Свяжитесь с объединением и укажите, что методы оплаты должны быть правильно настроены.',
      YOU_WILL_BE_MEMBER: 'Вы станете членом',
      MEMBERSHIP_CREATED: 'Членский аккаунт создан!',
      MEMBERSHIP_CREATED_INFO: 'Ваш аккаунт создан, и ссылка для активации отправлена на указанный вами адрес эл. почты',
      UNABLE_CREATE_MEMBERSHIP: 'Ваш аккаунт не был создан. Попробуйте позже.',
      UNABLE_LOAD: 'Не удалось загрузить форму регистрации. Ссылка недействительна.',
      UNABLE_GET_WIZARD: 'Не удалось получить мастер регистрации',
      MEMBERSHIP_PENDING: 'Мы рассматриваем вашу заявку',
      MEMBERSHIP_PENDING_INFO: 'Ваша заявка на членство будет рассмотрена, прежде чем мы сможем одобрить вас как члена. Пожалуйста, ожидайте подтверждения по эл. почте.',
      ERROR_OCCURED: 'Произошла ошибка',
      MEMBERSHIP_ERROR: 'Ошибка при регистрации',
      MEMBERSHIP_ERROR_MESSAGE: 'Не удалось зарегистрировать члена. Попробуйте позже или свяжитесь со своим объединением.'
    },
    DONATION: {
      DONATION: 'Пожертвование',
      FROM_NAME: 'Ваше имя',
      DONATION_TO: 'Ваше пожертвование для {name}',
      ENTER_AMOUNT: 'Введите сумму',
      MESSAGE: 'Напишите необязательное сообщение',
      START_PAYMENT: 'Продолжить'
    },
    RENEW: {
      CONFIRM_MEMBERSHIP: 'Подтвердить членство',
      MEMBERSHIPS: 'Членства',
      COMPANY: 'Объединение',
      PERIOD: 'Период',
      MEMBER_NOT_FOUND: 'Данные о члене не найдены',
      OLD_LINK: 'Ссылка для активации либо неверна, либо устарела.',
      UNABLE_CONFIRM: 'Не удалось подтвердить',
      THANKS_CONFIRM: 'Спасибо за подтверждение!',
      REDIRECT: 'Перенаправление на ваш профиль...',
      ERROR_CONFIRM_INFO: 'Не удалось подтвердить ваше членство, попробуйте позже или свяжитесь с администратором.',
      TRY_AGAIN_LATER: 'Не удалось подтвердить. Попробуйте позже.'
    },
    PAYMENT: {
      SELECT_PAYMENT: 'Выберите способ оплаты',
      NO_PAYMENT_METHODS: 'Нет доступных методов оплаты',
      CONTACT_SUPPORT: 'Свяжитесь с поддержкой',
      PAYMENT_TIMEOUT: 'Платеж занял слишком много времени.',
      START_OVER: 'Вернитесь назад и попробуйте снова.',
      CREDITCARD: 'Кредитная карта',
      CHANGE_RECIPIENT: 'Изменить получателя платежа',
      SEND_TO_EMAIL: 'Отправить квитанцию на эл. почту',
      INVALID_TOKEN: 'Недопустимый токен',
      INVALID_SHOP_ORDER_ID: 'Недействительный OrderID',
      WAITING_FOR_PAYMENT: 'Сумма к оплате',
      PAYMENT_COMPLETE: 'Оплата завершена',
      CLOSE_THIS_PAGE: 'Вы можете закрыть эту страницу',
      PAYMENT_ERROR: 'Не удалось выполнить оплату',
      LOADING_SWISH: 'Пожалуйста, подождите, загружается Swish...',
      TOTAL_INC_VAT: 'Общая сумма с учетом НДС',
      PAYMENT_FAILED: 'Платеж не прошел',
      PAYMENT_SUCCESS: 'Оплата прошла успешно!',
      WAITING: 'Ожидание оплаты...',
      TRY_LATER: 'Попробуйте позже',
      REVIEW: 'Просмотреть заказ',
      ITEMS: 'Товары',
      ITEM: 'Товар',
      PRICE: 'Цена',
      TOTAL: 'Итого',
      DISCOUNT: 'Скидка',
      INVALID_ACTION: 'Недопустимый метод оплаты',
      OPEN_SWISH: 'Оплатить сейчас',
      SCAN_QR_INFO: 'Откройте приложение Swish и отсканируйте код ниже',
      APP_WONT_OPEN_CLICK_HERE: 'Translate: App wont open? Click here',
      INVOICE: 'Счет',
      LOADING_SHOP_ORDER: 'Translate: Loading order'
    }
  }

};
